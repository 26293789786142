<template>
  <v-container fluid style="background-color: #f4f4f5 !important; height: 100%" class="pa-0 ma-0">
    <v-app-bar v-if="$vuetify.breakpoint.mdAndDown" height="100" color="#491655" dark elevation="0" app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <h4>Menu</h4>
    </v-app-bar>
    <v-card v-if="$vuetify.breakpoint.mdAndDown" width="256">
      <v-navigation-drawer v-model="drawer" floating app :mini-variant.sync="mini" color="#491655"
        class="no-border shadow">
        <v-img :src="require('../assets/mfw.png')" contain max-height="150" max-width="150" class="px-0 fill-height"
          style="position: relative; left: 60px" @click="$router.push({ name: '/admindboard' })" />
        <v-card-text style="color: white !important">
          <v-list-item two-line>
            <v-avatar style="position: relative; right: 28px">
              <v-img :src="require('../assets/admin.svg')" />
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 12px; color: white">
                Welcome Back!
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap mt-1" style="font-size: 18px; color: white">
                Hello, <strong>Admin</strong>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="(item, itemindex) in navigation" :key="itemindex" link @click="$router.push(item.url)">
              <v-list-item-icon>
                <v-img :src="item.icon" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title :style="item.style">{{
                  item.label
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider style="width: 80%;" class="px-5 ml-5 mt-4"></v-divider>
        <div>
          <v-list>
            <v-list-item link @click="mini = !mini">
              <v-list-item-icon>
                <v-img :src="require('../assets/collapse.svg')" />
              </v-list-item-icon>
              <v-list-item-content style="color: white; font-size: 16px; font-weight: normal">
                Collapse
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <v-divider />
        <v-img :src="require('../assets/mfw.png')" contain max-height="150" max-width="150" class="px-0 fill-height"
          style="position: relative; left: 60px" />
        <template v-slot:append>
          <div>
            <v-list-item-group>
              <v-list>
                <v-list-item v-for="(item, itemindex) in navigation2" :key="itemindex" link @click="
                  item.url.name == '' ? logout_fn() : $router.push(item.url)
                  ">
                  <v-list-item-icon>
                    <v-img :src="item.icon" />
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title :style="item.style">{{
                      item.label
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-item-group>
          </div>
        </template>
      </v-navigation-drawer>
      <!-- collapse -->
    </v-card>
    <!-- non mobile -->
    <v-card v-else width="256">
      <v-navigation-drawer floating app :permanent="!$vuetify.breakpoint.smAndDown" :mini-variant.sync="mini"
        color="#491655" class="no-border shadow">
        <v-img :src="require('../assets/mfw.png')" contain max-height="150" max-width="150" class="px-0 fill-height"
          style="position: relative; left: 60px" @click="$router.push({ name: '/admindboard' })" />
        <v-card-text style="color: white !important">
          <v-list-item two-line>
            <v-avatar style="position: relative; right: 28px">
              <v-img :src="require('../assets/admin.svg')" />
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 12px; color: white">
                Welcome Back!
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap mt-1" style="font-size: 18px; color: white">
                Hello, <strong>Admin</strong>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="(item, itemindex) in navigation" :key="itemindex" link @click="$router.push(item.url)">
              <v-list-item-icon>
                <v-img :src="item.icon" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title :style="item.style">{{
                  item.label
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider style="width: 80%;" class="px-5 ml-5 mt-4"></v-divider>
        <div>
          <v-list>
            <v-list-item link @click="mini = !mini">
              <v-list-item-icon>
                <v-img :src="require('../assets/collapse.svg')" />
              </v-list-item-icon>
              <v-list-item-content style="color: white; font-size: 16px; font-weight: normal">
                Collapse
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <v-divider />
        <!-- <v-img
              :src="require('../assets/mfw.png')"
              contain
              max-height="150"
              max-width="150"
              class="px-0 fill-height"
              style="position: relative; left: 60px"
            /> -->
        <template v-slot:append>
          <div>
            <v-list-item-group>
              <v-list>
                <v-list-item v-for="(item, itemindex) in navigation2" :key="itemindex" link @click="
                  item.url.name == '' ? logout_fn() : $router.push(item.url)
                  ">
                  <v-list-item-icon>
                    <v-img :src="item.icon" />
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title :style="item.style">{{
                      item.label
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-item-group>
          </div>
        </template>
      </v-navigation-drawer>
      <!-- collapse -->
    </v-card>
    <main class="main">
      <slot />
    </main>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    // Navigation
  },
  data: () => ({
    mini: false,
    drawer: false,
    navigation: [
      {
        icon: require("../assets/home.svg"),
        label: "Dashboard",
        style: "color: white; font-size: 16px; font-weight: normal;",
        class: "ml-5",
        url: {
          name: "/admin_dashboard",
        },
      },
      {
        icon: require("../assets/client.svg"),
        label: "Funeral Directors",
        style: "color: white; font-size: 16px; font-weight: normal;",
        class: "ml-5",
        url: {
          name: "/admin_funeral_directors",
        },
      },
      {
        icon: require("../assets/client.svg"),
        label: "Solicitors",
        style: "color: white; font-size: 16px; font-weight: normal;",
        class: "ml-5",
        url: {
          name: "Solicitors",
        },
      },
      {
        icon: require("../assets/client.svg"),
        label: "Charities",
        style: "color: white; font-size: 16px; font-weight: normal;",
        class: "ml-5",
        url: {
          name: "Charities",
        },
      },
      {
        icon: require("../assets/email.svg"),
        label: "Emails",
        style: "color: white; font-size: 16px; font-weight: normal;",
        url: {
          name: "/emails",
        },
      },
      {
        icon: require("../assets/fd_client.svg"),
        label: "Clients",
        style: "color: white; font-size: 16px; font-weight: normal;",
        url: {
          name: "/admin_clients",
        },
      },
      /*{
        icon: require("../assets/survey.svg"),
        label: "Checklist",
        style: "color: white; font-size: 16px; font-weight: normal;",
        url: {
          name: "/fd_admin_survey",
        },
      },*/
      {
        icon: require("../assets/fd_client.svg"),
        label: "Visitors",
        style: "color: white; font-size: 16px; font-weight: normal;",
        url: {
          name: "/visitors",
        },
      },
      {
        icon: require("../assets/survey.svg"),
        label: "Funeral Notice",
        style: "color: white; font-size: 16px; font-weight: normal;",
        url: {
          name: "/admin_funeral_notice",
        },
      },
      {
        icon: require("../assets/survey.svg"),
        label: "Documents",
        style: "color: white; font-size: 16px; font-weight: normal;",
        url: {
          name: "/admin_documents",
        },
      },
      // {
      //     icon:       require('../assets/email.svg'),
      //     label:      'Emails',
      //     style:      'color: white; font-size: 16px; font-weight: normal;',
      //     url:        {
      //         name:   '/contact'
      //     }
      // }
    ],
    navigation2: [
      //   {
      //     icon: require("../assets/settings.svg"),
      //     label: "Settings",
      //     style: "color: white; font-size: 16px; font-weight: normal;",
      //     url:        {
      //         name:   '/organisation'
      //     }
      //   },
      //   {
      //     icon: require("../assets/help.svg"),
      //     label: "Help",
      //     style: "color: white; font-size: 16px; font-weight: normal;",
      //   },
      {
        icon: require("../assets/logout.svg"),
        label: "Logout",
        style: "color: white; font-size: 16px; font-weight: normal;",
        url: {
          name: "",
        },
      },
    ],
  }),
  computed: {
    ...mapGetters({
      get_user: 'auth/get_user',
      get_companies: 'auth/get_companies',
      get_selected_company: 'auth/get_selected_company',
    }),
  },
  methods: {
    async logout_fn() {
      await this.$axios.post('/auth/logout')
      window.localStorage.clear()
      this.$router.push({ name: '/' })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        this.drawer = true
        return false
      }
    }
  },
};
</script>
<style scoped>
.no-border {
  border-radius: 0px !important;
}

.shadow {
  box-shadow: 0 2px 10px 0px rgb(0 0 0 / 20%);
}

::v-deep ::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.v-list-item--active {
  border-left: 0.2rem solid #76bdff;
}
</style>
