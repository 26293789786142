import Survey from '../SurveyComponent.vue'
import CreateSurvey from '../CreateSurveyComponent.vue'
// import CreateSurvey2 from '../CreateSurveyComponent2.vue'

export default [
    {
        path: '/fd_admin_survey',
        name: '/fd_admin_survey',
        component: Survey,
        meta: {
            layout: 'admin-layout'
        }
    },
    {
        path: '/create-survey',
        name: '/create-survey',
        component: CreateSurvey,
        meta: {
            layout: 'admin-layout'
        }
    }
]