import Vue from 'vue'
export default{
    namespaced: true,
    state:{
        survey: [],
        active_survey: [],
        action_index: null,
        active_section: 0,
        survey_creation: [{
            id:     1,
            title: 'Section Title',
            title_edit: false,
            multiple_answer: false,
            data: [{
                id:     1,
                question: '',
                question_edit: false,
                multiple_answer: false,
                dd_reselect: false,
                data: [{
                    action: [],
                    questions: [],
                    images_q: [],
                    image: [],
                    follow_ups: [],
                    file_upload: [],
                    answer: [],
                    answer_image: []
                }]
            }]
        }],
        current_index: null,
        question_index: null,
        reference_question: {},
        title: null,
        is_title_edit: false,
        cover_photo: null,
        is_cover_photo: false
    },
    actions: {
        async fetch_survey({commit}){
            await Vue.axios.get('/auth/admin/get_survey')
            .then(({data}) => {
                commit('set_survey', data.data)
                commit('set_active_survey', data.active_survey)
            })
        },
        set_action_index({commit}, payload){
            commit('set_action_index', payload)
        },
        set_title({commit}, payload){
            commit('set_title', payload)
        },
        set_is_title_edit({commit}, payload){
            commit('set_is_title_edit', payload)
        },
        set_cover_photo({commit}, payload){
            commit('set_cover_photo', payload)
        },
        set_is_cover_photo({commit}, payload){
            commit('set_is_cover_photo', payload)
        },
        set_survey_creation({commit}, payload){
            if(payload === 1){
                const data = {
                    id:     1,
                    title:  'Section Title',
                    title_edit: false,
                    multiple_answer: false,
                    data: [{
                        id: 1,
                        data: [{
                            action: [],
                            questions: [],
                            images_q: [],
                            image: [],
                            follow_ups: [],
                            file_upload: [],
                            answer: [],
                            answer_image: [],
                        }],
                        multiple_answer: false,
                        question: '',
                        question_edit: false,
                        dd_reselect: false,
                    }]
                }
                // console.log(data)
                commit('set_survey_creation', data)
                return
            }
        },
        set_active_section({commit}, payload){
            commit('set_active_section', payload)
        },
        set_add_question({commit}, payload){
            commit('set_survey_question', payload)
        },
        set_add_question_title_and_description({commit}, payload){
            commit('set_add_question_title_and_description', payload)
        },
        set_question_title({commit}, payload){
            commit('set_question_title', payload)
        },
        set_question_title_actual({commit}, payload){
            commit('set_question_title_actual', payload)
        },
        set_reselect_dd({commit}, payload){
            commit('set_reselect_dd', payload)
        },
        set_remove_question({commit}, payload){
            commit('set_remove_question', payload)
        },
        set_toggle_mutliple_answer({commit}, payload){
            commit('set_toggle_mutliple_answer', payload)
        },
        set_current_index({commit}, payload){
            commit('set_current_index', payload)
        },
        set_image_to_question_data({commit}, payload){
            commit('set_image_to_question_data', payload)
        },
        set_add_follow_up_question({commit}, payload){
            commit('set_add_follow_up_question', payload)
        },
        set_remove_follow_up_image({commit}, payload){
            commit('set_remove_follow_up_image', payload)
        },
        set_add_question_to_data({commit}, payload){
            commit('set_add_question_to_data', payload)
        },
        add_option_question({commit}, payload){
            commit('add_option_question', payload)
        },
        set_add_option({commit}, payload){
            commit('set_add_option', payload)
        },
        set_question({commit}, payload){
            commit('set_question', payload)
        },
        set_question_index({commit}, payload){
            commit('set_question_index', payload)
        },
        set_question_image({commit}, payload){
            commit('set_question_image', payload)
        },
        set_reference_question({commit}, payload){
            commit('set_reference_question', payload)
        },
        set_add_follow_up_questions({commit}, payload){
            commit('set_add_follow_up_questions', payload)
        },
        set_add_follow_up_actual_question({commit}, payload){
            commit('set_add_follow_up_actual_question', payload)
        },
        set_multiple_file_upload({commit}, payload){
            commit('set_multiple_file_upload', payload)
        },
        set_update_section_title({commit}, payload){
            commit('set_update_section_title', payload)
        },
        set_update_section_r_title({commit}, payload){
            commit('set_update_section_r_title', payload)
        }
    },
    mutations: {
        set_survey(state, payload){
            state.survey = [...payload]
        },
        set_active_survey(state, payload){
            state.active_survey = [...payload]
        },
        set_data_empty(state){
            state.active_section = 0,
            state.survey_creation = [{
                id:     1,
                title: 'Section Title',
                title_edit: false,
                multiple_answer: false,
                data: [{
                    id:     1,
                    question: '',
                    question_edit: false,
                    multiple_answer: false,
                    dd_reselect: false,
                    data: [{
                        action: [],
                        questions: [],
                        images_q: [],
                        image: [],
                        follow_ups: [],
                        file_upload: [],
                        answer: [],
                        answer_image: []
                    }]
                }]
            }],
            state.current_index = null
            state.question_index = null
            state.reference_question = []
            state.title = null
            state.is_title_edit = false
            state.cover_photo = null
            state.is_cover_photo = false
        },
        set_action_index(state, payload){
            state.action_index = payload
        },
        set_title(state, payload){
            state.title = payload
            state.is_title_edit = false
        },
        set_is_title_edit(state, payload){
            state.is_title_edit = payload
        },
        set_cover_photo(state, payload){
            state.cover_photo = payload
            state.is_cover_photo = false
        },
        set_is_cover_photo(state, payload){
            state.is_cover_photo = payload
        },
        set_question_index(state, payload){
            state.question_index = payload
        },
        set_survey_creation(state, payload){
            state.survey_creation = [...state.survey_creation, payload]
            // state.survey_creation = payload
        },
        set_active_section(state, payload){
            state.active_section = payload
        },
        set_reselect_dd(state, payload){
            state.survey_creation[payload.active_section].data[payload.question_index].dd_reselect = true
        },
        set_survey_question(state, payload){
            if(payload.reference === 2){
                const questions1 = state.survey_creation[payload.active_section]
                let add_question = {
                    id:     questions1.id + 1,
                    question: '',
                    question_edit: false,
                    multiple_answer: false,
                    dd_reselect: false,
                    data: [{
                        action: {
                            id: 99,
                            value: 'title and desciption'
                        },
                        questions: [],
                        images_q: [],
                        image: [],
                        follow_ups: [],
                        file_upload: [],
                        answer: [],
                        answer_image: []
                    }]
                }
                questions1.data.push(add_question)
                return
            }
            if(payload.reference === 1){
                const questions = state.survey_creation[payload.active_section]
                let add_question = {
                    id:     questions.id + 1,
                    question: '',
                    question_edit: false,
                    multiple_answer: false,
                    dd_reselect: false,
                    data: [{
                        action: [],
                        questions: [],
                        images_q: [],
                        image: [],
                        follow_ups: [],
                        file_upload: [],
                        answer: [],
                        answer_image: []
                    }]
                }
                questions.data.push(add_question)
                return
            }
        },
        set_add_question_title_and_description(state, payload){
            const questions = state.survey_creation[payload]
            let add_question = {
                id:     questions.id + 1,
                question: '',
                question_edit: false,
                multiple_answer: false,
                dd_reselect: false,
                data: [{
                    action: [],
                    questions: [],
                    images_q: [],
                    image: [],
                    follow_ups: [],
                    file_upload: [],
                    answer: [],
                    answer_image: []
                }]
            }
            questions.data.push(add_question)
        },
        set_question_title(state, payload){
            state.survey_creation[payload.active_section].data[payload.question_index].question_edit = true
        },
        set_question_title_actual(state, payload){
            state.survey_creation[payload.active_section].data[payload.question_index].question = payload.title
            state.survey_creation[payload.active_section].data[payload.question_index].question_edit = false
        },
        set_remove_question(state, payload){
            state.survey_creation[payload.active_section].data.splice(payload.question_index, 1)
        },
        set_toggle_mutliple_answer(state, payload){
            // state.survey_creation[payload.active_section].data[payload.question_index][0].mutliple_answer
            state.survey_creation[payload.active_section].data[payload.question_index].multiple_answer
        },
        set_current_index(state, payload){
            state.current_index = payload
        },
        set_image_to_question_data(state, payload){
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].image = []
            let data = {
                image:      payload.image,
                question:   payload.question
            }
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].image.push(data)
        },
        set_add_follow_up_question(state, payload){
            state.survey_creation[payload.active_section].data[payload.question_index][0].images[payload.image_index].has_follow_up = true
        },
        set_remove_follow_up_image(state, payload){
            // state.survey_creation[payload.active_section].data[payload.question_index][0].images[payload.image_index].has_follow_up = false
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].follow_ups.splice(payload.follow_up_index, 1)
        },
        set_add_question_to_data(state, payload){
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].action = []
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].action = payload.action
            state.survey_creation[payload.active_section].data[payload.question_index].dd_reselect = false
        },
        add_option_question(state, payload){
            const id = state.survey_creation[payload.active_section].data[payload.question_index].data[0].questions.length
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].questions.push({
                qq: '',
                id: id + 1,
                edit: false
            })
        },
        set_add_option(state, payload){
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].questions[payload.option_index].edit = true
        },
        set_question(state, payload){
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].questions[payload.qq_index].qq = payload.question
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].questions[payload.qq_index].edit = false
        },
        set_reference_question(state, payload){
            state.reference_question = payload
        },
        set_question_image(state, payload){
            // state.reference_question = payload
            let data = {
                image:              payload.image,
                qq:                 payload.reference_name,
                ref_id:             payload.r_question_index
            }
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].images_q.push(data)
        },
        set_add_follow_up_questions(state, payload){
            let data = {
                qq:                 payload.image,
                ref_id:             payload.ref_id,
                follow_up_question: ''
            }
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].follow_ups.push(data)
        },
        set_add_follow_up_actual_question(state, payload){
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].follow_ups[payload.follow_up_index].follow_up_question = payload.followupquestion
        },
        set_multiple_file_upload(state, payload){
            state.survey_creation[payload.active_section].data[payload.question_index].data[0].allow_multiple_file
        },
        set_update_section_title(state, payload){
            const data = state.survey_creation[payload.active_section].title_edit
            state.survey_creation[payload.active_section].title_edit = data ? false : true
        },
        set_update_section_r_title(state, payload){
            state.survey_creation[payload.active_section].title = payload.title
            state.survey_creation[payload.active_section].title_edit = false
        }
    },
    getters: {
        get_action_index : state => state.action_index,
        get_survey_creation : state => state.survey_creation,
        get_active_section : state => state.active_section,
        get_questions : state => state.survey_creation,
        get_current_index : state => state.current_index,
        get_question_index : state => state.question_index,
        get_reference_question: state => state.reference_question,
        get_title : state => state.title,
        get_is_title_edit : state => state.is_title_edit,
        get_cover_photo : state => state.cover_photo,
        get_is_cover_photo : state => state.is_cover_photo,
        get_survey : state => state.survey,
        get_active_survey : state => state.active_survey
    }
}