<template>
  <v-container fluid>
    <v-row justify="center">
      <v-dialog :value="open_profile" persistent :max-width="isMobile() ? '109%' : '80%'">
        <v-card class="pa-5" elevation="2" v-if="get_profile.get_sharer_info">
          <v-card-title>
            <v-btn text style="top:-10px;" @click="close_profile()">
              <h4>Close</h4>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title>
            <v-list-item two-line class="pa-5">
              <v-avatar v-if="get_profile.get_sharer_info.profile_picture === null" size="75" color="blue"
                style="position: relative; right: 18px;top:-10px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                <strong style="color:white;font-size:22px;">{{ get_profile.get_sharer_user_info.name[0] }}</strong>
              </v-avatar>
              <v-avatar v-else size="75" color="blue"
                style="position: relative; right: 18px;top:-10px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                <v-img contain :src="`${api_url}${get_profile.get_sharer_info.profile_picture}`" />
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title style="color:#494949" class="mb-5">
                  <h4 style="word-break: break-word;font-size: 25px;white-space: pre-line;">{{
                    get_profile.get_sharer_user_info.name }}</h4>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-title>
          <v-card-subtitle>
            <v-row class="mt-10">
              <v-col :cols="isMobile() ? 12 : 6">
                <v-row>
                  <v-col cols="4"> Funeral Director </v-col>
                  <v-col cols="8" class="float-right" style="text-align: right">
                    <strong>{{ get_profile.get_sharer_user_info.name }}</strong>
                  </v-col>
                  <v-col cols="4"> Contact Number </v-col>
                  <v-col cols="8" class="float-right" style="text-align: right">
                    <strong v-if="get_profile.mobile === null"> N/A </strong>
                    <strong>{{ get_profile.get_sharer_user_info.mobile }}</strong>
                  </v-col>
                  <v-col cols="4"> Email </v-col>
                  <v-col cols="8" class="float-right" style="text-align: right">
                    <strong v-if="get_profile.email === null"> N/A </strong>
                    <strong>{{ get_profile.get_sharer_user_info.email }}</strong>
                  </v-col>
                  <v-col cols="4"> Address </v-col>
                  <v-col cols="8" class="float-right" style="text-align: right">
                    <strong v-if="get_profile.address === null"> N/A </strong>
                    <strong>
                      {{ get_profile.get_sharer_user_info.address }}
                      {{ get_profile.get_sharer_user_info.county }}
                      {{ get_profile.get_sharer_user_info.town }}
                      {{ get_profile.get_sharer_user_info.eircode }}
                    </strong>
                  </v-col>
                </v-row>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <v-row>
                  <v-col cols="4"> Age </v-col>
                  <v-col cols="8" class="float-right" style="text-align: right">
                    <strong v-if="get_profile.get_sharer_user_info.age !== null">{{ get_profile.get_sharer_user_info.age
                    }}</strong>
                    <strong v-else>N/A</strong>
                  </v-col>
                  <v-col cols="4"> Gender </v-col>
                  <v-col cols="8" class="float-right" style="text-align: right">
                    <strong v-if="get_profile.get_sharer_user_info.get_gender !== null">{{
                      get_profile.get_sharer_user_info.get_gender.name
                    }}</strong>
                    <strong v-else> N/A </strong>
                  </v-col>
                  <v-col cols="4"> Birthday </v-col>
                  <v-col cols="8" class="float-right" style="text-align: right">
                    <strong v-if="get_profile.get_sharer_user_info.birthday === null"> N/A </strong>
                    <strong v-else>{{
                      $date(get_profile.get_sharer_user_info.birthday).format("MMMM DD, YYYY")
                    }}</strong>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-row v-for="(item, index) in additional_wishes" :key="index" class="px-5 py-5">
            <v-col cols="12">
              <h2 style="color:#172C54">{{ get_profile.get_sharer_user_info.firstname }}'s {{ item.title }}
                <v-divider style="width: 100%; background-color:#E9F0FF"></v-divider>
              </h2>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6" v-for="(survey_item, survey_index) in wishes_details[index].data"
              :key="survey_index">
              <v-card class="mb-5" elevation="0">
                <v-card-title>
                  <label style="word-break: break-word;">{{ survey_item.question }}</label>
                </v-card-title>
                <v-card-subtitle>
                  <i v-if="get_profile.get_sharer_user_info.get_survey.data[wishes_details[index].name][survey_item.answer] == null">no answer
                    yet</i>
                  <label v-else>
                    <v-chip v-if="get_profile.get_sharer_user_info.get_survey.data[wishes_details[index].name][survey_item.answer] != null"
                      style="white-space:pre-wrap;height:auto;">
                      <strong style="word-break: break-word;">{{
                        get_profile.get_sharer_user_info.get_survey.data[wishes_details[index].name][survey_item.answer] }}</strong>
                    </v-chip>
                  </label>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
  },
  props: [
    'open_profile',
  ],
  data: () => ({
    api_url: null,
    additional_wishes: [
      { title: "Burial / Cremation Wishes", wish_index: "funeral_service" },
      { title: "Ceremony Wishes", index_name: "ceremony" },
      { title: "Wake / Viewing / Reposing Wishes", index_name: "wake" },
      { title: "Personal Wishes", index_name: "personal_wishes" },
    ],
    wishes_details: [
      {
        name: 'funeral_service',
        data: [
          {
            question: 'Burial or cremation?',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
          {
            question: 'Preferred location?',
            answer: 'answer',
            is_active: false,
            is_editable: true
          },
          {
            question: 'More details',
            answer: 'details',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'ceremony',
        data: [
          {
            question: 'Would you like your ceremony to be...',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
          {
            question: 'What kind of ceremony would you prefer?',
            answer: 'chosen',
            is_active: false,
            is_editable: true
          },
          {
            question: 'More details',
            answer: 'details',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'wake',
        data: [
          {
            question: 'Would you like to have a wake / public reposing?',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
          {
            question: 'Your preferred wake location',
            answer: 'choice',
            is_active: false,
            is_editable: true
          },
          {
            question: 'More details',
            answer: 'details',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'personal_wishes',
        data: [
          {
            question: 'Things that I want',
            answer: 'want',
            is_active: false,
            is_editable: true
          },
          {
            question: "Things that I don't want",
            answer: 'dontwant',
            is_active: false,
            is_editable: true
          },
          {
            question: "People to inform / invite",
            answer: 'people',
            is_active: false,
            is_editable: true
          },
          {
            question: "Details and speakers for eulogy",
            answer: 'eulogy',
            is_active: false,
            is_editable: true
          },
          {
            question: "Symbols of the life lived for a ceremony",
            answer: 'symbols',
            is_active: false,
            is_editable: true
          },
          {
            question: "People to carry the coffin",
            answer: 'coffin_carry',
            is_active: false,
            is_editable: true
          },
          {
            question: "After funeral requests - food / drinks reception",
            answer: 'reception',
            is_active: false,
            is_editable: true
          },
          {
            question: "How to look after ashes",
            answer: 'ashes',
            is_active: false,
            is_editable: true
          },
        ]
      }
    ]
  }),
  async mounted() {
  },
  async created() {
    this.api_url = process.env.VUE_APP_API_URL
  },
  computed: {
    ...mapGetters({
      get_profile: "client_notification/get_profile",
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit("auth/setMessage",
        { show: true, message: message },
        { root: 1 })
    },
    close_profile() {
      this.$emit('close_profile')
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
