import Vue from 'vue'

export default{
    namespaced: true,
    state: {
        shared_profiles: [],
        profile: {},
    },
    actions: {
        async fetch_shared_profiles({commit}){
            await Vue.axios.get('/auth/client/profile_share/get_shared_profiles')
            .then(({data}) => {
                commit('set_shared_profiles', data.data)
            })
        },
        set_profile({ commit }, payload) {
            commit('set_profile', payload)
        },
    },
    mutations: {
        set_shared_profiles(state, payload){
            state.shared_profiles = [...payload]
        },
        set_profile(state, payload) {
            state.profile = payload
        },
    },
    getters: {
        get_shared_profiles : state => state.shared_profiles,
        get_profile: state => state.profile,
    }
}