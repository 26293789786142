<template>
    <PaymentComponent v-if="user" :user="user" @refreshUser="refreshUser" :show_footer="false" />
</template>

<script>
import PaymentComponent from "../../module_client/payment/components/PaymentComponent.vue"
export default {
    components: {
        PaymentComponent
    },
    props: [],
    data: () => ({
        user: null,
    }),
    async mounted() {
        await this.getData(this.$router.currentRoute.params.token)
    },
    async created() {
    },
    computed: {
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getData(data) {
            await this.$axios.get('/non_auth/decode_encrypted_id', { data: data })
                .then(({ data }) => {
                    if (data.response) {
                        this.user = data.data
                        this.$store.dispatch('funeral_request/set_funeral_director', data.data.get_user_add_info.get_fd_info)
                    }
                })
        },
        async refreshUser() {
            await this.$axios.get('/non_auth/decode_encrypted_id', { data: this.$router.currentRoute.params.token })
                .then(({ data }) => {
                    if (data.response) {
                        this.user = data.data
                    }
                })
        },
    },
    watch: {},
};
</script>

<style scoped lang="scss">
</style>
