import CharityComponent from '../CharityComponent.vue'

export default [
    {
        path: '/charities',
        name: 'Charities',
        component: CharityComponent,
        meta: {
            layout: 'admin-layout'
        }
    },
]