<template>
    <v-container fluid style="height: 100%;" class="pa-0 ma-0">
        <v-app-bar color="#193B5B" dark height="150" app>
            <label v-if="get_user.name && $router.currentRoute.name != 'Create Will'" style="margin-left:5%;">
                Hi, {{ get_user.name }}
                <br>
                <v-btn text @click="logout()">Logout</v-btn>
            </label>
            <v-spacer />
            <v-img :src="require('../assets/logo.png')" contain max-height="150" max-width="150" />
        </v-app-bar>
        <slot />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data: () => ({
        api_url: null
    }),
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        })
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    async mounted() {
        if (this.get_user.id == null && this.$router.currentRoute.name == 'Edit Will') {
            this.logout()
        }
    },
    methods: {
        async logout() {
            await this.$axios.post('/auth/logout')
            window.localStorage.clear()
            this.$router.push({ name: '/' })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: inherit;
    height: inherit;
    margin-right: 15px;
    cursor: pointer;
    margin-top: 7px;
}
</style>