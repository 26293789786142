<template>
    <v-container fluid>
        <v-row
            justify="center" align="center"
        >
            <v-card
                width="90%"
                class="mt-5"
                elevation="0"
            >
                <v-card-title
                    class="text-h5"
                >
                    <v-img
                        :src="require('../../assets/survey_dark.svg')"
                        class="mr-3"
                        contain
                        max-width="35"
                        max-height="35"
                    />
                    <strong>Survey</strong>
                    <v-spacer/>
                    <!-- <v-btn
                        icon
                    >
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-btn> -->
                </v-card-title>
                <v-card-title>
                    <v-btn
                        text
                        style="textTransform: none !important;"
                        @click="$router.push({name: '/fd_admin_survey'})"
                    >
                        <v-img
                            :src="require('../../assets/chev_left_dark.svg')"
                            contain
                            max-width="25"
                            max-height=25
                        />
                        Back
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        text
                        style="textTransform: none !important;"
                    >
                        Preview
                        <v-img
                            :src="require('../../assets/eye_dark.svg')"
                            contain
                            max-width="25"
                            max-height=25
                            class="ml-2"
                        />
                    </v-btn>
                    <v-btn
                        dark
                        color="primary"
                        style="textTransform: none !important;"
                        @click="save_survey"
                    >
                        Save
                        <v-img
                            :src="require('../../assets/save_white.svg')"
                            contain
                            max-width="25"
                            max-height=25
                            class="ml-2"
                        />
                    </v-btn>
                </v-card-title>
            </v-card>
        </v-row>
        <v-row
            justify="center" align="center"
        >
            <v-card
                width="90%"
                class="mt-5"
                elevation="0"
                style="border-radius: 15px;"
            >
                <v-card-title>
                    <v-list-item two-line>
                        <v-img
                            v-if="get_cover_photo === null || get_cover_photo === ''"
                            :src="require('../../assets/survey_untitled_white.svg')"
                            contain
                            max-width="200"
                            max-height="200"
                        />
                        <v-img
                            v-else
                            :src="get_cover_photo.cover_photo"
                            contain
                            max-width="200"
                            max-height="200"
                        />
                    <v-list-item-content>
                    <v-list-item-title
                        class="ml-3"
                    >
                        <strong
                            v-if="(get_title === '' || get_title === null) && !get_is_title_edit"
                            @click="edit_title"
                        >Untitled Survey</strong>
                        <strong
                            v-else-if="!get_is_title_edit"
                            @click="edit_title"
                        >{{get_title}}</strong>
                        <v-text-field
                            v-if="get_is_title_edit"
                            label="add title"
                            style="cursor: pointer;"
                            dense
                            v-model="title"
                            @keyup.enter="save_title"
                        >
                            <template v-slot:append>
                                <label>Press ENTER to save</label>
                            </template>
                        </v-text-field>
                    </v-list-item-title>
                    <v-list-item-subtitle
                        class="ml-3"
                    >
                        <v-btn
                            v-if="!get_is_cover_photo"
                            text
                            small
                            rounded
                            outlined
                            style="textTransform: none !important;"
                            @click="add_cover_photo"
                        >
                            <v-icon>mdi-plus</v-icon>
                            Add cover photo
                        </v-btn>
                        <v-file-input
                            v-else
                            v-model="cover_photo"
                            @change="change_cover_photo"
                        >
                        </v-file-input>
                    </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                </v-card-title>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                width="90%"
                class="mt-5"
                eleavtion="0"
                style="border-radius: 15px;"
            >
                <v-card-text>
                    <v-row>
                        <v-col
                            cols="3"
                        >
                            <v-list>
                                <v-list-item-group
                                    :value="get_active_section"
                                    active-class="border"
                                    color="indigo"
                                >
                                    <v-list-item
                                        v-for="(item, i) in get_survey_creation"
                                        :key="i"
                                        class="mt-5 pt-3 pb-3"
                                        @click="set_active_section(i)"
                                        style="box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1) border-radius: 4px;"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-if="!item.title_edit"
                                            >
                                                {{i + 1}}. {{item.title}}
                                            </v-list-item-title>
                                            <v-list-item-title
                                                v-else
                                            >
                                                <v-text-field
                                                    filled
                                                    label="Write your title here..."
                                                    v-model="section_title"
                                                    @keyup.enter="update_section_title"
                                                >
                                                    <template v-slot:prepend>
                                                        <label
                                                            style="position; relative; bottom: 5px;"
                                                        >{{i + 1}}</label>
                                                    </template>
                                                    <template v-slot:append>
                                                        <label>Press ENTER to save</label>
                                                    </template>
                                                </v-text-field>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action
                                            v-if="!item.title_edit"
                                        >
                                            <v-btn
                                                icon
                                                @click="edit_section_title_fn"
                                            >
                                                <v-icon small>mdi-pen</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <div>
                                <v-list>
                                    <v-list-item-group>
                                        <v-list>
                                            <v-list-item
                                                @click="add_section_fn"
                                                style="border: 2px dashed #D0D0D0; background-color: white !important;"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title>Add Section</v-list-item-title>
                                                </v-list-item-content>
                                                
                                            </v-list-item>
                                        </v-list>
                                    </v-list-item-group>
                                </v-list>
                            </div>
                        </v-col>
                        <v-col
                            cols="9"
                            style="background-color: #E9E9E9;"
                            class="pt-10 pl-10 pr-10 pb-10"
                        >
                            <v-row
                                justify="center"
                                align="center"
                            >
                                <v-card
                                    v-for="(qq, qqindex) in get_questions.data"
                                    :key="qqindex"
                                    width="90%"
                                    style="border-radius: 8px;"
                                    elevation="0"
                                    class="mb-5"
                                    @click="set_question_index(qqindex)"
                                    v-ripple="false"
                                >
                                    <v-card-title>
                                        <label>
                                            {{qqindex + 1}}
                                        </label>
                                        <label
                                            v-if="qq.question === '' && !qq.question_edit && qq.data[0].action.id !== 99"
                                            class="ml-3"
                                            style="cursor: pointer;"
                                            @click="add_question_title(qqindex)"
                                        >
                                            Click to add a question
                                        </label>
                                        <label
                                            v-else-if="qq.question !== '' && !qq.question_edit"
                                            class="ml-3"
                                            style="cursor: pointer;"
                                            @click="add_question_title(qqindex)"
                                        >
                                            {{qq.question}}
                                        </label>
                                        <v-text-field
                                            v-if="qq.question_edit"
                                            placeholder="Write your question here..."
                                            @keyup.enter="addtitlefn(qqindex)"
                                            v-model="qq_question"
                                            class="ml-3"
                                        >
                                            <template v-slot:append>
                                                <label>Press ENTER to save</label>
                                            </template>
                                        </v-text-field>
                                        <v-spacer/>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    color="#347CE9"
                                                    style="textTransform: none !important;"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <v-img
                                                        :src="require('../../assets/ellipsis.svg')"
                                                        max-width="20"
                                                        max-height="20"
                                                    />
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                    style="cursor: pointer;"
                                                    @click="remove_question_item(qqindex)"
                                                >
                                                    <v-list-item-title>
                                                        Remove Question
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    style="cursor: pointer;"
                                                    @click="add_image_menu_option(qqindex)"
                                                >
                                                    <v-list-item-title>
                                                        Add Image
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-card-title>
                                    <v-card-title>
                                        <v-col
                                            cols="3"
                                        >
                                            <v-autocomplete
                                                v-if="Object.keys(qq.data[0].action).length === 0 && !qq.dd_reselect"
                                                :items="dditems"
                                                item-value="id"
                                                item-text="value"
                                                outlined
                                                dense
                                                placeholder="Choose an option"
                                                @change="dd_item_fn(qqindex)"
                                                v-model="dd_item"
                                                return-object
                                            >
                                            </v-autocomplete>
                                            <label
                                                v-else-if="Object.keys(qq.data[0].action).length > 0 && !qq.dd_reselect"
                                                style="cursor: pointer;"
                                                @click="reselect_dd(qqindex)"
                                            >
                                                {{qq.data[0].action.value}}
                                            </label>
                                            <v-autocomplete
                                                v-else
                                                :items="dditems"
                                                item-value="id"
                                                item-text="value"
                                                outlined
                                                dense
                                                placeholder="Choose an option"
                                                @change="dd_item_fn(qqindex)"
                                                v-model="dd_item"
                                                return-object
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-spacer/>
                                        <v-switch
                                            v-if="Object.keys(qq.data[0].action).length > 0 && qq.data[0].action.id === 1"
                                            v-model="qq.data[0].mutliple_answer"
                                            style="position: relative; bottom:15px;"
                                            dense
                                            @change="toggle_multiple_answer(qqindex)"
                                            class="text-right"
                                            label="Allow multiple answer?"
                                        ></v-switch>
                                    </v-card-title>
                                    <!-- multiple start -->
                                    <div
                                        v-if="Object.keys(qq.data[0].action).length > 0 && qq.data[0].action.id === 1"
                                    >
                                        <v-card-title
                                            v-if="qq.data[0].image.length > 0"
                                        >
                                            <v-img
                                                :src="qq.data[0].image[0].image"
                                                contain
                                            />
                                        </v-card-title>
                                        <v-card-title>
                                            <v-row>
                                                <v-col
                                                    v-for="(qqimage, qqimageindex) in qq.data[0].images_q"
                                                    :key="qqimageindex"
                                                    cols="4"
                                                >
                                                    <v-card
                                                        width="100%"
                                                        elevation="0"
                                                    >
                                                        <v-card-title
                                                            style="background: #FAFAFA !important;"
                                                        >
                                                            <v-img
                                                                :src="qqimage.image"
                                                                contain
                                                                style="
                                                                    width: 250px;
                                                                    max-height: 250px;
                                                                "
                                                            />
                                                        </v-card-title>
                                                        <v-card-title>
                                                            <small>{{qqimage.qq}}</small>
                                                            <v-spacer/>
                                                            <v-menu offset-y>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        icon
                                                                        color="#347CE9"
                                                                        style="textTransform: none !important;"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-img
                                                                            :src="require('../../assets/ellipsis.svg')"
                                                                            max-width="20"
                                                                            max-height="20"
                                                                        />
                                                                    </v-btn>
                                                                </template>
                                                                <v-list>
                                                                    <v-list-item
                                                                        style="cursor: pointer;"
                                                                        @click="add_follow_up_questions(qqimage)"
                                                                    >
                                                                        <v-list-item-title>
                                                                            Add follow up question
                                                                        </v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </v-card-title>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <!-- follow ups -->
                                        <v-card-title
                                            v-if="qq.data[0].follow_ups.length > 0"
                                        >
                                            <small>Follow up question</small>
                                            <v-card
                                                width="100%"
                                                v-for="(followup, followupindex) in qq.data[0].follow_ups"
                                                :key="followupindex"
                                                style="background: #D0D0D0 !important; border: #FAFBFF !important; border-radius: 8px;"
                                                class="mb-5"
                                                elevation="0"
                                            >
                                                <v-card-title>
                                                    <small>If answer: </small>
                                                    <v-chip class="ml-3" color="#FAFBFF">{{followup.qq}}</v-chip>
                                                    <v-spacer/>
                                                    <v-btn
                                                        text
                                                        style="textTransform: none !important;"
                                                        @click="remove_follow_up(followupindex)"
                                                    >
                                                        Remove
                                                        <v-img
                                                            :src="require('../../assets/delete_dark.svg')"
                                                        />
                                                    </v-btn>
                                                </v-card-title>
                                                <v-card-title>
                                                    <v-text-field
                                                        v-if="followup.follow_up_question === ''"
                                                        label="Write a question here..."
                                                        @keyup.enter="save_follow_up_question(followupindex)"
                                                        v-model="follow_up_question"
                                                    >
                                                        <template v-slot:append>
                                                            <label>Press ENTER to save</label>
                                                        </template>
                                                    </v-text-field>
                                                    <small
                                                        v-else-if="followup.follow_up_question !== ''"
                                                    >
                                                        Question: {{followup.follow_up_question}}
                                                    </small>
                                                </v-card-title>
                                            </v-card>
                                        </v-card-title>
                                        <!-- end follow ups -->
                                        <v-card-title>
                                            <v-card
                                                v-for="(qqq, qqqindex) in qq.data[0].questions"
                                                :key="qqqindex"
                                                width="100%"
                                                class="mb-5"
                                                elevation="0"
                                                style="border-radius: 8px; border: 1px solid black;"
                                            >
                                                <v-card-title>
                                                    <small
                                                        v-if="qqq.qq === '' && !qqq.edit"
                                                        style="cursor: pointer;"
                                                        @click="add_question_option_fn(qqindex, qqqindex)"
                                                    >
                                                        click here to add an option
                                                    </small>
                                                    <small
                                                        v-else-if="qqq.qq !== '' && !qqq.edit"
                                                        style="cursor: pointer;"
                                                        @click="add_question_option_fn(qqindex, qqqindex)"
                                                    >
                                                        {{qqq.id}} {{qqq.qq}}
                                                    </small>
                                                    <v-text-field
                                                        v-else
                                                        placeholder="Write your question here..."
                                                        :value="qqq.qq"
                                                        v-model="question"
                                                        @keyup.enter="save_question(qqindex, qqqindex)"
                                                    >
                                                        <template v-slot:append>
                                                            <label>Press ENTER to save</label>
                                                        </template>
                                                    </v-text-field>
                                                    <v-spacer/>
                                                    <v-menu offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                icon
                                                                color="#347CE9"
                                                                style="textTransform: none !important;"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                                <v-img
                                                                    :src="require('../../assets/ellipsis.svg')"
                                                                    max-width="20"
                                                                    max-height="20"
                                                                />
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <v-list-item
                                                                style="cursor: pointer;"
                                                                @click="add_image_question(qqindex, qqqindex, qqq)"
                                                            >
                                                                <v-list-item-title>
                                                                    Add Image
                                                                </v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </v-card-title>
                                            </v-card>
                                        </v-card-title>
                                        <v-card-title>
                                            <v-btn
                                                text
                                                outlined
                                                @click="add_option_question_fn(qqindex)"
                                            >
                                                <v-img
                                                    :src="require('../../assets/plus.svg')"
                                                    contain
                                                    class="mr-1"
                                                    width="20"
                                                    height="20"
                                                />
                                                add option
                                            </v-btn>
                                        </v-card-title>
                                    </div>
                                    <!-- end multiple -->
                                    <!-- Short Answer -->
                                    <div
                                        v-if="Object.keys(qq.data[0].action).length > 0 && qq.data[0].action.id === 2"
                                    >
                                        <v-card-title
                                            v-if="qq.data[0].image.length > 0"
                                        >
                                            <v-img
                                                :src="qq.data[0].image[0].image"
                                                contain
                                            />
                                        </v-card-title>
                                        <v-card-title>
                                            <v-text-field
                                                label="Write your answer here..."
                                                disabled
                                                filled
                                            >

                                            </v-text-field>
                                        </v-card-title>
                                    </div>
                                    <!-- end short answer -->
                                    <!-- long paragraph -->
                                    <div
                                        v-if="Object.keys(qq.data[0].action).length > 0 && qq.data[0].action.id === 3"
                                    >
                                        <v-card-title
                                            v-if="qq.data[0].image.length > 0"
                                        >
                                            <v-img
                                                :src="qq.data[0].image[0].image"
                                                contain
                                            />
                                        </v-card-title>
                                        <v-card-title>
                                            <v-text-field
                                                label="Write your answer here..."
                                                disabled
                                                filled
                                            >

                                            </v-text-field>
                                        </v-card-title>
                                    </div>
                                    <!-- end long paragraph -->
                                    <!-- file upload -->
                                    <div
                                        v-if="Object.keys(qq.data[0].action).length > 0 && qq.data[0].action.id === 4"
                                    >
                                        <!-- <v-card-title>
                                            <v-switch
                                                v-if="Object.keys(qq.data[0].action).length > 0 && qq.data[0].action.id === 4"
                                                v-model="qq.data[0].allow_multiple_file"
                                                style="position: relative; bottom:15px;"
                                                dense
                                                @change="toggle_multiple_file(qqindex)"
                                                class="text-right"
                                                label="Allow only specific type of files?"
                                            ></v-switch>
                                        </v-card-title> -->
                                        <v-card-title
                                            v-if="qq.data[0].image.length > 0"
                                        >
                                            <v-img
                                                :src="qq.data[0].image[0].image"
                                                contain
                                            />
                                        </v-card-title>
                                        <!-- <v-card-title
                                            v-if="qq.data[0].allow_multiple_file"
                                        >
                                            <v-col
                                                cols="12"
                                                v-for="(item, itemindex) in documents"
                                                :key="itemindex"
                                            >
                                                <v-checkbox
                                                    :label="item"
                                                    @change="add_allowed_files(item)"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                        </v-card-title> -->
                                        <v-card-text>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <v-text-field
                                                        label="Maximum number of files"
                                                        filled
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <v-text-field
                                                        label="Maximum file size (value are in MB size)"
                                                        filled
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </div>
                                    <!-- end file upload -->
                                    <!-- date -->
                                    <div
                                        v-if="Object.keys(qq.data[0].action).length > 0 && qq.data[0].action.id === 5"
                                    >
                                        <v-card-title
                                            v-if="qq.data[0].image.length > 0"
                                        >
                                            <v-img
                                                :src="qq.data[0].image[0].image"
                                                contain
                                            />
                                        </v-card-title>
                                        <v-card-title>
                                            <v-col
                                                cols="12"
                                            >
                                                <small>What would client see</small>
                                            </v-col>
                                            <v-date-picker
                                                disabled
                                            >
                                            </v-date-picker>
                                        </v-card-title>
                                    </div>
                                    <!-- end date -->
                                    <!-- time -->
                                    <div
                                        v-if="Object.keys(qq.data[0].action).length > 0 && qq.data[0].action.id === 6"
                                    >
                                        <v-card-title
                                            v-if="qq.data[0].image.length > 0"
                                        >
                                            <v-img
                                                :src="qq.data[0].image[0].image"
                                                contain
                                            />
                                        </v-card-title>
                                        <v-card-title>
                                            <v-col
                                                cols="12"
                                            >
                                                <small>What would client see</small>
                                            </v-col>
                                            <v-time-picker
                                                disabled
                                            >
                                            </v-time-picker>
                                        </v-card-title>
                                    </div>
                                    <!-- end time -->
                                </v-card>
                            </v-row>
                            <!-- cta buttons -->
                            <v-row
                                justify="center"
                                align="center"
                            >
                                <v-card
                                    width="90%"
                                    style="border-radius: 15px;"
                                    class="mt-5 mb-5"
                                >
                                    <v-card-title
                                        class="justify-center"
                                    >
                                        <v-btn
                                            text
                                            outlined
                                            rounded
                                            style="textTransform: none !important;"
                                            @click="add_question"
                                            class="mr-3"
                                        >
                                            <v-img
                                                :src="require('../../assets/plus.svg')"
                                            />
                                            Add a question
                                        </v-btn>
                                        <!-- <v-btn
                                            text
                                            outlined
                                            rounded
                                            style="textTransform: none !important;"
                                            class="mr-3"
                                            @click="add_title_and_description"
                                        >
                                            <v-img
                                                :src="require('../../assets/plus.svg')"
                                            />
                                            Add title & description
                                        </v-btn>
                                        <v-btn
                                            text
                                            outlined
                                            rounded
                                            style="textTransform: none !important;"
                                            class="mr-3"
                                        >
                                            <v-img
                                                :src="require('../../assets/plus.svg')"
                                            />
                                            Add image
                                        </v-btn>
                                        <v-btn
                                            text
                                            outlined
                                            rounded
                                            style="textTransform: none !important;"
                                            class="mr-3"
                                        >
                                            <v-img
                                                :src="require('../../assets/plus.svg')"
                                            />
                                            Add video
                                        </v-btn> -->
                                    </v-card-title>
                                </v-card>
                            </v-row>
                            <!-- end cta buttons -->
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
        <!-- add image dialog -->
        <v-row justify="center">
            <v-dialog
                v-model="add_image_model"
                persistent
                max-width="560"
            >
                <v-card>
                    <v-card-title class="text-h5">
                        Upload an image
                    </v-card-title>
                    <v-card-text
                        class="mt-5"
                    >
                        <v-file-input
                            accept="image/*"
                            label="Select an image"
                            v-model="i.image"
                            outlined
                            dense
                        ></v-file-input>
                        <!-- <v-text-field
                            label="add a question"
                            outlined
                            dense
                            v-model="i.question"
                            prepend-icon="mdi-message-text-outline"
                        >
                        </v-text-field> -->
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="cancel_image_upload"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="save_image"
                    >
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- end add image dialog -->
        <!-- add image question dialog -->
        <v-row justify="center">
            <v-dialog
                v-model="add_image_question_model"
                persistent
                max-width="560"
            >
                <v-card>
                    <v-card-title class="text-h5">
                        Upload an image
                    </v-card-title>
                    <v-card-text
                        class="mt-5"
                    >
                        <v-file-input
                            accept="image/*"
                            label="Select an image"
                            v-model="i.image"
                            outlined
                            dense
                        ></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="cancel_image_upload"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="save_image_question"
                    >
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- end add image question dialog -->
    </v-container>
</template>

<script>
// import { VueEditor } from "vue2-editor";
import { mapGetters } from 'vuex'
export default {
  components: {
    // VueEditor
  },
  props: [
  ],
  data: () => ({
    section_title: null,
    title: null,
    cover_photo: null,
    qq_question: '',
    dd_item: null,
    dditems: [
        {
            id: 1,
            value: 'Multiple Choice',
        },
        {
            id: 2,
            value: 'Short Answer',
        },
        {
            id: 3,
            value: 'Long Paragraph',
        },
        {
            id: 4,
            value: 'File Upload',
        },
        {
            id: 5,
            value: 'Date',
        },
        {
            id: 6,
            value: 'Time'
        }
    ],
    add_image_model: false,
    i: {
        image: [],
        question: null
    },
    customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
    ],
    multiple_question: '',
    question: null,
    add_image_question_model: false,
    follow_up_question: null,
    documents: ['Document', 'Spreadsheet', 'PDF', 'Presentation', 'Image', 'Video', 'Audio'],
    allowed_files: []
  }),
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_action_index:           'fd_admin_survey/get_action_index',
        get_survey_creation:        'fd_admin_survey/get_survey_creation',
        get_active_section:         'fd_admin_survey/get_active_section',
        get_current_index:          'fd_admin_survey/get_current_index',
        get_question_index:         'fd_admin_survey/get_question_index',
        get_reference_question:     'fd_admin_survey/get_reference_question',
        get_reference_image:        'fd_admin_survey/get_reference_image',
        get_title:                  'fd_admin_survey/get_title',
        get_is_title_edit:          'fd_admin_survey/get_is_title_edit',
        get_cover_photo:            'fd_admin_survey/get_cover_photo',
        get_is_cover_photo:         'fd_admin_survey/get_is_cover_photo'
    }),
    get_questions(){
        return this.$store.getters['fd_admin_survey/get_questions'][this.get_active_section]
    },
  },
  methods: {
    showSnackBar(message){
        this.$store.commit("auth/setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    edit_title(){
        this.$store.dispatch('fd_admin_survey/set_is_title_edit', true)
    },
    save_title(){
        this.$store.dispatch('fd_admin_survey/set_title', this.title)
    },
    add_cover_photo(){
        this.$store.dispatch('fd_admin_survey/set_is_cover_photo', true)
    },
    change_cover_photo(){
        const getBase64 = (file) => new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject('Error: ', error);
        })
        getBase64(this.cover_photo)
        .then((result) => {
            let data = {
                cover_photo:          result,
            }
            this.$store.dispatch('fd_admin_survey/set_cover_photo', data)
        })
    },
    edit_section_title_fn(){
        let data = {
            active_section: this.get_active_section
        }
        this.$store.dispatch('fd_admin_survey/set_update_section_title', data)
    },
    update_section_title(){
        let data ={
            active_section: this.get_active_section,
            title:          this.section_title
        }
        this.$store.dispatch('fd_admin_survey/set_update_section_r_title', data)
    },
    add_section_fn(){
        this.$store.dispatch('fd_admin_survey/set_survey_creation', 1)
    },
    set_active_section(index){
        this.$store.dispatch('fd_admin_survey/set_active_section', index)
    },
    add_question(){
        let tp = {
            active_section: this.get_active_section,
            reference: 1
        }
        this.$store.dispatch('fd_admin_survey/set_add_question', tp)
    },
    add_title_and_description(){
        let tp = {
            active_section: this.get_active_section,
            reference:      2
        }
        this.$store.dispatch('fd_admin_survey/set_add_question', tp)
    },
    add_question_title(index){
        let data = {
            question_index: index,
            active_section: this.get_active_section
        }
        this.$store.dispatch('fd_admin_survey/set_question_title', data)
    },
    reselect_dd(index){
        let data = {
            question_index: index,
            active_section: this.get_active_section
        }
        this.$store.dispatch('fd_admin_survey/set_reselect_dd', data)
    },
    addtitlefn(index){
        this.add_title_model = false
        let data = {
            question_index: index,
            active_section: this.get_active_section,
            title:          this.qq_question
        }
        this.$store.dispatch('fd_admin_survey/set_question_title_actual', data)
        this.qq_question = null
    },
    remove_question_item(index){
        let data = {
            question_index: index,
            active_section: this.get_active_section
        }
        this.$store.dispatch('fd_admin_survey/set_remove_question', data)
    },
    toggle_multiple_answer(index){
        let data = {
            question_index: index,
            active_section: this.get_active_section,
        }
        this.$store.dispatch('fd_admin_survey/set_toggle_mutliple_answer', data)
    },
    add_image_menu_option(index){
        this.add_image_model = true
        this.$store.dispatch('fd_admin_survey/set_current_index', index)
    },
    cancel_image_upload(){
        this.add_image_model = false
        this.add_image_question_model = false
        this.i = {}
    },
    save_image(){
        const getBase64 = (file) => new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject('Error: ', error);
        })
        getBase64(this.i.image)
        .then((result) => {
            let data = {
                question_index: this.get_current_index,
                active_section: this.get_active_section,
                image:          result,
                question:       this.i.question
            }
            this.$store.dispatch('fd_admin_survey/set_image_to_question_data', data)
            this.cancel_image_upload()
        })
    },
    add_followup_question(imgindex){
        let data = {
            question_index: this.get_current_index,
            active_section: this.get_active_section,
            image_index:    imgindex
        }
        this.$store.dispatch('fd_admin_survey/set_add_follow_up_question', data)
        location.reload()
    },
    dd_item_fn(question_index){
        let data = {
            action:             this.dd_item,
            active_section:     this.get_active_section,
            question_index:     question_index
        }
        this.$store.dispatch('fd_admin_survey/set_add_question_to_data', data)
        this.dd_item = null
    },
    add_option_question_fn(index){
        let data = {
            active_section:     this.get_active_section,
            question_index:     index
        }
        this.$store.dispatch('fd_admin_survey/add_option_question', data)
    },
    add_question_option_fn(questionindex, questionoptionindex){
        let data = {
            active_section:     this.get_active_section,
            question_index:     questionindex,
            option_index:       questionoptionindex
        }
        this.$store.dispatch('fd_admin_survey/set_add_option', data)
    },
    save_question(questionindex, questioninnerindex){
        let data = {
            active_section:     this.get_active_section,
            question_index:     questionindex,
            qq_index:           questioninnerindex,
            question:           this.question
        }
        this.$store.dispatch('fd_admin_survey/set_question', data)
        this.question = null
    },
    add_image_question(qqindex, qqqindex, qq){
        this.$store.dispatch('fd_admin_survey/set_current_index', qqindex)
        this.$store.dispatch('fd_admin_survey/set_question_index', qqqindex)
        this.$store.dispatch('fd_admin_survey/set_reference_question', qq)
        this.add_image_question_model = true
    },
    save_image_question(){
        const getBase64 = (file) => new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject('Error: ', error);
        })
        getBase64(this.i.image)
        .then((result) => {
            let data = {
                question_index:     this.get_current_index,
                active_section:     this.get_active_section,
                r_question_index:   this.get_question_index,
                image:              result,
                reference_name:     this.get_reference_question.qq
            }
            this.$store.dispatch('fd_admin_survey/set_question_image', data)
            this.cancel_image_upload()
        })
    },
    set_question_index(qqindex){
        this.$store.dispatch('fd_admin_survey/set_current_index', qqindex)
    },
    add_follow_up_questions(image){
        let data = {
            active_section:     this.get_active_section,
            question_index:     this.get_current_index,
            image:              image.qq,
            ref_id:             image.ref_id
        }
        this.$store.dispatch('fd_admin_survey/set_add_follow_up_questions', data)
    },
    save_follow_up_question(followupindex){
        let data = {
            active_section:     this.get_active_section,
            question_index:     this.get_current_index,
            follow_up_index:    followupindex,
            followupquestion:   this.follow_up_question
        }
        this.$store.dispatch('fd_admin_survey/set_add_follow_up_actual_question', data)
        this.follow_up_question = null
    },
    remove_follow_up(followupindex){
        let data = {
            active_section:     this.get_active_section,
            question_index:     this.get_current_index,
            follow_up_index:    followupindex,
        }
        this.$store.dispatch('fd_admin_survey/set_remove_follow_up_image', data)
    },
    toggle_multiple_file(qindex){
        let data = {
            active_section:     this.get_active_section,
            question_index:     this.get_current_index,
            reference_index:    qindex,
        }
        this.$store.dispatch('fd_admin_survey/set_multiple_file_upload', data)
    },
    add_allowed_files(item){
        this.allowed_files.push(item)
    },
    async save_survey(){
        let tp = {
            title:          this.get_title,
            cover_photo:    this.get_cover_photo === null ? null : this.get_cover_photo.cover_photo,
            survey_data:    this.get_survey_creation
        }
        await this.$axios.post('/auth/admin/add_survey', tp)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.commit('fd_admin_survey/set_data_empty')
                this.$router.push({name: '/fd_admin_survey'})
                return
            }else{
                this.showSnackBar(data.message)
                return
            }
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.border {
  border-left: 4px solid #76BDFF;
}
</style>