<template>
    <v-container fluid style="height: 100%;" class="pa-0 ma-0">
        <v-app-bar :color="(Object.keys(get_funeral_director).length > 0) ? get_funeral_director.color : '#193B5B'" dark
            height="150" app>
            <v-card :color="(Object.keys(get_funeral_director).length > 0) ? get_funeral_director.color : '#193B5B'" v-if="!isMobile()"
                elevation="0">
                <v-card-title v-if="Object.keys(get_funeral_director).length > 0">
                    <v-img v-if="get_funeral_director && get_funeral_director.company_logo"
                        :src="`${api_url}frontend/images/${get_funeral_director.company_logo}`" contain max-width="320"
                        max-height="120" />
                </v-card-title>
            </v-card>
            <v-card :color="(Object.keys(get_funeral_director).length > 0) ? get_funeral_director.color : '#193B5B'" v-else
                elevation="0">
                <v-card-title v-if="Object.keys(get_funeral_director).length > 0">
                    <v-img :src="`${api_url}frontend/images/${get_funeral_director.company_logo}`" contain max-width="180"
                        max-height="120" />
                </v-card-title>
            </v-card>
            <v-spacer />
            <v-img :src="require('../assets/logo.png')" contain max-height="150" max-width="150" />
        </v-app-bar>
        <slot />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data: () => ({
        api_url: null
    }),
    computed: {
        ...mapGetters({
            get_funeral_director: 'funeral_request/get_funeral_director',
        })
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    mounted() {
    },
    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: inherit;
    height: inherit;
    margin-right: 15px;
    cursor: pointer;
    margin-top: 7px;
}
</style>