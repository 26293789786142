<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <strong style="font-size:26px;">Dashboard</strong>
                    <v-spacer />
                </v-card-title>
            </v-card>
        </v-row>
        <v-row justify="start" class="mt-15">
            <v-col :cols="isMobile() ? 12 : 5">
                <v-card width="90%" style="border-radius: 16px;" elevation="0">
                    <v-card-title>
                        Preplanning Checklist
                    </v-card-title>
                    <v-card-text v-if="get_my_survey[0] == null">
                        <v-card elevation="0" v-for="(survey, index) in get_survey" :key="index">
                            <v-card-title>
                                <small style="font-size: 18px;width:100%;">{{ survey.survey_name }}</small>
                                <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                    style="height:40px;text-transform: none !important" @click="create_link(survey.id)">
                                    Use template
                                </v-btn>
                            </v-card-title>
                        </v-card>
                    </v-card-text>
                    <v-card-text v-else>
                        <v-card elevation="0">
                            <v-card-title>
                                <small style="font-size: 18px;width:100%;">{{ get_my_survey[0].survey_name }}</small>
                                <br>
                                <v-btn text color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                    style="height:40px;text-transform: none !important"
                                    @click="edit_survey(get_my_survey[0])">
                                    Edit
                                </v-btn>
                                <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                    style="height:40px;text-transform: none !important"
                                    @click="copy_link(get_my_survey[0].link)">
                                    Copy Link
                                </v-btn>
                            </v-card-title>
                        </v-card>
                    </v-card-text>
                </v-card>

                <v-card width="90%" style="border-radius: 16px;" elevation="0" class="mt-10">
                    <v-card-title>
                        Funeral Notice Templates
                    </v-card-title>
                    <v-card-text>
                        <v-btn text color="#172C54" class="pl-2 pr-2 pt-2 pb-2 ml-3 mt-1"
                            style="text-transform: none !important" @click="create_template">
                            Create new
                        </v-btn>
                        <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                            style="height:40px;text-transform: none !important" @click="get_funeral_request_link">
                            Copy Link
                        </v-btn>
                        <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="header_templates"
                            :items="get_templates" :items-per-page="7" :mobile-breakpoint="0" hide-default-header
                            :sort-by.sync="sort_by" :sort-desc.sync="sortDesc" hide-default-footer>
                            <template v-slot:item="{ item }">
                                <tr class="mx-5" style="background-color: transparent;"
                                    v-if="item != null && !isMobile()">
                                    <td style="width:100%;">
                                        <b style="font-size: 16px;width:100%;">{{ item.name }}</b>
                                        <br>
                                        <v-btn text class="mr-3 mt-2" @click="open_template(item)"
                                            color="#172C54">View</v-btn>
                                        <v-btn class="mt-2" v-if="item.active && item.id > 1" disabled
                                            @click="set_active_template(item)">Active</v-btn>
                                        <v-btn class="mt-2" dark v-else-if="item.id > 1" color="#172C54"
                                            @click="set_active_template(item)">Set
                                            Active</v-btn>
                                        <v-btn @click="openDeleteNoticeDialog(item)" v-if="item.id > 1" color="red"
                                            icon class="mt-2 ml-5" small>
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr class="mx-5" style="background-color: transparent;"
                                    v-if="item != null && isMobile()" @click="open_template(item)">
                                    <td style="width:100%" class="py-5">
                                        <v-row>
                                            <v-col cols="12">
                                                <small style="font-size: 18px;width:100%;">{{ item.name }}</small>
                                                <br>
                                                <v-btn text class="mr-3" @click="open_template(item)"
                                                    color="#172C54">View</v-btn>
                                                <v-btn v-if="item.active && item.id > 1" disabled
                                                    @click="set_active_template(item)">Active</v-btn>
                                                <v-btn dark v-else-if="item.id > 1" color="#172C54"
                                                    @click="set_active_template(item)">Set
                                                    Active</v-btn>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>

                <!-- <v-card width="90%" style="border-radius: 16px;" elevation="0" class="mt-10">
                    <v-card-title>
                        Payments
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-if="user.get_fd_payment_gateway == null" class="mt-5 mx-3" filled dense
                            placeholder="Trust Payments site reference" v-model="site_reference"
                            v-on:keyup.enter="saveSiteReference()">
                            <template v-slot:append>
                                <v-btn class="mb-2" text @click="saveSiteReference()">Save</v-btn>
                            </template>
                        </v-text-field>
                        <v-text-field v-else class="mt-5 mx-3" filled dense placeholder="Trust Payments site reference"
                            v-model="user.get_fd_payment_gateway.site_reference" v-on:keyup.enter="saveSiteReference()">
                            <template v-slot:append>
                                <v-btn class="mb-2" text @click="saveSiteReference()">Update</v-btn>
                            </template>
                        </v-text-field>
                    </v-card-text>
                </v-card> -->
            </v-col>
            <v-col :cols="isMobile() ? 12 : 7">
                <v-card width="90%" style="border-radius: 16px;" elevation="0">
                    <v-card-title>
                        Recent Clients
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="client_headers"
                            :items="get_client_survey" hide-default-header hide-default-footer :items-per-page="5"
                            :sort-by.sync="sort_by" :sort-desc.sync="sortDesc">
                            <template v-slot:header="{ props: { headers } }">
                                <thead v-if="!isMobile()">
                                    <tr>
                                        <th style="padding-bottom:30px;padding-top:10px;width:25%" v-for="h in headers"
                                            :key="h.index">
                                            <div style="text-align:center;" v-if="h.text">
                                                <strong>{{ h.text }}</strong>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr v-if="!isMobile() && item.get_user_info != null" class="mx-5"
                                    style="background-color: transparent;">
                                    <td>
                                        <label v-if="item.get_user_info !== null">
                                            {{ item.get_user_info.name }}
                                        </label>
                                        <label v-else>N/A</label>
                                    </td>
                                    <td>
                                        <label v-if="item.get_user_info.representative">
                                            F
                                        </label>
                                        <label v-else>
                                            PP
                                        </label>
                                    </td>
                                    <td style="width:30%;">
                                        {{ $date(item.created_at).format("DD MMM YYYY") }}
                                        <br>
                                        {{ $date(item.created_at).format("hh:mm a") }}
                                    </td>
                                    <td style="width:10%;">
                                        <v-btn text color="#172C54" style="text-transform: none !important"
                                            @click="view_dialog(item)">
                                            View
                                        </v-btn>
                                    </td>
                                </tr>
                                <tr v-else-if="item.get_user_info != null && item.get_user_info.get_survey != null"
                                    class="mx-5" style="background-color: transparent;width:100%;">
                                    <td class="py-5" style="width:500px;">
                                        <v-row>
                                            <v-col cols="4">
                                                {{ client_headers[0].text }}
                                            </v-col>
                                            <v-col cols="8">
                                                <label>
                                                    {{ item.get_user_info.name }}
                                                </label>
                                            </v-col>
                                        </v-row>
                                        <!--<v-row>
                      <v-col cols="4">
                        {{ client_headers[1].text }}
                      </v-col>
                      <v-col cols="8">
                        <label v-if="item.get_user_info.get_survey.get_fd_survey != null">{{
                          item.get_user_info.get_survey.get_fd_survey.survey_name }}</label>
                        <label v-else>N/A</label>
                      </v-col>
                    </v-row>-->
                                        <v-row>
                                            <v-col cols="4">
                                                {{ client_headers[1].text }}
                                            </v-col>
                                            <v-col cols="8">
                                                {{ $date(item.get_user_info.get_survey.updated_at).format("DD MMM YYYY")
                                                }}
                                                <br>
                                                {{ $date(item.get_user_info.get_survey.updated_at).format("hh:mm a") }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4">
                                                {{ client_headers[2].text }}
                                            </v-col>
                                            <v-col cols="8">
                                                <v-btn v-if="item.get_user_info.get_survey !== null" text
                                                    color="#172C54" style="text-transform: none !important"
                                                    @click="view_dialog(item)">
                                                    View Checklist
                                                </v-btn>
                                                <v-btn v-else text disabled>
                                                    N/A
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialog_edit_survey" persistent max-width="560">
                <v-card>
                    <v-card-title class="text-h5">
                        Edit checklist
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-text-field filled v-model="survey_to_edit" dense>
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialog_edit_survey = false">
                            Cancel
                        </v-btn>
                        <v-btn color="#172C54" dark class="pl-10 pr-10" @click="save_survey">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>



        <v-row justify="center">
            <v-dialog v-model="open_template_dialog" persistent max-width="1000">
                <v-card v-if="selected_template != null">
                    <v-card-title class="text-h5">
                        <div style="font-size:16px;" v-if="selected_template.id == 1">{{ selected_template.name }}</div>
                        <v-text-field v-else filled v-model="selected_template.name"></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="open_template_dialog = false">
                            Close <v-icon class="mr-2">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-row v-if="selected_template.id == 1">
                            <v-col cols="12">
                                <v-textarea readonly filled v-model="selected_template.template" rows="10" dense>
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col :cols="isMobile() ? 12 : 9">
                                <v-textarea filled v-model="selected_template.template" rows="10" dense>
                                </v-textarea>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 3"
                                style="height:300px;overflow-y:scroll;padding-top:0px!important;padding-bottom:0px!important;margin-top:12px;margin-bottom:12px;">
                                Details of the Deceased
                                <v-text-field class="placeholder" dense v-for="contact in placeholders_contact"
                                    :key="contact.index" readonly @focus="$event.target.select()"
                                    :value="contact"></v-text-field>
                                <br>
                                Family of the Deceased
                                <v-text-field class="placeholder" dense v-for="family in placeholders_family"
                                    :key="family.index" readonly @focus="$event.target.select()"
                                    :value="family"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn v-if="selected_template.id > 1" color="red darken-1" text @click="delete_template">
                            <v-icon class="mr-2">mdi-delete</v-icon>
                            Delete
                        </v-btn>
                        <v-btn v-if="selected_template.id == 1" color="#172C54" dark class="pl-10 pr-10"
                            @click="clone_template">
                            <v-icon class="mr-2">mdi-plus</v-icon>
                            Create new from template
                        </v-btn>
                        <v-btn v-else color="#172C54" dark class="pl-10 pr-10" @click="edit_template">
                            <v-icon class="mr-2">mdi-plus</v-icon>
                            Save changes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-dialog :value="delete_notice_dialog" persistent width="500">
            <v-card>
                <v-card-title>Are you sure?</v-card-title>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="closeDeleteNoticeDialog()" text>Cancel</v-btn>
                    <v-btn @click="deleteNotice()" color="red" dark>Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--<DialogSurverView :data="data_view" :value="dialog_view" :answer_to_radio="answer_to_radio"
      @cancel_dialog="cancel_dialog" />-->
    </v-container>
</template>

<script>
//import DialogSurverView from "./components/DialogSurverView.vue";

import { mapGetters } from "vuex";
export default {
    components: {
        //DialogSurverView
    },
    props: [],
    async mounted() {
        await this.$store.dispatch("fd_dashboard/get_client_survey")
        await this.$store.dispatch("fd_survey/get_active_survey")
        await this.$store.dispatch("fd_funeral_notice/fetch_templates")
    },
    created() {
        this.url = process.env.VUE_APP_BUILD;
    },
    computed: {
        ...mapGetters({
            user: "auth/get_user",
            get_survey: "fd_survey/get_survey",
            get_my_survey: "fd_survey/get_my_survey",
            get_client_survey: "fd_dashboard/get_client_survey",
            get_templates: "fd_funeral_notice/get_templates",
        }),
    },
    data: () => ({
        url: null,
        dialog_view: false,
        data_view: null,
        answer_to_radio: null,
        client_headers: [
            { text: "Client" },
            { text: "Type" },
            { text: "Created" },
            { text: "" },
        ],
        search_name: null,
        dialog_edit_survey: false,
        survey_to_edit: null,

        header_templates: [
            { text: "Name" },
        ],
        sort_by: "created_at",
        sortDesc: true,
        filter_by: null,
        open_template_dialog: false,
        selected_template: null,
        placeholders_contact: [
            "{{name}}", "{{maiden_name}}", "{{address}}", "{{town}}", "{{county}}", "{{country}}", "{{eircode}}", "{{pronoun}}",
            "{{date_of_death}}", "{{circumstance_of_death}}", "{{place_of_death}}",
        ],
        placeholders_family: [
            "{{family.predeceased_by}}", "{{family.significant_other_type}}", "{{family.significant_other}}", "{{family.father}}", "{{family.mother}}",
            "{{family.brothers}}", "{{family.sisters}}", "{{family.sons}}", "{{family.daughters}}", "{{family.sons_in_law}}", "{{family.daughters_in_law}}",
            "{{family.grandchildren}}", "{{family.greatgrandchildren}}", "{{family.other_people}}",
        ],
        site_reference: null,
        delete_notice_dialog: false,
        selected_notice: null,
    }),
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                "auth/setMessage",
                { show: true, message: message },
                { root: 1 }
            );
        },
        view_dialog(item) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            this.$store.dispatch("fd_client/set_client", item);
            this.$router.push({ name: '/client-profile' })
        },
        cancel_dialog() {
            this.dialog_view = false;
        },
        async create_link(id) {
            await this.$axios.post("/auth/fd_survey/create_link", {
                id: id,
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch("fd_survey/get_active_survey");
                        return;
                    } else {
                        this.showSnackBar(data.message);
                        return;
                    }
                });
        },
        edit_survey(item) {
            this.dialog_edit_survey = true
            this.survey_to_edit = item.survey_name
        },
        async save_survey() {
            await this.$axios.patch("/auth/fd_survey/edit_fd_survey", {
                id: this.get_my_survey[0].id, survey_name: this.survey_to_edit
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.dialog_edit_survey = false
                        this.$store.dispatch("fd_dashboard/get_client_survey")
                        this.$store.dispatch("fd_survey/get_active_survey")
                        this.showSnackBar("Edited successfully");
                    }
                });
        },
        async saveSiteReference() {
            let new_site_reference = null
            if (this.user.get_fd_payment_gateway) {
                new_site_reference = this.user.get_fd_payment_gateway.site_reference
            }
            else {
                new_site_reference = this.site_reference
            }
            let payload = {
                user_id: this.user.id,
                site_reference: new_site_reference
            }
            await this.$axios.post('/auth/fd_dashboard/add_site_reference', payload)
                .then(() => {
                    this.$store.dispatch('auth/fetch_user')
                    this.showSnackBar("Successfully updated")
                })
        },
        async get_funeral_request_link() {
            await this.$axios.get('/auth/admin/funeral_notice/get_funeral_request_link')
                .then(({ data }) => {
                    if (data.response) {
                        const link = this.url + "/funeral-request/" + data.data
                        this.copy_request_link(link)
                    }
                })
        },
        async copy_request_link(link) {
            try {
                await navigator.clipboard.writeText(link)
                this.showSnackBar("Copied!")
            } catch ($e) {
                this.showSnackBar("Cannot copy")
            }
        },
        async copy_link(link) {
            const data = this.url + "/survey/" + link
            try {
                await navigator.clipboard.writeText(data)
                this.showSnackBar("Copied!")
            } catch ($e) {
                this.showSnackBar("Cannot copy")
            }
        },
        open_template(item) {
            this.selected_template = item
            this.open_template_dialog = true
        },
        async create_template() {
            await this.$axios.post('/auth/admin/funeral_notice/create_template')
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Created successfully")
                    }
                })
        },
        async clone_template() {
            let payload = {
                name: this.selected_template.name + ' copy',
                template: this.selected_template.template
            }
            await this.$axios.post('/auth/admin/funeral_notice/clone_template', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.selected_template = null
                        this.open_template_dialog = false
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Created successfully")
                    }
                })
        },
        async edit_template() {
            let payload = {
                id: this.selected_template.id,
                name: this.selected_template.name,
                template: this.selected_template.template
            }
            await this.$axios.patch('/auth/admin/funeral_notice/edit_template', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.selected_template = null
                        this.open_template_dialog = false
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Edited successfully")
                    }
                })
        },
        openDeleteNoticeDialog(item) {
            this.delete_notice_dialog = true
            this.selected_notice = item
        },
        closeDeleteNoticeDialog() {
            this.delete_notice_dialog = false
            this.selected_notice = null
        },
        async deleteNotice() {
            await this.$axios.delete(`/auth/admin/funeral_notice/delete_template/${this.selected_notice.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.closeDeleteNoticeDialog()
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Deleted successfully")
                    }
                })
        },
        async set_active_template(item) {
            let payload = {
                id: item.id,
            }
            await this.$axios.patch('/auth/admin/funeral_notice/set_active_template', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.selected_template = null
                        this.open_template_dialog = false
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Set active successfully")
                    }
                })
        },
        async delete_template() {
            await this.$axios.delete(`/auth/admin/funeral_notice/delete_template/${this.selected_template.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.selected_template = null
                        this.open_template_dialog = false
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Deleted successfully")
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
    width: 450px;
}

table {
    border-collapse: collapse;
}

// remove border
table td,
table th {
    height: 40px; //change the height
}

.mobile_table td {
    height: 100px !important;
}

.desktop_table td {
    height: 100px !important;
}

.mobile_card {
    margin-top: 100px;
}

.desktop_table {
    padding-left: 30px;
    padding-right: 30px;
}

.mobile_sort {
    margin-top: -40px;
}

.v-data-table.row-height-50 td {
    height: 100px !important;
}

.v-data-table::v-deep th {
    font-size: 16px !important;
    font-weight: bold;
}

.placeholder {
    height: 20px;
    font-size: 12px;
    border-radius: 4px;
    padding-left: 10px;
}

.placeholder:hover {
    background-color: rgb(233, 233, 233);
}

::v-deep .placeholder input {
    cursor: pointer !important;
}

::v-deep .placeholder .v-input__slot,
::v-deep .placeholder .v-input__control {
    min-height: 10px !important;
    height: 20px !important;
}

::v-deep .placeholder .v-input__control>.v-input__slot:before,
::v-deep .placeholder .v-input__control>.v-input__slot:after {
    border-style: none !important;
}
</style>
