<template>
    <v-container fluid :style="isMobile() ? '' : 'width: 1000px;'">
        <div class="top"></div>
        <v-skeleton-loader type="card" v-if="loading"></v-skeleton-loader>
        <v-row justify="center" v-if="completed">
            <v-card :width="isMobile() ? '100%' : '30%'" class="mt-10 mb-15" elevation="0">
                <v-card-title class="justify-center">
                    Congratulations
                </v-card-title>
                <v-card-subtitle class="text-center">
                    You have successfully created your will. We will reach out to you for further details.
                </v-card-subtitle>
                <v-card-text>
                    <v-btn class="start-survey mt-5" style="text-transform: none !important;height:50px;font-size:16px;"
                        dark block color="rgb(23,44,84)" small @click="previewWill()">
                        Preview Will
                    </v-btn>
                    <br>
                    <v-btn class="start-survey mt-5" style="text-transform: none !important;height:50px;font-size:16px;"
                        text block color="rgb(23,44,84)" small @click="resetWill()">
                        Edit Will
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row justify="center" v-if="!started && !loading">
            <v-card :width="isMobile() ? '100%' : '30%'" class="mt-10 mb-15" elevation="0">
                <v-card-title class="justify-center">
                    Peace of Mind
                </v-card-title>
                <v-card-subtitle class="text-center">
                    <strong>It only takes 10 minutes</strong>
                </v-card-subtitle>
                <v-card-actions>
                    <v-btn class="start-survey mt-5" style="text-transform: none !important;height:50px;font-size:16px;"
                        dark block color="rgb(23,44,84)" small @click="started = true">
                        <label v-if="get_user.id == null" style="cursor:pointer;">START MY WILL</label>
                        <label v-else style="cursor:pointer;">CONTINUE MY WILL</label>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
        <v-row justify="center" class="mt-15" style="margin-bottom:200px;" v-else-if="!completed && !loading">
            <v-row justify="center">
                <v-card width="100%" elevation="0" justify="center" align="center">
                    <v-stepper v-model="step" :alt-labels="isMobile() ? false : true"
                        :style="isMobile() ? 'width:400px;' : ''">
                        <v-stepper-header elevation="0">
                            <v-stepper-step :complete="step > 1" step="1" @click="step = 1" style="cursor:pointer;">
                                <small class="header_title">Basic Details</small>
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step :complete="step > 2" step="2" @click="get_user.id ? step = 2 : null"
                                :style="get_user.id ? 'cursor:pointer;' : ''">
                                <small class="header_title">Nominees</small>
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step :complete="step > 3" step="3" @click="get_user.id ? step = 3 : null"
                                :style="get_user.id ? 'cursor:pointer;' : ''">
                                <small class="header_title">Assets</small>
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step :complete="step > 4" step="4" @click="get_user.id ? step = 4 : null"
                                :style="get_user.id ? 'cursor:pointer;' : ''">
                                <small class="header_title">Residuary</small>
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step :complete="step > 5" step="5" @click="get_user.id ? step = 5 : null"
                                :style="get_user.id ? 'cursor:pointer;' : ''">
                                <small class="header_title">Gifts</small>
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step :complete="step > 6" step="6" @click="get_user.id ? step = 6 : null"
                                :style="get_user.id ? 'cursor:pointer;' : ''">
                                <small class="header_title">Funeral Details</small>
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step :complete="step > 7" step="7" @click="get_user.id ? step = 7 : null"
                                :style="get_user.id ? 'cursor:pointer;' : ''">
                                <small class="header_title">Provisions</small>
                            </v-stepper-step>
                            <v-divider />
                            <v-stepper-step :complete="step > 8" step="8" @click="get_user.id ? step = 8 : null"
                                :style="get_user.id ? 'cursor:pointer;' : ''">
                                <small class="header_title">Review</small>
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items class="mt-5">
                            <v-row>
                                <v-col>
                                    <v-stepper-content step="1">
                                        <v-card elevation="0" :class="isMobile() ? '' : 'mx-10'">
                                            <v-card-title style="word-break: break-word;">
                                                Basic Details
                                            </v-card-title>
                                            <v-card-text class="my-10">
                                                <v-row>
                                                    <v-col :cols="isMobile() ? '6' : '6'">
                                                        <v-text-field outlined v-model="new_user.firstname" dense
                                                            label="First name *">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '6' : '6'">
                                                        <v-text-field outlined v-model="new_user.lastname" dense
                                                            label="Last name *">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '6' : '6'">
                                                        <v-menu v-model="menu" :close-on-content-click="false"
                                                            :nudge-right="40" transition="scale-transition" offset-y
                                                            min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field v-model="new_user.birthday"
                                                                    label="Date of birth *" outlined dense readonly
                                                                    v-bind="attrs" v-on="on"></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="new_user.birthday"
                                                                @input="menu = false"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '6' : '6'">
                                                        <v-select
                                                            :items="[{ name: 'Male', value: 1 }, { name: 'Female', value: 2 }, { name: 'Non-binary', value: 3 }]"
                                                            item-text="name" item-value="value" label="Gender *"
                                                            outlined v-model="new_user.gender_id" dense>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '6'">
                                                        <v-text-field outlined v-model="new_user.email"
                                                            :disabled="this.editing" dense label="Email *">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '6'">
                                                        <v-text-field outlined v-model="new_user.mobile" dense
                                                            type="number" label="Mobile Number">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '6'">
                                                        <v-text-field outlined v-model="new_user.address" dense
                                                            label="Postal Address">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '6'">
                                                        <v-select outlined v-model="new_user.marital_status_id"
                                                            :items="[{ name: 'Single', value: 1 }, { name: 'Married', value: 2 }, { name: 'Divorced', value: 3 }, { name: 'Widowed', value: 4 }]"
                                                            item-text="name" item-value="value" dense
                                                            label="Marital Status">
                                                        </v-select>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-text class="text-center" v-if="isMobile()">
                                                <v-btn @click="redirectToLogin()" v-if="will_id == null"
                                                    style="font-size:14px;text-transform:none;" text class="pa-5">
                                                    Already started a will? Login here
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="saveNewUser()" color="rgb(23,44,84)" :dark="!saving_user"
                                                    :disabled="saving_user" style="font-size:20px;text-transform:none;"
                                                    class="pa-5">
                                                    Save & Continue
                                                </v-btn>
                                            </v-card-text>
                                            <v-card-actions class="text-center" v-else>
                                                <v-btn @click="redirectToLogin()" v-if="will_id == null"
                                                    style="font-size:14px;text-transform:none;" text class="pa-5">
                                                    Already started a will? Login here
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="saveNewUser()" color="rgb(23,44,84)" :dark="!saving_user"
                                                    :disabled="saving_user" style="font-size:20px;text-transform:none;"
                                                    class="pa-5">
                                                    Save & Continue
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-stepper-content>
                                    <v-stepper-content step="2">
                                        <v-card elevation="0" :class="isMobile() ? '' : 'mx-10'">
                                            <v-card-title style="word-break: break-word;">
                                                Children & Guardians
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                This question applies to all living biological and adopted children. You
                                                may also list stepchildren or foster children, even if they are not
                                                legally adopted, as beneficiaries in your will.

                                                It is essential to appoint a personal guardian for each of your children
                                                under 18.

                                                The appointed guardian(s) will take on the responsibility of caring for
                                                your child if both parents are unavailable.
                                            </v-card-subtitle>
                                            <v-card-text class="my-10" style="text-align:left;">
                                                <h3 class="mb-5">Do you have any children?</h3>
                                                <v-row>
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="has_children == 1 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="has_children = 1">Yes</v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="has_children == 0 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="has_children = 0">No</v-btn>
                                                    </v-col>
                                                    <v-col cols="6" v-if="!isMobile()"></v-col>
                                                    <v-col :cols="isMobile() ? '12' : '12'" v-if="has_children == 1">
                                                        <v-combobox v-model="children" :items="[]" multiple outlined
                                                            label="Full Names of Children" small-chips>
                                                            <template v-slot:no-data>
                                                                <v-list-item>
                                                                    <v-list-item-title>
                                                                        Press <kbd>enter</kbd> to add
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </template>
                                                        </v-combobox>
                                                    </v-col>
                                                </v-row>
                                                <br>
                                                <h3 class="mb-5" v-if="has_children == 1">
                                                    Are any of your children under the age of 18?
                                                </h3>
                                                <v-row v-if="has_children == 1">
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="has_underage == 1 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="has_underage = 1">Yes</v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="has_underage == 0 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="has_underage = 0">No</v-btn>
                                                    </v-col>
                                                    <v-col cols="6" v-if="!isMobile()"></v-col>
                                                    <v-col :cols="isMobile() ? '12' : '12'" v-if="has_underage == 1">
                                                        <v-combobox v-model="guardians" :items="[]" multiple outlined
                                                            label="Full Names of Guardians" small-chips>
                                                            <template v-slot:no-data>
                                                                <v-list-item>
                                                                    <v-list-item-title>
                                                                        Press <kbd>enter</kbd> to add
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </template>
                                                        </v-combobox>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-title style="word-break: break-word;">
                                                Pets & Caretakers
                                            </v-card-title>
                                            <v-card-text class="my-10" style="text-align:left;">
                                                <h3 class="mb-5">Do you have any pets you would like to provide for in
                                                    your will?</h3>
                                                <v-row>
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined :class="has_pets == 1 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="has_pets = 1">Yes</v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined :class="has_pets == 0 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="has_pets = 0">No</v-btn>
                                                    </v-col>
                                                    <v-col cols="6" v-if="!isMobile()"></v-col>
                                                    <v-col :cols="isMobile() ? '12' : '12'" v-if="has_pets == 1">
                                                        <v-combobox v-model="pets" :items="[]" multiple outlined
                                                            label="Full Names and Species of Pets" small-chips>
                                                            <template v-slot:no-data>
                                                                <v-list-item>
                                                                    <v-list-item-title>
                                                                        Press <kbd>enter</kbd> to add
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </template>
                                                        </v-combobox>
                                                    </v-col>
                                                    <v-col cols="12" v-if="has_pets == 1">
                                                        <h3 class="mb-5">How much money would you like to provide for
                                                            your pets' needs (food, veterinary costs, etc)?</h3>
                                                        <v-text-field v-model="pet_expenses" outlined
                                                            label="Amount"></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <br>
                                                <h3 class="mb-5" v-if="has_pets == 1">
                                                    Who should be responsible for taking care of your pets if you pass
                                                    away?
                                                </h3>
                                                <v-row v-if="has_pets == 1">
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="has_caretakers == 1 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="has_caretakers = 1">Yes</v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="has_caretakers == 0 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="has_caretakers = 0">No</v-btn>
                                                    </v-col>
                                                    <v-col cols="6" v-if="!isMobile()"></v-col>
                                                    <v-col :cols="isMobile() ? '12' : '12'" v-if="has_caretakers == 1">
                                                        <v-combobox v-model="caretakers" :items="[]" multiple outlined
                                                            label="Full Names of Caretakers" small-chips>
                                                            <template v-slot:no-data>
                                                                <v-list-item>
                                                                    <v-list-item-title>
                                                                        Press <kbd>enter</kbd> to add
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </template>
                                                        </v-combobox>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-actions class="text-center">
                                                <v-btn @click="previousStep()" v-if="step > 1"
                                                    style="font-size:18px;text-transform:none;" outlined class="pa-5">
                                                    Previous
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="nextStep()" color="rgb(23,44,84)" dark
                                                    style="font-size:18px;text-transform:none;" class="pa-5">
                                                    Save & Continue
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-stepper-content>
                                    <v-stepper-content step="3">
                                        <v-card elevation="0" :class="isMobile() ? '' : 'mx-10'">
                                            <v-card-title style="word-break: break-word;">
                                                Assets
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                Listing your major assets assists your executor in managing your affairs
                                                by detailing what assets you have and where they are held. While this
                                                section may take some time to complete, it is entirely optional. You can
                                                choose to skip it and return to it later if you prefer.
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <h3 class="mb-5">
                                                    What is the approximate total value of assets distributed by your
                                                    will?
                                                </h3>
                                                <v-row>
                                                    <v-col :cols="isMobile() ? '12' : '3'">
                                                        <v-btn style="font-size:20px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="assets_total == 'Less than €200K' ? 'pa-10 selected' : 'pa-10'"
                                                            @click="assets_total = 'Less than €200K'">
                                                            Less than €200K
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '3'">
                                                        <v-btn style="font-size:20px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="assets_total == '€200K-€500K' ? 'pa-10 selected' : 'pa-10'"
                                                            @click="assets_total = '€200K-€500K'">
                                                            €200K-€500K
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '3'">
                                                        <v-btn style="font-size:20px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="assets_total == '€500K-€1M' ? 'pa-10 selected' : 'pa-10'"
                                                            @click="assets_total = '€500K-€1M'">
                                                            €500K-€1M
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '3'">
                                                        <v-btn style="font-size:20px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="assets_total == '€1M-€2M' ? 'pa-10 selected' : 'pa-10'"
                                                            @click="assets_total = '€1M-€2M'">
                                                            €1M-€2M
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '3'">
                                                        <v-btn style="font-size:20px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="assets_total == '€2M-€5M' ? 'pa-10 selected' : 'pa-10'"
                                                            @click="assets_total = '€2M-€5M'">
                                                            €2M-€5M
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '3'">
                                                        <v-btn style="font-size:20px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="assets_total == '€5M-€10M' ? 'pa-10 selected' : 'pa-10'"
                                                            @click="assets_total = '€5M-€10M'">
                                                            €5M-€10M
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '3'">
                                                        <v-btn style="font-size:20px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="assets_total == 'More than €10M' ? 'pa-10 selected' : 'pa-10'"
                                                            @click="assets_total = 'More than €10M'">
                                                            More than €10M
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <h3 class="mb-5 mt-10">
                                                    List your major assets (optional)
                                                </h3>
                                                <v-row v-for="(asset, index) in assets" :key="index">
                                                    <v-col :cols="isMobile() ? '12' : '6'">
                                                        <v-text-field v-model="asset.name" outlined label="Asset Name">
                                                        </v-text-field>
                                                        <v-text-field v-model="asset.value" outlined
                                                            label="Approximate current value">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '5'">
                                                        <v-select outlined v-model="asset.type"
                                                            :items="['Real Estate', 'Vehicle', 'Cash/Valuables', 'Financial account', 'Retirement account', 'Brokerage account', 'Life insurance']"
                                                            label="Type">
                                                        </v-select>
                                                        <v-select v-model="asset.owner"
                                                            :items="['Only me', 'Me and someone else']" outlined
                                                            label="Owned by">
                                                        </v-select>
                                                        <v-textarea v-model="asset.description" outlined
                                                            label="Additional information">
                                                        </v-textarea>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '1'">
                                                        <v-btn @click="assets.splice(index, 1)" text
                                                            color="red">Remove</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-btn @click="assets.push({})" outlined>Add Asset</v-btn>
                                            </v-card-text>
                                            <v-card-actions class="text-center">
                                                <v-btn @click="previousStep()" v-if="step > 1"
                                                    style="font-size:18px;text-transform:none;" outlined class="pa-5">
                                                    Previous
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="nextStep()" color="rgb(23,44,84)" dark
                                                    style="font-size:18px;text-transform:none;" class="pa-5">
                                                    Save & Continue
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-stepper-content>
                                    <v-stepper-content step="4">
                                        <v-card elevation="0" :class="isMobile() ? '' : 'mx-10'">
                                            <v-card-title style="word-break: break-word;">
                                                Charitable gifts
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                My Farewell Wishes is provided to you through the generous support of
                                                nonprofit organizations dedicated to impactful causes such as
                                                environmental conservation medical research, education and human rights.
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <v-row>
                                                    <v-col :cols="isMobile() ? '6' : '3'"
                                                        v-for="(charity, index) in charities" :key="index">
                                                        <div @click="addCharity(charity)"
                                                            style="cursor:pointer;border: 1px solid black;border-radius:5px;height:200px;padding:10px;text-align:center;justify-content:space-between;display:flex;flex-direction:column;"
                                                            class="ma-2">
                                                            <div>
                                                                <v-img :src="api_url + 'images/' + charity.image"
                                                                    height="100" contain></v-img>
                                                            </div>
                                                            <div>
                                                                <b>{{ charity.name }}</b>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-for="(charity, index) in selected_charities" :key="index">
                                                    <v-col :cols="isMobile() ? '12' : '6'">
                                                        <div>
                                                            <v-img :src="api_url + 'images/' + charity.image"
                                                                height="100" contain></v-img>
                                                        </div>
                                                        <div style="text-align:center;">
                                                            <b>{{ charity.name }}</b>
                                                        </div>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '5'">
                                                        <v-select outlined v-model="charity.type"
                                                            :items="['Percentage of residuary estate', 'Fixed cash amount']"
                                                            label="Type">
                                                        </v-select>
                                                        <v-text-field outlined v-model="charity.amount" label="Amount">
                                                        </v-text-field>
                                                        <v-textarea outlined v-model="charity.message"
                                                            label="Leave a message for this charity">
                                                        </v-textarea>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '1'">
                                                        <v-btn @click="selected_charities.splice(index, 1)" text
                                                            color="red">
                                                            Remove
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-title style="word-break: break-word;">
                                                Primary beneficiaries of your residual estate
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                Your residuary estate comprises all remaining money and assets after
                                                your Executors have distributed your specified gifts and paid all
                                                expenses, such as inheritance tax and debts. Individual items are not
                                                listed because the list would be too long and subject to change over
                                                your lifetime, making it uncertain what you will own at the time of your
                                                death.
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <v-row v-for="(estate, index) in estates" :key="index">
                                                    <v-col :cols="isMobile() ? '12' : '6'">
                                                        <v-text-field v-model="estate.beneficiary" outlined
                                                            label="Beneficiary's Full Name"></v-text-field>
                                                        <v-select outlined v-model="estate.relationship"
                                                            :items="['Charity', 'Husband', 'Wife', 'Partner', 'Father', 'Mother', 'Brother', 'Sister', 'Son', 'Daughter', 'Stepson', 'Stepdaughter', 'Cousin', 'Nephew', 'Niece', 'Grandchild', 'Great Grandchild', 'Godchild', 'Other', 'Neighbour', 'Friend',]"
                                                            label="Relationship to you">
                                                        </v-select>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '5'">
                                                        <v-text-field outlined v-model="estate.percent"
                                                            label="Percentage" type="number">
                                                        </v-text-field>
                                                        <v-text-field outlined v-model="estate.address"
                                                            label="Address (optional)">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '1'">
                                                        <v-btn @click="estates.splice(index, 1)" text
                                                            color="red">Remove</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-btn @click="estates.push({})" outlined>Add Beneficiary</v-btn>
                                            </v-card-text>
                                            <v-card-title style="word-break: break-word;">
                                                Takers of last resort
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                If all named beneficiaries do not survive you, any remaining property
                                                will be inherited by the takers of last resort.
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <v-row v-for="(taker, index) in takers" :key="index">
                                                    <v-col :cols="isMobile() ? '12' : '6'">
                                                        <v-text-field v-model="taker.name" outlined
                                                            label="Taker's Full Name"></v-text-field>
                                                        <v-select outlined v-model="taker.relationship"
                                                            :items="['Charity', 'Husband', 'Wife', 'Partner', 'Father', 'Mother', 'Brother', 'Sister', 'Son', 'Daughter', 'Stepson', 'Stepdaughter', 'Cousin', 'Nephew', 'Niece', 'Grandchild', 'Great Grandchild', 'Godchild', 'Other', 'Neighbour', 'Friend',]"
                                                            label="Relationship to you">
                                                        </v-select>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '5'">
                                                        <v-text-field outlined v-model="taker.percent"
                                                            label="Percentage" type="number">
                                                        </v-text-field>
                                                        <v-text-field outlined v-model="taker.address"
                                                            label="Address (optional)">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '1'">
                                                        <v-btn @click="takers.splice(index, 1)" text
                                                            color="red">Remove</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-btn @click="takers.push({})" outlined>Add Taker of Last
                                                    Resort</v-btn>
                                            </v-card-text>
                                            <v-card-actions class="text-center">
                                                <v-btn @click="previousStep()" v-if="step > 1"
                                                    style="font-size:18px;text-transform:none;" outlined class="pa-5">
                                                    Previous
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="saveEstates()" color="rgb(23,44,84)" dark
                                                    style="font-size:18px;text-transform:none;" class="pa-5">
                                                    Save & Continue
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-stepper-content>
                                    <v-stepper-content step="5">
                                        <v-card elevation="0" :class="isMobile() ? '' : 'mx-10'">
                                            <v-card-title style="word-break: break-word;">
                                                Gifts
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                You have the option to leave a specific gift, such as real estate,
                                                vehicles, cash, or any other item, to a designated person or nonprofit
                                                organization of your choice.

                                                This section is entirely optional. If you prefer to skip this step,
                                                simply click 'Save & Continue' below.
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <v-row v-for="(gift, index) in gifts" :key="index">
                                                    <v-col :cols="isMobile() ? '12' : '6'">
                                                        <v-select outlined v-model="gift.type"
                                                            :items="['Real Estate', 'Vehicle', 'Cash', 'Digital', 'Other']"
                                                            label="Type">
                                                        </v-select>
                                                        <v-combobox v-model="gift.beneficiaries" :items="[]" multiple
                                                            outlined label="Beneficiaries" small-chips>
                                                            <template v-slot:no-data>
                                                                <v-list-item>
                                                                    <v-list-item-title>
                                                                        Press <kbd>enter</kbd> to add
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </template>
                                                        </v-combobox>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '5'">
                                                        <v-textarea outlined v-model="gift.description"
                                                            label="Description">
                                                        </v-textarea>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '1'">
                                                        <v-btn @click="gifts.splice(index, 1)" text
                                                            color="red">Remove</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-btn @click="gifts.push({})" outlined>Add Gift</v-btn>
                                            </v-card-text>
                                            <v-card-actions class="text-center">
                                                <v-btn @click="previousStep()" v-if="step > 1"
                                                    style="font-size:18px;text-transform:none;" outlined class="pa-5">
                                                    Previous
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="nextStep()" color="rgb(23,44,84)" dark
                                                    style="font-size:18px;text-transform:none;" class="pa-5">
                                                    Save & Continue
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-stepper-content>
                                    <v-stepper-content step="6">
                                        <v-card elevation="0" :class="isMobile() ? '' : 'mx-10'">
                                            <v-card-title style="word-break: break-word;">
                                                Funeral Details
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                Do you wish to leave any specific instructions for your funeral?
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <v-row>
                                                    <v-col :cols="isMobile() ? '12' : '4'">
                                                        <v-btn style="font-size:18px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="funeral_type == 1 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="funeral_type = 1">No
                                                            preference</v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '4'">
                                                        <v-btn style="font-size:18px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="funeral_type == 2 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="funeral_type = 2">
                                                            I would like to<br> be cremated
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '4'">
                                                        <v-btn style="font-size:18px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="funeral_type == 3 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="funeral_type = 3">
                                                            I would like a burial
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '4'">
                                                        <v-btn style="font-size:18px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="funeral_type == 4 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="funeral_type = 4">I would like
                                                            a<br> natural
                                                            burial</v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '4'">
                                                        <v-btn style="font-size:18px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="funeral_type == 5 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="funeral_type = 5">
                                                            I would like to donate<br> my body to science
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '12'" v-if="funeral_type > 1">
                                                        <v-textarea v-model="funeral_instructions" outlined
                                                            label="Additional Instructions"></v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-actions class="text-center">
                                                <v-btn @click="previousStep()" v-if="step > 1"
                                                    style="font-size:18px;text-transform:none;" outlined class="pa-5">
                                                    Previous
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="nextStep()" color="rgb(23,44,84)" dark
                                                    style="font-size:18px;text-transform:none;" class="pa-5">
                                                    Save & Continue
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-stepper-content>
                                    <v-stepper-content step="7">
                                        <v-card elevation="0" :class="isMobile() ? '' : 'mx-10'">
                                            <v-card-title style="word-break: break-word;">
                                                Executors of your Will
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                You may nominate an executor for your will here.
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <v-row v-for="(executor, index) in executors" :key="index">
                                                    <v-col :cols="isMobile() ? '12' : '6'">
                                                        <v-text-field outlined v-model="executor.name" label="Name">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '5'">
                                                        <v-select outlined v-model="executor.relationship"
                                                            :items="['Charity', 'Husband', 'Wife', 'Partner', 'Father', 'Mother', 'Brother', 'Sister', 'Son', 'Daughter', 'Stepson', 'Stepdaughter', 'Cousin', 'Nephew', 'Niece', 'Grandchild', 'Great Grandchild', 'Godchild', 'Other', 'Neighbour', 'Friend',]"
                                                            label="Relationship to you">
                                                        </v-select>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '1'">
                                                        <v-btn @click="executors.splice(index, 1)" text
                                                            color="red">Remove</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-btn @click="executors.push({})" outlined>Add Executor</v-btn>
                                            </v-card-text>
                                            <v-card-subtitle style="text-align:left;">
                                                We strongly recommend that you have the contents of this will reviewed
                                                by a solicitor so that it will help to further validate it, reduce the
                                                likelihood that it will be challenged in the future and help to speed up
                                                the process of probable for your loved ones. Would you like to have your
                                                will reviewed by a local solicitor?
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <v-row>
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="no_contest_clause == 1 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="no_contest_clause = 1">Yes</v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="no_contest_clause == 0 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="no_contest_clause = 0">No</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <!-- <v-card-title style="word-break: break-word;">
                                                Self-proving Affidavit
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                To create a self-proving affidavit, you will need to sign your will in
                                                the presence of a notary public. Doing so can ease the burden on your
                                                witnesses to validate your will and speed up the probate process for
                                                your loved ones.
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <h3 class="mb-5" style="text-align:left;">
                                                    Do you want to add a self-proving affidavit to your Will?
                                                </h3>
                                                <v-row>
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="affidavit == 1 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="affidavit = 1">Yes</v-btn>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '6' : '3'">
                                                        <v-btn style="font-size:24px;text-transform:none;width:100%;"
                                                            outlined
                                                            :class="affidavit == 0 ? 'pa-10 selected' : 'pa-10'"
                                                            @click="affidavit = 0">No</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text> -->
                                            <v-card-title style="word-break: break-word;">
                                                Personal Statement
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                If you wish, you can add a personal statement to your will. This section
                                                is often used to express spiritual or moral values to your next-of-kin,
                                                leave a letter to children or loved ones, convey additional messages to
                                                gift beneficiaries, or share life experiences. Please note, this is not
                                                a legally binding document, so it should not be used to bequeath gifts
                                                to your beneficiaries.
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <v-textarea v-model="personal_statement" outlined
                                                    label="Personal statement"></v-textarea>
                                            </v-card-text>
                                            <v-card-actions class="text-center">
                                                <v-btn @click="previousStep()" v-if="step > 1"
                                                    style="font-size:18px;text-transform:none;" outlined class="pa-5">
                                                    Previous
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="nextStep()" color="rgb(23,44,84)" dark
                                                    style="font-size:18px;text-transform:none;" class="pa-5">
                                                    <label v-if="will_id == null" style="cursor:pointer;">
                                                        Create Will
                                                    </label>
                                                    <label v-else style="cursor:pointer;">
                                                        Update Will
                                                    </label>
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-stepper-content>
                                    <v-stepper-content step="8">
                                        <v-card elevation="0" :class="isMobile() ? '' : 'mx-10'">
                                            <v-card-title style="word-break: break-word;">
                                                Additional Requests
                                            </v-card-title>
                                            <v-card-subtitle style="text-align:left;">
                                                You may add any additional requests you might have here.
                                            </v-card-subtitle>
                                            <v-card-text class="my-10">
                                                <v-row v-for="(additional, index) in additionals" :key="index">
                                                    <v-col :cols="isMobile() ? '12' : '11'">
                                                        <v-textarea outlined v-model="additional.request"
                                                            label="Request">
                                                        </v-textarea>
                                                    </v-col>
                                                    <v-col :cols="isMobile() ? '12' : '1'">
                                                        <v-btn @click="additionals.splice(index, 1)" text
                                                            color="red">Remove</v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-btn @click="additionals.push({})" outlined>Add Request</v-btn>
                                            </v-card-text>
                                            <v-card-actions class="text-center">
                                                <v-btn @click="previousStep()" v-if="step > 1"
                                                    style="font-size:18px;text-transform:none;" outlined class="pa-5">
                                                    Previous
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="nextStep()" color="rgb(23,44,84)" dark
                                                    style="font-size:18px;text-transform:none;" class="pa-5">
                                                    <label v-if="will_id == null" style="cursor:pointer;">
                                                        Create Will
                                                    </label>
                                                    <label v-else style="cursor:pointer;">
                                                        Update Will
                                                    </label>
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-stepper-content>
                                </v-col>
                            </v-row>
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
            </v-row>
        </v-row>
    </v-container>
</template>



<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        api_url: null,
        loading: false,
        solicitor_id: null,
        started: false,
        step: 1,
        new_user: {
            name: null,
            firstname: null,
            lastname: null,
            email: null,
            mobile: null,
            address: null,
            reach: null,
            birthday: null,
            gender_id: null,
            marital_status_id: null,
        },
        saving_user: false,
        menu: false,
        has_children: 0,
        children: [],
        has_underage: 0,
        guardians: [],
        pet_expenses: null,
        has_pets: 0,
        pets: [],
        has_caretakers: 0,
        caretakers: [],
        assets: [],
        assets_total: null,
        charities: [],
        selected_charities: [],
        gifts: [],
        estates: [],
        takers: [],
        funeral_type: null,
        funeral_instructions: null,
        executors: [],
        additionals: [],
        no_contest_clause: 0,
        affidavit: 0,
        personal_statement: null,
        completed: false,
        encrypted_id: null,
        editing: false,
        will_id: null,
    }),
    async mounted() {
        this.api_url = process.env.VUE_APP_API_URL
        this.scrollToElement()
        this.getData()
        this.getCharities()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                'auth/setMessage',
                { show: true, message: message },
                { root: 1 }
            )
        },
        convertGtag() {
            this.$gtag.event('conversion', {
                'send_to': 'AW-11041626133/JCTJCIvR87wZEJWwh5Ep'
            })
        },
        getData() {
            if (this.$router.currentRoute.name == 'Create Will') {
                this.solicitor_id = this.$router.currentRoute.params.solicitor_id
                if (this.get_user.id && this.get_user.role_id != 5) {
                    this.$axios.post('/auth/logout')
                    window.localStorage.clear()
                }
            }
            else if (this.get_user) {
                this.editing = true
                this.solicitor_id = this.get_user.solicitor_id
                this.new_user = this.get_user
                this.getWill()
            }
        },
        async getCharities() {
            await this.$axios.get('non_auth/get_charities')
                .then(({ data }) => {
                    if (data.response) {
                        this.charities = data.data
                    }
                })
        },
        resetWill() {
            this.step = 1
            this.completed = false
        },
        async getWill() {
            this.loading = true
            let payload = {
                client_id: this.get_user.id,
            }
            await this.$axios.get('auth/get_will', payload)
                .then(({ data }) => {
                    this.loading = false
                    if (data.response && data.data != null) {
                        this.will_id = data.data.id
                        this.has_children = data.data.has_children
                        this.has_underage = data.data.has_underage
                        this.funeral_type = data.data.funeral_type
                        this.funeral_instructions = data.data.funeral_instructions
                        this.children = JSON.parse(data.data.children)
                        this.guardians = JSON.parse(data.data.guardians)
                        this.pet_expenses = data.data.pet_expenses
                        this.pets = JSON.parse(data.data.pets)
                        this.has_pets = this.pets.length > 0 ? 1 : 0
                        this.caretakers = JSON.parse(data.data.caretakers)
                        this.has_caretakers = this.caretakers.length > 0 ? 1 : 0
                        this.assets = JSON.parse(data.data.assets)
                        this.assets_total = data.data.assets_total
                        this.selected_charities = JSON.parse(data.data.charities)
                        this.gifts = JSON.parse(data.data.gifts)
                        this.estates = JSON.parse(data.data.estates)
                        this.takers = JSON.parse(data.data.takers)
                        this.executors = JSON.parse(data.data.executors)
                        this.additionals = JSON.parse(data.data.additionals)
                        this.no_contest_clause = data.data.no_contest_clause
                        this.affidavit = data.data.affidavit
                        this.personal_statement = data.data.personal_statement
                    }
                })
        },
        nextStep() {
            this.step++
            this.scrollToElement()
            this.updateWill()
            if (this.step == 8) {
                this.completed = true
            }
        },
        previousStep() {
            this.step--
            this.scrollToElement()
        },
        async scrollToElement() {
            await this.$nextTick()
            document.getElementsByClassName('top')[0].scrollIntoView({ behavior: 'smooth' })
        },
        async saveNewUser() {
            if (this.editing == false) {
                this.saving_user = true
                let payload = {
                    solicitor_encrypted_id: this.solicitor_id,
                    ...this.new_user
                }
                await this.$axios.post('non_auth/create_will_user', payload)
                    .then(({ data }) => {
                        this.saving_user = false
                        if (data.response) {
                            this.new_user = data.data
                            this.new_user.password = data.password
                            this.convertGtag()
                            this.login()
                            this.createWill()
                        }
                        else {
                            this.showSnackBar(data.message)
                        }
                    })
            }
            else {
                this.saving_user = true
                let payload = {
                    ...this.new_user
                }
                await this.$axios.post('auth/update_user', payload)
                    .then(({ data }) => {
                        this.saving_user = false
                        if (data.response) {
                            this.nextStep()
                        }
                        else {
                            this.showSnackBar(data.message)
                        }
                    })
            }
        },
        addCharity(item) {
            if (!this.selected_charities.includes(item)) {
                this.selected_charities.push(item)
                this.showSnackBar('Added charity')
            }
        },
        saveEstates() {
            let total = 0
            let total_takers = 0
            this.estates.forEach(estate => {
                total += parseInt(estate.percent)
            })
            this.takers.forEach(taker => {
                total_takers += parseInt(taker.percent)
            })
            if (this.estates.length > 0 && (total != 100 || total == null)) {
                this.showSnackBar('The total percent of residuary estates must add up to 100%')
            }
            else if (this.takers.length > 0 && (total_takers != 100 || total_takers == null)) {
                this.showSnackBar('The total percent of takers of last resort must add up to 100%')
            }
            else {
                this.nextStep()
            }
        },
        async login() {
            let payload = {
                email: this.new_user.email,
                password: this.new_user.password,
            }
            await this.$axios.post('/non_auth/login', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('auth/set_user', data.data)
                        this.$store.dispatch('auth/set_token', data.token)
                        this.$router.push({ name: 'Edit Will' })
                        this.nextStep()
                    }
                })
        },
        async createWill() {
            if (this.will_id == null) {
                let payload = {
                    solicitor_id: this.solicitor_id,
                    client_id: this.new_user.id,
                    has_children: this.has_children ?? 0,
                    children: JSON.stringify(this.children),
                    has_underage: this.has_underage ?? 0,
                    guardians: JSON.stringify(this.guardians),
                    pet_expenses: this.pet_expenses ?? 0,
                    pets: JSON.stringify(this.pets),
                    caretakers: JSON.stringify(this.caretakers),
                    assets: JSON.stringify(this.assets),
                    assets_total: this.assets_total,
                    charities: JSON.stringify(this.selected_charities),
                    gifts: JSON.stringify(this.gifts),
                    estates: JSON.stringify(this.estates),
                    takers: JSON.stringify(this.takers),
                    funeral_type: this.funeral_type ?? 0,
                    funeral_instructions: this.funeral_instructions,
                    executors: JSON.stringify(this.executors),
                    additionals: JSON.stringify(this.additionals),
                    no_contest_clause: this.no_contest_clause,
                    affidavit: this.affidavit,
                    personal_statement: this.personal_statement,
                }
                await this.$axios.post('non_auth/create_will', payload)
                    .then(({ data }) => {
                        if (data.response) {
                            // this.completed = true
                            this.encrypted_id = data.data.encrypted_id
                            this.will_id = data.data.id
                        }
                    })
            }
            else {
                this.updateWill()
            }
        },
        async updateWill() {
            let payload = {
                has_children: this.has_children ?? 0,
                children: JSON.stringify(this.children),
                has_underage: this.has_underage ?? 0,
                guardians: JSON.stringify(this.guardians),
                pet_expenses: this.pet_expenses ?? 0,
                pets: JSON.stringify(this.pets),
                caretakers: JSON.stringify(this.caretakers),
                assets: JSON.stringify(this.assets),
                assets_total: this.assets_total,
                charities: JSON.stringify(this.selected_charities),
                gifts: JSON.stringify(this.gifts),
                estates: JSON.stringify(this.estates),
                takers: JSON.stringify(this.takers),
                funeral_type: this.funeral_type ?? 0,
                funeral_instructions: this.funeral_instructions,
                executors: JSON.stringify(this.executors),
                additionals: JSON.stringify(this.additionals),
                no_contest_clause: this.no_contest_clause,
                affidavit: this.affidavit,
                personal_statement: this.personal_statement,
            }
            await this.$axios.post(`auth/update_will/${this.will_id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        // this.completed = true
                        this.encrypted_id = data.data.encrypted_id
                    }
                })
        },
        previewWill() {
            this.$router.push({ name: 'View Will', params: { will_id: this.encrypted_id } })
        },
        redirectToLogin() {
            this.$router.push({ name: '/' })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.selected {
    background-color: rgb(23, 44, 84);
    color: white;
}
</style>