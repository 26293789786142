<template>
    <v-row justify="center">
        <v-dialog
            :value="value"
            persistent
            max-width="560"
        >
        <v-card>
            <v-card-title class="text-h5">
                Sort & Filter
            </v-card-title>
            <v-card-text
                class="mt-5"
            >
                <v-row>
                    <v-col
                        cols="6"
                    >
                        <v-select
                          :items="sorts"
                          v-model="selected_sort"
                          placeholder="Sort By"
                          item-text="name"
                          item-value="value"
                        >
                          <template v-slot:append>
                            <v-icon color="blue" class="mt-1" style="margin-left:-15px;">mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                        <v-select
                          :items="asc_desc"
                          v-model="selected_asc_desc"
                          placeholder="Order"
                          item-text="name"
                          item-value="value"
                        >
                          <template v-slot:append>
                            <v-icon color="blue" class="mt-1" style="margin-left:-15px;">mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                    </v-col>
                    <v-col
                        cols="6"
                    >
                        <v-select
                          chips
                          :items="statuses"
                          v-model="selected_status"
                          placeholder="Filter By"
                          item-text="name"
                          item-value="id"
                        >
                          <template v-slot:append>
                            <v-icon color="blue" class="mt-1" style="margin-left:-15px;">mdi-chevron-down</v-icon>
                          </template>
                          <template #selection="{ item }">
                            <v-chip
                                style="color:white;width:90%;cursor: pointer;"
                                :class="(item.id == 1) ? 'status_1' :
                                        (item.id == 2) ? 'status_2' :
                                        (item.id == 3) ? 'status_3' :
                                        (item.id == 4) ? 'status_4' :
                                        (item.id == 5) ? 'status_5' : 'status_0'"
                            >
                              <strong style="font-size:12px;">{{ item.name }}</strong>
                            </v-chip>
                          </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                v-if="selected_sort != null || selected_asc_desc != null || selected_status != null"
                color="green darken-1"
                text
                @click="clear_all"
            >
                Clear all
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="cancel_dialog_sort_filter"
            >
                Cancel
            </v-btn>
            <v-btn
                color="#1976D2"
                dark
                class="pl-10 pr-10"
                @click="apply_sort_filter"
            >
                <v-icon
                    class="mr-2"
                >mdi-check</v-icon>
                Apply
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
    'value'
  ],
  data: () => ({
    sorts: [ 
        {
            name: 'Client',
            value: 'get_user_info.name' 
        },
        {
            name: 'Last login',
            value: 'get_user_info.updated_at'
        },
        
     ],
    asc_desc: [ 
        {
            name: 'Ascending',
            value: false
        },
        {
            name: 'Descending',
            value: true
        },
        
     ],
    statuses: [
        {
            name: 'BOOKED APPT.',
            id: 1
        },
        {
            name: 'CALLED',
            id: 2
        },
        {
            name: 'FOLLOW-UP',
            id: 3
        },
        {
            name: 'NEW LEAD',
            id: 4
        },
        {
            name: 'PLAN SOLD',
            id: 5
        }
    ],
    selected_sort: null,
    selected_asc_desc: null,
    selected_status: null,
  }),
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_status:             'fd_client/get_status',
        fd_data:                'auth/get_user'
    })
  },
  methods: {
    showSnackBar(message){
        this.$store.commit("auth/setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    cancel_dialog_sort_filter(){
        this.$emit('cancel_dialog_sort_filter')
    },
    async apply_sort_filter(){
        let payload = {
            sort_by: this.selected_sort,
            asc_desc: this.selected_asc_desc,
            filter_by: this.selected_status
        }
        this.$emit('apply_sort_filter', payload)
      },
    clear_all(){
        this.$emit('clear_all')
        this.selected_sort = null
        this.selected_asc_desc = null
        this.selected_status = null
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-input__control>.v-input__slot:before, ::v-deep .v-input__control>.v-input__slot:after {
  border-style:none!important;
}
.status_0, .status_1, .status_2, .status_3, .status_4, .status_5 { justify-content: center; }
.status_1 { background-color:#DEF3FF!important;color:#347CE9!important; }
.status_2 { background-color:#FFFADE!important;color:#172C54!important; }
.status_3 { background-color:#FFEEE4!important;color:#CE6300!important; }
.status_4 { background-color:#FAFAFA!important;color:#347CE9!important; }
.status_5 { background-color:#F1FFEC!important;color:#219226!important; }
</style>