<template>
    <v-container>
        <v-row justify="center" align="center">
            <v-card width="100%" elevation="0">
                <v-stepper v-model="e1" :alt-labels="isMobile() ? false : true"
                    :style="isMobile() ? 'background: none !important; width: 380; position: relative; top: -55px;' : 'background: none !important;'">
                    <v-stepper-header elevation="0">
                        <v-stepper-step :complete="e1 > 1" step="1">
                            <small class="header_title">Funeral Service</small>
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="e1 > 2" step="2">
                            <small class="header_title">Ceremony</small>
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="e1 > 3" step="3">
                            <small class="header_title">Wake</small>
                        </v-stepper-step>

                        <!-- <v-divider/> -->

                        <!-- <v-stepper-step
                            :complete="e1 > 4"
                            step="4"
                        >
                            <small>Budget</small>
                        </v-stepper-step> -->

                        <v-divider />

                        <v-stepper-step :complete="e1 > 4" step="4">
                            <small class="header_title">Personal Wishes</small>
                        </v-stepper-step>

                        <v-divider />

                        <v-stepper-step :complete="e1 > 5" step="5">
                            <small class="header_title">Funeral Cost</small>
                        </v-stepper-step>

                        <v-divider />

                        <!-- <v-stepper-step
                            :complete="e1 > 6"
                            step="6"
                        >
                            <small class="header_title">Payment Preference</small>
                        </v-stepper-step>

                        <v-divider/> -->

                        <v-stepper-step :complete="e1 > 6" step="6">
                            <small class="header_title">Contact Info</small>
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items class="mt-5">
                        <v-row justify="center" align="center">
                            <v-stepper-content step="1">
                                <FuneralService @next="next" />
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <Ceremony @next="next" @back="back" />
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                <Wake @next="next" @back="back" />
                            </v-stepper-content>

                            <!-- <v-stepper-content step="4">
                                <Budget
                                    @next="next"
                                    @back="back"
                                />
                            </v-stepper-content> -->

                            <v-stepper-content step="4">
                                <PersonalWishes @next="next" @back="back" />
                            </v-stepper-content>

                            <v-stepper-content step="5">
                                <FuneralCost @next="next" @back="back" />
                            </v-stepper-content>

                            <!-- <v-stepper-content step="6">
                                <Payment
                                    @next="next"
                                    @back="back"
                                />
                            </v-stepper-content> -->

                            <!-- <v-stepper-content step="6">
                                <PersonalWishes
                                    @next="next"
                                    @back="back"
                                />
                            </v-stepper-content> -->

                            <v-stepper-content step="6">
                                <ContactForm />
                            </v-stepper-content>
                        </v-row>
                    </v-stepper-items>
                </v-stepper>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import FuneralService from './inner/FuneralService.vue'
import Wake from './inner/WakeComponent.vue'
import Ceremony from './inner/CeremonyComponent.vue'
// import Budget from './inner/BudgetComponent.vue'
import FuneralCost from './inner/FuneralCostComponent.vue'
// import Payment from './inner/PaymentComponent.vue'
import PersonalWishes from './inner/PersonalWishes.vue'
import ContactForm from './inner/ContactForm.vue'
export default {
    components: {
        FuneralService,
        Wake,
        Ceremony,
        // Budget,
        FuneralCost,
        // Payment,
        PersonalWishes,
        ContactForm,
    },
    props: [
    ],
    data: () => ({
        e1: 1,
    }),
    mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        next() {
            this.e1++
        },
        back() {
            this.e1--
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.header_title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
</style>