import Vue from 'vue'

export default{
    namespaced: true,
    state: {
        clients: [],
        fd_list: [],
        client_gender: {},
        marital_status: {},
        has_special_child: {},
        children: [],
        pets: {},
        beneficiaries: [],
        residual_state: [],
        digital_representative: [],
        digital_personal_representative: [],
        client_funeral: []
    },
    actions: {
        async get_whole_clients({commit}){
            await Vue.axios.get('/auth/admin/fd_clients/get_whole_clients')
            .then(({data}) => {
                commit('set_clients', data.data)
            })
        },
        async fd_list({commit}){
            await Vue.axios.get('/auth/admin/fd_clients/fd_list')
            .then(({data}) => {
                commit('set_fd_list', data.data)
            })
        },
        async fetch_client_gender({commit}, payload){
            await Vue.axios.get('/auth/info/get_gender', {
                id: payload
            })
            .then(({data}) => {
                commit('set_client_gender', data.data)
            })
        },
        async get_marital_status({commit}, payload){
            await Vue.axios.get('/auth/info/get_marital_status', {
                id: payload
            })
            .then(({data}) => {
                commit('set_marital_status', data.data)
            })
        },
        async get_special_children({commit}, payload){
            await Vue.axios.get('/auth/info/has_special_children', {
                id: payload
            })
            .then(({data}) => {
                commit('set_has_special_child', data.data)
            })
        },
        async get_children({commit}, payload){
            await Vue.axios.get('/auth/info/get_children', {
                id: payload
            })
            .then(({data}) => {
                commit('set_children', data.data)
            })
        },
        async get_pets({commit}, payload){
            await Vue.axios.get('/auth/info/get_pets', {
                id: payload
            })
            .then(({data}) => {
                commit('set_pets', data.data)
            })
        },
        async get_beneficiaries({commit}, payload){
            await Vue.axios.get('/auth/info/get_beneficiaries', {
                id: payload
            })
            .then(({data}) => {
                commit('set_beneficiaries', data.data)
            })
        },
        async get_residual_state({commit}, payload){
            await Vue.axios.get('/auth/info/get_residual_state', {
                id: payload
            })
            .then(({data}) => {
                commit('set_residual_state', data.data)
            })
        },
        async get_digital_representative({commit}, payload){
            await Vue.axios.get('/auth/info/get_digital_representative', {
                id: payload
            })
            .then(({data}) => {
                commit('set_digital_representative', data.data)
            })
        },
        async get_digital_personal_representative({commit}, payload){
            await Vue.axios.get('/auth/info/get_digital_personal_representative', {
                id: payload
            })
            .then(({data}) => {
                commit('set_digital_personal_representative', data.data)
            })
        },
        async fetch_client_funeral({commit}, payload){
            await Vue.axios.get(`/auth/client/client_funeral/${payload}`)
            .then(({data}) => {
                commit('set_client_funeral', data.data)
            })
        }
    },
    mutations: {
        set_clients(state, payload){
            state.clients = [...payload]
        },
        set_fd_list(state, payload){
            state.fd_list = [...payload]
        },
        set_client_gender(state, payload){
            state.client_gender = payload
        },
        set_marital_status(state, payload){
            state.marital_status = payload
        },
        set_has_special_child(state, payload){
            state.has_special_child = payload
        },
        set_children(state, payload){
            state.children = [...payload]
        },
        set_pets(state, payload){
            state.set_pets = payload
        },
        set_beneficiaries(state, payload){
            state.beneficiaries = [...payload]
        },
        set_residual_state(state, payload){
            state.residual_state = [...payload]
        },
        set_digital_representative(state, payload){
            state.digital_representative = [...payload]
        },
        set_digital_personal_representative(state, payload){
            state.digital_personal_representative = [...payload]
        },
        set_client_funeral(state, payload){
            state.client_funeral = [...payload]
        }
    },
    getters: {
        get_clients: state => (search, filter) => {
            if (filter !== null) {
                return state.clients.filter(q => {
                    if (search !== null && q.get_user_info != null) {
                        return q.get_status.id == filter && q.get_user_info.name.toLowerCase().includes(search)
                    }
                    return q.get_status.id == filter
                })
            }
            else if (search !== null) {
                return state.clients.filter(q => {
                    if (q.get_user_info != null){
                        return q.get_user_info.name.toLowerCase().includes(search)
                    }
                })
            }
            else {
                return state.clients
            }
        },
        get_clients_by_id: state => (search) => {
            if (search !== null) {
                return state.clients.filter(q => {
                    if (q.get_user_info != null) {
                        return search.includes(q.get_user_info.id)
                    }
                })
            }
            else {
                return null
            }
        },
        get_fd_list : state => state.fd_list,
        get_client_gender : state => state.client_gender,
        get_marital_status : state => state.marital_status,
        get_has_special_child : state => state.has_special_child,
        get_children : state => state.children,
        get_pets : state => state.pets,
        get_beneficiaries : state => state.beneficiaries,
        get_residual_state : state => state.residual_state,
        get_digital_representative : state => state.digital_representative,
        get_digital_personal_representative : state => state.digital_personal_representative,
        get_client_funeral : state => state.client_funeral,
        get_client_survey : state => state.funeral_director_client.get_user_info.get_survey
    }
}