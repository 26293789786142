<template>
    <v-container fluid>
        <v-row justify="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <v-img :src="require('../../assets/clientpage.svg')" class="mr-3" contain max-width="35"
                        max-height="35" />
                    <strong style="font-size:26px;">Solicitors</strong>
                    <v-spacer />
                </v-card-title>
                <v-card-title class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
                    <small style="position: relative; top: -10px; font-size:12px;">
                        Showing {{ solicitors.length }} solicitors
                    </small>
                </v-card-title>
            </v-card>
            <v-card width="90%" style="border-radius: 16px" elevation="0"
                :class="isMobile() ? 'mobile_card' : 'desktop_card'">
                <v-card-text class="mt-5">
                    <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="headers"
                        :items="solicitors" :items-per-page="10" :mobile-breakpoint="0" hide-default-header
                        :sort-by.sync="sort_by" :sort-desc.sync="sortDesc">
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr v-if="!isMobile()">
                                    <th style="padding-bottom:30px;padding-top:10px;width:25%" v-for="h in headers"
                                        :key="h.index">
                                        <div style="text-align:center;" v-if="h.text">
                                            <strong>{{ h.text }}</strong>
                                        </div>
                                        <div style="text-align:end;" v-if="h.value == 'add'">
                                            <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                                style="height:40px;text-transform: none !important"
                                                @click="openCreateSolicitorDialog()">
                                                Add a solicitor <v-icon class="ml-2">mdi-plus</v-icon>
                                            </v-btn>
                                        </div>
                                    </th>
                                </tr>
                                <tr v-else>
                                    <th style="padding-bottom:30px;padding-top:10px;width:25%;border-bottom:none;">
                                        <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                            style="height:40px;text-transform: none !important"
                                            @click="openCreateSolicitorDialog()">
                                            Add a solicitor <v-icon class="ml-2">mdi-plus</v-icon>
                                        </v-btn>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="{ item, headers }">
                            <tr class="mx-5" style="background-color: transparent;cursor: pointer;"
                                @click="selectSolicitor(item)" v-if="item != null && !isMobile()">
                                <td style="width:25%">
                                    <v-layout align-center class="ms-5">
                                        <v-avatar color="#4374F3" size="42"
                                            style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                            <strong style="color:white;font-size:22px;">{{ item.name[0] }}</strong>
                                        </v-avatar>
                                        <strong style="font-size:16px;" class="ms-5">{{ item.name }}</strong>
                                    </v-layout>
                                </td>
                                <td style="width:25%">
                                    {{ item.email }}
                                </td>
                                <td style="width:15%">
                                    <v-layout justify-center>
                                        {{ $date(item.updated_at).format("DD MMM YYYY") }}
                                        <strong
                                            style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                                        {{ $date(item.updated_at).format("hh:mm a") }}
                                    </v-layout>
                                </td>
                                <td style="width:15%">
                                    <v-btn text color="#347CE9"
                                        style="text-transform: none !important; float:right;font-size:14px;">
                                        View profile
                                    </v-btn>
                                </td>
                            </tr>
                            <tr class="mx-5" style="background-color: transparent;cursor: pointer;"
                                @click="selectSolicitor(item)" v-if="item != null && isMobile()">
                                <td style="width:100%" class="py-5">
                                    <v-row>
                                        <v-col cols="3" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[0].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="9">
                                            <v-layout justify-center align-center class="ms-5">
                                                <v-avatar color="#4374F3" size="42"
                                                    style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                                    <strong style="color:white;font-size:22px;">
                                                        {{ item.name[0] }}
                                                    </strong>
                                                </v-avatar>
                                                <strong style="font-size:16px;" class="ms-5">{{ item.name }}</strong>
                                            </v-layout>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[1].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="8">
                                            {{ item.email }}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[2].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="9">
                                            <v-layout justify-center>
                                                {{ $date(item.updated_at).format("DD MMM YYYY") }}
                                                <strong
                                                    style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                                                {{ $date(item.updated_at).format("hh:mm a") }}
                                            </v-layout>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn text color="#347CE9"
                                                style="text-transform: none !important; float:left;font-size:14px;margin-left:-15px;">
                                                Edit
                                                <v-img width="25" height="25" :src="require('../../assets/edit.svg')"
                                                    contain />
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
        <!--TODO component-->
        <v-row justify="center">
            <v-dialog v-model="create_solicitor_dialog" persistent max-width="560">
                <v-card>
                    <v-card-title class="text-h5"> Add a solicitor</v-card-title>
                    <v-card-text>
                        <v-row class="mt-5">
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong> First Name*</strong>
                                <v-text-field dense filled v-model="new_solicitor.firstname">
                                </v-text-field>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong> Last Name*</strong>
                                <v-text-field dense filled v-model="new_solicitor.lastname"> </v-text-field>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong> Email Address*</strong>
                                <v-text-field dense filled v-model="new_solicitor.email"> </v-text-field>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong> Mobile</strong>
                                <v-text-field dense filled type="number" v-model="new_solicitor.mobile">
                                </v-text-field>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong> Password*</strong>
                                <v-text-field dense filled v-model="new_solicitor.password">
                                </v-text-field>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong> Repeat Password*</strong>
                                <v-text-field dense filled v-model="new_solicitor.repeat_password">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeCreateSolicitorDialog()">
                            Cancel
                        </v-btn>
                        <v-btn color="#172C54" dark class="pl-10 pr-10" @click="createSolicitor()">
                            Add
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {},
    props: [],
    data: () => ({
        solicitors: [],
        new_solicitor: {
            firstname: null,
            lastname: null,
            email: null,
            mobile: null,
            password: null,
            repeat_password: null
        },
        headers: [
            { text: 'Solicitor', value: 'name' },
            { text: 'Email', value: 'email' },
            { text: 'Last login', value: 'updated_at' },
            { value: 'add' },
        ],
        sort_by: 'created_at',
        sortDesc: true,
        create_solicitor_dialog: false,
    }),
    async mounted() {
        this.getSolicitors()
    },
    created() { },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                'auth/setMessage',
                { show: true, message: message },
                { root: 1 }
            )
        },
        async getSolicitors() {
            await this.$axios.get('auth/admin/wills/get_solicitors')
                .then(({ data }) => {
                    if (data.response) {
                        this.solicitors = data.data
                    }
                })
        },
        selectSolicitor(item) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            console.log(item)
        },
        openCreateSolicitorDialog() {
            this.create_solicitor_dialog = true
        },
        closeCreateSolicitorDialog() {
            this.create_solicitor_dialog = false
            this.new_solicitor = {
                firstname: null,
                lastname: null,
                email: null,
                mobile: null,
                password: null,
                repeat_password: null,
            }
        },
        async createSolicitor() {
            if (this.new_solicitor.password != this.new_solicitor.repeat_password) {
                this.showSnackBar('Password does not match')
                return
            }
            await this.$axios.post('auth/admin/wills/create_solicitor', this.new_solicitor)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeCreateSolicitorDialog()
                        this.getSolicitors()
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
    border-radius: 5px;
    margin-top: -2px;
}

::v-deep .liitme .v-input__slot {
    background-color: white;
    padding: 7px;
}

table {
    border-collapse: collapse;
}

// remove border
table td,
table th {
    height: 40px; //change the height
}

.v-data-table.row-height-50 td {
    height: 100px !important;
}

.v-data-table::v-deep th {
    font-size: 16px !important;
    font-weight: bold;
}

.mobile_table td {
    height: 100px !important;
}

.desktop_table td {
    height: 100px !important;
}

.mobile_card {
    margin-top: 100px;
}

.desktop_table {
    padding-left: 30px;
    padding-right: 30px;
}

.mobile_sort {
    margin-top: -40px;
}

::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
    border-style: none !important;
}

.status_0,
.status_1,
.status_2 {
    justify-content: center;
}

.status_1 {
    background-color: #FAFAFA !important;
    color: #172C54 !important;
}

.status_2 {
    background-color: #4786FF !important;
    color: #FFF !important;
}
</style>