<template>
    <v-container fluid>
        <v-row
            justify="center"
            align="center"
            class="mt-15"
        >
            <v-card
                :width="isMobile() ? '95%' : '75%'"
                class="mt-5"
                elevation="0"
            >
                <v-card-title>
                    <v-img
                        :src="require('../../../../assets/activity_will_dark.svg')"
                        contain
                        max-width="50"
                        max-height="50"
                        class="mr-3"
                    />
                    <h2>Make My Last Will</h2>
                </v-card-title>
                <v-card-title>
                    <v-stepper 
                        :alt-labels="true" 
                        style="width: 2000px;"
                        v-model="get_last_will_el"
                        elevation="0"
                    >
                        <v-stepper-header>
                            <v-stepper-step
                                v-for="(step, stepindex) in steps_data"
                                :key="stepindex"
                                :step="step.step"
                                :style="isMobile() ? 'padding:0!important' : ''"
                                :complete="get_last_will_el > step.step"
                            >
                                <template v-slot:default>
                                    <small>{{step.label}}</small>
                                </template>
                            </v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content
                                v-for="(step, index) in steps_data" 
                                :step="step.step" 
                                :key="index"
                            >
                                <div
                                    v-if="step.step === 1"
                                >
                                    <Basic/>
                                </div>
                                <div
                                    v-if="step.step === 2"
                                >
                                    <Dependent/>
                                </div>
                                <div
                                    v-if="step.step === 3"
                                >
                                    <SpecialGifts/>
                                </div>
                                <div
                                    v-if="step.step === 4"
                                >
                                    <ResidualState/>
                                </div>
                                <div
                                    v-if="step.step === 5"
                                >
                                    <PersonalRep/>
                                </div>
                                <div
                                    v-if="step.step === 6"
                                >
                                    <DigitalPersonal/>
                                </div>
                                <div
                                    v-if="step.step === 7"
                                >
                                    <ReviewAndPay/>
                                </div>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-title>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Basic from './InnerMostComponents/LastWillBasicComponent.vue'
import Dependent from './InnerMostComponents/DependentsComponent.vue'
import SpecialGifts from './InnerMostComponents/SpecialGifts.vue'
import ResidualState from './InnerMostComponents/ResidualState.vue'
import PersonalRep from './InnerMostComponents/PersonalRep.vue'
import DigitalPersonal from './InnerMostComponents/DigitalPersonal.vue'
import ReviewAndPay from './InnerMostComponents/ReviewAndPay.vue'
export default {
  components: {
    Basic,
    Dependent,
    SpecialGifts,
    ResidualState,
    PersonalRep,
    DigitalPersonal,
    ReviewAndPay
  },
  props: [
  ],
  data: () => ({
    steps_data: [
        
        {
            label:  'Basic',
            step:   1
        },
        {
            label:  'Dependents',
            step:   2
        },
        {
            label:  'Distribution of special gifts',
            step:   3
        },
        {
            label:  'Distribution of residual state',
            step:   4
        },
        {
            label:  'Personal representative',
            step:   5
        },
        {
            label:  'Digital personal representative',
            step:   6
        },
        {
            label:  'Review & Pay',
            step:   7
        }
    ]
  }),
  async mounted () {
    await this.$store.dispatch('client_profile/fetch_marital_status')
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_last_will_el:       'client_activity/get_last_will_el'
    })
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.v-stepper__step {
  flex: 1 1;
}
</style>