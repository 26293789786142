import Vue from "vue"

export default{
    namespaced: true,
    state: {
        snackbar: {
            show: false,
            message: "",
            button: false,
        },
        user: {},
        token: '',
        children: [],
        pets: [],
        beneficiaries: [],
        residual_state: [],
        digital_persona: [],
        digital_representative: []
    },
    actions: {
        set_user({commit}, payload){
            commit('set_user', payload)
        },
        async fetch_user({ commit }) {
            await Vue.axios.get('/auth/get_user')
                .then(({ data }) => {
                    commit('set_user', data.data)
            })
        },
        set_token({commit}, payload){
            commit('set_token', payload)
        },
        set_user_info({commit}, payload){
            commit('set_user_info', payload)
        },
        set_children({commit}, payload){
            commit('set_children', payload)
        },
        async fetch_children({commit}){
            await Vue.axios.get('/auth/client/get_children')
            .then(({data}) => {
                commit('set_children', data.data)
            })
        },
        set_pets({commit}, payload){
            commit('set_pets', payload)
        },
        async fetch_pets({commit}){
            Vue.axios.get('auth/client/pets/get_pets')
            .then(({data}) => {
                commit('set_pets', data.data)
            })
        },
        set_beneficiaries({commit}, payload){
            commit('set_beneficiaries', payload)
        },
        async fetch_beneficiaries({commit}){
            await Vue.axios.get('/auth/client/beneficiary/get_beneficiaries')
            .then(({data}) => {
                commit('set_beneficiaries', data.data)
            })
        },
        set_residual_state({commit}, payload){
            commit('set_residual_state', payload)
        },
        async fetch_residual_state({commit}){
            await Vue.axios.get('/auth/client/residual/get_residual_state')
            .then(({data}) => {
                commit('set_residual_state', data.data)
            })
        },
        set_digital_persona({commit}, payload){
            commit('set_digital_persona', payload)
        },
        fetch_digital_persona({commit}){
            Vue.axios.get('/auth/client/personas/get_digital_persona')
            .then(({data}) => {
                commit('set_digital_persona', data.data)
            })
        },
        set_digital_representative({commit}, payload){
            commit('set_digital_representative', payload)
        },
        async fetch_digital_representative({commit}){
            await Vue.axios.get('/auth/client/digital_representative/get_digital_representative')
            .then(({data}) => {
                commit('set_digital_representative', data.data)
            })
        }
    },
    mutations: {
        setMessage(state, v) {
            state.snackbar.show = v.show
            state.snackbar.message = v.message
            state.snackbar.button = v.button
        },
        set_user(state, payload){
            state.user = payload
        },
        set_token(state, payload){
            state.token = payload
        },
        set_empty_data(state){
            state.user = {}
            state.token = ""
        },
        set_user_info(state, payload){
            state.user = payload
        },
        set_children(state, payload){
            state.children = [...payload]
        },
        set_pets(state, payload){
            state.pets = [...payload]
        },
        set_beneficiaries(state, payload){
            state.beneficiaries = [...payload]
        },
        set_residual_state(state, payload){
            state.residual_state = [...payload]
        },
        set_digital_persona(state, payload){
            state.digital_persona = [...payload]
        },
        set_digital_representative(state, payload){
            state.digital_representative = [...payload]
        }
    },
    getters: {
        mSnackbar : state => state.snackbar,
        get_user : state => state.user,
        get_token : state => state.token,
        get_children : state => state.children,
        get_pets : state => state.pets,
        get_beneficiaries : state => state.beneficiaries,
        get_residual_state : state => state.residual_state,
        get_digital_persona : state => state.digital_persona,
        get_digital_representative : state => state.digital_representative
    }
}