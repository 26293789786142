import Vue from "vue";

export default {
    namespaced: true,
    state: {
        payment_terms: [],
        transactions: [],
    },
    mutations: {
        set_payment_terms(state, payload) {
            state.payment_terms = [...payload];
        },
        set_transactions(state, payload) {
            state.transactions = [...payload];
        },
    },
    getters: {
        get_payment_terms: (state) => state.payment_terms,
        get_transactions: state => (filter, date_range) => {
            if (filter == 'live') {
                return state.transactions.filter(q => {
                    q.baseamountactual = q.baseamount / 100
                    q.settlebaseamountactual = q.settlebaseamount / 100
                    if (date_range == null) {
                        return q.sitereference == 'myfarewell117269'
                    }
                    else if (q.settleduedate >= date_range[0] && q.settleduedate <= date_range[1]) {
                        return q.sitereference == 'myfarewell117269'
                    }
                })
            }
            else if (filter == 'test') {
                return state.transactions.filter(q => {
                    q.baseamountactual = q.baseamount / 100
                    q.settlebaseamountactual = q.settlebaseamount / 100
                    if (date_range == null) {
                        return q.sitereference == 'test_myfarewell117268'
                    }
                    else if (q.settleduedate >= date_range[0] && q.settleduedate <= date_range[1]) {
                        return q.sitereference == 'test_myfarewell117268'
                    }
                })
            }
        },
    },
    actions: {
        async fetch_payment_terms({ commit }) {
            await Vue.axios.get("auth/admin/payment/get_payment_terms")
                .then(({ data }) => {
                    commit("set_payment_terms", data.data);
                });
        },
        async fetch_transactions({ commit }) {
            await Vue.axios.get("auth/admin/payment/get_all_transactions")
                .then(({ data }) => {
                    let transactions = []
                    data.data.forEach($transaction => {
                        if ($transaction.transaction_history.responses[0].found > 0 && $transaction.get_user != null) {
                            $transaction.transaction_history.responses[0].records.forEach($record => {
                                $record['get_user'] = $transaction.get_user
                                transactions.push($record)
                            })
                        }
                    })
                    commit("set_transactions", transactions);
                });
        },
        async set_payment_terms({ commit }, payload) {
            commit("set_payment_terms", payload)
        }
    },
};
