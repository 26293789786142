<template>
    <v-container fluid>
        <v-row>
            <v-col v-for="(item, index) in wishes[wishes_index].data" :key="index" :cols="isMobile() ? 12 : 6">
                <v-card class="mb-5" elevation="0">
                    <v-card-title>
                        <label style="word-break: break-word;">{{ item.question }}</label>
                    </v-card-title>
                    <v-card-subtitle>
                        <i class="ml-5"
                            v-if="(user.get_survey.data[wishes[wishes_index].name] != null && user.get_survey.data[wishes[wishes_index].name][item.answer] == null) || user.get_survey.data[wishes[wishes_index].name][item.answer].length == 0">no
                            answer yet</i>
                        <label v-else-if="user.get_survey.data[wishes[wishes_index].name] != null && !item.is_active"
                            class="ml-3">
                            <small>Answer: </small><br><v-chip style="height:auto">
                                <strong style="white-space:pre-line;word-break: break-word;" v-if="item.answer == 'check_box'">
                                    <div v-for="(checkbox_item, checkbox_index) in user.get_survey.data[wishes[wishes_index].name][item.answer]"
                                        :key="checkbox_index">
                                        {{ checkbox_item }}
                                    </div>
                                </strong>
                                <strong style="white-space:pre-line;word-break: break-word;" v-else>
                                    {{ user.get_survey.data[wishes[wishes_index].name][item.answer] }}
                                </strong>
                            </v-chip>
                        </label>
                        <v-spacer />
                        <v-btn v-if="!item.is_active && item.is_editable" dark
                            :class="isMobile() ? 'mobile_answer' : 'desktop_answer'"
                            style="border-radius:10px;text-transform:none;background-color:#172C54;"
                            @click="toggle_answer(wishes_index, index, item.is_active)">
                            Edit
                        </v-btn>
                        <!--<v-btn v-else-if="item.is_editable" text :class="isMobile() ? 'mobile_answer' : 'desktop_answer'"
                        @click="toggle_answer(wishes_index, index, item.is_active)">
                        Dismiss
                    </v-btn>-->
                        <v-textarea v-if="item.is_active" rows="3" filled class="mt-2" label="Press ENTER to save"
                            style="white-space:pre-line;word-break:break-word;"
                            v-model="user.get_survey.data[wishes[wishes_index].name][item.answer]"
                            @keyup.enter="save_answer(wishes_index, index, item.is_active)">
                            <template v-slot:append>
                                <label class="mt-1 mr-3" v-if="!isMobile()"></label>
                                <v-icon @click="save_answer(wishes_index, index, item.is_active)">
                                    mdi-check
                                </v-icon>
                            </template>
                        </v-textarea>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'wishes',
        'wishes_index'
    ],
    async mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            user: "auth/get_user"
        })
    },
    data: () => ({
    }),
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        toggle_answer(wish_index, question_index, state) {
            let tp = {
                wish_index: wish_index,
                question_index: question_index,
                state: state
            }
            this.$emit('set_active', tp)
        },
        async save_answer(wish_index, question_index, state) {
            this.toggle_answer(wish_index, question_index, state)
            let wish_data = this.user.get_survey.data
            /*if (!Array.isArray(wish_data.funeral_service.check_box)) {
                wish_data.funeral_service.check_box = [wish_data.funeral_service.check_box]
            }*/
            let new_progress = this.user.progress
            let tp = {
                user_id: this.user.id,
                budget: wish_data.budget,
                ceremony: wish_data.ceremony,
                funeral_service: wish_data.funeral_service,
                payment_preference: wish_data.payment_preference,
                funeral_cost: wish_data.funeral_cost,
                personal_wishes: wish_data.personal_wishes,
                wake: wish_data.wake,
                progress: new_progress
            }
            await this.$axios.patch('/auth/client/activities/edit_client_survey', tp)
                .then(() => {
                    this.$store.dispatch('auth/fetch_user')
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.desktop_answer {
    margin-top: 15px;
}

.mobile_answer {
    margin-top: 25px;
    width: 100%;
    margin-bottom: 25px;
}
</style>