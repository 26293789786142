<template>
  <v-container fluid style="background-color: #f4f4f5 !important; height: 100%" class="pa-0 ma-0">
    <v-app-bar v-if="$vuetify.breakpoint.mdAndDown" height="100" color="#491655" dark elevation="0" app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <h4>Menu</h4>
    </v-app-bar>
    <v-card v-if="$vuetify.breakpoint.mdAndDown" width="256">
      <v-navigation-drawer v-model="drawer" floating app :mini-variant.sync="mini" :color="get_user.color"
        class="no-border shadow pt-2">
        <v-img v-if="get_user.company_logo == null" :src="require('../assets/mfw.png')" contain max-height="150"
          max-width="150" class="px-0 fill-height" style="position: relative; left: 60px"
          @click="$router.push({ name: '/funeral-director' })" />
        <v-img v-else :src="`${api_url}frontend/images/${get_user.company_logo}`" contain max-height="150"
          max-width="150" class="px-0 fill-height" style="position: relative; left: 60px"
          @click="$router.push({ name: '/funeral-director' })" />
        <v-card-text style="color: white !important">
          <v-list-item two-line>
            <v-avatar style="position: relative; right: 28px">
              <v-img :src="require('../assets/admin.svg')" />
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 12px; color: white">
                Welcome Back!
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap mt-1" style="font-size: 18px; color: white">
                <strong>{{ get_user.name }}</strong>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="width:15px;min-width:15px;padding:0px;margin-right:-15px" text color="white"
                  v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list style="padding-top:0px;padding-bottom:0px;">
                <v-list-item style="cursor: pointer" @click="open_edit_profile()">
                  <v-icon class="mr-2">mdi-pen</v-icon>
                  Edit
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="(item, itemindex) in navigation" :key="itemindex" link @click="$router.push(item.url)">
              <v-list-item-icon>
                <v-img :src="item.icon" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title :style="item.style">{{
                  item.label
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider style="width: 80%;" class="px-5 ml-5 mt-4"></v-divider>
        <div>
          <v-list>
            <v-list-item link @click="mini = !mini">
              <v-list-item-icon>
                <v-img :src="require('../assets/collapse.svg')" />
              </v-list-item-icon>
              <v-list-item-content style="color: white; font-size: 16px; font-weight: normal">
                Collapse
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <v-divider />
        <v-img :src="require('../assets/mfw.png')" contain max-height="150" max-width="150" class="px-0 fill-height"
          style="position: relative; left: 60px" />
        <template v-slot:append>
          <div>
            <v-list-item-group>
              <v-list>
                <v-list-item v-for="(item, itemindex) in navigation2" :key="itemindex" link @click="
                  item.url.name == '' ? logout_fn() : $router.push(item.url)
                  ">
                  <v-list-item-icon>
                    <v-img :src="item.icon" />
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title :style="item.style">{{
                      item.label
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-item-group>
          </div>
        </template>
      </v-navigation-drawer>
      <!-- collapse -->
    </v-card>
    <!-- non mobile -->
    <v-card v-else width="256">
      <v-navigation-drawer floating app :permanent="!$vuetify.breakpoint.smAndDown" :mini-variant.sync="mini"
        :color="get_user.color" class="no-border shadow pt-2">
        <v-img v-if="get_user.company_logo == null" :src="require('../assets/mfw.png')" contain max-height="150"
          max-width="150" class="px-0 fill-height" style="position: relative; left: 60px"
          @click="$router.push({ name: '/funeral-director' })" />
        <v-img v-else :src="`${api_url}frontend/images/${get_user.company_logo}`" contain max-height="150"
          max-width="150" class="px-0 fill-height" style="position: relative; left: 60px"
          @click="$router.push({ name: '/funeral-director' })" />
        <v-card-text style="color: white !important">
          <v-list-item two-line>
            <v-avatar style="position: relative; right: 28px">
              <v-img :src="require('../assets/admin.svg')" />
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 12px; color: white">
                Welcome Back!
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap mt-1" style="font-size: 18px; color: white">
                <strong>{{ get_user.name }}</strong>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="width:15px;min-width:15px;padding:0px;margin-right:-15px" text color="white"
                  v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list style="padding-top:0px;padding-bottom:0px;">
                <v-list-item style="cursor: pointer" @click="open_edit_profile()">
                  <v-icon class="mr-2">mdi-pen</v-icon>
                  Edit
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="(item, itemindex) in navigation" :key="itemindex" link @click="$router.push(item.url)">
              <v-list-item-icon>
                <v-img :src="item.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :style="item.style">
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider style="width: 80%;" class="px-5 ml-5 mt-4"></v-divider>
        <div>
          <v-list>
            <v-list-item link @click="mini = !mini">
              <v-list-item-icon>
                <v-img :src="require('../assets/collapse.svg')" />
              </v-list-item-icon>
              <v-list-item-content style="color: white; font-size: 16px; font-weight: normal">
                Collapse
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <v-divider />
        <v-img :src="require('../assets/mfw.png')" contain max-height="150" max-width="150" class="px-0 fill-height"
          style="position: relative; left: 60px" />
        <template v-slot:append>
          <div>
            <v-list-item-group>
              <v-list>
                <v-list-item v-for="(item, itemindex) in navigation2" :key="itemindex" link
                  @click="item.url.name == '' ? logout_fn() : $router.push(item.url)">
                  <v-list-item-icon>
                    <v-img :src="item.icon" />
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title :style="item.style">
                      {{ item.label }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-item-group>
          </div>
        </template>
      </v-navigation-drawer>
    </v-card>
    <main class="main">
      <slot />
      <EditProfile :edit_profile="edit_profile" :getuser="get_user" @close_edit_profile="close_edit_profile" />
    </main>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import EditProfile from "./Components/EditProfile.vue"
export default {
  components: {
    // Navigation
    EditProfile
  },
  data: () => ({
    api_url: null,
    mini: false,
    drawer: false,
    edit_profile: false,
    navigation: [
      {
        icon: require("../assets/home.svg"),
        label: "Dashboard",
        style: "color: white; font-size: 14px; font-weight: normal;",
        class: "ml-5",
        url: {
          name: "/funeral-director",
        },
      },
      {
        icon: require("../assets/client.svg"),
        label: "Preplanning Clients",
        style: "color: white; font-size: 14px; font-weight: normal;",
        class: "ml-5",
        url: {
          name: "/preplanning-clients",
        },
      },
      {
        icon: require("../assets/client.svg"),
        label: "Funeral Clients",
        style: "color: white; font-size: 14px; font-weight: normal;",
        class: "ml-5",
        url: {
          name: "/funeral-clients",
        },
      },
      {
        icon: require("../assets/survey.svg"),
        label: "Documents",
        style: "color: white; font-size: 14px; font-weight: normal;",
        url: {
          name: "/fd_documents",
        },
      },
      {
        icon: require("../assets/email.svg"),
        label: "Emails",
        style: "color: white; font-size: 14px; font-weight: normal;",
        url: {
          name: "/fd_emails",
        },
      },
      /*{
        icon: require("../assets/survey.svg"),
        label: "Funeral Notice Requests",
        style: "color: white; font-size: 14px; font-weight: normal;",
        url: {
          name: "/fd_funeral_notice",
        },
      },*/
    ],
    navigation2: [
      // {
      //     icon:       require('../assets/settings.svg'),
      //     label:      'Settings',
      //     style:      'color: white; font-size: 16px; font-weight: normal;',
      //     url:        {
      //         name:   '/organisation'
      //     }
      // },
      // {
      //     icon:       require('../assets/help.svg'),
      //     label:      'Help',
      //     style:      'color: white; font-size: 16px; font-weight: normal;'
      // },
      {
        icon: require("../assets/logout.svg"),
        label: "Logout",
        style: "color: white; font-size: 16px; font-weight: normal;",
        url: {
          name: "",
        },
      },
    ],
  }),
  created() {
    this.api_url = process.env.VUE_APP_API_URL
  },
  computed: {
    ...mapGetters({
      get_user: "auth/get_user",
      get_companies: "auth/get_companies",
      get_selected_company: "auth/get_selected_company",
    }),
  },
  methods: {
    async logout_fn() {
      await this.$axios.post("/auth/logout");
      this.$store.commit("auth/set_empty_data");
      this.$router.push({ name: "/" });
    },
    open_edit_profile() {
      this.edit_profile = true
    },
    close_edit_profile() {
      this.edit_profile = false
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        this.drawer = true
        return false
      }
    }
  },
};
</script>
<style scoped>
.no-border {
  border-radius: 0px !important;
}

.shadow {
  box-shadow: 0 2px 10px 0px rgb(0 0 0 / 20%);
}

::v-deep ::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.v-list-item--active {
  border-left: 0.2rem solid #76bdff;
}
</style>
