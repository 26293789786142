import Vue from 'vue'

export default{
    namespaced: true,
    state: {
        countries: [],
        states: [],
        cities: [],
        gender: [],
        marital_status: [],
        marital_status_client: [],
        has_special_child: {},
        children: [],
        pets: {},
        beneficiaries: [],
        residual_state: [],
        digital_representative: [],
        digital_personal_representative: [],
        client_funeral: [],
        invited: []
    },
    actions: {
        set_countries({commit}, payload){
            commit('set_countries', payload)
        },
        set_states({commit}, payload){
            commit('set_states', payload)
        },
        set_cities({commit}, payload){
            commit('set_cities', payload)
        },
        async fetch_gender({commit}){
            await Vue.axios.get('/auth/get_gender')
            .then(({data}) => {
                commit('set_gender', data.data)
            })
        },
        async fetch_marital_status({commit}){
            await Vue.axios.get('/auth/get_marital_status')
            .then(({data}) => {
                commit('set_marital_status', data.data)
            })
        },
        async get_marital_status({commit}, payload){
            await Vue.axios.get('/auth/info/get_marital_status', {
                id: payload
            })
            .then(({data}) => {
                commit('set_marital_status_client', data.data)
            })
        },
        async get_special_children({commit}, payload){
            await Vue.axios.get('/auth/info/has_special_children', {
                id: payload
            })
            .then(({data}) => {
                commit('set_has_special_child', data.data)
            })
        },
        async get_children({commit}, payload){
            await Vue.axios.get('/auth/info/get_children', {
                id: payload
            })
            .then(({data}) => {
                commit('set_children', data.data)
            })
        },
        async get_pets({commit}, payload){
            await Vue.axios.get('/auth/info/get_pets', {
                id: payload
            })
            .then(({data}) => {
                commit('set_pets', data.data)
            })
        },
        async get_beneficiaries({commit}, payload){
            await Vue.axios.get('/auth/info/get_beneficiaries', {
                id: payload
            })
            .then(({data}) => {
                commit('set_beneficiaries', data.data)
            })
        },
        async get_residual_state({commit}, payload){
            await Vue.axios.get('/auth/info/get_residual_state', {
                id: payload
            })
            .then(({data}) => {
                commit('set_residual_state', data.data)
            })
        },
        async get_digital_representative({commit}, payload){
            await Vue.axios.get('/auth/info/get_digital_representative', {
                id: payload
            })
            .then(({data}) => {
                commit('set_digital_representative', data.data)
            })
        },
        async get_digital_personal_representative({commit}, payload){
            await Vue.axios.get('/auth/info/get_digital_personal_representative', {
                id: payload
            })
            .then(({data}) => {
                commit('set_digital_personal_representative', data.data)
            })
        },
        async fetch_client_funeral({commit}, payload){
            await Vue.axios.get(`/auth/client/client_funeral/${payload}`)
            .then(({data}) => {
                // console.log(commit, data)
                commit('set_client_funeral', data.data)
            })
        },
        async fetch_invited({commit}){
            await Vue.axios.get('/auth/client/profile_share/get_invited')
            .then(({data}) => {
                // console.log(commit, data)
                commit('set_invited', data.data)
            })
        }
    },
    mutations: {
        set_countries(state, payload){
            state.countries = [...payload]
        },
        set_states(state, payload){
            state.states = [...payload]
        },
        set_cities(state, payload){
            state.cities = [...payload]
        },
        set_gender(state, payload){
            state.gender = [...payload]
        },
        set_marital_status(state, payload){
            state.marital_status = [...payload]
        },
        set_marital_status_client(state, payload){
            state.marital_status_client = payload
        },
        set_has_special_child(state, payload){
            state.has_special_child = payload
        },
        set_children(state, payload){
            state.children = [...payload]
        },
        set_pets(state, payload){
            state.set_pets = payload
        },
        set_beneficiaries(state, payload){
            state.beneficiaries = [...payload]
        },
        set_residual_state(state, payload){
            state.residual_state = [...payload]
        },
        set_digital_representative(state, payload){
            state.digital_representative = [...payload]
        },
        set_digital_personal_representative(state, payload){
            state.digital_personal_representative = [...payload]
        },
        set_client_funeral(state, payload){
            state.client_funeral = [...payload]
        },
        set_invited(state, payload){
            state.invited = [...payload]
        }
    },
    getters: {
        get_countries : state => state.countries,
        get_states : state => state.states,
        get_cities : state => state.cities,
        get_gender : state => state.gender,
        get_marital_status : state => state.marital_status,
        get_marital_status_client: state => state.marital_status_client,
        get_has_special_child : state => state.has_special_child,
        get_children : state => state.children,
        get_pets : state => state.pets,
        get_beneficiaries : state => state.beneficiaries,
        get_residual_state : state => state.residual_state,
        get_digital_representative : state => state.digital_representative,
        get_digital_personal_representative : state => state.digital_personal_representative,
        get_client_funeral : state => state.client_funeral,
        get_invited : state => state.invited
    }
}