<template>
    <v-container fluid>
        <v-row justify="center" align="center" v-if="email">
            <v-card :class="isMobile() ? '' : 'mt-10'" elevation="0">
                <v-card-title v-if="!resubscribed">
                    Unsubscribed {{ email }} successfully
                </v-card-title>
                <v-card-title v-else>
                    Resubscribed {{ email }} successfully
                </v-card-title>
                <v-card-title>
                    <v-btn dark text color="#172C54" @click="resubscribe()">Resubscribe to emails</v-btn>
                </v-card-title>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        encrypted_email: null,
        email: null,
        resubscribed: false
    }),
    async mounted() {
        this.encrypted_email = this.$router.currentRoute.params.token
        await this.getData()
    },
    created() {
    },
    computed: {
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getData() {
            console.log(this.encrypted_email)
            await this.$axios.patch(`/non_auth/unsubscribe/${this.encrypted_email}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.email = data.data
                    }
                })
        },
        async resubscribe() {
            console.log(this.encrypted_email)
            await this.$axios.patch(`/non_auth/resubscribe/${this.encrypted_email}`)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.resubscribed = true
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>