<template>
    <v-row justify="center">
        <v-dialog :value="value" persistent max-width="800">
            <v-card>
                <v-card-title class="text-h5">
                    PRESENT DAY FUNERAL COST ESTIMATE
                </v-card-title>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="6">
                            <h3 class="mb-5">Funeral Director's Cost</h3>
                        </v-col>
                        <v-col cols="6">
                            <h3 class="mb-5">Third Party Cost</h3>
                        </v-col>
                        <v-col cols="4">
                            Professional Service Charge
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.professional_service_charge }}
                        </v-col>
                        <v-col cols="4">
                            Cemetery Fees
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.cemetary_fees }}
                        </v-col>
                        <v-col cols="4">
                            Coffin
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.coffin }}
                        </v-col>
                        <v-col cols="4">
                            Cremation Fees
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.cremation_fees }}
                        </v-col>
                        <v-col cols="4">
                            Ashes/Casket/Urn
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.ashes_casket_urn }}
                        </v-col>
                        <v-col cols="4">
                            Church/Ceremony Venue Fees
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.church_ceremony_venue }}
                        </v-col>
                        <v-col cols="4">
                            Hygienic Treatment/Embalming and Presentation
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.hygienic_embalming_presentation }}
                        </v-col>
                        <v-col cols="4">
                            Priest/Minister/Celebrant Fees
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.priest_minister_celebrant }}
                        </v-col>
                        <v-col cols="4">
                            Funeral Home Use
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.funeral_home_use }}
                        </v-col>
                        <v-col cols="4">
                            Musicians/Soloist
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.musicians_soloist }}
                        </v-col>
                        <v-col cols="4">
                            Additional Movements of the Deceased
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.movements_of_deceased }}
                        </v-col>
                        <v-col cols="4">
                            Funeral Flowers
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.flowers }}
                        </v-col>
                        <v-col cols="4">
                            Funeral Cars
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.funeral_cars }}
                        </v-col>
                        <v-col cols="4">
                            Funeral Notices (Radio/Newspaper/Online)
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.notices }}
                        </v-col>
                        <v-col cols="4">
                            Temporary Grave Markers
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.temporary_grave_markers }}
                        </v-col>
                        <v-col cols="4">
                            Live Streaming/Recording
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.live_streaming }}
                        </v-col>
                        <v-col cols="6"></v-col>
                        <v-col cols="4">
                            Grave Digging
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.grave_digging }}
                        </v-col>
                        <v-col cols="6">
                            <v-row v-for="(item, index) in client.get_funeral_cost.optional_details_1" :key="index">
                                <v-col cols="8">
                                    {{ item.name }}
                                </v-col>
                                <v-col cols="4">
                                    {{ item.cost }}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-row v-for="(item, index) in client.get_funeral_cost.optional_details_2" :key="index">
                                <v-col cols="8">
                                    {{ item.name }}
                                </v-col>
                                <v-col cols="4">
                                    {{ item.cost }}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6"></v-col>
                        <v-col cols="4">
                            My Farewell Wishes Administration Fee
                        </v-col>
                        <v-col cols="2">
                            €{{ client.get_funeral_cost.administration_fee }}
                        </v-col>
                        <v-col cols="6"></v-col>
                        <v-col cols="4">
                            Total Present Day Funeral Cost Estimate
                        </v-col>
                        <v-col cols="2">
                            €{{ client.funeral_cost }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closeDialogCostEstimate()">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'value', 'client'
    ],
    data: () => ({
    }),
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            fd_data: 'auth/get_user'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        closeDialogCostEstimate() {
            this.$emit('closeDialogCostEstimate')
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>