import Login from '../LoginComponent.vue'

export default [
    {
        path: '/',
        name: '/',
        component: Login,
        meta: {
            layout: 'blank-layout'
        }
    }
]