import SurveyComponent from '../SurveyComponent2.vue'
// import ThankYou from '../ThankYouComponent.vue'
import EmptySurvey from '../SurveyEmptyComponent.vue'

export default [
    {
        path: '/survey/:token',
        name: '/survey/:token',
        component: SurveyComponent,
        meta: {
            layout: 'survey-layout'
        }
    },
    {
        path: '/survey',
        name: '/survey',
        component: EmptySurvey,
        meta: {
            layout: 'survey-layout'
        }
    }
    // {
    //     path: '/thankyou',
    //     name: '/thankyou',
    //     component: ThankYou,
    //     meta: {
    //         layout: 'survey-layout'
    //     }
    // }
]