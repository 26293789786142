<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card width="90%" class="mt-5" elevation="0">
        <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
          <v-img :src="require('../../assets/clientpage.svg')" class="mr-3" contain max-width="35" max-height="35" />
          <strong style="font-size:26px;">Funeral Directors</strong>
          <v-spacer />
          <!-- <v-btn
                        icon
                    >
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-btn> -->
        </v-card-title>
        <v-card-title class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
          <small style="position: relative; top: -10px; font-size:12px;">Showing {{ get_fd_lists.length }}/500
            clients</small>
          <v-spacer />
          <v-row>
            <v-col :cols="isMobile() ? 12 : 9">
              <v-text-field dense placeholder="Search" append-icon="mdi-magnify" class=" liitme" v-model="search_fd">
              </v-text-field>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 3">
              <v-btn :class="isMobile() ? 'mobile_sort' : 'desktop_sort'" color="primary" style="text-transform:none;"
                @click="open_sort_filter()">
                Sort & Filter
                <v-img class="ml-3" :src="require('../../assets/chevdown.svg')" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card width="90%" style="border-radius: 16px" elevation="0" :class="isMobile() ? 'mobile_card' : 'desktop_card'">
        <v-card-text class="mt-5">
          <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="headers" :items="get_fd_lists"
            :items-per-page="10" :mobile-breakpoint="0" hide-default-header :sort-by.sync="sort_by"
            :sort-desc.sync="sortDesc">
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr v-if="!isMobile()">
                  <th style="padding-bottom:30px;padding-top:10px;width:25%" v-for="h in headers" :key="h.index">
                    <div style="text-align:center;" v-if="h.text">
                      <strong>{{ h.text }}</strong>
                    </div>
                    <div style="text-align:end;" v-if="h.value == 'add'">
                      <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                        style="height:40px;text-transform: none !important" @click="add_funeral_director()">
                        Add a director <v-icon class="ml-2">mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </th>
                </tr>
                <tr v-else>
                  <th style="padding-bottom:30px;padding-top:10px;width:25%;border-bottom:none;">
                    <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                      style="height:40px;text-transform: none !important" @click="add_funeral_director()">
                      Add a director <v-icon class="ml-2">mdi-plus</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item, headers }">
              <tr class="mx-5" style="background-color: transparent;cursor: pointer;" @click="show_details(item)"
                v-if="item != null && !isMobile()">
                <td style="width:25%">
                  <v-layout align-center class="ms-5">
                    <v-avatar color="#4374F3" size="42"
                      style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                      <strong style="color:white;font-size:22px;">{{ item.name[0] }}</strong>
                    </v-avatar>
                    <strong style="font-size:16px;" class="ms-5">{{ item.name }}</strong>
                  </v-layout>
                </td>
                <td style="width:25%">
                  <v-layout justify-center>
                    {{
                      $date(item.updated_at).format("DD MMM YYYY")
                    }}
                    <strong style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                    {{
                      $date(item.updated_at).format("hh:mm a")
                    }}
                  </v-layout>
                </td>
                <td style="width:15%">
                  <v-layout justify-center align-center>
                    <v-chip :class="(item.feature == 'BASIC') ? 'status_1' :
                      (item.feature == 'ADVANCE') ? 'status_2' : 'status_0'" style="width: 100px;"
                      v-for="(chip, chipindex) in item.feature" :key="chipindex">
                      <strong>{{ chip }}</strong>
                    </v-chip>
                  </v-layout>
                </td>
                <!--<td style="width:10%">
                  <v-layout justify-center align-center>
                    <strong class="pe-1">{{ item.get_fd_survey.length }}</strong>
                    Surveys
                    <v-icon color="#347ce9" style="margin-top:-1px;left:8px;font-size:20px;">mdi-chevron-right</v-icon>
                  </v-layout>
                </td>-->
                <td style="width:15%">
                  <v-btn text color="#347CE9" style="text-transform: none !important; float:right;font-size:14px;">
                    View profile
                  </v-btn>
                </td>
              </tr>
              <tr class="mx-5" style="background-color: transparent;cursor: pointer;" @click="show_details(item)"
                v-if="item != null && isMobile()">
                <td style="width:100%" class="py-5">
                  <v-row>
                    <v-col cols="3" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[0].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="9">
                      <v-layout justify-center align-center class="ms-5">
                        <v-avatar color="#4374F3" size="42"
                          style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                          <strong style="color:white;font-size:22px;">{{ item.name[0] }}</strong>
                        </v-avatar>
                        <strong style="font-size:16px;" class="ms-5">{{ item.name }}</strong>
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[1].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="8">
                      <v-layout justify-center>
                        {{
                          $date(item.updated_at).format("DD MMM YYYY")
                        }}
                        <strong style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                        {{
                          $date(item.updated_at).format("hh:mm a")
                        }}
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[2].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="9">
                      <v-layout justify-center align-center>
                        <v-chip :class="(item.feature == 'BASIC') ? 'status_1' :
                          (item.feature == 'ADVANCE') ? 'status_2' : 'status_0'" style="width: 100px;"
                          v-for="(chip, chipindex) in item.feature" :key="chipindex">
                          <strong>{{ chip }}</strong>
                        </v-chip>
                      </v-layout>
                    </v-col>
                  </v-row>
                  <!--<v-row>
                    <v-col cols="3" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[3].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="9">
                      <v-layout justify-center align-center>
                        <strong class="pe-1">{{ item.get_fd_survey.length }}</strong>
                        Surveys
                        <v-icon color="#347ce9" style="margin-top:-1px;left:8px;font-size:20px;">mdi-chevron-right</v-icon>
                      </v-layout>
                    </v-col>
                  </v-row>-->
                  <v-row>
                    <v-col cols="12">
                      <v-btn text color="#347CE9"
                        style="text-transform: none !important; float:left;font-size:14px;margin-left:-15px;">
                        Edit
                        <v-img width="25" height="25" :src="require('../../assets/edit.svg')" contain />
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <!--TODO component-->
    <v-row justify="center">
      <v-dialog v-model="add_funeral_director_state" persistent max-width="560">
        <v-card>
          <v-card-title class="text-h5"> Add a funeral director</v-card-title>
          <v-card-text>
            <v-row class="mt-5">
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Firstname*</strong>
                <v-text-field dense filled v-model="c.firstname">
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Lastname*</strong>
                <v-text-field dense filled v-model="c.lastname"> </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Email Address*</strong>
                <v-text-field dense filled v-model="c.email"> </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Mobile*</strong>
                <v-text-field dense filled type="number" v-model="c.mobile">
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Company Name*</strong>
                <v-text-field dense filled v-model="c.company_name">
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong>Feature*</strong>
                <v-autocomplete :items="get_features" item-text="name" item-value="name" filled dense v-model="c.feature">
                </v-autocomplete>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Password*</strong>
                <v-text-field dense filled v-model="c.password">
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Repeat Password*</strong>
                <v-text-field dense filled v-model="c.repeat_password">
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="cancel_add_fd">
              Cancel
            </v-btn>
            <v-btn color="#172C54" dark class="pl-10 pr-10" @click="add_funeral_director_fn">
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <DialogSortFilter :value="dialog_sort_filter" @cancel_dialog_sort_filter="cancel_dialog_sort_filter"
      @apply_sort_filter="apply_sort_filter" @clear_all="clear_all" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DialogSortFilter from "../components/DialogSortFilterFD.vue";
export default {
  components: { DialogSortFilter },
  props: [],
  data: () => ({
    c: {
      firstname: null,
      lastname: null,
      email: null,
      mobile: null,
      company_name: null,
      feature: null,
      password: null,
      repeat_password: null
    },
    headers: [
      { text: "Client", value: 'name' },
      { text: "Last login", value: 'updated_at' },
      { text: "Feature" },
      { value: "add" },
    ],
    dialog_add_client: false,
    dialog_sort_filter: false,
    sort_by: 'created_at',
    sortDesc: true,
    filter_by: null,
    search_fd: null,
    add_funeral_director_state: false,
  }),
  async mounted() {
    await this.$store.dispatch("client/get_fds");
    await this.$store.dispatch("client/get_features");
  },
  created() { },
  computed: {
    ...mapGetters({
      get_features: "client/get_features",
    }),
    get_fd_lists() {
      return this.$store.getters["client/get_fd_lists"](this.search_fd, this.filter_by);
    },
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    show_details(data) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      this.$store.dispatch("client/set_funeral_director", data);
      this.$router.push({ name: "/admin_funeral_director_profile" });
    },
    add_funeral_director() {
      this.add_funeral_director_state = true;
    },
    cancel_add_fd() {
      this.add_funeral_director_state = false;
      this.c = {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
        feature: null,
      };
    },
    async add_funeral_director_fn() {
      if (this.c.password != this.c.repeat_password) {
        this.showSnackBar('Password does not match')
        return
      }
      this.showSnackBar("Please wait...")
      await this.$axios
        .post("auth/admin/fd_actions/add_fd", this.c)
        .then(({ data }) => {
          console.log(data)
          if (data.response) {
            this.showSnackBar(data.message);
            this.cancel_add_fd();
            this.$store.dispatch("client/get_fds");
          }
          this.showSnackBar(data.message);
          return;
        });
    },
    async change_client_status(client_id, new_status_id) {
      let payload = {
        id: client_id,
        status_id: new_status_id
      }
      await this.$axios.patch('/auth/fd_client/edit_fd_client', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.commit("fd_client/set_clients", data.data);
          }
        })
    },
    open_sort_filter() {
      this.dialog_sort_filter = true
    },
    cancel_dialog_sort_filter() {
      this.dialog_sort_filter = false;
    },
    apply_sort_filter($data) {
      this.cancel_dialog_sort_filter()
      if ($data['sort_by'] != null) { this.sort_by = $data['sort_by'] }
      if ($data['asc_desc'] != null) { this.sortDesc = $data['asc_desc'] }
      if ($data['filter_by'] != null) { this.filter_by = $data['filter_by'] }
    },
    clear_all() {
      this.cancel_dialog_sort_filter()
      this.sort_by = null
      this.sortDesc = false
      this.filter_by = null
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
  border-radius: 5px;
  margin-top: -2px;
}

::v-deep .liitme .v-input__slot {
  background-color: white;
  padding: 7px;
}

table {
  border-collapse: collapse;
}

// remove border
table td,
table th {
  height: 40px; //change the height
}

.v-data-table.row-height-50 td {
  height: 100px !important;
}

.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: bold;
}

.mobile_table td {
  height: 100px !important;
}

.desktop_table td {
  height: 100px !important;
}

.mobile_card {
  margin-top: 100px;
}

.desktop_table {
  padding-left: 30px;
  padding-right: 30px;
}

.mobile_sort {
  margin-top: -40px;
}

::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
  border-style: none !important;
}

.status_0,
.status_1,
.status_2 {
  justify-content: center;
}

.status_1 {
  background-color: #FAFAFA !important;
  color: #172C54 !important;
}

.status_2 {
  background-color: #4786FF !important;
  color: #FFF !important;
}
</style>
