<template>
    <v-row justify="center">
        <v-dialog :value="value" persistent max-width="800">
            <v-card>
                <v-card-title class="text-h5">
                    PRESENT DAY FUNERAL COST ESTIMATE
                </v-card-title>
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="6">
                            <h3 class="mb-5">Funeral Director's Cost</h3>
                        </v-col>
                        <v-col cols="6">
                            <h3 class="mb-5">Third Party Cost</h3>
                        </v-col>
                        <v-col cols="4">
                            <div>Professional Service Charge</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.professional_service_charge">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Cemetery Fees</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.cemetary_fees">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Coffin</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.coffin">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Cremation Fees</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.cremation_fees">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Ashes/Casket/Urn</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.ashes_casket_urn">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Church/Ceremony Venue Fees</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.church_ceremony_venue">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Hygienic Treatment/Embalming and Presentation</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.hygienic_embalming_presentation">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Priest/Minister/Celebrant Fees</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.priest_minister_celebrant">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Funeral Home Use</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.funeral_home_use">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Musicians/Soloist</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.musicians_soloist">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Additional Movements of the Deceased</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.movements_of_deceased">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Funeral Flowers</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.flowers">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Funeral Cars</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.funeral_cars">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Funeral Notices (Radio/Newspaper/Online)</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.notices">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Temporary Grave Markers</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.temporary_grave_markers">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <div>Live Streaming/Recording</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.live_streaming">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6"></v-col>
                        <v-col cols="4">
                            <div>Grave Digging</div>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense type="number" v-model="funeral_cost.grave_digging">
                                <template v-slot:prepend-inner>
                                    <div style="margin-top:5px!important">€</div>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-btn text @click="funeral_cost.optional_details_1.push({ name: 'Optional', cost: 0 });">
                                <v-icon>mdi-plus</v-icon> Add Optional
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn text @click="funeral_cost.optional_details_2.push({ name: 'Optional', cost: 0 });">
                                <v-icon>mdi-plus</v-icon> Add Optional
                            </v-btn>
                        </v-col>

                        <v-col cols="6">
                            <v-row v-for="(item, index) in funeral_cost.optional_details_1" :key="index">
                                <v-col cols="1">
                                    <v-btn style="min-width:10px!important;width:10px!important;" text
                                        @click="funeral_cost.optional_details_1.splice(index, 1)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="7">
                                    <v-text-field v-model="item.name" dense></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field dense type="number" v-model="item.cost">
                                        <template v-slot:prepend-inner>
                                            <div style="margin-top:5px!important">€</div>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="6">
                            <v-row v-for="(item, index) in funeral_cost.optional_details_2" :key="index">
                                <v-col cols="1">
                                    <v-btn style="min-width:10px!important;width:10px!important;" text
                                        @click="funeral_cost.optional_details_2.splice(index, 1)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="7">
                                    <v-text-field v-model="item.name" dense></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field dense type="number" v-model="item.cost">
                                        <template v-slot:prepend-inner>
                                            <div style="margin-top:5px!important">€</div>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6"></v-col>
                        <v-col cols="4">
                            <div v-if="type == 'preplanning'">My Farewell Wishes Administration Fee</div>
                        </v-col>
                        <v-col cols="2" v-if="type == 'preplanning'">
                            €<b>{{ funeral_cost.administration_fee }}</b>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="type == 'funeral'">
                        <v-col cols="12">
                            <b>Notes</b>
                            <wysiwyg v-model="funeral_cost.notes" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="cancel_dialog_cost_estimate()">
                        Cancel
                    </v-btn>
                    <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="saveFuneralCost()">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'value', 'client', 'type'
    ],
    data: () => ({
        client_id: null,
        funeral_cost: {
            id: null,
            professional_service_charge: 0,
            coffin: 0,
            ashes_casket_urn: 0,
            hygienic_embalming_presentation: 0,
            funeral_home_use: 0,
            movements_of_deceased: 0,
            funeral_cars: 0,
            temporary_grave_markers: 0,
            // THIRD PARTY COSTS
            cemetary_fees: 0,
            cremation_fees: 0,
            church_ceremony_venue: 0,
            priest_minister_celebrant: 0,
            musicians_soloist: 0,
            flowers: 0,
            notices: 0,
            live_streaming: 0,
            grave_digging: 0,
            // OPTIONAL
            optional: 0,
            optional_details_1: [],
            optional_details_2: [],
            // TOTAL
            administration_fee: 300,
            total_funeral_cost: 0,
            notes: null,
        },
    }),
    mounted() {
        this.getFuneralCost()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            fd_data: 'auth/get_user'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit('auth/setMessage',
                { show: true, message: message },
                { root: 1 })
        },
        getFuneralCost() {
            this.client_id = this.client.id
            if (this.client.get_funeral_cost != null) {
                this.funeral_cost.id = this.client.get_funeral_cost.id
                this.funeral_cost.professional_service_charge = this.client.get_funeral_cost.professional_service_charge
                this.funeral_cost.coffin = this.client.get_funeral_cost.coffin
                this.funeral_cost.ashes_casket_urn = this.client.get_funeral_cost.ashes_casket_urn
                this.funeral_cost.hygienic_embalming_presentation = this.client.get_funeral_cost.hygienic_embalming_presentation
                this.funeral_cost.funeral_home_use = this.client.get_funeral_cost.funeral_home_use
                this.funeral_cost.movements_of_deceased = this.client.get_funeral_cost.movements_of_deceased
                this.funeral_cost.funeral_cars = this.client.get_funeral_cost.funeral_cars
                this.funeral_cost.temporary_grave_markers = this.client.get_funeral_cost.temporary_grave_markers
                // THIRD PARTY COSTS
                this.funeral_cost.cemetary_fees = this.client.get_funeral_cost.cemetary_fees
                this.funeral_cost.cremation_fees = this.client.get_funeral_cost.cremation_fees
                this.funeral_cost.church_ceremony_venue = this.client.get_funeral_cost.church_ceremony_venue
                this.funeral_cost.priest_minister_celebrant = this.client.get_funeral_cost.priest_minister_celebrant
                this.funeral_cost.musicians_soloist = this.client.get_funeral_cost.musicians_soloist
                this.funeral_cost.flowers = this.client.get_funeral_cost.flowers
                this.funeral_cost.notices = this.client.get_funeral_cost.notices
                this.funeral_cost.live_streaming = this.client.get_funeral_cost.live_streaming
                this.funeral_cost.grave_digging = this.client.get_funeral_cost.grave_digging
                // OPTIONAL
                this.funeral_cost.optional = this.client.get_funeral_cost.optional
                this.funeral_cost.optional_details_1 = this.client.get_funeral_cost.optional_details_1 ?? []
                this.funeral_cost.optional_details_2 = this.client.get_funeral_cost.optional_details_2 ?? []
                // TOTAL
                this.funeral_cost.total_funeral_cost = this.client.get_funeral_cost.total_funeral_cost
                this.funeral_cost.notes = this.client.get_funeral_cost.notes
            }
            if (this.funeral_cost.notes == null || this.funneral_cost.notes == '') {
                this.funeral_cost.notes = 'Professional Service Fee Includes:<br>'
                this.funeral_cost.notes += '•	Guidance and coordination of funeral plans.<br>'
                this.funeral_cost.notes += '•	Preparation of and publishing of funeral notices with provision of associated condolence books<br>'
                this.funeral_cost.notes += '•	Management of logistics for ceremony/ funeral mass/service and wake.<br>'
                this.funeral_cost.notes += '•	Coordination and payment of third parties (grave diggers, clergy, media, etc.).<br>'
                this.funeral_cost.notes += '•	All transport, coordination of any necessary floral arrangements, and memorial contributions.<br>'
                this.funeral_cost.notes += '•	24/7 Availability: Funeral director access for support and guidance before, during and after a funeral<br>'
                this.funeral_cost.notes += '<br>Notes:<br>'
                this.funeral_cost.notes += 'This cost estimate has been prepared to reflect the total funeral cost at this present day<br>'
                this.funeral_cost.notes += 'That is, the cost required to carry out the funeral at the date stated above.<br>'
                this.funeral_cost.notes += 'This estimate is based on the services listed above only and is valid for one month from date of issue.<br>'
                this.funeral_cost.notes += 'It is your responsibility to check the listed services in the present day funeral cost estimate to ensure that they are sufficient.<br>'
            }
        },
        saveFuneralCost() {
            let new_optional = 0
            if(this.type == 'funeral') {
                this.funeral_cost.administration_fee = 0
            }
            if (this.funeral_cost.optional_details_1) {
                this.funeral_cost.optional_details_1.forEach(item => {
                    new_optional += parseFloat(item.cost)
                })
            }
            if (this.funeral_cost.optional_details_2) {
                this.funeral_cost.optional_details_2.forEach(item => {
                    new_optional += parseFloat(item.cost)
                })
            }
            this.funeral_cost.optional = new_optional
            if (this.client.get_funeral_cost == null) {
                this.$axios.post('auth/fd_client/create_funeral_cost', { client_id: this.client_id, funeral_cost: this.funeral_cost })
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch("fd_client/set_client", data.client)
                            this.cancel_dialog_cost_estimate()
                        }
                    })
            }
            else {
                this.$axios.patch('auth/fd_client/update_funeral_cost', { client_id: this.client_id, funeral_cost: this.funeral_cost })
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch("fd_client/set_client", data.client)
                            this.cancel_dialog_cost_estimate()
                        }
                    })
            }
        },
        cancel_dialog_cost_estimate() {
            this.$emit('cancel_dialog_cost_estimate')
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>