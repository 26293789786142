<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-card elevation="0" style="border:solid 2px #F3F3F3;border-radius:10px;" class="mb-3 mt-8">
                <v-card-subtitle style="font-size:18px;" class="mb-5">
                    Documents and brochures to help guide you in planning your funeral.
                </v-card-subtitle>
                <v-card-text>
                    <v-data-table :sort-by.sync="sort_by" :sort-desc.sync="sortDesc"
                        :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="document_headers"
                        hide-default-header :items="get_documents" :items-per-page="10">
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr v-if="!isMobile()">
                                    <th style="padding-bottom:30px;padding-top:10px" v-for="h in headers" :key="h.index">
                                        <div style="text-align:center;" v-if="h.text">
                                            <strong>{{ h.text }}</strong>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr v-if="!isMobile()" class="mx-5" style="background-color: transparent;">
                                <td>
                                    <v-layout style="white-space:pre-line;word-break: break-word;">
                                        {{ item.document_name }}
                                    </v-layout>
                                </td>
                                <td style="width:50%;">
                                    <v-layout v-if="item.description" class="py-5"
                                        style="white-space:pre-line;word-break: break-word;">
                                        {{ item.description }}
                                    </v-layout>
                                    <v-layout v-else justify-center>
                                        N/A
                                    </v-layout>
                                </td>
                                <td style="width:15%;">
                                    <v-btn style="float:right;" text @click="download_document(item)">
                                        <span style="text-transform: none !important;color:#347CE9">
                                            Download
                                        </span>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr v-else class="mx-5" style="background-color: transparent;width:100%;">
                                <td class="py-5" style="width:500px;">
                                    <v-row>
                                        <v-col cols="12" style="white-space:pre-line;word-break: break-word;">
                                            <b>{{ document_headers[0].text }}</b>
                                            <br>{{ item.document_name }}
                                        </v-col>
                                        <v-col cols="12">
                                            <label style="white-space:pre-line;word-break: break-word;">
                                            </label>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" style="white-space:pre-line;word-break: break-word;">
                                            <b>{{ document_headers[1].text }}</b>
                                            <br>
                                            <label v-if="item.description" class="py-5"
                                                style="white-space:pre-line;word-break: break-word;">
                                                {{ item.description }}
                                            </label>
                                            <label v-else justify-center>
                                                N/A
                                            </label>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn style="float:right;" text @click="download_document(item)">
                                                <span style="text-transform: none !important;color:#347CE9">
                                                    Download
                                                </span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        api_url: null,
        sort_by: 'created_at',
        sortDesc: true,
        document_headers: [
            { text: "Title", sortable: false },
            { text: "Description", sortable: false },
            { text: "", sortable: false }
        ],
    }),
    async mounted() {
        await this.$store.dispatch("public_documents/get_documents")
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        ...mapGetters({
            get_documents: "public_documents/get_documents",
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        download_document(item) {
            window.open(this.api_url + 'frontend/documents/' + item.file_name + '.' + item.extension, '_blank')
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>