<template>
    <v-container fluid>
        <v-row justify="center">
            <v-dialog :value="terms_modal" persistent max-width="900">
                <v-card>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closePrivacyTermsModal()">
                            Close
                        </v-btn>
                    </v-card-actions>
                    <v-card-text class="mt-5">
                        <h1>Terms and Conditions</h1>
                        <vue-pdf-embed :source="app_url + '/30Nov22_t&cs.pdf'" />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closePrivacyTermsModal()">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog :value="terms_payments_modal" persistent max-width="500">
                <v-card>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closePrivacyTermsModal()">
                            Close
                        </v-btn>
                    </v-card-actions>
                    <v-card-text class="mt-5">
                        <v-row class="px-5">
                            <v-col cols="2">
                                <b>6.6</b>
                            </v-col>
                            <v-col cols="10">
                                <b>Option Two: Monthly Payments</b>
                                <br>
                                <b>
                                    <i>
                                        Note: This Section 6.6 only applies where you have chosen to make monthly
                                        instalments to the Trust.
                                    </i>
                                </b>
                            </v-col>
                        </v-row>
                        <v-row class="px-5">
                            <v-col cols="2">

                            </v-col>
                            <v-col cols="2">
                                <p>6.6.1</p>
                            </v-col>
                            <v-col cols="8">
                                <p>
                                    A minimum deposit of €500 must be paid to the Trust. The Trust will pay us the My
                                    Farewell Wishes Administration Fee. The rest of the payments received from you will be
                                    held by the Trust and used by the Trust to pay for your Funeral Services. You must pay
                                    an additional amount of your choice to the Trust in monthly instalments over a set
                                    period of time as agreed with the Funeral Director. A Direct Debit Application and
                                    Mandate Form will be provided to you by your Funeral Director which will detail the
                                    schedules of payments options, the periods of time of the payments and the service
                                    charges associated with the Direct Debit option. Please note that the Direct Debit
                                    Service Charge will be charged for the use of and accountancy of this facility by the
                                    Trust. A separate service charge may also be applied in the event of any missed
                                    instalments.
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closePrivacyTermsModal()">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog :value="privacy_modal" persistent max-width="600">
                <v-card>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closePrivacyTermsModal()">
                            Close
                        </v-btn>
                    </v-card-actions>
                    <v-card-text class="mt-5">
                        <h1>Privacy Policy of My Farewell Wishes</h1>
                        <p>This page is used to inform website visitors regarding our policies with the collection, use, and
                            disclosure of Personal Information if anyone decided to use our Service, the Website Name
                            website.</p>

                        <p>If you choose to use our Service, then you agree to the collection and use of information in
                            relation with this policy. The Personal Information that we collect are used for providing and
                            improving the Service. We will not use or share your information with anyone except as described
                            in
                            this Privacy Policy.</p>

                        <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
                            which
                            is accessible at Website URL, unless otherwise defined in this Privacy Policy.</p>

                        <h1>Information Collection and Use</h1>
                        <p>For a better experience while using our Service, we may require you to provide us with certain
                            personally identifiable information, including but not limited to your name, phone number, and
                            postal address. The information that we collect will be used to contact or identify you.</p>

                        <h1>Log Data</h1>
                        <p>We want to inform you that whenever you visit our Service, we collect information that your
                            browser
                            sends to us that is called Log Data. This Log Data may include information such as your
                            computer's
                            Internet Protocol (“IP”) address, browser version, pages of our Service that you visit, the time
                            and
                            date of your visit, the time spent on those pages, and other statistics.</p>

                        <h1>Cookies</h1>
                        <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier.
                            These are sent to your browser from the website that you visit and are stored on your computer's
                            hard drive.</p>

                        <p>Our website uses these “cookies” to collection information and to improve our Service. You have
                            the option to either accept or refuse these cookies, and know when a cookie is being sent to
                            your computer. If you choose to refuse our cookies, you may not be able to use some portions of
                            our Service.</p>

                        <h1>Service Providers</h1>
                        <p>We may employ third-party companies and individuals due to the following reasons:</p>

                        <p>To facilitate our Service;</p>
                        <p>To provide the Service on our behalf;</p>
                        <p>To perform Service-related services; or</p>
                        <p>To assist us in analyzing how our Service is used.</p>
                        <p>We want to inform our Service users that these third parties have access to your Personal
                            Information. The reason is to perform the tasks assigned to them on our behalf. However, they
                            are obligated not to disclose or use the information for any other purpose.</p>
                        <h1>Security</h1>
                        <p>We value your trust in providing us your Personal Information, thus we are striving to use
                            commercially acceptable means of protecting it. But remember that no method of transmission over
                            the internet, or method of electronic storage is 100% secure and reliable, and we cannot
                            guarantee its absolute security.</p>
                        <h1>Links to Other Sites</h1>
                        <p>Our Service may contain links to other sites. If you click on a third-party link, you will be
                            directed to that site. Note that these external sites are not operated by us. Therefore, we
                            strongly advise you to review the Privacy Policy of these websites. We have no control over, and
                            assume no responsibility for the content, privacy policies, or practices of any third-party
                            sites or services.</p>
                        <h1>Children's Privacy</h1>
                        <p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal
                            identifiable information from children under 13. In the case we discover that a child under 13
                            has provided us with personal information, we immediately delete this from our servers. If you
                            are a parent or guardian and you are aware that your child has provided us with personal
                            information, please contact us so that we will be able to do necessary actions.</p>
                        <h1>Changes to This Privacy Policy</h1>
                        <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page
                            periodically for any changes. We will notify you of any changes by posting the new Privacy
                            Policy on
                            this page. These changes are effective immediately, after they are posted on this page.</p>
                        <h1>Contact Us</h1>
                        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closePrivacyTermsModal()">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
    components: {
        VuePdfEmbed
    },
    props: [
        'terms_modal',
        'terms_payments_modal',
        'privacy_modal'
    ],
    data: () => ({
        api_url: null,
        app_url: null,
        headers: [
            { text: 'Applicable Charge', sortable: false, width: '33%' },
            { text: 'Amount', sortable: false, width: '33%' },
            { text: 'Description', sortable: false, width: '33%' },
        ],
        items: [
            { charge: 'Registration Administration Fee', amount: '€65 (including VAT)*', description: 'This is the administration fee payable for advanced funeral planning services to the Funeral Director on the submission of your Record of Wishes form.' },
            { charge: 'My Farewell Wishes Administration Fee', amount: '€300 (including VAT)**', description: 'This is the administration fee charged by us associated with the supply of the Services.' },
            { charge: 'Direct Debit Service Charge', amount: 'As set out on the bank’s direct debit application and mandate form.', description: 'If you choose to pay by direct debit, a service charge will be applied by the Trustee and/ or by the bank * to the monthly amount paid by you. This will be set out on the Direct Debit Application and Mandate Form which will be provided to you by your Funeral Director. This only applies to you if you choose Option 2 (as detailed in Section 6.6 of these Terms) to make payments to the Trust. * Note that you may incur direct debit service charges and/ or unpaid direct debit charges by your bank if you miss a monthly instalment.' },
            { charge: 'Trust Management Fees', amount: '3% per annum of the value of the funds held by the Trust.', description: 'The annual operating expenses for maintaining the Trust.' },
            { charge: 'Change in Funeral Director Fee', amount: '€65 (including VAT)', description: 'This is an administration charge of €65 to My Farewell Wishes Limited to record a change in the Funeral Director as requested by you.' },
            { charge: 'Change of Wishes Fee', amount: '€65 (including VAT)', description: 'This is an administration charge that will apply to each request if you ask us to record a change in your wishes more than once per calendar year.' },
            { charge: 'Cancellation Fee', amount: '€150 plus VAT or 3% of the Ultimate Value, whichever is the greater. ', description: 'Please see Section 11.1.2 for more information.' },
        ]
    }),
    mounted() { },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
        this.app_url = process.env.VUE_APP_BUILD
    },
    computed: {
    },
    methods: {
        closePrivacyTermsModal() {
            this.$emit('closePrivacyTermsModal')
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
h1,
h2,
p {
    padding-top: 5px;
    padding-bottom: 5px;
}
</style>
