import SolicitorComponent from '../SolicitorComponent.vue'

export default [
    {
        path: '/solicitors',
        name: 'Solicitors',
        component: SolicitorComponent,
        meta: {
            layout: 'admin-layout'
        }
    },
]