import FuneralPayment from '../FuneralPayment.vue'
import PaymentSuccessful from '../PaymentSuccessful.vue'

export default [
    {
        path: '/funeral-payment/:token',
        name: '/funeral-payment/:token',
        component: FuneralPayment,
        meta: {
            layout: 'public-layout'
        }
    },
    {
        path: '/funeral-payment-successful/:token',
        name: '/funeral-payment-successful/:token',
        component: PaymentSuccessful,
        meta: {
            layout: 'public-layout'
        }
    },
]