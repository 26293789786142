<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card width="90%" class="mt-5" elevation="0">
        <v-card-title class="text-h5" style="background-color: #f4f4f5 !important;">
          <strong style="font-size:26px;">Admin Dashboard</strong>
          <v-spacer />
        </v-card-title>
        <v-card-subtitle class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
        </v-card-subtitle>
      </v-card>
      <v-card width="90%" style="border-radius: 16px" class="mb-10" elevation="0">
        <v-card-title>
          Recent Payments
          <v-spacer></v-spacer>
          <v-btn :text="payment_type == 'test' ? true : false" @click="payment_type = 'live';"
            :disabled="payment_type == 'live' ? true : false" color="#172C54">Live</v-btn>
          <v-btn :text="payment_type == 'live' ? true : false" @click="payment_type = 'test';"
            :disabled="payment_type == 'test' ? true : false" color="#172C54">Test</v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date" label="Select Date Range" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="applyDateRange()">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <vue-excel-xlsx :data="get_transactions" :columns="columns" :file-name="'transactions'" :file-type="'xlsx'"
                :sheet-name="'Sheet 1'">
                <v-btn text>Export</v-btn>
              </vue-excel-xlsx>
            </v-col>
          </v-row>
          <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="transaction_headers"
            :items="get_transactions.filter((x) => x.settlestatus == 100)" hide-default-header :items-per-page="10" :sort-by.sync="sort_by_transactions"
            :sort-desc.sync="sortDesc">
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th style="padding-bottom:30px;padding-top:10px;" :width="h.width" v-for="h in headers" :key="h.index">
                    <div style="text-align:left;">
                      <strong>{{ h.text }}</strong>
                    </div>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr class="mx-5" style="background-color: transparent;">
                <td>
                  {{ item.settleduedate }}
                </td>
                <td>
                  {{ item.billingfirstname + ' ' + item.billinglastname }}
                </td>
                <td>
                  {{ item.get_user.get_user_add_info.get_fd_info.firstname + ' ' +
                    item.get_user.get_user_add_info.get_fd_info.lastname }}
                </td>
                <td>
                  <div v-if="item.subscriptiontype">
                    Recurring
                  </div>
                  <div v-else>
                    Deposit
                  </div>
                </td>
                <td>
                  {{ item.settlebaseamount / 100 }}
                </td>
                <td>
                  <v-btn text color="#347CE9" @click="openPaymentDetails(item)">Details</v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <!--<v-col :cols="isMobile() ? 12 : 5">
        <v-card width="90%" style="border-radius: 16px;" elevation="0">
          <v-card-title>
            Manage Payment Terms
          </v-card-title>
          <v-card-text>
            <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="payment_terms_headers"
              :items="get_payment_terms" hide-default-header hide-default-footer :items-per-page="5"
              :sort-by.sync="sort_by" :sort-desc.sync="sortDesc">
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr>
                    <th style="padding-bottom:30px;padding-top:10px;width:50%;" v-for="h in headers" :key="h.index">
                      <div style="text-align:center;" v-if="h.text">
                        <strong>{{ h.text }}</strong>
                      </div>
                    </th>
                    <th style="padding-bottom:30px;padding-top:10px;">
                      <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                        style="height:40px;text-transform: none !important" @click="open_create_payment_term_dialog()">
                        Add <v-icon class="ml-2">mdi-plus</v-icon>
                      </v-btn>
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr class="mx-5" style="background-color: transparent;" v-if="item != null && !isMobile()">
                  <td>
                    <v-layout justify-center>{{ item.name }}</v-layout>
                  </td>
                  <td>
                    <v-layout justify-center>{{ item.duration }}</v-layout>
                  </td>
                  <td>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn style="float:right;" text color="#347CE9" v-bind="attrs" v-on="on">
                          Options
                        </v-btn>
                      </template>
                      <v-list style="padding-top:0px;padding-bottom:0px;width:150px;">
                        <v-list-item style="cursor: pointer" @click="open_edit_payment_term_dialog(item)">
                          <span style="text-transform: none !important;float:right;color:#347CE9">
                            Edit
                          </span>
                        </v-list-item>
                        <v-list-item style="cursor: pointer" @click="open_delete_payment_term_dialog(item)">
                          <span style="text-transform: none !important;float:right;color:red;">
                            Delete
                          </span>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
                <tr class="mx-5" style="background-color: transparent;" v-if="item != null && isMobile()"
                  @click="open_template(item)">
                  <td style="width:100%" class="py-5">
                    <v-row>
                      <v-col cols="12">
                        {{ item.name }}
                        <br>
                        {{ item.duration }}
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn style="float:right;" text color="#347CE9" v-bind="attrs" v-on="on">
                              Options
                            </v-btn>
                          </template>
                          <v-list style="padding-top:0px;padding-bottom:0px;width:150px;">
                            <v-list-item style="cursor: pointer" @click="open_edit_payment_term_dialog(item)">
                              <span style="text-transform: none !important;float:right;color:#347CE9">
                                Edit
                              </span>
                            </v-list-item>
                            <v-list-item style="cursor: pointer" @click="open_delete_payment_term_dialog(item)">
                              <span style="text-transform: none !important;float:right;color:red;">
                                Delete
                              </span>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>-->
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="create_payment_term_dialog" persistent max-width="560">
        <v-card>
          <v-card-title class="text-h5">
            Add Payment Term
          </v-card-title>
          <v-card-text class="mt-5">
            <v-row>
              <v-col :cols="isMobile() ? 12 : 6">
                <v-text-field v-model="new_payment_term_name" label="Payment Term Name" required>
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <v-text-field type="number" v-model="new_payment_term_duration" label="Payment Term Duration" required>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="close_create_payment_term_dialog()">
              Cancel
            </v-btn>
            <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="create_payment_term()">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="edit_payment_term_dialog" persistent max-width="560">
        <v-card v-if="payment_term_to_edit != null">
          <v-card-title class="text-h5">
            Edit Payment Term
          </v-card-title>
          <v-card-text class="mt-5">
            <v-row>
              <v-col :cols="isMobile() ? 12 : 6">
                <v-text-field v-model="payment_term_to_edit.name" label="Payment Term Name" required>
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <v-text-field type="number" v-model="payment_term_to_edit.duration" label="Payment Term Duration"
                  required>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="close_edit_payment_term_dialog()">
              Cancel
            </v-btn>
            <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="edit_payment_term()">
              <v-icon class="mr-2">mdi-pen</v-icon>
              Edit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="delete_payment_term_dialog" persistent max-width="560">
        <v-card>
          <v-card-title class="text-h5">
            Are you sure?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="close_delete_payment_term_dialog()">
              Cancel
            </v-btn>
            <v-btn color="red" dark class="pl-10 pr-10" @click="delete_payment_term()">
              <v-icon class="mr-2">mdi-delete</v-icon>
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <PaymentDetails :value="dialog_payment_details" :payment="payment_details" @closePaymentDetails="closePaymentDetails"
      @viewClient="viewClient" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import PaymentDetails from "./components/PaymentDetails.vue"
export default {
  components: {
    PaymentDetails
  },
  props: [
  ],
  data: () => ({
    payment_terms_headers: [
      { text: "Name" },
      { text: "Duration" }
    ],
    transaction_headers: [
      { text: 'Date', value: 'settleduedate' },
      { text: 'Client', value: 'billingfirstname' },
      { text: 'Funeral Director' },
      { text: 'Payment Type', value: 'requesttypedescription' },
      { text: 'Settle Amount', value: 'settlebaseamountactual' },
      { text: '', width: '5%' },
    ],
    columns: [
      {
        label: "Settle Due Date",
        field: "settleduedate",
      },
      {
        label: "First Name",
        field: "billingfirstname",
      },
      {
        label: "Last Name",
        field: "billinglastname",
      },
      {
        label: "Payment Type",
        field: "requesttypedescription",
      },
      {
        label: "Base Amount",
        field: "baseamountactual",
      },
      {
        label: "Settle Amount",
        field: "settlebaseamountactual",
      },
      {
        label: "Currency",
        field: "currencyiso3a",
      },
      {
        label: "Card Type",
        field: "paymenttypedescription",
      },
      {
        label: "Transaction Ref",
        field: "transactionreference",
      },
      {
        label: "Funeral Cost",
        field: "get_user.get_user_add_info.funeral_cost",
      },
    ],
    payment_type: 'live',
    sort_by_transactions: "settleduedate",
    sort_by: "created_at",
    sortDesc: true,
    create_payment_term_dialog: false,
    new_payment_term_name: null,
    new_payment_term_duration: null,
    edit_payment_term_dialog: false,
    payment_term_to_edit: {
      id: null,
      name: null,
      duration: null
    },

    delete_payment_term_dialog: false,
    payment_term_to_delete: null,
    dialog_payment_details: false,
    payment_details: null,
    menu: false,
    date: null,
    date_range: null,
  }),
  async mounted() {
    await this.$store.dispatch("admin_payment_terms/fetch_transactions")
    await this.$store.dispatch("admin_payment_terms/fetch_payment_terms")
  },
  created() {
  },
  computed: {
    ...mapGetters({
      get_payment_terms: "admin_payment_terms/get_payment_terms",
    }),
    get_transactions() {
      return this.$store.getters["admin_payment_terms/get_transactions"](this.payment_type, this.date_range)
    },
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    applyDateRange() {
      this.$refs.menu.save(this.date)
      this.menu = false
      this.date_range = this.date
    },
    openPaymentDetails(item) {
      this.dialog_payment_details = true
      this.payment_details = item
    },
    closePaymentDetails() {
      this.dialog_payment_details = false
      this.payment_details = null
    },
    async viewClient() {
      let client = this.payment_details.get_user
      await this.$axios.get('/auth/admin/fd_clients/get_specific_client', { id: client.id })
        .then(({ data }) => {
          if (data.response) {
            this.dialog_payment_details = false
            this.payment_details = null
            this.$store.dispatch("client/set_funeral_director_client", data.client)
            this.$router.push({ name: "/admin_client_profile" })
          }
        })
    },
    open_create_payment_term_dialog() {
      this.create_payment_term_dialog = true
    },
    close_create_payment_term_dialog() {
      this.create_payment_term_dialog = false
      this.new_payment_term_name = null
      this.new_payment_term_duration = null
    },
    async create_payment_term() {
      let payload = {
        name: this.new_payment_term_name,
        duration: this.new_payment_term_duration,
      }
      await this.$axios.post('auth/admin/payment/create_payment_term', payload)
        .then(({ data }) => {
          if (data.response) {
            this.close_create_payment_term_dialog()
            this.showSnackBar('Payment term created successfully')
            this.$store.dispatch("admin_payment_terms/set_payment_terms", data.data)
          }
        })
    },
    open_edit_payment_term_dialog(item) {
      this.edit_payment_term_dialog = true
      this.payment_term_to_edit.id = item.id
      this.payment_term_to_edit.name = item.name
      this.payment_term_to_edit.duration = item.duration
    },
    close_edit_payment_term_dialog() {
      this.edit_payment_term_dialog = false
      this.payment_term_to_edit.id = null
      this.payment_term_to_edit.name = null
      this.payment_term_to_edit.duration = null
    },
    async edit_payment_term() {
      let payload = {
        id: this.payment_term_to_edit.id,
        name: this.payment_term_to_edit.name,
        duration: this.payment_term_to_edit.duration,
      }
      await this.$axios.patch('auth/admin/payment/edit_payment_term', payload)
        .then(({ data }) => {
          if (data.response) {
            this.close_edit_payment_term_dialog()
            this.showSnackBar('Payment term edited successfully')
            this.$store.dispatch("admin_payment_terms/set_payment_terms", data.data)
          }
        })
    },
    open_delete_payment_term_dialog(item) {
      this.delete_payment_term_dialog = true
      this.payment_term_to_delete = item
    },
    close_delete_payment_term_dialog() {
      this.delete_payment_term_dialog = false
      this.payment_term_to_delete = null
    },
    async delete_payment_term() {
      let payload = {
        id: this.payment_term_to_delete.id,
      }
      await this.$axios.post('auth/admin/payment/delete_payment_term', payload)
        .then(({ data }) => {
          if (data.response) {
            this.close_delete_payment_term_dialog()
            this.showSnackBar('Payment term deleted successfully')
            this.$store.dispatch("admin_payment_terms/set_payment_terms", data.data)
          }
        })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
table {
  border-collapse: collapse;
}
</style>