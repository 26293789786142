import EmailComponent from '../EmailComponent.vue'

export default [
    {
        path: '/fd_emails',
        name: '/fd_emails',
        component: EmailComponent,
        meta: {
            layout: 'funeral-director-layout'
        }
    }
]