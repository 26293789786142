import Vue from 'vue'

export default{
    namespaced: true,
    state: {
        survey: {},
        show_survey: true,
        question: [],
        section_selected: null,
        is_done: false,
        el_index: 1,
        answer: {
            funeral_service: {},
            wake: {},
            ceremony: {},
            budget: {},
            funeral_cost: {},
            payment_preference: {},
            personal_wishes: {},
            contact_info: {}
        },
        fds: []
    },
    actions: {
        set_survey({commit}, payload){
            commit('set_survey', payload)
        },
        set_show_survey({commit}, payload){
            commit('set_show_survey', payload)
        },
        set_question({commit}, payload){
            commit('set_question', payload)
        },
        set_section_selected({commit}, payload){
            commit('set_section_selected', payload)
        },
        set_answer({commit}, payload){
            commit('set_answer', payload)
        },
        next_section({commit}){
            commit('next_section')
        },
        set_el_index({commit}, payload){
            commit('set_el_index', payload)
        },
        set_answer_r({commit}, payload){
            commit('set_answer_r', payload)
        },
        async get_fds({commit}){
            await Vue.axios.get('/non_auth/g_funeral_directors')
            .then(({data}) => {
                commit('set_fds', data.data)
            })
        }
    },
    mutations: {
        set_el_index(state, payload){
            state.el_index = payload
        },
        set_clear_answer_r_state(state){
            state.answer.funeral_service = {}
            state.answer.wake = {}
            state.answer.ceremony = {}
            state.answer.budget = {}
            state.answer.funeral_cost = {}
            state.answer.payment_preference = {}
            state.answer.personal_wishes = {}
            state.answer.contact_info = {}
        },
        set_answer_r(state, payload){
            if(payload.choice === 'funeral_service'){
                state.answer.funeral_service = payload.answer
                return
            }
            if(payload.choice === 'wake'){
                state.answer.wake = payload.answer
                return
            }
            if(payload.choice === 'ceremony'){
                state.answer.ceremony = payload.answer
                true
            }
            if(payload.choice === 'budget'){
                state.answer.budget = payload.answer
                true
            }
            if(payload.choice === 'funeral_cost'){
                state.answer.funeral_cost = payload.answer
                return
            }
            if(payload.choice === 'payment_preference'){
                state.answer.payment_preference = payload.answer
                true
            }
            if(payload.choice === 'personal_wishes'){
                state.answer.personal_wishes = payload.answer
                return
            }
        },
        set_survey(state, payload){
            state.survey = payload
        },
        set_survey_empty(state){
            state.survey = {}
        },
        set_show_survey(state, payload){
            state.show_survey = !payload
        },
        set_question(state, payload){
            state.question = [...payload]
            state.is_done = false
        },
        set_section_selected(state, payload){
            state.section_selected = payload
        },
        set_questions(state){
            // const questions = state.survey.data[payload].data
            // state.question = questions
            state.section_selected = 0
            state.el_index = 1
        },
        set_show_survey_false(state, payload){
            state.show_survey = payload
        },
        set_answer(state, payload){
            state.survey.data[payload.section_index].data[payload.question_index].data[0].answer.push(payload.answer)
            state.question[payload.question_index].data[0].answer = []
            if(payload.answer_image !== undefined){
                state.question[payload.question_index].data[0].answer_image.push(payload.answer_image)
            }else{
                state.question[payload.question_index].data[0].answer.push(payload.answer)
            }
            if(payload.answer_index !== undefined){
                state.question[payload.question_index].data[0].images_q[payload.answer_index].active = true
            }
        },
        next_section(state){
            const current = state.section_selected
            const questionlength = state.survey.data.length - 1
            if(questionlength === 0){
                state.is_done = true
            }else{
                const next = state.section_selected =+ 1
                const questions = state.survey.data[next].data
                if(current < questionlength){
                    state.section_selected =+ 1
                    state.question = [...questions]
                    return
                }
                state.is_done = true
                return
            }
        },
        set_fds(state, payload){
            state.fds = [...payload]
        }
    },
    getters: {
        get_survey : state => state.survey,
        // state.survey,
        get_show_survey : state => state.show_survey,
        get_question : state => {
            let out = []
            state.survey.data.forEach((q, i) => {
                out.push({
                    data:               q.data,
                    step:               i + 1,
                    multiple_answer:    q.multiple_answer,
                    title:              q.title,
                    title_edit:         q.title_edit
                })
            })
            out.push({
                data:   '',
                step:   out.length + 1,
                status: 'contact',
                title:  'Contact'
            })
            return out
        },
        get_section_selected : state => state.section_selected,
        get_is_done : state => state.is_done,
        get_el_index : state => state.el_index,
        get_funeral_service : state => state.answer.funeral_service,
        get_wake : state => state.answer.wake,
        get_ceremony : state => state.answer.ceremony,
        get_budget : state => state.answer.budget,
        get_funeral_cost : state => state.answer.funeral_cost,
        get_payment_preference : state => state.answer.payment_preference,
        get_personal_wishes : state => state.answer.personal_wishes,
        get_fds : state => state.fds
    }
}