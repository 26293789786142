import LastWill from '../../activity/components/InnerComponents/CreateLastWillComponent.vue'

export default [
    {
        path: '/client-last-will',
        name: '/client-last-will',
        component: LastWill,
        meta: {
            layout: 'blank-input-layout'
        }
    }
]