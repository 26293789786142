<template>
    <v-container fluid style="background-color: #FAFBFF !important; height: 100%">
        <v-row justify="center" align="center" class="mt-15">
            <v-card :width="isMobile() ? '95%' : '70%'" class="mt-5 pt-10 pb-10" :id="isMobile() ? 'mobile_card' : 'desktop_card'" elevation="2"
                style="border-radius: 16px;margin-bottom:10px;">
                <v-card-title class="text-h5">
                    <v-img :src="require('../../assets/client_notification.svg')" contain max-width="40" max-height="40"
                        class="mr-3" />
                    <strong>Notifications</strong>
                </v-card-title>
                <v-divider />
                <v-card-text v-if="get_shared_profiles.length > 0">
                    <v-row>
                        <v-col cols="12" v-for="(profile, userindex) in get_shared_profiles" :key="userindex">
                            {{ $date(profile.created_at).format("MMMM DD, YYYY | hh:mm a") }}
                            <v-list-item two-line>
                                <v-avatar
                                    v-if="profile.get_sharer_info !== null && profile.get_sharer_info.profile_picture !== null"
                                    size="55" color="blue"
                                    style="position: relative; right: 18px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                    <v-img contain :src="`${api_url}${profile.get_sharer_info.profile_picture}`" />
                                </v-avatar>
                                <v-avatar v-else size="55" color="blue"
                                    style="position: relative; right: 18px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                    <strong style="fontsize: 20px;color:white;">{{ profile.get_sharer_user_info.email[0] }}</strong>
                                </v-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="ml-3" style="white-space:pre-wrap;word-break: break-word;">
                                        <strong>{{ profile.get_sharer_user_info.email }}</strong> shared their profile with you
                                        <v-btn :class="isMobile() ? 'px-0' : 'ml-5'" style="color:#172C54" text
                                            @click="view_shared_profile(profile)">View</v-btn>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-else>
                    No notifications
                </v-card-text>
            </v-card>
        </v-row>
        <SharedProfile
            :open_profile="open_profile"
            @close_profile="close_profile"
        />
        <ClientFooter />
    </v-container>
</template>

<script>
import ClientFooter from "../components/ClientFooter.vue"
import SharedProfile from "./SharedProfile.vue"
import { mapGetters } from "vuex";
export default {
    components: {
        ClientFooter,
        SharedProfile,
    },
    props: [],
    data: () => ({
        api_url: null,
        open_profile: false,
    }),
    async mounted() {
        await this.$store.dispatch("client_notification/fetch_shared_profiles");
    },
    async created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        ...mapGetters({
            user: "auth/get_user",
            get_shared_profiles: "client_notification/get_shared_profiles",
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        view_shared_profile(data) {
            this.$store.dispatch("client_notification/set_profile", data);
            this.open_profile = true
        },
        close_profile() {
            this.open_profile = false
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
#desktop_card {
    padding-left: 50px;
    padding-right: 50px;
}
</style>
