<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card width="90%" class="mt-5" elevation="0">
        <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
          <v-img :src="require('../../assets/fd_clients.svg')" class="mr-3" contain max-width="35" max-height="35" />
          <strong style="font-size:26px;">Clients</strong>
          <v-spacer />
          <!-- <v-btn
            icon
          >
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-title class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
          <small style="position: relative; top: -10px; font-size:12px;">Showing {{ get_clients.length }}
            clients</small>
          <v-spacer />
          <v-row>
            <v-col :cols="isMobile() ? 12 : 9">
              <v-text-field dense placeholder="Search" append-icon="mdi-magnify" class=" liitme" v-model="search_user">
              </v-text-field>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 3">
              <v-btn :class="isMobile() ? 'mobile_sort' : 'desktop_sort'" color="primary" style="text-transform:none;"
                @click="openSortFilter()">
                Sort & Filter
                <v-img class="ml-3" :src="require('../../assets/chevdown.svg')" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card width="90%" style="border-radius: 16px" elevation="0"
        :class="isMobile() ? 'mobile_card' : 'desktop_card'">
        <v-card-text class="mt-5">
          <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'"
            :headers="headers_funeral_director_clients" :items="get_clients" :items-per-page="10" :mobile-breakpoint="0"
            hide-default-header :sort-by.sync="sort_by" :sort-desc.sync="sortDesc">
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr v-if="!isMobile()">
                  <th style="padding-bottom:30px;padding-top:10px;width:25%" v-for="h in headers" :key="h.index">
                    <div style="text-align:center;" v-if="h.text">
                      <strong>{{ h.text }}</strong>
                    </div>
                    <div style="text-align:end;" v-if="h.value == 'add'">
                      <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                        style="height:40px;text-transform: none !important" @click="openAddClient()">
                        Add a client <v-icon class="ml-2">mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </th>
                </tr>
                <tr v-else>
                  <th style="padding-bottom:30px;padding-top:10px;width:25%;border-bottom:none;">
                    <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                      style="height:40px;text-transform: none !important" @click="openAddClient()">
                      Add a client <v-icon class="ml-2">mdi-plus</v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item, headers }">
              <tr class="mx-5" style="background-color: transparent;" v-if="item.get_user_info != null && !isMobile()">
                <td style="width:25%">
                  <v-layout align-center class="ms-5">
                    <v-avatar v-if="item.profile_picture === null" color="#4374F3" size="42"
                      style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                      <strong style="color:white;font-size:22px;">{{ item.get_user_info.name[0] }}</strong>
                    </v-avatar>
                    <v-avatar v-else color="#4374F3" size="42"
                      style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                      <v-img contain :src="`${api_url}${item.profile_picture}`" />
                    </v-avatar>
                    <strong style="font-size:16px;" class="ms-5">{{ item.get_user_info.name }}</strong>
                  </v-layout>
                  <small style="margin-left:82px;font-size:10px;color:#c4c4c4;display:block;margin-top:-10px;"
                    v-if="item.get_user_info.lead">lead</small>
                </td>
                <td style="width:25%">
                  <v-layout justify-center>
                    {{
                    $date(item.get_user_info.created_at).format("DD MMM YYYY")
                    }}
                    <strong style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                    {{
                    $date(item.get_user_info.created_at).format("hh:mm a")
                    }}
                  </v-layout>
                </td>
                <td style="width:10%">
                  <v-layout justify-center align-center>
                    <strong>{{ item.get_fd_info.name }}</strong>
                  </v-layout>
                </td>
                <td style="width:15%">
                  <v-layout justify-center align-center class="ms-7">
                    <v-select chips :items="statuses" v-model="item.get_status" item-text="name" item-value="id"
                      @change="changeClientStatus(item.id, item.get_status)">
                      <template v-slot:append>
                        <v-icon color="blue" class="mt-1" style="margin-left:-15px;">mdi-chevron-down</v-icon>
                      </template>
                      <template #selection="{ item }">
                        <v-chip style="color:white;width:90%;cursor: pointer;" :class="(item.id == 1) ? 'status_1' :
                          (item.id == 2) ? 'status_2' :
                            (item.id == 3) ? 'status_3' :
                              (item.id == 4) ? 'status_4' :
                                (item.id == 5) ? 'status_5' : 'status_0'">
                          <strong style="font-size:12px;">{{ item.name }}</strong>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-layout>
                </td>
                <td style="width:15%">
                  <v-btn text color="#347CE9" style="text-transform:none!important;float:right;font-size:14px;"
                    v-if="item.get_user_info.get_payments.length > 0 && item.get_user_info.get_payments[0].transaction_history.responses[0].found > 0"
                    @click="openPaymentHistory(item)">
                    View
                  </v-btn>
                  <v-btn disabled text color="grey" style="text-transform:none!important;float:right;font-size:14px;"
                    v-else>
                    N/A
                  </v-btn>
                </td>
                <td style="width:15%">
                  <v-btn text color="#347CE9" style="text-transform: none !important; float:right;font-size:14px;"
                    @click="viewProfile(item)">
                    View profile
                  </v-btn>
                </td>
              </tr>
              <tr class="mx-5" style="background-color: transparent;" v-if="item.get_user_info != null && isMobile()">
                <td style="width:100%" class="py-5">
                  <v-row>
                    <v-col cols="3" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[0].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="9">
                      <v-layout justify-center align-center class="ms-5">
                        <v-avatar v-if="item.profile_picture === null" color="#4374F3" size="42"
                          style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                          <strong style="color:white;font-size:22px;">{{ item.get_user_info.name[0] }}</strong>
                        </v-avatar>
                        <v-avatar v-else color="#4374F3" size="42"
                          style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                          <v-img contain :src="`${api_url}${item.profile_picture}`" />
                        </v-avatar>
                        <strong style="font-size:16px;" class="ms-5">{{ item.get_user_info.name }}</strong>
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[1].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="8">
                      <v-layout justify-center>
                        {{
                        $date(item.get_user_info.updated_at).format("DD MMM YYYY")
                        }}
                        <strong style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                        {{
                        $date(item.get_user_info.updated_at).format("hh:mm a")
                        }}
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[2].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="8">
                      <v-layout justify-center align-center>
                        <strong>{{ item.get_fd_info.name }}</strong>
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[3].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="9">
                      <v-layout justify-center align-center class="ms-7">
                        <v-select chips :items="statuses" v-model="item.get_status" item-text="name" item-value="id"
                          @change="changeClientStatus(item.id, item.get_status)">
                          <template v-slot:append>
                            <v-icon color="blue" class="mt-1" style="margin-left:-15px;">mdi-chevron-down</v-icon>
                          </template>
                          <template #selection="{ item }">
                            <v-chip style="color:white;width:90%;cursor: pointer;" :class="(item.id == 1) ? 'status_1' :
                              (item.id == 2) ? 'status_2' :
                                (item.id == 3) ? 'status_3' :
                                  (item.id == 4) ? 'status_4' :
                                    (item.id == 5) ? 'status_5' : 'status_0'">
                              <strong style="font-size:12px;">{{ item.name }}</strong>
                            </v-chip>
                          </template>
                        </v-select>
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn text color="#347CE9"
                        style="text-transform: none !important; float:left;font-size:14px;margin-left:-15px;"
                        @click="viewProfile(item)">
                        View profile
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog_payment_history" persistent max-width="900">
        <v-card>
          <v-card-title>
            Payment History
          </v-card-title>
          <v-card-text v-if="payment_history">
            <vue-excel-xlsx :data="payment_history" :columns="columns"
              :file-name="selected_client.get_user_info.firstname + ' ' + selected_client.get_user_info.lastname + ' transactions'"
              :file-type="'xlsx'" :sheet-name="'Sheet 1'">
              <v-btn text>Export</v-btn>
            </vue-excel-xlsx>
            <v-data-table :items="payment_history.filter((x) => x.settlestatus == 100)" :headers="transaction_headers"
              :sort-by.sync="transaction_sort_by" :sort-desc.sync="transaction_sortDesc">
              <template v-slot:item="{ item }">
                <tr class="mx-5" style="background-color: transparent;">
                  <td>
                    {{ item.settleduedate }}
                  </td>
                  <td>
                    <div v-if="item.subscriptiontype">
                      Recurring
                    </div>
                    <div v-else>
                      Deposit
                    </div>
                  </td>
                  <td>
                    {{ item.baseamount / 100 }}
                  </td>
                  <td>
                    {{ item.settlebaseamount / 100 }}
                  </td>
                  <td>
                    {{ item.currencyiso3a }}
                  </td>
                  <td>
                    {{ item.paymenttypedescription }}
                  </td>
                  <td>
                    {{ item.transactionreference }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text v-if="payment_history">
            <v-row>
              <v-col cols="4">
                <h3>Payments Missed: {{ payment_totals.total_missed }}</h3>
              </v-col>
              <v-col cols="4">
                <h3>Total Paid: {{ payment_totals.total_paid }}</h3>
              </v-col>
              <v-col cols="4">
                <h3>Admin Fees Paid: {{ payment_totals.total_admin }}</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closePaymentHistory()">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="open_add_client" persistent max-width="900">
        <v-card>
          <v-card-title class="text-h5">
            Add my client
          </v-card-title>
          <v-card-text class="mt-5">
            <v-row>
              <v-col cols="12">
                <v-text-field label="First name *" outlined dense prepend-inner-icon="mdi-account" v-model="c.firstname"
                  type="text">
                </v-text-field>
                <v-text-field label="Last name *" outlined dense prepend-inner-icon="mdi-account" v-model="c.lastname"
                  type="text">
                </v-text-field>
                <v-text-field label="Email Address*" outlined dense prepend-inner-icon="mdi-at" v-model="c.email"
                  type="email">
                </v-text-field>
                <v-autocomplete :items="get_status" item-text="name" item-value="id" label="Status *" outlined dense
                  prepend-inner-icon="mdi-text" v-model="c.status">
                </v-autocomplete>
                <v-autocomplete :items="get_fds" item-text="name" item-value="id" label="Funeral Director *" outlined
                  dense prepend-inner-icon="mdi-text" v-model="c.fd">
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeAddClient">
              Cancel
            </v-btn>
            <v-btn v-if="!adding_client" color="#1976D2" dark class="pl-10 pr-10" @click="addClient()">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add client
            </v-btn>
            <v-btn v-else color="#1976D2" disabled class="pl-10 pr-10">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add client
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <DialogSortFilter :value="dialog_sort_filter" @cancel_dialog_sort_filter="cancel_dialog_sort_filter"
      @apply_sort_filter="apply_sort_filter" @clear_all="clear_all" />
  </v-container>
</template>

<script>
import DialogSortFilter from "../components/DialogSortFilterClient.vue";
import { mapGetters } from 'vuex'
export default {
  components: {
    DialogSortFilter
  },
  props: [],
  data: () => ({
    api_url: null,
    open_add_client: false,
    c: {
      firstname: null,
      lastname: null,
      email: null,
      status: null,
      fd: null
    },
    adding_client: false,
    dialog_sort_filter: false,
    dialog_payment_history: false,
    payment_history: null,
    payment_totals: {
      total_missed: 0,
      total_paid: 0,
      total_admin: 0,
    },
    transaction_headers: [
      { text: 'Date', value: 'settleduedate' },
      { text: 'Payment Type', value: 'requesttypedescription' },
      { text: 'Base Amount', value: 'baseamountactual' },
      { text: 'Settle Amount', value: 'settlebaseamountactual' },
      { text: 'Currency', value: 'currencyiso3a' },
      { text: 'Card Type', value: 'paymenttypedescription' },
      { text: 'Transaction Ref', value: '"transactionreference"' },
    ],
    transaction_sort_by: 'settleduedate',
    transaction_sortDesc: true,
    selected_client: null,
    columns: [
      {
        label: "Settle Due Date",
        field: "settleduedate",
      },
      {
        label: "First Name",
        field: "billingfirstname",
      },
      {
        label: "Last Name",
        field: "billinglastname",
      },
      {
        label: "Payment Type",
        field: "requesttypedescription",
      },
      {
        label: "Base Amount",
        field: "baseamountactual",
      },
      {
        label: "Settle Amount",
        field: "settlebaseamountactual",
      },
      {
        label: "Currency",
        field: "currencyiso3a",
      },
      {
        label: "Card Type",
        field: "paymenttypedescription",
      },
      {
        label: "Transaction Ref",
        field: "transactionreference",
      },
    ],
    headers_funeral_director_clients: [
      { text: "Client", value: 'get_user_info.name' },
      { text: "Sign Up Date", value: 'get_user_info.created_at' },
      { text: "Funeral Dir." },
      { text: "Status" },
      { text: "Payments" },
      { value: "add" },
    ],
    statuses: [
      {
        name: 'BOOKED APPT.',
        id: 1
      },
      {
        name: 'CALLED',
        id: 2
      },
      {
        name: 'FOLLOW-UP',
        id: 3
      },
      {
        name: 'NEW LEAD',
        id: 4
      },
      {
        name: 'PLAN SOLD',
        id: 5
      }
    ],
    search_user: null,
    sort_by: 'created_at',
    sortDesc: true,
    filter_by: null,
  }),
  async mounted() {
    await this.$store.dispatch("fd_client/get_status");
    await this.$store.dispatch("client/get_fds");
    await this.$store.dispatch("fds/get_whole_clients");
  },
  created() {
    this.app_url = process.env.VUE_APP_API_URL
  },
  computed: {
    ...mapGetters({
      get_status: 'fd_client/get_status'
    }),
    get_clients() {
      return this.$store.getters["fds/get_clients"](this.search_user, this.filter_by);
    },
    get_fds() {
      return this.$store.getters["client/get_fd_lists"](null, null);
    },
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    formatAmount(amount) {
      return amount / 10
    },
    viewProfile(data) {
      this.$store.dispatch("client/set_funeral_director_client", data);
      this.$router.push({ name: "/admin_client_profile" });
    },
    async changeClientStatus(client_id, new_status_id) {
      let payload = {
        id: client_id,
        status_id: new_status_id
      }
      await this.$axios.patch('/auth/fd_client/edit_fd_client', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.commit("fd_client/set_clients", data.data);
          }
        })
    },
    openPaymentHistory(item) {
      this.dialog_payment_history = true
      this.selected_client = item
      this.payment_history = item.get_user_info.get_payments[0].transaction_history.responses[0].records
      this.payment_history.forEach((payment, index) => {
        if (payment.settlestatus != '100') {
          this.payment_history.splice(index, 1)
        }
        else {
          payment.baseamountactual = payment.baseamount / 100
          payment.settlebaseamountactual = payment.settlebaseamount / 100
          this.payment_totals.total_paid += parseFloat(payment.baseamountactual)
        }
      })
    },
    closePaymentHistory() {
      this.dialog_payment_history = false
      this.selected_client = null
      this.payment_history = null
      this.payment_totals.total_paid = 0
    },
    openSortFilter() {
      this.dialog_sort_filter = true
    },
    cancel_dialog_sort_filter() {
      this.dialog_sort_filter = false;
    },
    apply_sort_filter($data) {
      this.cancel_dialog_sort_filter()
      if ($data['sort_by'] != null) { this.sort_by = $data['sort_by'] }
      if ($data['asc_desc'] != null) { this.sortDesc = $data['asc_desc'] }
      if ($data['filter_by'] != null) { this.filter_by = $data['filter_by'] }
    },
    clear_all() {
      this.cancel_dialog_sort_filter()
      this.sort_by = null
      this.sortDesc = false
      this.filter_by = null
    },
    openAddClient() {
      this.open_add_client = true
    },
    closeAddClient() {
      this.open_add_client = false
      this.c.firstname = null
      this.c.lastname = null
      this.c.email = null
      this.c.status = null
      this.c.fd = null
    },
    async addClient() {
      let payload = {
        firstname: this.c.firstname,
        lastname: this.c.lastname,
        email: this.c.email,
        status: this.c.status,
        fd_id: this.c.fd
      }
      this.adding_client = true
      this.showSnackBar("Please wait...")
      await this.$axios.post('/auth/fd_client/create_client', payload)
        .then(({ data }) => {
          this.showSnackBar(data.message)
          this.adding_client = false
          if (data.response) {
            this.closeAddClient()
            this.$store.dispatch("fds/get_whole_clients");
          }
        })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
  border-radius: 5px;
  margin-top: -2px;
}

::v-deep .liitme .v-input__slot {
  background-color: white;
  padding: 7px;
}

table {
  border-collapse: collapse;
}

// remove border
table td,
table th {
  height: 40px; //change the height
}

.mobile_table td {
  height: 100px !important;
}

.desktop_table td {
  height: 100px !important;
}

.mobile_card {
  margin-top: 100px;
}

.desktop_table {
  padding-left: 30px;
  padding-right: 30px;
}

.mobile_sort {
  margin-top: -40px;
}

.v-data-table.row-height-50 td {
  height: 100px !important;
}

.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: bold;
}

::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
  border-style: none !important;
}

.status_0,
.status_1,
.status_2,
.status_3,
.status_4,
.status_5 {
  justify-content: center;
}

.status_1 {
  background-color: #DEF3FF !important;
  color: #347CE9 !important;
}

.status_2 {
  background-color: #FFFADE !important;
  color: #172C54 !important;
}

.status_3 {
  background-color: #FFEEE4 !important;
  color: #CE6300 !important;
}

.status_4 {
  background-color: #FAFAFA !important;
  color: #347CE9 !important;
}

.status_5 {
  background-color: #F1FFEC !important;
  color: #219226 !important;
}
</style>
