<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card width="90%" class="mt-5" style="background: none !important" elevation="0">
        <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
          <v-img :src="require('../../assets/clientpage.svg')" class="mr-3" contain max-width="35" max-height="35" />
          <strong style="font-size:26px;">Funeral Directors</strong>
          <v-spacer />
          <!-- <v-btn
                        icon
                    >
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-btn> -->
        </v-card-title>
        <v-card-title class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
          <v-btn @click="$router.push({ name: '/admin_funeral_directors' })"
            style="position: relative; top: -10px; font-size:14px;text-transform:none;" class="pl-0" color="#347CE9" text>
            <v-icon color="#347CE9">mdi-chevron-left</v-icon>
            <strong>Back</strong>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-card width="90%" style="border-radius: 16px" elevation="0">
        <v-card-title>
          <v-list-item two-line class="pa-5">
            <v-avatar size="75" color="blue"
              style="position: relative; right: 18px;top:-20px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
              <strong style="color:white;font-size:22px;">{{ client.name[0] }}</strong>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 30px;color:#494949;">
                <h4 style="word-break: break-word;font-size: 25px;white-space: pre-wrap;">{{ client.name }}</h4>
                <v-chip :class="(client.feature == 'BASIC') ? 'status_1' :
                  (client.feature == 'ADVANCE') ? 'status_2' : 'status_0'"
                  style="width: 100px;justify-content: center;left:20px;" v-for="(chip, chipitem) in client.feature"
                  :key="chipitem">
                  <v-layout justify-center align-center>
                    <strong>{{ chip }}</strong>
                  </v-layout>
                </v-chip>
              </v-list-item-title>
              <v-list-item-subtitle style="fontsize: 12px" class="mt-2">
                ID: {{ client.id }}
                <br>
                Last Login:
                {{
                  $date(client.updated_at).format("DD MMM YYYY")
                }}
                <strong style="font-size:16px;margin-left:1px;margin-right:1px;margin-top:-2px">|</strong>
                {{
                  $date(client.updated_at).format("hh:mm a")
                }}
                <!--<label class="ml-3">View logs</label>-->
                <v-btn text style="text-transform: none;float:right;color:#347CE9" :class="isMobile() ? 'mt-2' : ''"
                  @click="editClient()">
                  Edit
                  <v-icon style="font-size:20px;color:#347CE9" class="ml-1 mb-1">mdi-pencil-outline</v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-title>
        <v-card-subtitle>
          <v-row>
            <v-col :cols="isMobile() ? 12 : 6">
              <v-row>
                <v-col cols="8"> Contact Number </v-col>
                <v-col cols="4" class="float-right" style="text-align: right">
                  <strong v-if="client.mobile !== null">{{
                    client.mobile
                  }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
                <v-col cols="8"> Company Name</v-col>
                <v-col cols="4" class="float-right" style="text-align: right">
                  <strong v-if="client.company_name !== null">{{
                    client.company_name
                  }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
                <v-col cols="8"> Address </v-col>
                <v-col cols="4" class="float-right" style="text-align: right">
                  <strong v-if="client.address !== null">{{
                    client.address
                  }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 6">
              <v-row>
                <v-col cols="6"> Email </v-col>
                <v-col cols="6" style="text-align: right">
                  <strong v-if="client.email !== null">{{
                    client.email
                  }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
                <v-col cols="6"> Feature </v-col>
                <v-col cols="6" style="text-align: right">
                  <strong v-if="client.feature !== null">{{
                    client.feature[0]
                  }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
                <v-col cols="6"> Website </v-col>
                <v-col cols="6" style="text-align: right">
                  <strong v-if="client.website !== null">{{
                    client.website
                  }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-subtitle>
          <h4 v-if="client.feature !== null && client.feature[0] === 'ADVANCE'">
            <strong>Advance Features</strong>
          </h4>
          <h4 v-else>
            <strong>Basic Features</strong>
          </h4>
          <v-chip v-for="(chips, chipsindex) in client.features" :key="chipsindex" color="#347CE9" style="color: white"
            class="mr-2 pl-5 pr-5" small>
            {{ chips }}
          </v-chip>
        </v-card-subtitle>
      </v-card>
      <!-- CLIENTS -->
      <v-card width="90%" class="mt-10" elevation="0">
        <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
          <v-img :src="require('../../assets/fd_clients.svg')" class="mr-3" contain max-width="35" max-height="35" />
          <strong style="font-size:26px;">Clients</strong>
          <v-spacer />
          <!-- <v-btn
                    icon
                >
                    <v-icon>mdi-bell-outline</v-icon>
                </v-btn> -->
        </v-card-title>
        <v-card-title class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
          <small style="position: relative; top: -10px; font-size:12px;">Showing {{ get_fd_clients.length }}/500
            clients</small>
          <v-spacer />
          <v-row>
            <v-col :cols="isMobile() ? 12 : 9">
              <v-text-field dense placeholder="Search" append-icon="mdi-magnify" class=" liitme" v-model="search_client">
              </v-text-field>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 3">
              <v-btn :class="isMobile() ? 'mobile_sort' : 'desktop_sort'" color="primary" style="text-transform:none;"
                @click="open_sort_filter()">
                Sort & Filter
                <v-img class="ml-3" :src="require('../../assets/chevdown.svg')" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card width="90%" style="border-radius: 16px" elevation="0" :class="isMobile() ? 'mobile_card' : 'desktop_card'">
        <v-card-text class="mt-5">
          <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="headers" :items="get_fd_clients"
            :items-per-page="7" :mobile-breakpoint="0" hide-default-header :sort-by.sync="sort_by"
            :sort-desc.sync="sortDesc">
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr v-if="!isMobile()">
                  <th style="padding-bottom:30px;padding-top:10px;width:25%" v-for="h in headers" :key="h.index">
                    <div style="text-align:center;" v-if="h.text">
                      <strong>{{ h.text }}</strong>
                    </div>
                    <!--<div style="text-align:end;" v-if="h.value == 'add'">
                        <v-btn
                          dark
                          color="#172C54"
                          class="pl-5 pt-2 pb-2 mr-3"
                          style="height:40px;text-transform: none !important"
                          @click="add_client_fd_init"
                        >
                          Add a client <v-icon class="ml-2">mdi-plus</v-icon>
                        </v-btn>
                      </div>-->
                  </th>
                </tr>
                <!--<tr v-else>
                    <th style="padding-bottom:30px;padding-top:10px;width:25%;border-bottom:none;">
                      <v-btn
                        dark
                        color="#172C54"
                        class="pl-5 pt-2 pb-2 mr-3"
                        style="height:40px;text-transform: none !important"
                        @click="add_client_fd_init"
                      >
                        Add a client <v-icon class="ml-2">mdi-plus</v-icon>
                      </v-btn>
                    </th>
                  </tr>-->
              </thead>
            </template>
            <template v-slot:item="{ item, headers }">
              <tr class="mx-5" style="background-color: transparent;" v-if="item.get_user_info != null && !isMobile()">
                <td style="width:25%">
                  <v-layout align-center class="ms-5">
                    <v-avatar v-if="item.profile_picture === null" color="#4374F3" size="42"
                      style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                      <strong style="color:white;font-size:22px;">{{ item.get_user_info.name[0] }}</strong>
                    </v-avatar>
                    <v-avatar v-else color="#4374F3" size="42"
                      style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                      <v-img contain :src="`${api_url}/${item.profile_picture}`" />
                    </v-avatar>
                    <strong style="font-size:16px;" class="ms-5">{{ item.get_user_info.name }}</strong>
                  </v-layout>
                </td>
                <td style="width:25%">
                  <v-layout justify-center>
                    {{
                      $date(item.get_user_info.updated_at).format("DD MMM YYYY")
                    }}
                    <strong style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                    {{
                      $date(item.get_user_info.updated_at).format("hh:mm a")
                    }}
                  </v-layout>
                </td>
                <td style="width:25%">
                  <v-layout justify-center align-center class="ms-7">
                    <v-select chips :items="statuses" v-model="item.get_status" item-text="name" item-value="id"
                      @change="change_client_status(item.id, item.get_status)">
                      <template v-slot:append>
                        <v-icon color="blue" class="mt-1" style="margin-left:-15px;">mdi-chevron-down</v-icon>
                      </template>
                      <template #selection="{ item }">
                        <v-chip style="color:white;width:90%;cursor: pointer;" :class="(item.id == 1) ? 'c-status_1' :
                          (item.id == 2) ? 'c-status_2' :
                            (item.id == 3) ? 'c-status_3' :
                              (item.id == 4) ? 'c-status_4' :
                                (item.id == 5) ? 'c-status_5' : 'c-status_0'">
                          <strong style="font-size:12px;">{{ item.name }}</strong>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-layout>
                </td>
                <td style="width:25%">
                  <v-btn text color="#347CE9"
                    style="text-transform: none !important; float:right;font-size:14px;margin-right:-15px;"
                    @click="funeral_director_client(item)">
                    View profile
                  </v-btn>
                </td>
              </tr>
              <tr class="mx-5" style="background-color: transparent;" v-if="item.get_user_info != null && isMobile()">
                <td style="width:100%" class="py-5">
                  <v-row>
                    <v-col cols="3" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[0].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="9">
                      <v-layout justify-center align-center class="ms-5">
                        <v-avatar v-if="item.profile_picture === null" color="#4374F3" size="42"
                          style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                          <strong style="color:white;font-size:22px;">{{ item.get_user_info.name[0] }}</strong>
                        </v-avatar>
                        <v-avatar v-else color="#4374F3" size="42"
                          style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                          <v-img contain :src="`${api_url}/${item.profile_picture}`" />
                        </v-avatar>
                        <strong style="font-size:16px;" class="ms-5">{{ item.get_user_info.name }}</strong>
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[1].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="8">
                      <v-layout justify-center>
                        {{
                          $date(item.get_user_info.updated_at).format("DD MMM YYYY")
                        }}
                        <strong style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                        {{
                          $date(item.get_user_info.updated_at).format("hh:mm a")
                        }}
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[2].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="9">
                      <v-layout justify-center align-center class="ms-7">
                        <v-select chips :items="statuses" v-model="item.get_status" item-text="name" item-value="id"
                          @change="change_client_status(item.id, item.get_status)">
                          <template v-slot:append>
                            <v-icon color="blue" class="mt-1" style="margin-left:-15px;">mdi-chevron-down</v-icon>
                          </template>
                          <template #selection="{ item }">
                            <v-chip style="color:white;width:90%;cursor: pointer;" :class="(item.id == 1) ? 'c-status_1' :
                              (item.id == 2) ? 'c-status_2' :
                                (item.id == 3) ? 'c-status_3' :
                                  (item.id == 4) ? 'c-status_4' :
                                    (item.id == 5) ? 'c-status_5' : 'c-status_0'">
                              <strong style="font-size:12px;">{{ item.name }}</strong>
                            </v-chip>
                          </template>
                        </v-select>
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn text color="#347CE9" style="text-transform: none !important; float:left;font-size:14px;"
                        @click="funeral_director_client(item)">
                        View profile
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <!--TODO component-->
    <v-row justify="center">
      <v-dialog v-model="edit_client_state" persistent max-width="560">
        <v-card>
          <v-card-title>
            <small>Edit client</small>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-5">
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Firstname* </strong>
                <v-text-field dense filled v-model="to_edit.firstname">
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Lastname* </strong>
                <v-text-field dense filled v-model="to_edit.lastname"> </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Email Address* </strong>
                <v-text-field dense filled v-model="to_edit.email"> </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Mobile* </strong>
                <v-text-field dense filled type="number" v-model="to_edit.mobile">
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Company Name* </strong>
                <v-text-field dense filled v-model="to_edit.company_name">
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong>Feature* </strong>
                <v-autocomplete :items="get_features" item-text="name" item-value="name" filled dense
                  v-model="to_edit.feature">
                </v-autocomplete>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> New Password</strong>
                <v-text-field dense filled v-model="to_edit.password">
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Repeat New Password</strong>
                <v-text-field dense filled v-model="to_edit.repeat_password">
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="cancel_edit_client">
              Cancel
            </v-btn>
            <v-btn color="#172C54" dark class="pl-10 pr-10" @click="edit_funeral_director()">
              Edit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="add_client_state" persistent max-width="560">
        <v-card>
          <v-card-title>
            <small>Add a client</small>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-5">
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Firstname* </strong>
                <v-text-field dense filled v-model="c.firstname">
                </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Lastname* </strong>
                <v-text-field dense filled v-model="c.lastname"> </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Email Address* </strong>
                <v-text-field dense filled v-model="c.email"> </v-text-field>
              </v-col>
              <v-col :cols="isMobile() ? 12 : 6">
                <strong> Mobile* </strong>
                <v-text-field dense filled type="number" v-model="c.mobile">
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="cancel_add_fd_client">
              Cancel
            </v-btn>
            <v-btn color="#172C54" dark class="pl-10 pr-10" @click="add_funeral_director_client_fn">
              Agree
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <DialogSortFilter :value="dialog_sort_filter" @cancel_dialog_sort_filter="cancel_dialog_sort_filter"
      @apply_sort_filter="apply_sort_filter" @clear_all="clear_all" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DialogSortFilter from "../components/DialogSortFilterClient.vue";
export default {
  components: { DialogSortFilter },
  props: [],
  data: () => ({
    api_url: null,
    headers: [
      { text: "Client", value: 'get_user_info.name' },
      { text: "Last login", value: 'get_user_info.updated_at' },
      { text: "Status" },
      { value: "add" },
    ],
    statuses: [
      {
        name: 'BOOKED APPT.',
        id: 1
      },
      {
        name: 'CALLED',
        id: 2
      },
      {
        name: 'FOLLOW-UP',
        id: 3
      },
      {
        name: 'NEW LEAD',
        id: 4
      },
      {
        name: 'PLAN SOLD',
        id: 5
      }
    ],
    search_client: null,
    add_client_state: false,
    edit_client_state: false,
    c: {
      firstname: null,
      lastname: null,
      email: null,
      mobile: null,
    },
    to_edit: {
      firstname: '',
      lastname: '',
      email: '',
      mobile: '',
      company_name: '',
      feature: '',
      color: '',
      password: null,
      repeat_password: null
    },
    dialog_sort_filter: false,
    sort_by: 'created_at',
    sortDesc: true,
    filter_by: null,
  }),
  async mounted() {
    await this.$store.dispatch("client/get_fd_clients", this.client.id);
  },
  created() {
    this.api_url = process.env.VUE_APP_API_URL;
  },
  computed: {
    ...mapGetters({
      client: "client/get_funeral_director",
      get_features: "client/get_features",
    }),
    get_fd_clients() {
      return this.$store.getters["client/get_fd_clients"](this.search_client, this.filter_by);
    },
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    funeral_director_client(data) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      // this.$store.dispatch("client/set_funeral_director_client", data);
      // this.$router.push({ name: "/fd-client" });

      this.$store.dispatch("client/set_funeral_director_client", data);
      this.$router.push({ name: "/admin_client_profile" });
    },
    editClient() {
      this.edit_client_state = true
      this.to_edit = {
        firstname: this.client.firstname,
        lastname: this.client.lastname,
        email: this.client.email,
        mobile: this.client.mobile,
        company_name: this.client.company_name,
        feature: (this.client.feature == null) ? '' : this.client.feature[0],
        id: this.client.id,
        color: this.client.color
      }
    },
    cancel_edit_client() {
      this.edit_client_state = false
    },
    add_client_fd_init() {
      this.add_client_state = true;
    },
    cancel_add_fd_client() {
      this.add_client_state = false;
      this.c = {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
      };
    },
    async edit_funeral_director() {
      if (this.to_edit.password != this.to_edit.repeat_password) {
        this.showSnackBar('Password does not match')
        return
      }
      await this.$axios.patch("/auth/admin/fd_actions/edit_fd", this.to_edit)
        .then(({ data }) => {
          this.showSnackBar(data.message)
          if (data.response) {
            this.cancel_edit_client()
            this.$store.dispatch("client/set_funeral_director", data.data[0])
          }
        })
    },
    async add_funeral_director_client_fn() {
      let tp = {
        firstname: this.c.firstname,
        lastname: this.c.lastname,
        email: this.c.email,
        mobile: this.c.mobile,
        fd_id: this.client.id,
        status: 4,
      };
      await this.$axios
        .post("/auth/admin/fd_actions/add_client_of_fd", tp)
        .then(({ data }) => {
          if (data.response) {
            this.showSnackBar(data.message);
            this.$store.dispatch("client/get_fd_clients", this.client.id);
            this.cancel_add_fd_client();
            return;
          }
          this.showSnackBar(data.message);
          return;
        });
    },
    async change_client_status(client_id, new_status_id) {
      let payload = {
        id: client_id,
        status_id: new_status_id
      }
      await this.$axios.patch('/auth/fd_client/edit_fd_client', payload)
        .then(({ data }) => {
          if (data.response) {
            this.$store.commit("fd_client/set_clients", data.data);
          }
        })
    },
    open_sort_filter() {
      this.dialog_sort_filter = true
    },
    cancel_dialog_sort_filter() {
      this.dialog_sort_filter = false;
    },
    apply_sort_filter($data) {
      this.cancel_dialog_sort_filter()
      if ($data['sort_by'] != null) { this.sort_by = $data['sort_by'] }
      if ($data['asc_desc'] != null) { this.sortDesc = $data['asc_desc'] }
      if ($data['filter_by'] != null) { this.filter_by = $data['filter_by'] }
    },
    clear_all() {
      this.cancel_dialog_sort_filter()
      this.sort_by = null
      this.sortDesc = false
      this.filter_by = null
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
  border-radius: 5px;
  margin-top: -2px;
}

::v-deep .liitme .v-input__slot {
  background-color: white;
  padding: 7px;
}

.v-data-table.row-height-50 td {
  height: 100px !important;
}

.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: bold;
}

::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
  border-style: none !important;
}

.status_0,
.status_1,
.status_2 {
  justify-content: center;
}

.status_1 {
  background-color: #FAFAFA !important;
  color: #172C54 !important;
}

.status_2 {
  background-color: #4786FF !important;
  color: #FFF !important;
}

.c-status_0,
.c-status_1,
.c-status_2,
.c-status_3,
.c-status_4,
.c-status_5 {
  justify-content: center;
}

.c-status_1 {
  background-color: #DEF3FF !important;
  color: #347CE9 !important;
}

.c-status_2 {
  background-color: #FFFADE !important;
  color: #172C54 !important;
}

.c-status_3 {
  background-color: #FFEEE4 !important;
  color: #CE6300 !important;
}

.c-status_4 {
  background-color: #FAFAFA !important;
  color: #347CE9 !important;
}

.c-status_5 {
  background-color: #F1FFEC !important;
  color: #219226 !important;
}

.desktop_card {
  padding-left: 50px;
  padding-right: 50px;
}

::v-deep .v-slide-group__prev {
  display: none !important;
}

.mobile_table td {
  height: 100px !important;
}

.desktop_table td {
  height: 100px !important;
}

.mobile_card {
  margin-top: 100px;
}

.desktop_table {
  padding-left: 30px;
  padding-right: 30px;
}

.mobile_sort {
  margin-top: -40px;
}
</style>
