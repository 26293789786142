export default{
    namespaced: true,
    state: {
        tab: 'tab-0'
    },
    actions: {
        set_tab({commit}, payload){
            const tp = 'tab-' + payload
            commit('set_tab', tp)
        }
    },
    mutations: {
        set_tab(state, payload){
            state.tab = payload
        }
    },
    getters: {
        get_tab : state => state.tab
    }
}