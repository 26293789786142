<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card width="90%" class="mt-5" elevation="0">
        <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
          <v-img :src="require('../../assets/survey_dark.svg')" class="mr-3" contain max-width="35" max-height="35" />
          <strong style="font-size:26px;">Checklist</strong>
        </v-card-title>
      </v-card>
    </v-row>
    <v-row justify="center" align="center" class="mt-15">
      <v-card width="90%" style="border-radius: 16px" elevation="0">
        <v-card-title>
          <h5>Checklist Templates</h5>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="isMobile() ? 6 : 4" v-for="(active, activeindex) in get_survey" :key="activeindex">
              <v-card elevation="0">
                <!--<v-card-title>
                  <v-img
                    v-if="active.image !== null"
                    :src="`${image}/cover_photo/${active.image}`"
                    contain
                    max-width="250px"
                    max-height="250px"
                  />
                  <v-img
                    v-else
                    :src="require('../../assets/surveydefault.svg')"
                    contain
                  />
                </v-card-title>-->
                <v-card-title>
                  <small style="font-size: 18px;width:100%;">{{ active.survey_name }}</small>
                  <!-- TODO: create survey id in fd_survey based on the template, so new templates can be used -->
                  <v-btn v-if="get_my_survey[0] == null" dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                    style="height:40px;text-transform: none !important" @click="create_link(active.id)">
                    Create link
                  </v-btn>
                  <v-btn v-else disabled color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                    style="height:40px;text-transform: none !important">
                    Link created
                  </v-btn>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row justify="center" align="center" class="mt-15">
      <v-card width="90%" style="border-radius: 16px" elevation="0">
        <v-card-title>
          <h5>My Checklists</h5>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="isMobile() ? 6 : 4">
              <v-card elevation="0">
                <!--<v-card-title>
                  <v-img
                    v-if="active.image !== null"
                    :src="`${image}/cover_photo/${active.image}`"
                    contain
                    max-width="250px"
                    max-height="250px"
                  />
                  <v-img
                    v-else
                    :src="require('../../assets/surveydefault.svg')"
                    contain
                  /
                </v-card-title>>-->
                <v-card-title>
                  <small style="font-size: 18px;width:100%;">{{ get_my_survey[0].survey_name }}</small>
                  <br>
                  <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                    style="height:40px;text-transform: none !important" @click="copy_link(get_my_survey[0].link)">
                    Copy link
                  </v-btn>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: [],
  data: () => ({
    image: null,
    url: null,
  }),
  async mounted() {
    await this.$store.dispatch("fd_survey/get_active_survey");
  },
  created() {
    this.image = process.env.VUE_APP_URL;
    this.url = process.env.VUE_APP_BUILD;
  },
  computed: {
    ...mapGetters({
      get_survey: "fd_survey/get_survey",
      get_my_survey: "fd_survey/get_my_survey",
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    async create_link(id) {
      await this.$axios
        .post("/auth/fd_survey/create_link", {
          id: id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch("fd_survey/get_active_survey");
            return;
          } else {
            this.showSnackBar(data.message);
            return;
          }
        });
    },
    async copy_link(link) {
      const data = this.url + "/survey/" + link
      try {
        await navigator.clipboard.writeText(data)
        this.showSnackBar("Copied!")
      } catch ($e) {
        this.showSnackBar("Cannot copy")
      }
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
