import AdminDocuments from '../AdminDocuments.vue'

export default [
    {
        path: '/admin_documents',
        name: '/admin_documents',
        component: AdminDocuments,
        meta: {
            layout: 'admin-layout'
        }
    }
]