<template>
    <v-container fluid>
      <v-card
        elevation="0"
      >
        <v-card-title>
            Basic
        </v-card-title>
        <v-card-subtitle style="word-break: break-word;">
            To get started, we need to know who you are. We've populated some info below with what we have from your profile. Please make corrections if needed.
        </v-card-subtitle>
        <v-card-text>
            <v-row
                class="mt-8"
            >
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                    <strong>First name*</strong>
                    <v-text-field
                        v-model="get_user.firstname"
                        outlined
                        dense
                        prepend-inner-icon="mdi-account"
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                    <strong>Middle name</strong>
                    <v-text-field
                        v-model="get_user.middlename"
                        outlined
                        dense
                        prepend-inner-icon="mdi-account"
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                    <strong>Last name*</strong>
                    <v-text-field
                        v-model="get_user.lastname"
                        outlined
                        dense
                        prepend-inner-icon="mdi-account"
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                    <strong>Suffix</strong>
                    <v-text-field
                        v-model="get_user.suffix"
                        outlined
                        dense
                        prepend-inner-icon="mdi-account"
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                    <strong>Date of birth</strong>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="get_user.birthday"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="get_user.birthday"
                        :active-picker.sync="activePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1900-01-01"
                    ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                    <strong>Marital Status</strong>
                    <v-autocomplete
                        v-if="get_user.marital_status_id === null"
                        outlined
                        dense
                        :items="get_marital_status"
                        item-text="name"
                        item-value="id"
                        v-model="c.marital_status"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                        v-else
                        outlined
                        dense
                        :items="get_marital_status"
                        item-text="name"
                        item-value="id"
                        v-model="get_user.get_marital_status.id"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                    <strong>Email Address</strong>
                    <v-text-field
                        v-model="get_user.email"
                        outlined
                        dense
                        prepend-inner-icon="mdi-at"
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                    <strong>Country</strong>
                    <v-autocomplete
                        outlined
                        dense
                        :items="get_countries"
                        item-text="country_name"
                        item-value="country_name"
                        v-model="get_user.country"
                        @change="get_states"
                        prepend-inner-icon="mdi-map-marker"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                    <strong>County | State</strong>
                    <v-autocomplete
                        v-model="get_user.county"
                        :items="get_states_computed"
                        outlined
                        dense
                        item-value="state_name"
                        item-text="state_name"
                        prepend-inner-icon="mdi-map-marker"
                        @change="get_cities"
                    ></v-autocomplete>
                </v-col>
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                <strong>Town | City</strong>
                    <v-autocomplete
                        v-model="get_user.town"
                        :items="get_cities_computed"
                        outlined
                        dense
                        item-value="city_name"
                        item-text="city_name"
                        prepend-inner-icon="mdi-map-marker"
                    ></v-autocomplete>
                </v-col>
                <v-col
                    :cols="isMobile() ? 12 : 3"
                >
                    <strong>Eircode | Zipcode</strong>
                    <v-text-field
                        outlined
                        dense
                        v-model="get_user.eircode"
                        prepend-inner-icon="mdi-map-marker"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text
            class="mt-10 pa-5"
            style="background-color: #DEF3FF;word-break: break-word;"
        >
            <h2>Why do we ask for this info?</h2>
            <strong>
                For your will to be legal, it needs to refrence your legal name as opposed to a nickname or chosen name you use.<br/>Your email address will also allow you to save your will so you can come back and edit it in the future.
            </strong>
            <br/><br/>
            <strong>
                Not that only 18 years old and above may create a will, your marital status may influence certain will<br/>choices, which we can highlight for you as your create your will.
            </strong>
            <br/><br/>
            <strong>
                Your place of residence will help ensure we are creating a wil lthat is specific to your state of residence. Your<br/>approximate value of assets Will help us determine whether you might want to see an attorney instead of<br/>using an online will.
            </strong>
        </v-card-text>
        <v-card-actions
            class="mt-15"
        >
            <v-btn
                text
                @click="back_to_dashboard"
            >
                <v-img
                    :src="require('../../../../../assets/chev_left_dark.svg')"
                    contain
                    max-width="35"
                    max-height="35"
                />
                Back
            </v-btn>
            <v-spacer/>
            <v-btn
                dark
                class="pl-10 pr-10 pt-5 pb-5"
                style="textTransform: none !important;"
                @click="next_step"
            >
                Save and continue
                <v-img
                    class="ml-3"
                    :src="require('../../../../../assets/chev_right_white.svg')"
                />
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    menu: false,
    activePicker: null,
    c: {
        marital_status: null
    }
  }),
  async mounted () {
    await axios.get("https://www.universal-tutorial.com/api/getaccesstoken", {
        headers: {
            "Accept": "application/json",
            "api-token": process.env.VUE_APP_UNIVERSAL_TOKEN,
            "user-email": process.env.VUE_APP_UNIVERSAL_EMAIL
        }
    })
    .then(({data}) => {
        this.token = "Bearer " + data.auth_token
    })
    await axios.get("https://www.universal-tutorial.com/api/countries", {
        headers:{
            "Authorization": this.token,
            "Accept": "application/json"
        }
    })
    .then(({data}) => {
        this.$store.dispatch('client_profile/set_countries', data)
    })
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_user:                           'auth/get_user',
        get_marital_status:                 'client_profile/get_marital_status',
        get_countries:                      'client_profile/get_countries',
        get_states_computed:                'client_profile/get_states',
        get_cities_computed:                'client_profile/get_cities',
    })
  },
  methods: {
    async back_to_dashboard(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.$router.push({name: '/client-dashboard'})
    },
    async next_step(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        let tp = {
            firstname:          this.get_user.firstname,
            lastname:           this.get_user.lastname,
            gender_id:          this.get_user.gender_id,
            email:              this.get_user.email,
            mobile:             this.get_user.mobile,
            address:            this.get_user.address,
            country:            this.get_user.country,
            county:             this.get_user.county,
            town:               this.get_user.town,
            eircode:            this.get_user.eircode,
            birthday:           this.get_user.birthday,
            age:                this.get_user.age,
            user_id:            this.get_user.id,
            marital_status_id:  this.get_user.marital_status_id === null ? this.c.marital_status : this.get_user.get_marital_status.id
        }
        await this.$axios.post('/auth/client/update_information', tp)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('auth/set_user_info', data.data)
                this.$store.dispatch('client_activity/set_last_will_el', 2)
            }
        })
    },
    async get_states(){
        await axios.get(`https://www.universal-tutorial.com/api/states/${this.get_user.country}`, {
            headers: {
                "Authorization": this.token,
                "Accept": "application/json"
            }
        })
        .then(({data}) => {
            this.$store.dispatch('client_profile/set_states', data)
        })
    },
    async get_cities(){
        await axios.get(`https://www.universal-tutorial.com/api/cities/${this.get_user.county}`, {
            headers: {
                "Authorization": this.token,
                "Accept": "application/json"
            }
        })
        .then(({data}) => {
            this.$store.dispatch('client_profile/set_cities', data)
        })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>