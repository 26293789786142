import FuneralDocuments from '../FuneralDocuments.vue'

export default [
    {
        path: '/funeral-documents',
        name: '/funeral-documents',
        component: FuneralDocuments,
        meta: {
            layout: 'public-layout'
        }
    },
]