<template>
    <v-container fluid>
      <v-expansion-panels
        accordion
      >
        <v-expansion-panel
          v-for="(item, i) in expansion_items"
          :key="i"
          @click="expansion_slot(i)"
        >
          <v-expansion-panel-header>
            <v-list-item two-line>
              <v-avatar
                style="position: relative; right: 18px;"
                size="100"
                v-if="!isMobile()"
              >
                <v-img
                  max-width="75"
                  max-height="75"
                  :src="item.icon"
                />
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <h2>{{item.label}}</h2>
                </v-list-item-title>
                <v-list-item-subtitle
                    style="fontSize: 12px;"
                    class="mt-2"
                >
                  {{item.sub_label}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-if="panel === 0"
            >
              <InnerFuneral/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </template>
  
  <script>
  import InnerFuneral from './inner/InnerFuneral.vue'
  export default {
    components: {
        InnerFuneral
    },
    props: [
    ],
    data: () => ({
        panel: null,
        expansion_items: [
            {
                label:      'Client Funeral',
                sub_label:  'What to do with client\'s body, client\'s ideal funeral etc.',
                icon:       require('../../../assets/farewell_funeral.svg')
            },
        ]
    }),
    mounted () {
    },
    created () {
    },
    computed: {
    },
    methods: {
        expansion_slot(index){
            this.panel = index
        },
        isMobile() {
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
          } else {
            return false
          }
        }
    },
    watch: {
    }
  }
  </script>
  
  <style scoped lang="scss">
  </style>