<template>
    <v-container fluid>
        <v-row
            v-if="get_client_funeral.length > 0"
        >
            <v-col
                cols="12"
                v-for="(item, itemindex) in get_client_funeral"
                :key="itemindex"
            >
                <v-card
                    class="mb-5"
                    elevation="0"
                >
                    <v-card-title style="word-break: break-all;display:inline-block">
                        <h4>{{item.question}}</h4>
                        <v-spacer/>
                    </v-card-title>
                    <v-card-subtitle>
                        <i
                            v-if="item.answer === null && !item.is_active"
                        >no answer yet</i>
                        <label
                            v-else
                        >
                            <v-chip>
                                <strong>{{item.answer}}</strong>
                            </v-chip>
                        </label>
                        <v-text-field
                            v-if="item.is_active"
                            filled
                            label="Answer here..."
                            v-model="item.answer"
                            @keyup.enter="save_answer(item.answer, item.id)"
                        >
                            <template v-slot:append>
                                <label>Press ENTER to save</label>
                            </template>
                        </v-text-field>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            v-else        
        >
            <v-card
                elevation="0"
                width="100%"
            >
                <v-card-title>
                    <small>Client does not answer yet</small>
                </v-card-title>
            </v-card>
        </v-row>
    </v-container>
  </template>
  
<script>
import { mapGetters } from 'vuex'
  export default {
    components: {
    },
    props: [
    ],
    data: () => ({
    }),
    async mounted () {
        await this.$store.dispatch('client/fetch_client_funeral', this.get_client.client_id)
    },
    created () {
    },
    computed: {
        ...mapGetters({
            get_client:         'client/get_funeral_director_client',
            get_client_funeral: 'client/get_client_funeral'
        })
    },
    methods: {
    },
    watch: {
    }
  }
</script>
  
  <style scoped lang="scss">
  </style>