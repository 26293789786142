import Vue from "vue";

export default {
    namespaced: true,
    state: {
        documents: [],
    },
    mutations: {
        set_documents(state, payload) {
            state.documents = [...payload];
        },
    },
    getters: {
        get_documents: (state) => state.documents,
    },
    actions: {
        async get_documents({ commit }) {
            await Vue.axios
                .get("non_auth/get_public_documents")
                .then(({ data }) => {
                    if (data.data)
                        commit("set_documents", data.data);
                });
        },
    },
};
