<template>
    <PaymentComponent :user="user" @refreshUser="refreshUser" :show_footer="true" />
</template>

<script>
import { mapGetters } from "vuex"
import PaymentComponent from "./components/PaymentComponent.vue"
export default {
    components: {
        PaymentComponent
    },
    props: [],
    data: () => ({
    }),
    async mounted() {
    },
    async created() {
    },
    computed: {
        ...mapGetters({
            user: "auth/get_user",
        }),
    },
    methods: {
        refreshUser() {
            this.$store.dispatch('auth/fetch_user')
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
</style>
