<template>
    <div>
        <v-app-bar
            color="#193B5B"
            dark
            height="150"
            app
        >
            <v-img
                :src="require('../assets/mfw.png')"
                contain
                max-height="120"
                max-width="120"
                class="mx-2"
                @click="$router.push({name: '/'})"
            />
            <v-spacer/>
            <v-btn
                v-if="token !== ''"
                outlined
                dark
                class="pl-10 pr-10"
                @click="go_board"
            >
                <v-icon
                    class="mr-3"
                >mdi-home</v-icon> Home
            </v-btn>
            <v-btn
                v-else
                outlined
                dark
                class="pl-10 pr-10"
            >
                Login
            </v-btn>
        </v-app-bar>
        <slot/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            token:              'auth/get_token',
            user:               'auth/get_user'
        })
    },
    methods: {
        go_board(){
            // if(this.user.role_id === 1){
            //     this.$router.push({name: '/admindboard'})
            // }
            switch(this.user.role_id){
                case 1:
                    this.$router.push({name: '/admindboard'})
                break
                case 2:
                    this.$router.push({name: '/funeral-director'})
                break
                case 3:
                    this.$router.push({name: '/client-dashboard'})
                break
                default:
                break;
            }
        }
    }
}
</script>

<style scoped>
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: inherit;
        height: inherit;
        margin-right: 15px;
        cursor: pointer;
        margin-top: 7px;
    }
</style>