<template>
  <v-container fluid>
    <h3 style="font-size:25px;color:#172C54" class="mb-8">My Farewell Wishes</h3>
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="(item, i) in expansion_items" :key="i" @click="expansion_slot(i)"
        style="border:solid 2px #F3F3F3;border-radius:10px;" class="mb-5">
        <v-expansion-panel-header :id="'panel' + i">
          <v-list-item two-line>
            <v-avatar style="position: relative; right: 18px;" size="65">
              <v-img max-width="65" max-height="65" :src="item.icon" />
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <h2 style="font-size:22px;color:#172C54;white-space: pre-line;word-break: break-word;">{{ item.label }}
                </h2>
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 18px;width:50%;white-space: pre-line;word-break: break-word;"
                class="mt-2">
                {{ item.sub_label }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <SurveyWishes :wishes="wishes_details" :wishes_index="panel" @set_active="set_active" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel @click="expansion_slot(6)"
        style="border:solid 2px #F3F3F3;border-radius:10px;" class="mb-5">
        <v-expansion-panel-header :id="'panel6'">
          <v-list-item two-line>
            <v-avatar style="position: relative; right: 18px;" size="65">
              <v-img max-width="65" max-height="65" :src="require('../../../assets/activity_memorial_dark.svg')" />
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <h2 style="font-size:22px;color:#172C54;white-space: pre-line;word-break: break-word;">Funeral Notice
                </h2>
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 18px;width:50%;white-space: pre-line;word-break: break-word;"
                class="mt-2">
                Provide your details for your funeral notice
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <FuneralNotice />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import SurveyWishes from './InnerComponents/SurveyWishes.vue'
import FuneralNotice from './FuneralNotice.vue'
import { mapGetters } from 'vuex'
//import AdditionalWishes from './InnerComponents/AdditionalWishes.vue'
export default {
  components: {
    SurveyWishes, FuneralNotice
  },
  props: [
  ],
  data: () => ({
    expansion_items: [
      {
        label: 'Burial / Cremation Wishes',
        sub_label: 'Will it be burial or cremation for you?',
        icon: require('../../../assets/activity_farewell_dark_2.svg')
      },
      {
        label: 'Ceremony Wishes',
        sub_label: 'What kind of ceremony you want',
        icon: require('../../../assets/activity_post_loss_dark.svg')
      },
      {
        label: 'Wake / Viewing / Reposing Wishes',
        sub_label: 'What you would like at your wake / viewing / reposing',
        icon: require('../../../assets/activity_memorial_dark.svg')
      },
      {
        label: 'Personal Wishes',
        sub_label: "Things you want or don't want at your funeral",
        icon: require('../../../assets/farewell_health.svg')
      },
      {
        label: 'Funeral Cost',
        sub_label: 'If you would like an estimate for the funeral cost',
        icon: require('../../../assets/farewell_funeral.svg')
      },
      {
        label: 'Payment Information',
        sub_label: 'If you would like more information about how to pay',
        icon: require('../../../assets/farewell_digital.svg')
      },
    ],
    wishes_details: [
      {
        name: 'funeral_service',
        data: [
          {
            question: 'Burial or cremation? *',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
          /*{
            question: 'Instructions for cremated remains',
            answer: 'check_box',
            is_active: false,
            is_editable: true
          },*/
          {
            question: 'Preferred location?',
            answer: 'answer',
            is_active: false,
            is_editable: true
          },
          {
            question: 'More details',
            answer: 'details',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'ceremony',
        data: [
          {
            question: 'Would you like your ceremony to be... *',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
          {
            question: 'What kind of ceremony would you prefer?',
            answer: 'chosen',
            is_active: false,
            is_editable: true
          },
          {
            question: 'More details',
            answer: 'details',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'wake',
        data: [
          {
            question: 'Would you like to have a wake / public reposing? *',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
          {
            question: 'Family home or funeral home? *',
            answer: 'choice',
            is_active: false,
            is_editable: true
          },
          {
            question: 'Location *',
            answer: 'answer',
            is_active: false,
            is_editable: true
          },
          {
            question: 'Coffin',
            answer: 'coffin',
            is_active: false,
            is_editable: true
          },
          {
            question: 'More details',
            answer: 'details',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'personal_wishes',
        data: [
          {
            question: 'Things that I want',
            answer: 'want',
            is_active: false,
            is_editable: true
          },
          {
            question: "Things that I don't want",
            answer: 'dontwant',
            is_active: false,
            is_editable: true
          },
          {
            question: "People to inform / invite",
            answer: 'people',
            is_active: false,
            is_editable: true
          },
          {
            question: "Details and speakers for eulogy",
            answer: 'eulogy',
            is_active: false,
            is_editable: true
          },
          {
            question: "Symbols of the life lived for a ceremony",
            answer: 'symbols',
            is_active: false,
            is_editable: true
          },
          {
            question: "People to carry the coffin",
            answer: 'coffin_carry',
            is_active: false,
            is_editable: true
          },
          {
            question: "After funeral requests - food / drinks reception",
            answer: 'reception',
            is_active: false,
            is_editable: true
          },
          {
            question: "How to look after ashes",
            answer: 'ashes',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'funeral_cost',
        data: [
          {
            question: 'Would you like to get an estimate for the cost of your funeral? *',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'payment_preference',
        data: [
          {
            question: 'Would you like more information about how to pay towards your funeral?',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
        ]
      },
    ],
    panel: null
  }),
  mounted() {
  },
  created() {
    this.check_survey()
  },
  computed: {
    ...mapGetters({
      user: "auth/get_user"
    })
  },
  methods: {
    async expansion_slot(index) {
      this.panel = index
      setTimeout(() => {
        this.$vuetify.goTo('#panel' + index, { offset: 50 });
      }, 300)
    },
    async check_survey() {
      await this.$axios.post('/auth/client/create_client_survey', { fd_id: this.user.get_user_add_info.funeral_director_id })
        .then(() => {
          this.$store.dispatch('auth/fetch_user')
        })
    },
    set_active(item) {
      let active = this.wishes_details[item.wish_index].data[item.question_index].is_active
      this.wishes_details[item.wish_index].data[item.question_index].is_active = !active
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss"></style>