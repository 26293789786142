import PrivacyPolicy from '../PrivacyPolicy.vue'

export default [
    {
        path: '/privacy-policy',
        name: '/privacy-policy',
        component: PrivacyPolicy,
        meta: {
            layout: 'public-layout'
        }
    },
]