import SolicitorDashboard from '../SolicitorDashboard.vue'
import ViewWill from '../ViewWill.vue'
import WillComponent from '../WillComponent.vue'

export default [
    {
        path: '/solicitor-dashboard',
        name: 'Solicitor Dashboard',
        component: SolicitorDashboard,
        meta: {
            layout: 'solicitor-layout'
        }
    },
    {
        path: '/view_will/:will_id',
        name: 'View Will',
        component: ViewWill,
        meta: {
            layout: 'will-layout'
        }
    },
    {
        path: '/create_will/:solicitor_id',
        name: 'Create Will',
        component: WillComponent,
        meta: {
            layout: 'will-layout'
        }
    },
    {
        path: '/edit_will',
        name: 'Edit Will',
        component: WillComponent,
        meta: {
            layout: 'will-layout'
        }
    },
]