<template>
  <v-container fluid style="background-color: #FAFBFF !important; height: 100%">
    <v-row justify="center" align="center" class="mt-15">
      <v-card :width="isMobile() ? '100%' : '55%'" class="mt-5" elevation="0" style="background-color: #FAFBFF">
        <v-card-title class="mb-5">
          <h2 v-if="user.representative != null" style="font-size:35px;color:black;word-break: break-word">Hello, {{
            user.representative }}!</h2>
          <h2 v-else style="font-size:35px;color:black;word-break: break-word">Hello, {{
            user.name }}!</h2>
        </v-card-title>
        <v-card-subtitle>
          <i style="color:rgb(70, 70, 70);font-size:15px;font-weight:500">Welcome to your My Farewell Wishes online
            account. Use the
            tabs below to review and update your wishes.</i>
        </v-card-subtitle>
        <v-card-subtitle style="text-align:center;">
          <h2 style="color:rgb(70, 70, 70);font-weight:600" class="mb-5">How to use the Dashboard</h2>
          <v-btn @click="show_dashboard_video()" color="grey" dark :width="isMobile() ? '300' : '700'" :height="isMobile() ? '150' : '350'" style="border-radius:5px;">
            <v-img class="dashboard_preview" style="border-radius:5px;" :width="isMobile() ? '300' : '700'" :height="isMobile() ? '150' : '350'"
              :src="require('../../assets/dashboard_preview.png')" />
          </v-btn>
        </v-card-subtitle>
      </v-card>
      <v-card style="border-radius: 16px;margin-bottom:100px;" :width="isMobile() ? '95%' : '65%'" elevation="2"
        class="mt-5 pl-5 pr-5 pt-10">
        <v-row>
          <v-col :cols="isMobile() ? 12 : 4">
            <v-card-title class="mb-5">
              <v-img contain max-height="50" max-width="50" :src="require('../../assets/client_dashboard.svg')" />
              <h2 class="ml-1" style="font-size:35px;color:#172C54;">Dashboard</h2>
            </v-card-title>
          </v-col>
          <v-col :cols="isMobile() ? 12 : 8">
            <v-card-subtitle class="mt-3">
              <div class="d-flex">
                <h2 class="ml-1 mr-5" style="font-size:16px;color:#172C54;">Progress:</h2>
                <div v-if="user.progress != null" class="shell">
                  <div class="bar" :style="{ width: user.progress.percentage + '%' }">
                    <span>{{ user.progress.percentage }}%</span>
                  </div>
                </div>
              </div>
            </v-card-subtitle>
          </v-col>
        </v-row>
        <v-divider style="width: 100%; color:#E9F0FF" class="mb-5"></v-divider>
        <v-card-text>
          <div v-for="card in dashboard_cards" :key="card.id">
            <v-card @click="change_tabs(1, card.id)" elevation="0"
              style="border:solid 2px #F3F3F3;border-radius:10px;cursor: pointer;" class="mb-3 hover">
              <v-card-title>
                <v-row style="width:100%;">
                  <v-col :cols="isMobile() ? 4 : 2">
                    <v-layout justify-center align-center>
                      <v-img contain max-width="80" :src="card.icon" />
                    </v-layout>
                  </v-col>
                  <v-col :cols="isMobile() ? 8 : 10">
                    <div :class="isMobile() ? '' : 'd-flex'">
                      <h2 class="mb-7" style="font-size:24px;color:#172C54;white-space: pre-line;word-break: break-word;">
                        {{
                          card.title }}</h2>
                      <v-spacer></v-spacer>
                      <div v-if="card.has_completion">
                        <div v-if="user.progress != null && user.progress['tab_' + card.id] == true"
                          :class="isMobile() ? 'mb-7' : ''" style="margin-top:-4px;">
                          <v-icon size="30" style="color:#108606;">mdi-check</v-icon><small
                            style="color:#108606;font-size:16px;" class="ml-2">completed</small>
                        </div>
                        <div v-else style="margin-top:-4px;" :class="isMobile() ? 'mb-7' : ''">
                          <small style="color:#8b8b8b;font-size:16px;" class="ml-3"><i>incomplete</i></small>
                        </div>
                      </div>
                    </div>
                    <br />
                    <h5
                      style="font-size:14px;margin-top:-50px;font-weight:400;color:rgb(119, 119, 119);white-space: pre-line;word-break: break-word;">
                      {{ card.description }}
                    </h5>
                  </v-col>
                </v-row>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <h2 style="color:#172C54;font-size:17px;text-decoration: underline;text-transform: none;">{{
                  card.button_text }}</h2>
                <v-icon color=#172C54>mdi-chevron-right</v-icon>
              </v-card-actions>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="share_model" persistent max-width="560">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field filled v-model="s.firstname" dense label="First Name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field filled v-model="s.lastname" dense label="Last Name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field filled v-model="s.email" dense label="Email"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field filled v-model="s.mobile" dense label="Mobile"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="pl-10 pr-10" @click="cancel_dialog_share">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="share_profile_fn" style="text-transform: none !important;">
              Share my profile
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="open_dashboard_video" persistent max-width="700">
        <v-card>
          <v-card-title>
          </v-card-title>
          <v-card-text class="mt-5">
            <video id="dashboard_video" width="100%" controls autoplay>
              <source src="../../assets/dashboard_2.mp4">
            </video>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close_dashboard_video()">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <ClientFooter />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ClientFooter from "../components/ClientFooter.vue"
export default {
  components: {
    ClientFooter
  },
  props: [],
  data: () => ({
    progress: 0,
    dashboard_cards: [
      {
        id: 0,
        title: "My Farewell Wishes",
        description: "Plan your funeral wishes and make things easier on your family one day.",
        button_text: "Go to My Farewell Wishes",
        icon: require("../../assets/activity_farewell_dark_2.svg"),
        has_activity: false,
        has_completion: true
      },
      {
        id: 1,
        title: "Share your Wishes",
        description: "Share your wishes so someone knows your plans.",
        button_text: "Go to Share your Wishes",
        icon: require("../../assets/activity_profile_share_dark.svg"),
        has_activity: false,
        has_completion: true
      },
      {
        id: 2,
        title: "Request a Funeral Cost Estimate",
        description: "Request a cost estimate for your funeral.",
        button_text: "Request a Funeral Cost Estimate",
        icon: require("../../assets/activity_documents_dark.svg"),
        has_activity: false,
        has_completion: true
      },
      {
        id: 3,
        title: "Request a Certificate of Your Wishes by Post",
        description: "Request a certificate of your wishes.",
        button_text: "Request a Certificate of Wishes",
        icon: require("../../assets/farewell_legacy.svg"),
        has_activity: false,
        has_completion: false
      },
      {
        id: 4,
        title: "View our Funeral Planning Guides",
        description: "Documents and brochures to help guide you in planning your funeral.",
        button_text: "Go to Funeral Planning Guides",
        icon: require("../../assets/activity_post_loss_dark.svg"),
        has_activity: false,
        has_completion: false
      },
      {
        id: 5,
        title: "My Documents",
        description: "Upload and store your key documents, including files, photos and your will.",
        button_text: "Go to My Documents",
        icon: require("../../assets/activity_documents_dark.svg"),
        has_activity: false
      },
      /*{
        id: 5,
        title: "Create your Funeral Notice",
        description: "Provide your details for your funeral notice.",
        button_text: "Go to Funeral Notice",
        icon: require("../../assets/activity_memorial_dark.svg"),
        has_activity: false,
        has_completion: false
      },*/
      /*{
        id: 0,
        title: "Make My Last Will",
        description: "Create your specific last will and testament in minutes.",
        button_text: "View My Last Will",
        icon: require("../../assets/activity_will_dark.svg"),
        has_activity: true
      },*/
      /*{
        id: 2,
        title: "Identity Verification",
        description: "Upload necessary documents for the safety and security of your account.",
        button_text: "View my indentification",
        icon: require("../../assets/activity_will_dark.svg"),
        has_activity: true
      },
      {
        id: 2,
        title: "My Documents",
        description: "Upload and store your key documents, including files, photos and your will.",
        button_text: "Go to My Documents",
        icon: require("../../assets/activity_documents_dark.svg"),
        has_activity: false
      },
      {
        id: 3,
        title: "Create a Memorial",
        description: "Create something on how your loved ones will be remembered.",
        button_text: "Start creating a Memorial",
        icon: require("../../assets/activity_memorial_dark.svg"),
        has_activity: false
      },
      {
        id: 4,
        title: "My Post Loss Checklist",
        description: "Create a checklist of important things to do when someone close to you dies.",
        button_text: "Create your Post Loss Checklist",
        icon: require("../../assets/activity_post_loss_dark.svg"),
        has_activity: true
      },*/
    ],
    share_model: false,
    s: {
      firstname: null,
      lastname: null,
      email: null,
      mobile: null,
    },
    open_dashboard_video: false,
  }),
  mounted() {
    this.create_progress()
  },
  created() {
  },
  computed: {
    ...mapGetters({
      user: "auth/get_user",
      get_invited: 'client_activity/get_invited'
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit("auth/setMessage",
        { show: true, message: message },
        { root: 1 })
    },
    async check_completion() {
      let wish_data = this.user.get_survey.data
      let new_progress = this.user.progress
      let tp = {
        budget: wish_data.budget,
        ceremony: wish_data.ceremony,
        funeral_service: wish_data.funeral_service,
        payment_preference: wish_data.payment_preference,
        funeral_cost: wish_data.funeral_cost,
        personal_wishes: wish_data.personal_wishes,
        wake: wish_data.wake,
        progress: new_progress
      }
      await this.$axios.patch('/auth/client/activities/edit_client_survey', tp)
        .then(() => {
          this.$store.dispatch('auth/fetch_user')
        })
    },
    async create_progress() {
      if (this.user.progress == null) {
        await this.$axios.post('/auth/client/create_progress')
          .then(() => {
            this.$store.dispatch('auth/fetch_user')
              .then(() => {
                this.check_completion()
              })
          })
      }
      else {
        this.check_completion()
      }
    },
    show_dashboard_video() {
      this.open_dashboard_video = true
      document.getElementById("dashboard_video").play()
    },
    close_dashboard_video() {
      this.open_dashboard_video = false
      document.getElementById("dashboard_video").pause()
    },
    change_tabs(index, activity_index) {
      let payload = {
        tab_index: index,
        activity_index: activity_index
      }
      this.$emit('change_tabs', payload)
    },
    share_model_change() {
      this.share_model = true
    },
    cancel_dialog_share() {
      this.share_model = false
    },
    async share_profile_fn() {
      this.showSnackBar('Please wait...')
      await this.$axios.post('/auth/client/profile_share/search_email', {
        email: this.s.email,
        firstname: this.s.firstname,
        lastname: this.s.lastname,
        mobile: this.s.mobile
      })
        .then(({ data }) => {
          if (data.response) {
            this.showSnackBar('Invite has been sent')
            this.s.email = null
            this.s.firstname = null
            this.s.lastname = null
            this.s.mobile = null
            this.share_model = false
            this.$store.dispatch('client_activity/fetch_invited')
            this.$store.dispatch('client_profile/fetch_invited')
          }
          else {
            this.showSnackBar(data.message)
          }
        })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.shell {
  height: 28px;
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 13px;
  padding: 3px;
}

.bar {
  background: linear-gradient(to right, #193B5B, #21486d);
  height: 20px;
  width: 15px;
  border-radius: 9px;

  span {
    float: right;
    color: #fff;
    font-size: 0.7em;
    padding-bottom: 10px;
    padding-right: 5px;
  }
}

.hover:hover {
  background-color: rgb(243, 243, 243);
}

.dashboard_preview:hover {
  opacity: 0.8;
}
</style>
