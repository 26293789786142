import Vue from "vue";

export default {
    namespaced: true,
    state: {
        email_sequences: [],
        selected_sequence: {},
        email_templates: [],
        email_records: []
    },
    actions: {
        set_email_sequences({ commit }, payload) {
            commit('set_email_sequences', payload)
        },
        async fetch_email_sequences({ commit }) {
            await Vue.axios.get("auth/admin/emails/get_email_sequences")
                .then(({ data }) => {
                    if (data.response) {
                        commit("set_email_sequences", data.data)
                    }
                    if (data.data.length == 0) {
                        commit("set_selected_sequence", {})
                    }
                });
        },
        async fetch_email_templates({ commit }) {
            await Vue.axios.get("auth/admin/emails/get_email_templates")
                .then(({ data }) => {
                    if (data.response) {
                        commit("set_email_templates", data.data)
                    }
                })
        },
        async fetch_email_records({ commit }) {
            await Vue.axios.get("auth/admin/emails/get_email_records")
                .then(({ data }) => {
                    if (data.response) {
                        commit("set_email_records", data.data)
                    }
                })
        }
    },
    mutations: {
        set_email_sequences(state, payload) {
            state.email_sequences = [...payload]
            if (state.selected_sequence.id) {
                let index = state.email_sequences.findIndex(item => item.id == state.selected_sequence.id)
                if (index == -1) {
                    state.selected_sequence = {}
                }
                else {
                    state.selected_sequence = state.email_sequences[index]
                }
            }
        },
        set_selected_sequence(state, payload) {
            state.selected_sequence = payload
        },
        set_email_templates(state, payload) {
            state.email_templates = [...payload]
        },
        set_email_records(state, payload) {
            state.email_records = [...payload]
        },
        set_email_template(state, payload) {
            let index = state.email_templates.findIndex(item => item.id == payload.id)
            if (index != -1) {
                Vue.set(state.email_templates, index, payload)
            }
        }
    },
    getters: {
        get_email_sequences: (state) => state.email_sequences,
        get_selected_sequence: (state) => state.selected_sequence,
        get_email_templates: (state) => state.email_templates,
        get_email_records: (state) => state.email_records
    },
};
