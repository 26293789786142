import Vue from "vue";

export default {
    namespaced: true,
    state: {
        payment_terms: [],
        deposit_payment: {},
        installment_payments: [],
        all_payments: [],
    },
    mutations: {
        set_payment_terms(state, payload) {
            state.payment_terms = [...payload];
        },
        set_deposit_payment(state, payload) {
            state.deposit_payment = payload;
        },
        set_installment_payments(state, payload) {
            state.installment_payments = [...payload];
        },
        set_all_payments(state, payload) {
            state.all_payments = [payload.deposit, ...payload.installments];
        },
    },
    getters: {
        get_payment_terms: (state) => state.payment_terms,
        get_deposit_payment: (state) => state.deposit_payment,
        get_installment_payments: (state) => state.installment_payments,
        get_all_payments: (state) => state.all_payments,
    },
    actions: {
        async fetch_payment_terms({ commit }) {
            await Vue.axios.get("auth/client/payment/get_payment_terms")
                .then(({ data }) => {
                    commit("set_payment_terms", data.data);
                });
        },
        async set_payment_terms({ commit }, payload) {
            commit("set_payment_terms", payload)
        },
        async fetch_deposit_payment({ commit }) {
            await Vue.axios.get("auth/client/payment/get_payments")
                .then(({ data }) => {
                    commit("set_deposit_payment", data.deposit);
                    commit("set_installment_payments", data.installments);
                    commit("set_all_payments", data);
                });
        }
    },
};
