import ViewInvoice from '../ViewInvoice.vue'
import CostEstimate from '../CostEstimate.vue'

export default [
    {
        path: '/view_invoice/:token',
        name: 'View Invoice',
        component: ViewInvoice,
        meta: {
            layout: 'public-layout'
        }
    },
    {
        path: '/cost_estimate/:token',
        name: 'Cost Estimate',
        component: CostEstimate,
        meta: {
            layout: 'public-layout'
        }
    },
]