import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        default_template: {}
    },
    actions: {
        async fetch_default_template({ commit }) {
            await Vue.axios.get('/auth/admin/funeral_notice/get_default_template')
                .then(({ data }) => {
                    commit('set_default_template', data.data)
                })
        },
    },
    mutations: {
        set_default_template(state, payload) {
            state.default_template = payload
        },
    },
    getters: {
        get_default_template: state => state.default_template,
    }
}