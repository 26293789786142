import EmailComponent from '../EmailComponent.vue'

export default [
    {
        path: '/emails',
        name: '/emails',
        component: EmailComponent,
        meta: {
            layout: 'admin-layout'
        }
    }
]