<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <strong style="font-size:26px;">Email Management</strong>
                    <v-spacer />
                </v-card-title>
                <v-card-subtitle class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
                    Manage emails sent to clients.
                </v-card-subtitle>
            </v-card>
        </v-row>
        <v-row>
            <v-col :cols="isMobile() ? 12 : 8">
                <v-card width="90%" style="border-radius: 16px" class="mb-10" elevation="0">
                    <v-card-title>
                        Email Sequences
                        <v-spacer></v-spacer>
                        <v-btn dark text color="#172C54" class="px-5"
                            @click="openSequence('new')"><v-icon>mdi-plus</v-icon>
                            New</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-select :items="get_email_sequences" dense filled item-text="name" item-value="id"
                            return-object v-model="selected_sequence" label="Select sequence...">
                        </v-select>
                    </v-card-text>
                </v-card>
                <v-card v-if="selected_sequence.name" width="90%" style="border-radius: 16px" class="mb-10"
                    elevation="0">
                    <v-card-title>
                        {{ selected_sequence.name }} <small class="ml-3 mt-1">
                            ({{ (selected_sequence.active) ? 'Active' : 'Not Active' }})
                        </small>
                        <v-spacer></v-spacer>
                        <v-btn dark text color="#172C54" @click="openSequence('edit')">
                            <v-icon class="mr-2">mdi-pen</v-icon>
                            Edit</v-btn>
                        <v-btn dark text color="red" @click="openDelete('sequence')">
                            <v-icon class="mr-2">mdi-delete</v-icon>
                            Delete</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <!-- <div class="mb-5">
                            Scheduled to advance
                            <b>{{ (selected_sequence.client_ids) ? selected_sequence.client_ids.length : 0 }} clients</b>
                            through sequence every <b>{{ selected_sequence.frequency }} day/s</b>
                            at <b>{{ $date(new Date('1970-01-01T' + selected_sequence.time)).format('h:mm a') }}</b>
                        </div> -->
                        <!-- <div class="mb-5">
                            Next schedule:
                            <b>
                                {{
                                    $date().add(selected_sequence.frequency, 'day').format('ddd, D MMM YYYY') + ' ' +
                                    $date(new Date('1970-01-01T' + selected_sequence.time)).format('h:mm a')
                                }}
                            </b>
                        </div> -->
                        <!-- <div class="mb-5">
                            Automatically add leads of directors: {{ selected_sequence.fd_ids }}
                        </div> -->
                        <v-btn text @click="advanceSequence()">Advance Sequence</v-btn>
                    </v-card-text>
                    <v-card-title class="pl-15">
                        Emails
                        <v-spacer></v-spacer>
                        <v-btn dark text color="#172C54" @click="openEmail('new')"><v-icon
                                class="mr-2">mdi-plus</v-icon>
                            Add Email To Sequence</v-btn>
                    </v-card-title>
                    <v-card-text class="pl-15">
                        <v-data-table :items="selected_sequence.get_emails" :headers="email_headers" hide-default-footer
                            disable-pagination>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td align="center">
                                        <b>{{ (item.client_ids) ? item.client_ids.length : 0 }}</b>
                                        <v-btn v-if="item.client_ids && item.client_ids.length > 0" small text
                                            @click="openClientList(item)">View</v-btn>
                                    </td>
                                    <td>
                                        <small>
                                            <div class="mb-5"><b>Subject:</b> {{ item.subject }}</div>
                                            <div class="mb-5"><b>Time:</b> {{ item.time }}</div>
                                            <div class="mb-5">Send after {{ item.interval }} day<span
                                                    v-if="item.interval != 1">s</span></div>
                                        </small>
                                    </td>
                                    <td style="max-width:200px;">
                                        <div style="overflow-y:scroll;max-height:250px;padding:20px;"
                                            v-html="item.content">
                                        </div>
                                    </td>
                                    <td>
                                        <v-btn style="min-width:20px;" text color="#172C54"
                                            @click="openEmail('edit', item)"><v-icon>mdi-pen</v-icon></v-btn>
                                        <v-btn style="min-width:20px;" text color="red"
                                            @click="openDelete('email', item)"><v-icon>mdi-delete</v-icon></v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template slot="no-data">
                                No emails found in sequence
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col :cols="isMobile() ? 12 : 4">
                <v-card width="90%" style="border-radius: 16px" class="mb-10" elevation="0">
                    <v-card-title>
                        Send Email to Client/s
                        <v-spacer></v-spacer>
                        <v-btn text @click="openRecordsModal()">Records</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-select :items="get_clients" dense filled multiple item-text="get_user_info.name"
                            item-value="get_user_info.email" v-model="selected_clients" label="Send to...">
                        </v-select>
                        <v-text-field dense filled label="Subject" v-model="subject"></v-text-field>
                        <wysiwyg v-model="content" />
                        <br>
                        <v-btn :disabled="sending ? true : false" :dark="sending ? false : true" color="#172C54"
                            class="px-5" @click="sendEmail()">Send</v-btn>
                    </v-card-text>
                </v-card>
                <v-card width="90%" style="border-radius: 16px" class="mb-10" elevation="0">
                    <v-card-title>
                        Edit email templates
                    </v-card-title>
                    <v-card-text>
                        <v-select :items="get_email_templates" dense filled v-model="selected_email_template"
                            item-text="name" item-value="id" return-object label="Select an email template">
                        </v-select>
                        <div v-if="selected_email_template">
                            <v-text-field dense filled label="Subject"
                                v-model="selected_email_template.subject"></v-text-field>
                            Variables
                            <div class="mb-5 d-flex" style="flex-wrap:wrap;">
                                <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                    :value="variables.client_name"></v-text-field>
                                <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                    :value="variables.client_email"></v-text-field>
                                <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                    :value="variables.client_password"></v-text-field>
                                <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                    :value="variables.fd_name"></v-text-field>
                                <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                    :value="variables.fd_company"></v-text-field>
                                <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                    :value="variables.invoice_link"></v-text-field>
                                <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                    :value="variables.checklist_link"></v-text-field>
                            </div>
                            <wysiwyg v-model="selected_email_template.content" />
                            <br>
                            <v-btn dark color="#172C54" class="px-5" @click="saveWelcomeEmail()">Save</v-btn>
                            <v-text-field dense filled v-model="test_email" label="Test email" class="mt-5"
                                v-on:keyup.enter="sendEmailTemplate()"></v-text-field>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="open_sequence" persistent max-width="600">
                <v-card>
                    <v-card-title v-if="sequence_type == 'new'">New Sequence</v-card-title>
                    <v-card-title v-if="sequence_type == 'edit'">Edit Sequence</v-card-title>
                    <v-card-text>
                        <v-checkbox label="Set as Active" v-model="sequence.active"></v-checkbox>
                        <v-text-field label="Sequence Name *" v-model="sequence.name"></v-text-field>
                        <!-- <v-dialog ref="dialog" v-model="open_time" :return-value.sync="sequence.time" persistent lazy
                            full-width width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="sequence.time" label="Time of day *" readonly
                                    v-on="on"></v-text-field>
                            </template>
                            <v-time-picker v-if="open_time" v-model="sequence.time" full-width>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="open_time = false">Cancel</v-btn>
                                <v-btn color="primary" @click="$refs.dialog.save(sequence.time)">OK</v-btn>
                            </v-time-picker>
                        </v-dialog>
                        <v-text-field type="number" label="Frequency in Days *" v-model="sequence.frequency"></v-text-field> -->
                        <v-select :items="get_clients" multiple item-text="get_user_info.name" item-value="client_id"
                            v-model="sequence.client_ids" label="Select clients to add to sequence..."></v-select>
                        <!-- <div class="mt-5 mb-3">
                            Select which directors' new leads should automatically be added to sequence
                            <br>
                            <b>or</b> leave empty to add all new leads to sequence
                        </div>
                        <v-select :items="get_fds" dense multiple item-text="name" item-value="id" v-model="sequence.fd_ids"
                            label="Select directors..."></v-select> -->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeSequence()">
                            Cancel
                        </v-btn>
                        <v-btn v-if="sequence_type == 'new'" color="#172C54" dark class="pl-10 pr-10"
                            @click="createSequence()">
                            Create
                        </v-btn>
                        <v-btn v-if="sequence_type == 'edit'" color="#172C54" dark class="pl-10 pr-10"
                            @click="editSequence()">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="open_email" persistent max-width="600">
                <v-card>
                    <v-card-title v-if="email_type == 'new'">New Email</v-card-title>
                    <v-card-title v-if="email_type == 'edit'">Edit Email</v-card-title>
                    <v-card-text>
                        <v-dialog ref="dialog" v-model="open_time" :return-value.sync="email.time" persistent lazy
                            full-width width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="email.time" label="Time of day *" readonly
                                    v-on="on"></v-text-field>
                            </template>
                            <v-time-picker v-if="open_time" v-model="email.time" full-width>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="open_time = false">Cancel</v-btn>
                                <v-btn color="primary" @click="$refs.dialog.save(email.time)">OK</v-btn>
                            </v-time-picker>
                        </v-dialog>
                        <v-text-field type="number" label="Wait how many days *"
                            v-model="email.interval"></v-text-field>
                        Variables
                        <div class="mb-5 d-flex" style="flex-wrap:wrap;">
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.client_name"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.client_email"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.client_password"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.fd_name"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.fd_company"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.invoice_link"></v-text-field>
                            <v-text-field class="placeholder" dense readonly @focus="$event.target.select()"
                                :value="variables.checklist_link"></v-text-field>
                        </div>
                        <v-text-field label="Subject *" v-model="email.subject"></v-text-field>
                        <wysiwyg v-model="email.content" />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeEmail()">
                            Cancel
                        </v-btn>
                        <v-btn v-if="email_type == 'new'" color="#172C54" dark class="pl-10 pr-10"
                            @click="createEmail()">
                            Create
                        </v-btn>
                        <v-btn v-if="email_type == 'edit'" color="#172C54" dark class="pl-10 pr-10"
                            @click="editEmail()">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="open_client_list" persistent max-width="600">
                <v-card v-if="selected_email">
                    <v-card-title>Clients Sent</v-card-title>
                    <v-card-text>
                        <v-data-table :items="get_clients_by_id" :headers="client_headers" hide-default-footer
                            disable-pagination>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.get_user_info.name }}</td>
                                    <td>{{ item.get_user_info.email }}</td>
                                    <td>{{ item.get_fd_info.company_name }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeClientList()">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="open_delete" persistent max-width="600">
                <v-card>
                    <v-card-title>Are you sure?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="open_delete = false">
                            Cancel
                        </v-btn>
                        <v-btn v-if="delete_type == 'sequence'" text color="red" dark class="pl-10 pr-10"
                            @click="deleteSequence()">
                            <v-icon class="mr-2">mdi-delete</v-icon> Delete Sequence
                        </v-btn>
                        <v-btn v-if="delete_type == 'email'" text color="red" dark class="pl-10 pr-10"
                            @click="deleteEmail()">
                            <v-icon class="mr-2">mdi-delete</v-icon> Delete Email
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="records_modal" persistent max-width="800">
                <v-card>
                    <v-card-title>Records</v-card-title>
                    <v-card-text>
                        <v-data-table :items="get_email_records" :headers="email_record_headers" :sort-by.sync="sort_by"
                            :sort-desc.sync="sort_desc">
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ $date(item.created_at).format('D MMM YYYY, h:mm a') }}</td>
                                    <td>
                                        <div v-for="(email, index) in item.client_emails" :key="index">{{ email }}</div>
                                    </td>
                                    <td>{{ item.subject }}</td>
                                    <td>
                                        <div style="overflow-y:scroll;max-height:250px;padding:20px;"
                                            v-html="item.content">
                                        </div>
                                    </td>
                                </tr>
                            </template></v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="records_modal = false">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [],
    data: () => ({
        api_url: null,
        open_sequence: false,
        open_time: false,
        open_email: false,
        open_delete: false,
        records_modal: false,
        // Email sequences
        sequence_type: null,
        sequence: {
            created_by: null,
            active: false,
            name: null,
            // time: null,
            // frequency: null,
            client_ids: null,
            fd_ids: null,
        },
        // Emails
        email_type: null,
        email_id: null,
        email: {
            sequence_id: null,
            subject: null,
            content: null,
            time: null,
            interval: null,
        },
        email_headers: [
            { text: 'Clients Sent', sortable: false, width: '10%' },
            { text: 'Details', sortable: false, width: '10%' },
            { text: 'Content', sortable: false, width: '60%' },
            { text: 'Actions', sortable: false, width: '20%' }
        ],
        client_headers: [
            { text: 'Name', sortable: false },
            { text: 'Email', sortable: false },
            { text: 'Funeral Director', sortable: false }
        ],
        email_record_headers: [
            { text: 'Date Sent', sortable: false, width: '20%' },
            { text: 'Clients Sent', sortable: false, width: '10%' },
            { text: 'Subject', sortable: false, width: '10%' },
            { text: 'Content', sortable: false, width: '60%' }
        ],
        sort_by: 'created_at',
        sort_desc: true,
        open_client_list: false,
        selected_email: null,
        delete_type: null,
        // Send direct email
        selected_clients: null,
        subject: null,
        content: null,
        sending: false,
        selected_email_template: null,
        test_email: null,
        variables: {
            client_name: '{{client_name}}',
            client_email: '{{client_email}}',
            client_password: '{{client_password}}',
            fd_name: '{{fd_name}}',
            fd_company: '{{fd_company}}',
            invoice_link: '{{invoice_link}}',
            checklist_link: '{{checklist_link}}',
        },
    }),
    async mounted() {
        await this.$store.dispatch('client/get_fds')
        await this.$store.dispatch('fds/get_whole_clients')
        await this.$store.dispatch('admin_emails/fetch_email_sequences')
        await this.$store.dispatch('admin_emails/fetch_email_templates')
        await this.$store.dispatch('admin_emails/fetch_email_records')
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        get_user() {
            return this.$store.getters['auth/get_user']
        },
        get_fds() {
            return this.$store.getters['client/get_fd_lists'](null, null).sort((a, b) => b.id - a.id)
        },
        get_clients() {
            return this.$store.getters['fds/get_clients'](null, null).sort((a, b) => b.id - a.id)
        },
        get_clients_by_id() {
            return this.$store.getters['fds/get_clients_by_id'](this.selected_email.client_ids)
        },
        get_email_sequences() {
            return this.$store.getters['admin_emails/get_email_sequences']
        },
        selected_sequence: {
            get() {
                return this.$store.getters['admin_emails/get_selected_sequence']
            },
            set(sequence) {
                this.$store.commit('admin_emails/set_selected_sequence', sequence)
            }
        },
        get_email_templates() {
            return this.$store.getters['admin_emails/get_email_templates']
        },
        get_email_records() {
            return this.$store.getters['admin_emails/get_email_records']
        }
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit('auth/setMessage',
                { show: true, message: message },
                { root: 1 })
        },
        async advanceSequence() {
            this.showSnackBar('Advancing sequence...')
            let payload = {
                advancing: true,
            }
            await this.$axios.post('auth/admin/emails/advance_email_sequence', payload)
                .then(({ data }) => {
                    if (data.response) {
                        setTimeout(() => {
                            this.refreshSequence()
                        }, 6000)
                    }
                })
        },
        async refreshSequence() {
            await this.$axios.get('auth/admin/emails/get_email_sequences')
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.$store.dispatch('admin_emails/set_email_sequences', data.data)
                    }
                })
        },
        openSequence(type) {
            this.open_sequence = true
            this.sequence_type = type

            this.sequence.created_by = this.get_user.id
            if (type == 'edit') {
                this.sequence.active = this.selected_sequence.active
                this.sequence.name = this.selected_sequence.name
                // this.sequence.time = this.selected_sequence.time
                // this.sequence.frequency = this.selected_sequence.frequency
                this.sequence.client_ids = this.selected_sequence.client_ids
                this.sequence.fd_ids = this.selected_sequence.fd_ids
            }
        },
        closeSequence() {
            this.open_sequence = false
            this.sequence.created_by = null
            this.sequence.active = false
            this.sequence.name = null
            // this.sequence.time = null
            // this.sequence.frequency = null
            this.sequence.client_ids = null
            this.sequence.fd_ids = null
        },
        async createSequence() {
            await this.$axios.post('auth/admin/emails/create_email_sequence', this.sequence)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeSequence()
                        this.$store.dispatch('admin_emails/set_email_sequences', data.data)
                    }
                })
        },
        async editSequence() {
            await this.$axios.patch(`auth/admin/emails/edit_email_sequence/${this.selected_sequence.id}`, this.sequence)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeSequence()
                        this.$store.dispatch('admin_emails/set_email_sequences', data.data)
                    }
                })
        },
        async deleteSequence() {
            await this.$axios.delete(`auth/admin/emails/delete_email_sequence/${this.selected_sequence.id}`)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeDelete()
                        this.$store.dispatch('admin_emails/set_email_sequences', data.data)
                    }
                })
        },
        openEmail(type, item) {
            this.open_email = true
            this.email_type = type
            this.email.sequence_id = this.selected_sequence.id
            if (type == 'edit') {
                this.email_id = item.id
                this.email.subject = item.subject
                this.email.content = item.content
                this.email.time = item.time
                this.email.interval = item.interval
            }
        },
        closeEmail() {
            this.open_email = false
            this.email_id = null
            this.email.sequence_id = null
            this.email.subject = null
            this.email.content = null
            this.email.time = null
            this.email.interval = null
        },
        async createEmail() {
            await this.$axios.post('auth/admin/emails/create_email', this.email)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeEmail()
                        this.$store.dispatch('admin_emails/set_email_sequences', data.data)
                    }
                })
        },
        async editEmail() {
            await this.$axios.patch(`auth/admin/emails/edit_email/${this.email_id}`, this.email)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeEmail()
                        this.$store.dispatch('admin_emails/set_email_sequences', data.data)
                    }
                })
        },
        async deleteEmail() {
            await this.$axios.delete(`auth/admin/emails/delete_email/${this.email_id}`)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeDelete()
                        this.$store.dispatch('admin_emails/set_email_sequences', data.data)
                    }
                })
        },
        openClientList(item) {
            this.open_client_list = true
            this.selected_email = item
        },
        closeClientList() {
            this.open_client_list = false
            this.selected_email = null
        },
        openDelete(type, item) {
            this.delete_type = type
            this.open_delete = true
            if (type == 'email') {
                this.email_id = item.id
            }
        },
        closeDelete() {
            this.delete_type = null
            this.open_delete = false
        },
        openRecordsModal() {
            this.records_modal = true
        },
        closeRecordsModal() {
            this.records_modal = false
        },
        async sendEmail() {
            this.sending = true
            let payload = {
                send_to: this.selected_clients,
                subject: this.subject,
                content: this.content
            }
            await this.$axios.post('auth/admin/emails/send_email', payload)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    this.sending = false
                    if (data.response) {
                        this.subject = null
                        this.content = null
                        this.$store.dispatch('admin_emails/fetch_email_records')
                    }
                })
        },
        async saveWelcomeEmail() {
            let payload = {
                subject: this.selected_email_template.subject,
                content: this.selected_email_template.content
            }
            await this.$axios.patch(`auth/admin/emails/edit_email_template/${this.selected_email_template.id}`, payload)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                })
        },
        async sendEmailTemplate() {
            this.showSnackBar('Sending...')
            let payload = {
                email_address: this.test_email,
                subject: this.selected_email_template.subject,
                content: this.selected_email_template.content
            }
            await this.$axios.post('auth/admin/emails/send_email_template', payload)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.placeholder {
    width: 30%;
    height: 20px;
    font-size: 12px;
    border-radius: 4px;
    padding-left: 10px;
}

.placeholder:hover {
    background-color: rgb(233, 233, 233);
}

::v-deep .placeholder input {
    cursor: pointer !important;
}

::v-deep .placeholder .v-input__slot,
::v-deep .placeholder .v-input__control {
    min-height: 10px !important;
    height: 20px !important;
}

::v-deep .placeholder .v-input__control>.v-input__slot:before,
::v-deep .placeholder .v-input__control>.v-input__slot:after {
    border-style: none !important;
}
</style>
