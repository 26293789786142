import FDComponent from '../FDComponent.vue'
import FDProfile from '../FDProfile.vue'

export default [
    {
        path: '/admin_funeral_directors',
        name: '/admin_funeral_directors',
        component: FDComponent,
        meta: {
            layout: 'admin-layout'
        }
    },
    {
        path: '/admin_funeral_director_profile',
        name: '/admin_funeral_director_profile',
        component: FDProfile,
        meta: {
            layout: 'admin-layout'
        }
    }
]