import FuneralNotice from '../FuneralNotice.vue'

export default [
    {
        path: '/fd_funeral_notice',
        name: '/fd_funeral_notice',
        component: FuneralNotice,
        meta: {
            layout: 'funeral-director-layout'
        }
    }
]