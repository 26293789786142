<template>
    <v-container fluid>
        <v-row justify="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <v-img :src="require('../assets/clientpage.svg')" class="mr-3" contain max-width="35"
                        max-height="35" />
                    <strong style="font-size:26px;">Wills</strong>
                    <v-spacer />
                </v-card-title>
                <v-card-title class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
                    <small style="position: relative; top: -10px; font-size:12px;">
                        Showing {{ wills.length }} wills
                    </small>
                </v-card-title>
            </v-card>
            <v-card width="90%" style="border-radius: 16px" elevation="0"
                :class="isMobile() ? 'mobile_card' : 'desktop_card'">
                <v-card-text class="mt-5">
                    <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="headers"
                        :items="wills" :items-per-page="10" :mobile-breakpoint="0" hide-default-header
                        :sort-by.sync="sort_by" :sort-desc.sync="sortDesc">
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr v-if="!isMobile()">
                                    <th style="padding-bottom:30px;padding-top:10px;width:25%" v-for="h in headers"
                                        :key="h.index">
                                        <div style="text-align:center;" v-if="h.text">
                                            <strong>{{ h.text }}</strong>
                                        </div>
                                        <div style="text-align:end;" v-if="h.value == 'add'">
                                            <v-btn dark color="#172C54"
                                                style="height:40px;text-transform: none !important"
                                                @click="createWill()">
                                                Will Template Link
                                            </v-btn>
                                        </div>
                                    </th>
                                </tr>
                                <tr v-else>
                                    <th style="padding-bottom:30px;padding-top:10px;width:25%;border-bottom:none;">
                                        <v-btn dark color="#172C54" style="height:40px;text-transform: none !important"
                                            @click="createWill()">
                                            Will Template Link
                                        </v-btn>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="{ item, headers }">
                            <tr class="mx-5" style="background-color: transparent;" v-if="item != null && !isMobile()">
                                <td style="width:25%">
                                    <v-layout align-center class="ms-5">
                                        <v-avatar color="#4374F3" size="42"
                                            style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                            <strong style="color:white;font-size:22px;">{{ item.client.name[0]
                                                }}</strong>
                                        </v-avatar>
                                        <strong style="font-size:16px;" class="ms-5">{{ item.client.name }}</strong>
                                    </v-layout>
                                </td>
                                <td style="width:25%">
                                    {{ item.client.email }}
                                </td>
                                <td style="width:15%">
                                    <v-btn text color="#347CE9"
                                        style="text-transform: none !important; float:right;font-size:14px;"
                                        @click="previewWill(item)">
                                        Preview will
                                    </v-btn>
                                    <v-btn text color="#347CE9"
                                        style="text-transform: none !important; float:right;font-size:14px;"
                                        @click="viewWill(item)">
                                        View will details
                                    </v-btn>
                                </td>
                            </tr>
                            <tr class="mx-5" style="background-color: transparent;" v-if="item != null && isMobile()">
                                <td style="width:100%" class="py-5">
                                    <v-row>
                                        <v-col cols="3" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[0].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="9">
                                            <v-layout justify-center align-center class="ms-5">
                                                <v-avatar color="#4374F3" size="42"
                                                    style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                                    <strong style="color:white;font-size:22px;">
                                                        {{ item.client.name[0] }}
                                                    </strong>
                                                </v-avatar>
                                                <strong style="font-size:16px;" class="ms-5">{{ item.client.name
                                                    }}</strong>
                                            </v-layout>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[1].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="8">
                                            {{ item.client.email }}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn text color="#347CE9"
                                                style="text-transform: none !important; float:left;font-size:14px;margin-left:-15px;"
                                                @click="previewWill(item)">
                                                Preview Will
                                                <v-img width="25" height="25" :src="require('../assets/edit.svg')"
                                                    contain />
                                            </v-btn>
                                            <v-btn text color="#347CE9"
                                                style="text-transform: none !important; float:right;font-size:14px;"
                                                @click="viewWill(item)">
                                                View will details
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
        <v-dialog v-model="show_will_dialog" persistent width="1000">
            <v-card v-if="selected_will && selected_will.client">
                <v-card-title></v-card-title>
                <v-card-text>
                    <h1 class="mb-3">Basic Details</h1>
                    <v-row>
                        <v-col cols="6">
                            <b>First Name:</b> {{ selected_will.client.firstname }}<br>
                            <b>Last Name:</b> {{ selected_will.client.lastname }}<br>
                            <b>Email:</b> {{ selected_will.client.email }}<br>
                            <b>Mobile:</b> {{ selected_will.client.mobile }}
                        </v-col>
                        <v-col cols="6">
                            <b>Gender:</b> {{ selected_will.client.gender_id == 1 ? 'Male' :
                            selected_will.client.gender_id == 2 ? 'Female' : 'Non-binary' }}<br>
                            <b>Address:</b> {{ selected_will.client.address }}<br>
                            <b>Marital Status:</b> {{ selected_will.client.marital_status_id == 1 ? 'Single' :
                            selected_will.client.marital_status_id == 2 ? 'Married' :
                            selected_will.client.marital_status_id == 3 ? 'Divorced' : selected_will.client.marital_status_id == 4 ? 'Widowed' : 'Not specified' }}<br>
                            <b>Birthday:</b> {{ selected_will.client.birthday }}
                        </v-col>
                    </v-row>
                    <hr class="my-5">
                    <h1 class="mb-3">Nominees</h1>
                    <v-row>
                        <v-col cols="3">
                            <b>Children:</b><br>
                            <i v-if="selected_will.children == 0">No children</i>
                            <ul v-for="(child, index) in selected_will.children" :key="'children' + index">
                                <li>{{ child }}</li>
                            </ul>
                        </v-col>
                        <v-col cols="3">
                            <b>Guardians:</b><br>
                            <i v-if="selected_will.guardians == 0">No guardians assigned</i>
                            <ul v-for="(guardian, index) in selected_will.guardians" :key="'guardian' + index">
                                <li>{{ guardian }}</li>
                            </ul>
                        </v-col>
                        <v-col cols="3">
                            <b>Pets:</b><br>
                            <i v-if="selected_will.pets == 0">No pets</i>
                            <ul v-for="(child, index) in selected_will.pets" :key="'pet' + index">
                                <li>{{ child }}</li>
                            </ul>
                        </v-col>
                        <v-col cols="3">
                            <b>Caretakers:</b><br>
                            <i v-if="selected_will.caretakers == 0">No caretakers assigned</i>
                            <ul v-for="(guardian, index) in selected_will.caretakers" :key="'caretaker' + index">
                                <li>{{ guardian }}</li>
                            </ul>
                        </v-col>
                        <v-col cols="6"></v-col>
                        <v-col cols="6">
                            <b>Money to be provided for pets' needs:</b>
                            {{ selected_will.pet_expenses }}
                        </v-col>
                    </v-row>
                    <hr class="my-5">
                    <h1 class="mb-3">Assets</h1>
                    <v-row>
                        <v-col cols="12">
                            <b>Major assets:</b><br>
                            <i v-if="selected_will.assets == 0">No assets given</i>
                        </v-col>
                        <v-col cols="4" v-for="(asset, index) in selected_will.assets" :key="'asset' + index">
                            <ul>
                                <li>
                                    <b>Asset name:</b> {{ asset.name }}
                                    <br>
                                    <b>Approx. value:</b> {{ asset.value }}
                                    <br>
                                    <b>Type:</b> {{ asset.type }}
                                    <br>
                                    <b>Owner:</b> {{ asset.owner }}
                                    <br>
                                    <b>Description:</b> {{ asset.description }}
                                    <br>
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="12">
                            <b>Approximate total value of assets:</b>
                            {{ selected_will.assets_total ?? 'No approximate value given' }}
                        </v-col>
                    </v-row>
                    <hr class="my-5">
                    <h1 class="mb-3">Residuary</h1>
                    <v-row>
                        <v-col cols="4">
                            <b>Charities:</b><br>
                            <i v-if="selected_will.charities == 0">No charities selected</i>
                            <ul v-for="(charity, index) in selected_will.charities" :key="'charity' + index"
                                class="mb-2">
                                <li><b>Name: </b>{{ charity.name }}</li>
                                <b>Gift type:</b> {{ charity.type }}
                                <br>
                                <b>Amount:</b> {{ charity.amount }}
                                <br>
                                <b>Message:</b> {{ charity.message }}
                            </ul>
                        </v-col>
                        <v-col cols="4">
                            <b>Primary beneficiaries:</b><br>
                            <i v-if="selected_will.estates == 0">No estates specified</i>
                            <ul v-for="(estate, index) in selected_will.estates" :key="'estate' + index">
                                <li>
                                    <b>Beneficiary:</b> {{ estate.beneficiary }}
                                    <br>
                                    <b>Relationship:</b> {{ estate.relationship }}
                                    <br>
                                    <b>Address:</b> {{ estate.address }}
                                    <br>
                                    <b>Percent:</b> {{ estate.percent }}
                                    <br>
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="4">
                            <b>Takers of last resort:</b><br>
                            <i v-if="selected_will.takers == 0">No takers specified</i>
                            <ul v-for="(taker, index) in selected_will.takers" :key="'taker' + index">
                                <li>
                                    <b>Beneficiary:</b> {{ taker.beneficiary }}
                                    <br>
                                    <b>Relationship:</b> {{ taker.relationship }}
                                    <br>
                                    <b>Address:</b> {{ taker.address }}
                                    <br>
                                    <b>Percent:</b> {{ taker.percent }}
                                    <br>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                    <hr class="my-5">
                    <h1 class="mb-3">Gifts</h1>
                    <v-row>
                        <v-col cols="12">
                            <b>Personal gifts:</b><br>
                            <i v-if="selected_will.gifts == 0">No gifts given</i>
                        </v-col>
                        <v-col cols="4" v-for="(gift, index) in selected_will.gifts" :key="'gift' + index">
                            <ul>
                                <li>
                                    <b>Gift type:</b> {{ gift.type }}
                                    <br>
                                    <b>Description:</b> {{ gift.description }}
                                    <br>
                                    <b>Beneficiaries:</b>
                                    <ul v-for="(beneficiary, index2) in gift.beneficiaries"
                                        :key="'beneficiary' + index2">
                                        <li>
                                            {{ beneficiary }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                    <hr class="my-5">
                    <h1 class="mb-3">Funeral Details</h1>
                    <v-row>
                        <v-col cols="6">
                            <b>Funeral Type:</b>
                            <label v-if="selected_will.funeral_type == 1 || selected_will.funeral_type == 0">
                                No preference
                            </label>
                            <label v-if="selected_will.funeral_type == 2">
                                I would like to be cremated
                            </label>
                            <label v-if="selected_will.funeral_type == 3">
                                I would like a burial
                            </label>
                            <label v-if="selected_will.funeral_type == 4">
                                I would like a natural burial
                            </label>
                            <label v-if="selected_will.funeral_type == 5">
                                I would like to donate my body to science
                            </label>
                        </v-col>
                        <v-col cols="6">
                            <b>Instructions:</b> {{ selected_will.funeral_instructions ?? 'None' }}
                        </v-col>
                    </v-row>
                    <hr class="my-5">
                    <h1 class="mb-3">Provisions</h1>
                    <v-row>
                        <v-col cols="6">
                            <b>Executors of will:</b><br>
                            <i v-if="selected_will.executors == 0">No executors specified</i>
                            <ul v-for="(executor, index) in selected_will.executors" :key="'executor' + index">
                                <li>
                                    <b>Name:</b> {{ executor.name }}
                                    <br>
                                    <b>Relationship:</b> {{ executor.relationship }}
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="6">
                            <b>Additional requests:</b><br>
                            <i v-if="selected_will.additionals == 0">No additional requests specified</i>
                            <ul v-for="(additional, index) in selected_will.additionals" :key="'additional' + index">
                                <li>
                                    {{ additional.request }}
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="6">
                            <b>Solicitor review:</b>
                            {{ selected_will.no_contest_clause == 1 ? 'Yes' : 'No' }}
                        </v-col>
                        <v-col cols="6">
                            <b>Personal statement:</b>
                            {{ selected_will.personal_statement ?? 'None' }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeViewWill()" text>Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {},
    props: [],
    data: () => ({
        wills: [],
        headers: [
            { text: 'Client', value: 'name' },
            { text: 'Email', value: 'email' },
            { value: 'add' },
        ],
        sort_by: 'created_at',
        sortDesc: true,
        selected_will: {
            client: null,
            has_children: 0,
            has_underage: 0,
            funeral_type: 0,
            funeral_instructions: null,
            assets_total: null,
            pet_expenses: 0,
            no_contest_clause: 0,
            affidavit: 0,
            personal_statement: null,
            children: [],
            guardians: [],
            pets: [],
            caretakers: [],
            assets: [],
            charities: [],
            gifts: [],
            estates: [],
            takers: [],
            executors: [],
            additionals: [],
        },
        show_will_dialog: false,
    }),
    async mounted() {
        this.getSolicitors()
    },
    created() { },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                'auth/setMessage',
                { show: true, message: message },
                { root: 1 }
            )
        },
        async getSolicitors() {
            await this.$axios.get('auth/admin/wills/get_wills')
                .then(({ data }) => {
                    if (data.response) {
                        this.wills = data.data
                    }
                })
        },
        viewWill(item) {
            this.selected_will.client = item.client
            this.selected_will.has_children = item.has_children
            this.selected_will.has_underage = item.has_underage
            this.selected_will.funeral_type = item.funeral_type
            this.selected_will.funeral_instructions = item.funeral_instructions
            this.selected_will.assets_total = item.assets_total
            this.selected_will.pet_expenses = item.pet_expenses
            this.selected_will.no_contest_clause = item.no_contest_clause
            this.selected_will.affidavit = item.affidavit
            this.selected_will.personal_statement = item.personal_statement
            this.selected_will.children = JSON.parse(item.children)
            this.selected_will.guardians = JSON.parse(item.guardians)
            this.selected_will.pets = JSON.parse(item.pets)
            this.selected_will.caretakers = JSON.parse(item.caretakers)
            this.selected_will.assets = JSON.parse(item.assets)
            this.selected_will.charities = JSON.parse(item.charities)
            this.selected_will.gifts = JSON.parse(item.gifts)
            this.selected_will.estates = JSON.parse(item.estates)
            this.selected_will.executors = JSON.parse(item.executors)
            this.selected_will.takers = JSON.parse(item.takers)
            this.selected_will.additionals = JSON.parse(item.additionals)
            this.show_will_dialog = true
        },
        closeViewWill() {
            this.selected_will = {
                has_children: 0,
                has_underage: 0,
                funeral_type: 0,
                funeral_instructions: null,
                assets_total: null,
                pet_expenses: 0,
                no_contest_clause: 0,
                affidavit: 0,
                personal_statement: null,
                children: [],
                guardians: [],
                pets: [],
                caretakers: [],
                assets: [],
                charities: [],
                gifts: [],
                estates: [],
                takers: [],
                executors: [],
                additionals: [],
            }
            this.show_will_dialog = false
        },
        previewWill(item) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            this.$router.push({ name: 'View Will', params: { will_id: item.encrypted_id } })
        },
        createWill() {
            this.$router.push({ name: 'Create Will', params: { solicitor_id: this.get_user.encrypted_id } })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
    border-radius: 5px;
    margin-top: -2px;
}

::v-deep .liitme .v-input__slot {
    background-color: white;
    padding: 7px;
}

table {
    border-collapse: collapse;
}

// remove border
table td,
table th {
    height: 40px; //change the height
}

.v-data-table.row-height-50 td {
    height: 100px !important;
}

.v-data-table::v-deep th {
    font-size: 16px !important;
    font-weight: bold;
}

.mobile_table td {
    height: 100px !important;
}

.desktop_table td {
    height: 100px !important;
}

.mobile_card {
    margin-top: 100px;
}

.desktop_table {
    padding-left: 30px;
    padding-right: 30px;
}

.mobile_sort {
    margin-top: -40px;
}

::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
    border-style: none !important;
}

.status_0,
.status_1,
.status_2 {
    justify-content: center;
}

.status_1 {
    background-color: #FAFAFA !important;
    color: #172C54 !important;
}

.status_2 {
    background-color: #4786FF !important;
    color: #FFF !important;
}
</style>