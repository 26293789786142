<template>
    <v-container fluid>
        <v-card
            elevation="0"
        >
            <v-card-title style="word-break: break-word;">
                Distribution of Residual State
            </v-card-title>
            <v-card-subtitle style="word-break: break-word;">
                This section determines how to pass on your residual estate. Your estate includes all of your cash, investments, real estate, and belongings. You residual estate is what remains of your estate after paying taxes and debts and making specific gifts.
            </v-card-subtitle>
            <v-card-text
                class="mt-15"
                style="word-break: break-word;"
            >
                <h2><strong>Who do you want to inherit your property when you pass away?</strong></h2>
                <v-btn
                    text
                    class="py-10 mt-10"
                    outlined
                    width="100%"
                    @click="create_plan('No')"
                    :disabled="get_residual_state.length > 0 ? true : false"
                >
                    <h4 style="word-break: break-word;white-space: pre-wrap;width:50%">
                        Split every among my children, else their descendants
                    </h4>
                </v-btn>
                <v-btn
                    text
                    class="py-10 mt-10"
                    outlined
                    width="100%"
                    @click="create_plan('Yes')"
                    :disabled="get_residual_state.length > 0 ? true : false"
                >
                    <h4 style="word-break: break-word;white-space: pre-wrap;width:50%">
                        Create a custom distribution plan.
                    </h4>
                </v-btn>
            </v-card-text>
            <v-card-text style="word-break: break-word;">
                <i>* When you elect to split your estate evenly among your children, this will not include any children you disinherited.</i>
            </v-card-text>
            <v-card-text
                v-if="add_beneficiary && this.get_residual_state.length === 0"
            >
                <v-card-title>
                    <h2>My Distribution Plan</h2>
                    <v-spacer/>
                    Total:
                    <label
                        class="ml-3"
                        v-if="get_total_distribution != undefined || !isNan(parseInt(get_total_distribution))"
                    >
                        {{get_total_distribution}}
                    </label>
                    <label v-else class="ml-3">0</label>
                    %
                </v-card-title>
                <v-card-subtitle>Distribution must equal 100%</v-card-subtitle>
                <v-row
                    v-for="(beneficiary, beneficiaryindex) in beneficiaries"
                    :key="beneficiaryindex"
                >
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>First name*</strong>
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-tooltip-text-outline"
                            v-model="beneficiary.firstname"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Middle name</strong>
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-tooltip-text-outline"
                            v-model="beneficiary.middlename"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Last name*</strong>
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-tooltip-text-outline"
                            v-model="beneficiary.lastname"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Suffix</strong>
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-tooltip-text-outline"
                            v-model="beneficiary.suffix"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 4"
                    >
                        <strong>Relationship to you*</strong>
                        <v-autocomplete
                            :items="relationship"
                            v-model="beneficiary.relationship"
                            outlined
                            dense
                            item-value="name"
                            item-text="name"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 4"
                    >
                        <strong>Distribution*</strong>
                        <v-text-field
                            outlined
                            dense
                            v-model="beneficiary.distribution"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 4"
                    >
                        <v-btn
                            text
                            class="pa-10"
                            @click="remove_beneficiary(beneficiaryindex)"
                        >
                            Remove Beneficiary
                            <v-img
                                :src="require('../../../../../assets/delete_dark.svg')"
                            />
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-btn
                        text
                        outlined
                        class="pl-10 pr-10 mt-5"
                        @click="add_beneficiary_fn"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Add a beneficiary
                    </v-btn>
                    <v-btn
                        text
                        outlined
                        class="pl-10 pr-10 ml-3 mt-5"
                        disabled="true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Add a charity
                    </v-btn>
                </v-row>
            </v-card-text>
            <v-card-text
                v-else
                class="mt-15"
            >
                <v-row
                    v-for="(beneficiary, beneficiaryindex) in get_residual_state"
                    :key="beneficiaryindex"
                >
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>First name*</strong>
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-tooltip-text-outline"
                            v-model="beneficiary.firstname"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Middle name</strong>
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-tooltip-text-outline"
                            v-model="beneficiary.middlename"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Last name*</strong>
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-tooltip-text-outline"
                            v-model="beneficiary.lastname"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Suffix</strong>
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-tooltip-text-outline"
                            v-model="beneficiary.suffix"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 4"
                    >
                        <strong>Relationship to you*</strong>
                        <v-autocomplete
                            :items="relationship"
                            v-model="beneficiary.relationship"
                            outlined
                            dense
                            item-value="name"
                            item-text="name"
                            disabled="true"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 4"
                    >
                        <strong>Distribution*</strong>
                        <v-text-field
                            outlined
                            dense
                            v-model="beneficiary.distribution"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-btn
                        text
                        outlined
                        class="pl-10 pr-10 mt-5"
                        @click="add_beneficiary_fn"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Add a beneficiary
                    </v-btn>
                    <v-btn
                        text
                        outlined
                        class="pl-10 pr-10 ml-3 mt-5"
                        :disabled="true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Add a charity
                    </v-btn>
                </v-row>
            </v-card-text>
            <v-card-text
                class="mt-10 pa-5"
                style="background-color: #DEF3FF;word-break: break-word;"
            >
                <h2><strong>Note</strong></h2>
                <ul>
                    <li>
                        <strong>
                            If you have a joint owner with rights of survivorship on any accounts or real estate, the joint owner will own the asset after your death, regardless of what's written in your will.
                            <br/><br/>
                            Also, if you designated specific beneficiaries for any financial accounts, such as through your bank or life insurance provider, those beneficiaries will receive those funds regardless of what's written in your will.
                        </strong>
                    </li>
                    <br/><br/>
                    <li>
                        <strong>
                            Many states require that you leave a dollar amount or a percentage of your estate (or both) to your surviving spouse, unless you have a prenuptial agreement that states otherwise. If you attempt to leave less than this minimum “marital share” or “elective share” to your spouse, then your surviving spouse will have the right to carve out the minimum amount from the whole estate. Generally, 1/3 to 1/2 of your estate is sufficient to cover the marital share.
                            <br/><br/>
                            You should consult an attorney if you want more specific or individualized guidance on how to plan for the marital share. 
                        </strong>
                    </li>
                </ul>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    text
                    @click="back"
                >
                    <v-img
                        :src="require('../../../../../assets/chev_left_dark.svg')"
                        contain
                        max-width="35"
                        max-height="35"
                    />
                    Back
                </v-btn>
                <v-spacer/>
                <v-btn
                    dark
                    class="pl-10 pr-10 pt-5 pb-5"
                    style="textTransform: none !important;"
                    @click="next_step"
                >
                    Save and continue
                    <v-img
                        class="ml-3"
                        :src="require('../../../../../assets/chev_right_white.svg')"
                    />
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    add_beneficiary: false,
    beneficiaries: [{
        firstname: null,
        middlename: null,
        lastname: null,
        suffix: null,
        relationship: null,
        distribution: null
    }],
    relationship: [
        {id: 1, name: 'Child'},
        {id: 2, name: 'Another Dropdown data'},
    ],
  }),
  async mounted () {
    await this.$store.dispatch('auth/fetch_residual_state')
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_residual_state:             'auth/get_residual_state'
    }),
    get_total_distribution(){
        let data = []
        this.beneficiaries.forEach( q => {
            data.push(q.distribution)
        })
        let total = 0
        for(const i in data){
            total += parseInt(data[i])
        }
        return total
    }
  },
  methods: {
    showSnackBar(message){
        this.$store.commit("auth/setMessage", 
        {show: true, message: message}, 
        {root: 1})
    },
    back(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.$store.dispatch('client_activity/set_last_will_el', 3)
    },
    create_plan(data){
        if(data === 'Yes'){
            this.add_beneficiary = true
        }
    },
    add_beneficiary_fn(){
        this.beneficiaries.push(Vue.util.extend({}, this.beneficiaries))
    },
    async next_step(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if(this.get_total_distribution > 100){
            this.showSnackBar('Distribution should not exceed to 100%')
            return
        }
        if(this.get_residual_state.length > 0){
            this.$store.dispatch('client_activity/set_last_will_el', 5)
            return
        }
        let tp = []
        this.beneficiaries.forEach(q => {
            tp.push({
                firstname:          q.firstname,
                middlename:         q.middlename,
                lastname:           q.lastname,
                suffix:             q.suffix,
                relationship:       q.relationship,
                distribution:       q.distribution
            })
        })
        await this.$axios.post('/auth/client/residual/create_residual', tp)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('auth/set_residual_state', data.data)
                this.$store.dispatch('client_activity/set_last_will_el', 5)
            }
        })
    },
    remove_beneficiary(index){
        this.beneficiaries.splice(index, 1)
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>