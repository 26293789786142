import Vue from "vue";

export default {
  namespaced: true,
  state: {
    client_survey: [],
  },
  mutations: {
    set_client_survey(state, payload) {
      state.client_survey = [...payload];
    },
  },
  getters: {
    get_client_survey: (state) => state.client_survey,
  },
  actions: {
    async get_client_survey({ commit }) {
      await Vue.axios.get("auth/fd_client/get_clients")
        .then(({ data }) => {
          commit("set_client_survey", data.data);
        });
    },
  },
};
