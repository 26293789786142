<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <v-img :src="require('../../assets/fd_clients.svg')" class="mr-3" contain max-width="35"
                        max-height="35" />
                    <strong style="font-size:26px;">Preplanning Clients</strong>
                    <v-spacer />
                    <!-- <v-btn
                    icon
                >
                    <v-icon>mdi-bell-outline</v-icon>
                </v-btn> -->
                </v-card-title>
                <v-card-title class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
                    <small style="position: relative; top: -10px; font-size:12px;">Showing {{ get_clients.length }}/500
                        clients</small>
                    <v-spacer />
                    <v-row>
                        <v-col :cols="isMobile() ? 12 : 9">
                            <v-text-field dense placeholder="Search" append-icon="mdi-magnify" class="liitme"
                                v-model="search_name">
                            </v-text-field>
                        </v-col>
                        <v-col :cols="isMobile() ? 12 : 3">
                            <v-btn :class="isMobile() ? 'mobile_sort' : 'desktop_sort'" color="primary"
                                style="text-transform:none;" @click="open_sort_filter()">
                                Sort & Filter
                                <v-img class="ml-3" :src="require('../../assets/chevdown.svg')" />
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-card>
            <v-card width="90%" style="border-radius: 16px" elevation="0"
                :class="isMobile() ? 'mobile_card' : 'desktop_card'">
                <v-card-text class="mt-5">
                    <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="client_headers"
                        :items="get_clients" :items-per-page="10" hide-default-header :mobile-breakpoint="0"
                        :sort-by.sync="sort_by" :sort-desc.sync="sortDesc">
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr v-if="!isMobile()">
                                    <th style="padding-bottom:30px;padding-top:10px;width:25%" v-for="h in headers"
                                        :key="h.index">
                                        <div style="text-align:center;" v-if="h.text">
                                            <strong>{{ h.text }}</strong>
                                        </div>
                                        <div style="text-align:end;" v-if="h.value == 'add'">
                                            <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                                style="height:40px;text-transform: none !important"
                                                @click="add_fd_client()">
                                                Add Preplanning Client <v-icon class="ml-2">mdi-plus</v-icon>
                                            </v-btn>
                                        </div>
                                    </th>
                                </tr>
                                <tr v-else>
                                    <th style="padding-bottom:30px;padding-top:10px;width:25%;border-bottom:none;">
                                        <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                            style="height:40px;text-transform: none !important"
                                            @click="add_fd_client()">
                                            Add Preplanning Client <v-icon class="ml-2">mdi-plus</v-icon>
                                        </v-btn>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="{ item, headers }">
                            <tr class="mx-5" style="background-color: transparent;"
                                v-if="item.get_user_info != null && !isMobile()">
                                <td style="width:25%">
                                    <v-layout align-center class="ms-5">
                                        <v-avatar v-if="item.profile_picture === null" color="#4374F3" size="42"
                                            style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                            <strong style="color:white;font-size:22px;">{{ item.get_user_info.name[0]
                                                }}</strong>
                                        </v-avatar>
                                        <v-avatar v-else color="#4374F3" size="42"
                                            style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                            <v-img contain :src="`${api_url}${item.profile_picture}`" />
                                        </v-avatar>
                                        <strong style="font-size:16px;" class="ms-5">{{ item.get_user_info.name
                                            }}</strong>
                                    </v-layout>
                                </td>
                                <td style="width:25%">
                                    <v-layout justify-center>
                                        {{
                                            $date(item.get_user_info.updated_at).format("DD MMM YYYY")
                                        }}
                                        <strong
                                            style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                                        {{
                                            $date(item.get_user_info.updated_at).format("hh:mm")
                                        }}
                                    </v-layout>
                                </td>
                                <td style="width:25%">
                                    <v-layout justify-center align-center class="ms-7">
                                        <v-select chips :items="statuses" v-model="item.source_id" item-text="name"
                                            item-value="id" @change="change_client_status(item.id, item.source_id)">
                                            <template v-slot:append>
                                                <v-icon color="blue" class="mt-1"
                                                    style="margin-left:-15px;">mdi-chevron-down</v-icon>
                                            </template>
                                            <template #selection="{ item }">
                                                <v-chip style="color:white;width:90%;cursor: pointer;" :class="(item.id <= 1) ? 'status_1' :
                                                    (item.id == 2) ? 'status_2' :
                                                        (item.id == 3) ? 'status_3' :
                                                            (item.id == 4) ? 'status_4' :
                                                                (item.id == 5) ? 'status_5' : 'status_0'">
                                                    <strong style="font-size:12px;">{{ item.name }}</strong>
                                                </v-chip>
                                            </template>
                                        </v-select>
                                    </v-layout>
                                </td>
                                <td style="width:25%">
                                    <v-btn text color="#347CE9"
                                        style="text-transform: none !important; float:right;font-size:14px;"
                                        @click="view_profile(item)">
                                        View profile
                                    </v-btn>
                                </td>
                            </tr>
                            <tr class="mx-5" style="background-color: transparent;"
                                v-if="item.get_user_info != null && isMobile()">
                                <td style="width:100%" class="py-5">
                                    <v-row>
                                        <v-col cols="3" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[0].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="9">
                                            <v-layout align-center class="ms-5">
                                                <v-avatar v-if="item.profile_picture === null" color="#4374F3" size="42"
                                                    style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                                    <strong style="color:white;font-size:22px;">{{
                                                        item.get_user_info.name[0] }}</strong>
                                                </v-avatar>
                                                <v-avatar v-else color="#4374F3" size="42"
                                                    style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                                    <v-img contain :src="`${api_url}${item.profile_picture}`" />
                                                </v-avatar>
                                                <strong style="font-size:16px;" class="ms-5">{{ item.get_user_info.name
                                                    }}</strong>
                                            </v-layout>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[1].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-layout justify-center>
                                                {{
                                                    $date(item.get_user_info.updated_at).format("DD MMM YYYY")
                                                }}
                                                <strong
                                                    style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                                                {{
                                                    $date(item.get_user_info.updated_at).format("hh:mm a")
                                                }}
                                            </v-layout>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[2].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="9">
                                            <v-layout justify-center align-center class="ms-7">
                                                <v-select chips :items="statuses" v-model="item.source_id"
                                                    item-text="name" item-value="id"
                                                    @change="change_client_status(item.id, item.source_id)">
                                                    <template v-slot:append>
                                                        <v-icon color="blue" class="mt-1"
                                                            style="margin-left:-15px;">mdi-chevron-down</v-icon>
                                                    </template>
                                                    <template #selection="{ item }">
                                                        <v-chip style="color:white;width:90%;cursor: pointer;" :class="(item.id <= 1) ? 'status_1' :
                                                            (item.id == 2) ? 'status_2' :
                                                                (item.id == 3) ? 'status_3' :
                                                                    (item.id == 4) ? 'status_4' :
                                                                        (item.id == 5) ? 'status_5' : 'status_0'">
                                                            <strong style="font-size:12px;">{{ item.name }}</strong>
                                                        </v-chip>
                                                    </template>
                                                </v-select>
                                            </v-layout>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn text color="#347CE9"
                                                style="text-transform: none !important; float:center;font-size:14px;margin-top:-50px;margin-left:-15px;"
                                                @click="view_profile(item)">
                                                View profile
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
        <DialogAddClient :value="dialog_add_client" type="preplanning"
            @cancel_dialog_add_client="cancel_dialog_add_client" />
        <DialogSortFilter :value="dialog_sort_filter" @cancel_dialog_sort_filter="cancel_dialog_sort_filter"
            @apply_sort_filter="apply_sort_filter" @clear_all="clear_all" />
    </v-container>
</template>

<script>
// import { mapGetters } from 'vuex'
import DialogAddClient from './components/DialogAddClient.vue'
import DialogSortFilter from './components/DialogSortFilter.vue'
export default {
    components: {
        DialogAddClient, DialogSortFilter
    },
    props: [],
    data: () => ({
        api_url: null,
        dialog_add_client: false,
        dialog_sort_filter: false,
        client_headers: [
            { text: 'Client', value: 'get_user_info.name' },
            { text: 'Created', value: 'get_user_info.created_at' },
            { text: 'Source' },
            { value: 'add' },
        ],
        statuses: [
            {
                name: 'None',
                id: null
            },
            {
                name: 'In person.',
                id: 1
            },
            {
                name: 'URL Form',
                id: 2
            },
            {
                name: 'Facebook Form',
                id: 3
            },
        ],
        search_name: null,
        sort_by: 'created_at',
        sortDesc: true,
        filter_by: null
    }),
    async mounted() {
        await this.$store.dispatch('fd_client/get_status')
        await this.$store.dispatch('fd_client/get_clients')
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        // ...mapGetters({
        //     get_clients:            'fd_client/get_clients'
        // })
        get_clients() {
            return this.$store.getters['fd_client/get_preplanning_clients'](this.search_name, this.filter_by)
        },
    },
    methods: {
        async change_client_status(client_id, new_source_id) {
            let payload = {
                id: client_id,
                source_id: new_source_id
            }
            await this.$axios.patch('/auth/fd_client/edit_fd_client', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.commit('fd_client/set_clients', data.data)
                    }
                })
        },
        open_sort_filter() {
            this.dialog_sort_filter = true
        },
        cancel_dialog_sort_filter() {
            this.dialog_sort_filter = false
        },
        apply_sort_filter($data) {
            this.cancel_dialog_sort_filter()
            if ($data['sort_by'] != null) { this.sort_by = $data['sort_by'] }
            if ($data['asc_desc'] != null) { this.sortDesc = $data['asc_desc'] }
            if ($data['filter_by'] != null) { this.filter_by = $data['filter_by'] }
        },
        clear_all() {
            this.cancel_dialog_sort_filter()
            this.sort_by = null
            this.sortDesc = false
            this.filter_by = null
        },
        add_fd_client() {
            this.dialog_add_client = true;
        },
        cancel_dialog_add_client() {
            this.dialog_add_client = false;
        },
        view_profile(data) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            this.$store.dispatch('fd_client/set_client', data);
            this.$router.push({ name: '/client-profile' });
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
    border-radius: 5px;
    margin-top: -2px;
}

::v-deep .liitme .v-input__slot {
    background-color: white;
    padding: 7px;
}

table {
    border-collapse: collapse;
}

// remove border
table td,
table th {
    height: 40px; //change the height
}

.mobile_table td {
    height: 100px !important;
}

.desktop_table td {
    height: 100px !important;
}

.mobile_card {
    margin-top: 100px;
}

.desktop_table {
    padding-left: 30px;
    padding-right: 30px;
}

.mobile_sort {
    margin-top: -40px;
}

.v-data-table::v-deep th {
    font-size: 16px !important;
    font-weight: bold;
}

::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
    border-style: none !important;
}

.status_0,
.status_1,
.status_2,
.status_3,
.status_4,
.status_5 {
    justify-content: center;
}

.status_1 {
    background-color: #DEF3FF !important;
    color: #347CE9 !important;
}

.status_2 {
    background-color: #FFFADE !important;
    color: #172C54 !important;
}

.status_3 {
    background-color: #FFEEE4 !important;
    color: #CE6300 !important;
}

.status_4 {
    background-color: #FAFAFA !important;
    color: #347CE9 !important;
}

.status_5 {
    background-color: #F1FFEC !important;
    color: #219226 !important;
}
</style>
