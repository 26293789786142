import Vue from "vue";

export default {
    namespaced: true,
    state: {
        payment_terms: [],
    },
    mutations: {
        set_payment_terms(state, payload) {
            state.payment_terms = [...payload];
        },
    },
    getters: {
        get_payment_terms: (state) => state.payment_terms,
    },
    actions: {
        async fetch_payment_terms({ commit }) {
            await Vue.axios.get("non_auth/get_payment_terms")
                .then(({ data }) => {
                    commit("set_payment_terms", data.data);
                });
        },
    },
};
