<template>
    <v-container fluid>
        <v-row justify="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <v-img :src="require('../../assets/clientpage.svg')" class="mr-3" contain max-width="35"
                        max-height="35" />
                    <strong style="font-size:26px;">Charities</strong>
                    <v-spacer />
                </v-card-title>
                <v-card-title class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
                    <small style="position: relative; top: -10px; font-size:12px;">
                        Showing {{ charities.length }} charities
                    </small>
                </v-card-title>
            </v-card>
            <v-card width="90%" style="border-radius: 16px" elevation="0"
                :class="isMobile() ? 'mobile_card' : 'desktop_card'">
                <v-card-text class="mt-5">
                    <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="headers"
                        :items="charities" :items-per-page="10" :mobile-breakpoint="0" hide-default-header
                        :sort-by.sync="sort_by" :sort-desc.sync="sortDesc">
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr v-if="!isMobile()">
                                    <th style="padding-bottom:30px;padding-top:10px;width:25%" v-for="h in headers"
                                        :key="h.index">
                                        <div style="text-align:center;" v-if="h.text">
                                            <strong>{{ h.text }}</strong>
                                        </div>
                                        <div style="text-align:end;" v-if="h.value == 'add'">
                                            <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                                style="height:40px;text-transform: none !important"
                                                @click="openCreateCharityDialog()">
                                                Add a charity <v-icon class="ml-2">mdi-plus</v-icon>
                                            </v-btn>
                                        </div>
                                    </th>
                                </tr>
                                <tr v-else>
                                    <th style="padding-bottom:30px;padding-top:10px;width:25%;border-bottom:none;">
                                        <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                            style="height:40px;text-transform: none !important"
                                            @click="openCreateCharityDialog()">
                                            Add a charity <v-icon class="ml-2">mdi-plus</v-icon>
                                        </v-btn>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="{ item, headers }">
                            <tr class="mx-5" style="background-color: transparent;cursor: pointer;"
                                @click="selectCharity(item)" v-if="item != null && !isMobile()">
                                <td style="width:15%">
                                    <v-img :src="api_url + 'images/' + item.image" width="150" contain></v-img>
                                </td>
                                <td style="width:25%">
                                    {{ item.name }}
                                </td>
                                <td style="width:25%">
                                    {{ item.description }}
                                </td>
                                <td style="width:15%">
                                    <!-- <v-btn text color="#347CE9"
                                        style="text-transform: none !important; float:right;font-size:14px;">
                                        View profile
                                    </v-btn> -->
                                </td>
                            </tr>
                            <tr class="mx-5" style="background-color: transparent;cursor: pointer;"
                                @click="selectCharity(item)" v-if="item != null && isMobile()">
                                <td style="width:100%" class="py-5">
                                    <v-row>
                                        <v-col cols="3" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[0].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="9">
                                            <v-img :src="item.image"></v-img>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[1].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="8">
                                            {{ item.name }}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="3" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[2].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="9">
                                            {{ item.description }}
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <!-- <v-btn text color="#347CE9"
                                                style="text-transform: none !important; float:left;font-size:14px;margin-left:-15px;">
                                                Edit
                                                <v-img width="25" height="25" :src="require('../../assets/edit.svg')"
                                                    contain />
                                            </v-btn> -->
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
        <!--TODO component-->
        <v-row justify="center">
            <v-dialog v-model="create_charity_dialog" persistent max-width="560">
                <v-card>
                    <v-card-title class="text-h5"> Add a charity</v-card-title>
                    <v-card-text>
                        <v-row class="mt-5">
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong>Name *</strong>
                                <v-text-field dense filled v-model="new_charity.name">
                                </v-text-field>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong>Image *</strong>
                                <v-file-input v-model="new_charity.image" dense filled></v-file-input>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 12">
                                <strong>Description</strong>
                                <v-textarea dense filled v-model="new_charity.description"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeCreateCharityDialog()">
                            Cancel
                        </v-btn>
                        <v-btn color="#172C54" dark class="pl-10 pr-10" @click="createCharity()">
                            Add
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {},
    props: [],
    data: () => ({
        api_url: null,
        charities: [],
        new_charity: {
            name: null,
            description: null,
            image: null,
        },
        headers: [
            { text: '', value: 'image' },
            { text: 'Name', value: 'name' },
            { text: 'Description', value: 'description' },
            { value: 'add' },
        ],
        sort_by: 'created_at',
        sortDesc: true,
        create_charity_dialog: false,
    }),
    async mounted() {
        this.api_url = process.env.VUE_APP_API_URL
        this.getCharities()
    },
    created() { },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                'auth/setMessage',
                { show: true, message: message },
                { root: 1 }
            )
        },
        async getCharities() {
            await this.$axios.get('auth/admin/charities/get_charities')
                .then(({ data }) => {
                    if (data.response) {
                        this.charities = data.data
                    }
                })
        },
        selectCharity() {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        },
        openCreateCharityDialog() {
            this.create_charity_dialog = true
        },
        closeCreateCharityDialog() {
            this.create_charity_dialog = false
            this.new_charity = {
                name: null,
                image: null,
                description: null,
            }
        },
        async createCharity() {
            if (this.new_charity.name == null || this.new_charity.image == null) {
                this.showSnackBar('Please fill out all required fields')
                return
            }
            const formData = new FormData()
            formData.append('name', this.new_charity.name)
            formData.append('image', this.new_charity.image)
            if (this.new_charity.description != null) {
                formData.append('description', this.new_charity.description)
            }
            await this.$axios.post('auth/admin/charities/create_charity', formData)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.closeCreateCharityDialog()
                        this.getCharities()
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
    border-radius: 5px;
    margin-top: -2px;
}

::v-deep .liitme .v-input__slot {
    background-color: white;
    padding: 7px;
}

table {
    border-collapse: collapse;
}

// remove border
table td,
table th {
    height: 40px; //change the height
}

.v-data-table.row-height-50 td {
    height: 100px !important;
}

.v-data-table::v-deep th {
    font-size: 16px !important;
    font-weight: bold;
}

.mobile_table td {
    height: 100px !important;
}

.desktop_table td {
    height: 100px !important;
}

.mobile_card {
    margin-top: 100px;
}

.desktop_table {
    padding-left: 30px;
    padding-right: 30px;
}

.mobile_sort {
    margin-top: -40px;
}

::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
    border-style: none !important;
}

.status_0,
.status_1,
.status_2 {
    justify-content: center;
}

.status_1 {
    background-color: #FAFAFA !important;
    color: #172C54 !important;
}

.status_2 {
    background-color: #4786FF !important;
    color: #FFF !important;
}
</style>