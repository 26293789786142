<template>
    <v-container fluid>
        <v-row justify="center" align="center" v-if="notice != null">
            <v-card :class="isMobile() ? '' : 'mt-10'" elevation="0">
                <v-card-text>
                    <v-row class="px-10">
                        <v-col :cols="isMobile() ? 12 : 3">
                            <v-img v-if="notice.get_user.funeral_notice_image != null" contain max-height="300px"
                                :src="`${img_url}${notice.get_user.funeral_notice_image}`" />
                        </v-col>
                        <v-col :cols="isMobile() ? 12 : 9"
                            style="font-size:14px;white-space:pre-line;word-break:break-word;">
                            {{ notice.notice }}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        img_url: null,
        notice: null,
    }),
    async mounted() {
        await this.get_data(this.$router.currentRoute.params.token)
    },
    created() {
        this.img_url = process.env.VUE_APP_API_URL;
    },
    computed: {
    },
    methods: {
        async get_data(data) {
            await this.$axios.get('/non_auth/decode_funeral_notice', { data: data })
                .then(({ data }) => {
                    if (data.response) {
                        this.notice = data.data
                        this.$store.dispatch('funeral_request/set_funeral_director', data.data.get_user.get_user_add_info.get_fd_info)
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>