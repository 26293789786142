import { render, staticRenderFns } from "./DialogComponent.vue?vue&type=template&id=5fb6d9dc&scoped=true&"
import script from "./DialogComponent.vue?vue&type=script&lang=js&"
export * from "./DialogComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb6d9dc",
  null
  
)

export default component.exports