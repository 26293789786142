<template>
    <v-container fluid>
        <v-row justify="center">
            <v-dialog :value="value" persistent max-width="600">
                <v-card v-if="payment != null">
                    <v-card-title class="text-h5">
                        Payment Details
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                Client Name: {{ payment.get_user.name }}
                            </v-col>
                            <v-col cols="6">
                                Billing Name: {{ payment.billingfirstname + ' ' + payment.billinglastname }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                Base Amount: {{ payment.baseamount / 100 + ' ' + payment.currencyiso3a }}
                            </v-col>
                            <v-col cols="6">
                                Settle Base Amount: {{ payment.settlebaseamount / 100 + ' ' + payment.currencyiso3a }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                Settle Due Date: {{ payment.settleduedate }}
                            </v-col>
                            <v-col cols="6">
                                Settled Timestamp: {{ payment.settledtimestamp }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                Payment Type: {{ payment.paymenttypedescription }}
                            </v-col>
                            <v-col cols="6">
                                Transaction Reference: {{ payment.transactionreference }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn text @click="viewClient()">View Client</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="#172C54" @click="closePaymentDetails()">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<script>
export default {
    components: {},
    props: [
        'value',
        'payment',
    ],
    data: () => ({
        
    }),
    async mounted() {
    },
    created() { },
    computed: {
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                "auth/setMessage",
                { show: true, message: message },
                { root: 1 }
            );
        },
        closePaymentDetails() {
            this.$emit('closePaymentDetails')
        },
        viewClient() {
            this.$emit('viewClient')
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss"></style>
