import Unsubscribe from '../UnsubscribeComponent.vue'

export default [
    {
        path: '/unsubscribe/:token',
        name: '/unsubscribe/:token',
        component: Unsubscribe,
        meta: {
            layout: 'public-layout'
        }
    }
]