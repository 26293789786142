<template>
    <v-container fluid>
        <v-card
            elevation="0"
        >
            <v-card-title>
                Distribution of Special Gifts
            </v-card-title>
            <v-card-subtitle style="word-break: break-word;">
                This optional section allows you to leave gifts, such as cash or items of value, to specific people. These gifts are made before distributing the rest of your estate to the beneficiaries selected in the previous step.
            </v-card-subtitle>
            <v-card-text
                class="mt-15"
                style="word-break: break-word;"
            >
                <strong><h2>Would you like to leave specific gifts, property, or items to specific people?</h2></strong>
                <v-btn
                    text
                    class="pt-6 pb-6 pl-15 pr-15 mr-5 mt-5"
                    outlined
                    @click="has_gifts('Yes')"
                    :disabled="get_beneficiaries.length > 0 ? true : false"
                >
                    Yes
                </v-btn>
                <v-btn
                    text
                    class="pt-6 pb-6 pl-15 pr-15 mr-5 mt-5"
                    outlined
                    @click="has_gifts('No')"
                    :disabled="get_beneficiaries.length > 0 ? true : false"
                >
                    No
                </v-btn>
            </v-card-text>
            <v-card-text
                v-if="get_beneficiaries.length > 0"
            >
                <v-card
                    v-for="(person, personindex) in get_beneficiaries"
                    :key="personindex"
                    class="mt-5"
                >
                    <v-card-text>
                        Gift {{personindex + 1}}
                        <v-row>
                            <v-col
                                :cols="isMobile() ? 12 : 6"
                            >
                                <strong>Gift Type*</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.gift_type"
                                    prepend-inner-icon="mdi-tooltip-text-outline"
                                    disabled="true"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 6"
                            >
                                <strong>Gift Name</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.gift_name"
                                    prepend-inner-icon="mdi-tooltip-text-outline"
                                    disabled="true"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 6"
                            >
                                <strong>Approximate value</strong>
                                <v-text-field
                                    type="number"
                                    outlined
                                    dense
                                    v-model="person.value"
                                    prepend-inner-icon="mdi-currency-eur"
                                    disabled="true"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 6"
                            >
                                <strong>Description</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.description"
                                    prepend-inner-icon="mdi-tooltip-text-outline"
                                    disabled="true"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <h4><strong>Who should inherit this gift when you pass away?</strong></h4>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-autocomplete
                                    :items="beneficiaries"
                                    item-value="name"
                                    item-text="name"
                                    outlined
                                    dense
                                    v-model="person.beneficiary"
                                    disabled="true"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <h4><strong>Beneficiary's legal name</strong></h4>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 3"
                            >
                                <strong>First name*</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.firstname"
                                    prepend-inner-icon="mdi-tooltip-text-outline"
                                    disabled="true"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 3"
                            >
                                <strong>Middle name</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.middlename"
                                    prepend-inner-icon="mdi-tooltip-text-outline"
                                    disabled="true"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 3"
                            >
                                <strong>Last name*</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.lastname"
                                    prepend-inner-icom="mdi-tooltip-text-outline"
                                    disabled="true"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 3"
                            >
                                <strong>Suffix*</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.suffix"
                                    prepend-inner-icom="mdi-tooltip-text-outline"
                                    disabled="true"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <strong>Relationship to you</strong>
                                <v-autocomplete
                                    :items="relationship"
                                    item-id="name"
                                    item-text="name"
                                    outlined
                                    dense
                                    v-model="person.relationship"
                                    disabled="true"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-text
                v-if="has_people && get_beneficiaries.length === 0"
            >
                <h2>My Gifts:</h2>
                <v-card
                    v-for="(person, personindex) in people"
                    :key="personindex"
                    class="mt-5"
                >
                    <v-card-text style="word-break: break-word;">
                        <v-row>
                            <v-col
                                :cols="isMobile() ? 12 : 6"
                            >
                                <strong>Gift Type*</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.gift_type"
                                    prepend-inner-icon="mdi-tooltip-text-outline"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 6"
                            >
                                <strong>Gift Name</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.gift_name"
                                    prepend-inner-icon="mdi-tooltip-text-outline"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 6"
                            >
                                <strong>Approximate value</strong>
                                <v-text-field
                                    type="number"
                                    outlined
                                    dense
                                    v-model="person.value"
                                    prepend-inner-icon="mdi-currency-eur"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 6"
                            >
                                <strong>Description</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.description"
                                    prepend-inner-icon="mdi-tooltip-text-outline"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <h4><strong>Who should inherit this gift when you pass away?</strong></h4>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-autocomplete
                                    :items="beneficiaries"
                                    item-value="name"
                                    item-text="name"
                                    outlined
                                    dense
                                    v-model="person.beneficiary"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <h4><strong>Beneficiary's legal name</strong></h4>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 3"
                            >
                                <strong>First name*</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.firstname"
                                    prepend-inner-icon="mdi-tooltip-text-outline"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 3"
                            >
                                <strong>Middle name</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.middlename"
                                    prepend-inner-icon="mdi-tooltip-text-outline"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 3"
                            >
                                <strong>Last name*</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.lastname"
                                    prepend-inner-icom="mdi-tooltip-text-outline"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                :cols="isMobile() ? 12 : 3"
                            >
                                <strong>Suffix*</strong>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="person.suffix"
                                    prepend-inner-icom="mdi-tooltip-text-outline"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <strong>Relationship to you</strong>
                                <v-autocomplete
                                    :items="relationship"
                                    item-id="name"
                                    item-text="name"
                                    outlined
                                    dense
                                    v-model="person.relationship"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-btn
                    text
                    class="pl-10 pr-10 mt-8"
                    @click="add_more_gifts"
                >
                    <v-icon
                        class="mr-2"
                    >mdi-plus</v-icon>
                    Leave another gift
                </v-btn>
            </v-card-text>
            <v-card-text
                class="mt-10 pa-5"
                style="background-color: #DEF3FF;word-break: break-word;"
            >
                <h2><strong>Note</strong></h2>
                <strong>If you leave gift to someone who is also a beneficiary of your residual estate, the gift does not count toward the percent of the residual estate that person receives.</strong>
            </v-card-text>
            <v-card-actions
                class="mt-15"
            >
                <v-btn
                    text
                    @click="back_to_second"
                >
                    <v-img
                        :src="require('../../../../../assets/chev_left_dark.svg')"
                        contain
                        max-width="35"
                        max-height="35"
                    />
                    Back
                </v-btn>
                <v-spacer/>
                <v-btn
                    dark
                    class="pl-10 pr-10 pt-5 pb-5"
                    style="textTransform: none !important;"
                    @click="next_step"
                >
                    Save and continue
                    <v-img
                        class="ml-3"
                        :src="require('../../../../../assets/chev_right_white.svg')"
                    />
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    people: [{
        gift_type: null,
        gift_name: null,
        value: null,
        description: null,
        firstname: null,
        middlename: null,
        lastname: null,
        suffix: null,
        beneficiary: null,
        relationship: null
    }],
    has_people: false,
    beneficiaries: [
        {id: 1, name: 'Beneficiary'},
        {id: 2, name: 'Sample Dropdown data'}
    ],
    relationship: [
        {id: 1, name: 'Child'},
        {id: 2, name: 'Sample Dropdown data'}
    ]
  }),
  async mounted () {
    await this.$store.dispatch('auth/fetch_beneficiaries')
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_children:           'auth/get_children',
        get_pets:               'auth/get_pets',
        get_beneficiaries:      'auth/get_beneficiaries'
    })
  },
  methods: {
    back_to_second(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.$store.dispatch('client_activity/set_last_will_el', 2)
    },
    has_gifts(data){
        if(data === 'Yes'){
            this.has_people = true
            return
        }
    },
    add_more_gifts(){
        this.people.push(Vue.util.extend({}, this.people))
    },
    async next_step(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if(this.get_beneficiaries.length > 0){
            this.$store.dispatch('client_activity/set_last_will_el', 4)
            return
        }
        let dd = []
        this.people.forEach( q => {
            dd.push({
                gift_type:      q.gift_type,
                gift_name:      q.gift_name,
                value:          q.value,
                description:    q.description,
                firstname:      q.firstname,
                middlename:     q.middlename,
                lastname:       q.lastname,
                suffix:         q.suffix,
                beneficiary:    q.beneficiary,
                relationship:   q.relationship
            })
        })
        await this.$axios.post('/auth/client/beneficiary/create_beneficiary', dd)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('auth/set_beneficiaries', data.data)
                this.$store.dispatch('client_activity/set_last_will_el', 4)
            }
        })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>