<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card
        width="90%"
        class="mt-5"
        elevation="0"
        style="background-color: #fafafa !important"
      >
        <v-card-title class="text-h5">
          <v-img
            :src="require('../../assets/survey_dark.svg')"
            class="mr-3"
            contain
            max-width="35"
            max-height="35"
          />
          <strong>Checklist</strong>
          <v-spacer />
          <!-- <v-btn
                        icon
                    >
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-btn> -->
        </v-card-title>
      </v-card>
    </v-row>
    <v-row justify="center" align="center" class="mt-15">
      <v-card width="90%" class="mt-5" rounded style="border-radius: 16px">
        <v-card-title>
          <h4>Active Checklist</h4>
          <!-- <v-spacer/>
                    <v-btn
                        dark
                        color="primary"
                        style="textTransform: none !important;"
                        @click="$router.push({name: '/create-survey'})"
                    >
                        <v-icon
                            class="mr-2"
                        >mdi-plus</v-icon>
                        Create Survey
                    </v-btn> -->
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              :cols="isMobile() ? 6 : 4"
              v-for="(active, activeindex) in get_active_survey"
              :key="activeindex"
            >
              <v-card elevation="0">
                <v-card-title>
                  <v-img
                    v-if="active.image !== null"
                    :src="`${image}/cover_photo/${active.image}`"
                    contain
                    max-width="250px"
                    max-height="250px"
                  />
                  <v-img
                    v-else
                    :src="require('../../assets/surveydefault.svg')"
                    contain
                  />
                </v-card-title>
                <v-card-title>
                  <small style="font-size: 12px;">{{ active.survey_name }}</small>
                  <v-spacer />
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="#347CE9" v-bind="attrs" v-on="on">
                        <h4 v-if="isMobile()">Options</h4>
                        <v-img
                          :src="require('../../assets/ellipsis.svg')"
                          contain
                          max-width="15"
                          max-height="15"
                        />
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title @click="deactivate(active.id)">
                          <v-icon>mdi-exclamation</v-icon>
                          Deactivate
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-divider />
        </v-card-text>
        <v-card-title>
          <h4>Saved Checklist</h4>
          <v-spacer />
          <v-btn text style="texttransform: none !important">
            List View
            <v-img class="ml-3" :src="require('../../assets/list_view.svg')" />
          </v-btn>
          <v-btn
            color="primary"
            class="pt-5 pb-5"
            style="texttransform: none !important"
          >
            Sort & Filter
            <v-img class="ml-3" :src="require('../../assets/chevdown.svg')" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="4"
              v-for="(active, activeindex) in get_survey"
              :key="activeindex"
            >
              <v-card elevation="0">
                <v-card-title>
                  <v-img
                    v-if="active.image !== null"
                    :src="`${image}/cover_photo/${active.image}`"
                    contain
                    max-width="250px"
                    max-height="250px"
                  />
                  <v-img
                    v-else
                    :src="require('../../assets/surveydefault.svg')"
                    contain
                  />
                </v-card-title>
                <v-card-title>
                  <small style="font-size: 12px;">{{ active.survey_name }}</small>
                  <v-spacer />
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="#347CE9"
                        v-bind="attrs"
                        v-on="on"
                        contain
                        max-width="15"
                        max-height="15"
                      >
                        <v-img :src="require('../../assets/ellipsis.svg')" />
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        style="cursor: pointer"
                        @click="set_active(active.id)"
                      >
                        <v-list-item-title>
                          <v-icon>mdi-exclamation</v-icon>
                          Set Active
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: [],
  data: () => ({
    image: null,
  }),
  async mounted() {
    await this.$store.dispatch("fd_admin_survey/fetch_survey");
  },
  created() {
    this.image = process.env.VUE_APP_URL;
  },
  computed: {
    ...mapGetters({
      get_survey: "fd_admin_survey/get_survey",
      get_active_survey: "fd_admin_survey/get_active_survey",
    }),
  },
  methods: {
    async set_active(index) {
      await this.$axios
        .patch(`auth/admin/set_active/${index}`)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch("fd_admin_survey/fetch_survey");
          }
        });
    },
    async deactivate(index) {
      await this.$axios
        .patch(`auth/admin/set_deactive/${index}`)
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch("fd_admin_survey/fetch_survey");
          }
        });
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
