<template>
    <v-container fluid>
        <v-card
            elevation="0"
        >
            <v-card-title style="word-break: break-word;">
                Digital Personal Representative
            </v-card-title>
            <v-card-subtitle style="word-break: break-word;">
                <strong>The digital personal representative will get access to your digital assets, such as virtual currency, computer files, and web domains. You'll still need to provide logins to your digital accounts in a safe spot so your Digital Executor can access them.</strong>
            </v-card-subtitle>
            <v-card-subtitle
                class="mt-15"
                style="word-break: break-word;"
            >
                <h3><strong>Who would you like to be the digital personal representative of your will?</strong></h3>
                <v-btn
                    text
                    outlined
                    :width="isMobile() ? '100%' : ''"
                    class="pa-10 mt-5 mr-3"
                    @click="same_as_personal"
                    :disabled="get_digital_representative.length > 0 ? true : false"
                >
                    <h4 style="word-break: break-word;white-space: pre-wrap;width:50%">
                        Same as my Personal Representative
                    </h4>
                </v-btn>
                <v-btn
                    text
                    outlined
                    :width="isMobile() ? '100%' : ''"
                    class="pa-10 mt-5 mr-3"
                    @click="assign_someone_fn"
                    :disabled="get_digital_representative.length > 0 ? true : false"
                >
                    <h4 style="word-break: break-word;white-space: pre-wrap;width:50%">
                        Assign someone else
                    </h4>
                </v-btn>
                <v-btn
                    text
                    outlined
                    :width="isMobile() ? '100%' : ''"
                    class="pa-10 mt-5"
                    :disabled="get_digital_representative.length > 0 ? true : false"
                >
                    <h4 style="word-break: break-word;white-space: pre-wrap;width:50%">
                        No one
                    </h4>
                </v-btn>
            </v-card-subtitle>
            <v-card-subtitle
                class="mt-15"
                v-if="get_digital_representative.length > 0"
            >
                <v-row
                    v-for="(persona, personalindex) in get_digital_representative"
                    :key="personalindex"
                >
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>First name*</strong>
                        <v-text-field
                            v-model="persona.firstname"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Middle name</strong>
                        <v-text-field
                            v-model="persona.middlename"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Last name*</strong>
                        <v-text-field
                            v-model="persona.lastname"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Suffix</strong>
                        <v-text-field
                            v-model="persona.suffix"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <h4><strong>Relationship to you</strong></h4>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 4"
                    >
                        <v-autocomplete
                            :items="relationship"
                            item-value="name"
                            item-text="name"
                            outlined
                            dense
                            v-model="persona.relationship"
                            disabled="true"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-subtitle
                v-if="same_as_persona && get_digital_representative.length === 0"
                class="mt-15"
            >
                <v-row
                    v-for="(persona, personalindex) in get_digital_persona"
                    :key="personalindex"
                >
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>First name*</strong>
                        <v-text-field
                            v-model="persona.firstname"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Middle name</strong>
                        <v-text-field
                            v-model="persona.middlename"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Last name*</strong>
                        <v-text-field
                            v-model="persona.lastname"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Suffix</strong>
                        <v-text-field
                            v-model="persona.suffix"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <h4><strong>Relationship to you</strong></h4>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 4"
                    >
                        <v-autocomplete
                            :items="relationship"
                            item-value="name"
                            item-text="name"
                            outlined
                            dense
                            v-model="persona.relationship"
                            disabled="true"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-subtitle
                v-if="assign_someone && get_digital_representative.length === 0"
            >
                <v-row
                    v-for="(digitalpersonal, digitalpersonalindex) in digital_people"
                    :key="digitalpersonalindex"
                >
                    <v-col
                        cols="12"
                    >
                        <h3><strong>Digital Personal Representative legal name:</strong></h3>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>First name*</strong>
                        <v-text-field
                            v-model="digitalpersonal.firstname"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Middle name</strong>
                        <v-text-field
                            v-model="digitalpersonal.middlename"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Last name*</strong>
                        <v-text-field
                            v-model="digitalpersonal.lastname"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Suffix</strong>
                        <v-text-field
                            v-model="digitalpersonal.suffix"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <h4><strong>Relationship to you</strong></h4>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 4"
                    >
                        <v-autocomplete
                            :items="relationship"
                            item-value="name"
                            item-text="name"
                            outlined
                            dense
                            v-model="digitalpersonal.relationship"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-btn
                    text
                    outlined
                    class="py-10"
                    @click="add_digital_representative_fn"
                >
                    <v-icon>mdi-plus</v-icon>
                    <h4 style="word-break: break-word;white-space:pre-wrap;width:50%;">Add a backup digital personal representative</h4>
                </v-btn>
            </v-card-subtitle>
            <v-card-actions>
                <v-btn
                    text
                    @click="back"
                >
                    <v-img
                        :src="require('../../../../../assets/chev_left_dark.svg')"
                        contain
                        max-width="35"
                        max-height="35"
                    />
                    Back
                </v-btn>
                <v-spacer/>
                <v-btn
                    dark
                    class="pl-10 pr-10 pt-5 pb-5"
                    style="textTransform: none !important;"
                    @click="next_step"
                >
                    Save and continue
                    <v-img
                        class="ml-3"
                        :src="require('../../../../../assets/chev_right_white.svg')"
                    />
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex' 
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    same_as_persona: false,
    assign_someone: false,
    relationship: [
        {id: 1, name: 'Child'},
        {id: 2, name: 'Another Dropdown data'},
    ],
    digital_people: [{
        firstname: null,
        middlename: null,
        lastname: null,
        suffix: null,
        relationship: null
    }]
  }),
  async mounted () {
    await this.$store.dispatch('auth/fetch_digital_representative')
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_digital_persona:            'auth/get_digital_persona',
        get_digital_representative:     'auth/get_digital_representative'
    })
  },
  methods: {
    back(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.$store.dispatch('client_activity/set_last_will_el', 5)
    },
    same_as_personal(){
        this.same_as_persona = true
        this.assign_someone = false
    },
    assign_someone_fn(){
        this.assign_someone = true
        this.same_as_persona = false
    },
    add_digital_representative_fn(){
        this.digital_people.push(Vue.util.extend({}, this.digital_people))
    },
    async next_step(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if(this.get_digital_representative.length > 0){
            this.$store.dispatch('client_activity/set_last_will_el', 7)
            return
        }
        if(this.same_as_persona){
            await this.$axios.post('/auth/client/digital_representative/add_digital_rep', this.get_digital_persona)
            .then(({data}) => {
                if(data.response){
                    this.$store.dispatch('auth/set_digital_representative', data.data)
                    this.$store.dispatch('client_activity/set_last_will_el', 7)
                }
            })
            return
        }
        if(this.assign_someone){
            let tp = []
            this.digital_people.forEach( q => {
                tp.push({
                    firstname:      q.firstname,
                    middlename:     q.middlename,
                    lastname:       q.lastname,
                    suffix:         q.suffix,
                    relationship:   q.relationship
                })
            })
            await this.$axios.post('/auth/client/digital_representative/add_digital_rep', tp)
            .then(({data}) => {
                if(data.response){
                    this.$store.dispatch('auth/set_digital_representative', data.data)
                    this.$store.dispatch('client_activity/set_last_will_el', 7)
                }
            })
        }
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>