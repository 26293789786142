<template>
    <v-container fluid>
        <v-skeleton-loader v-if="funeral_cost == null" type="card"></v-skeleton-loader>
        <v-row justify="center" align="center" v-else>
            <v-col cols="12">
                <div style="text-align:center;">
                    <v-btn color="#1976D2" dark class="pl-10 pr-10 mt-3" @click="print()">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Download
                    </v-btn>
                </div>
            </v-col>
            <v-card style="padding-left:10px;padding-right:10px;margin-top:3px;margin-bottom:3px;" elevation="0"
                id="funeral_cost">
                <v-card-text class="mt-5">
                    <v-row no-gutters>
                        <v-col cols="2">
                            <v-img :src="funeral_cost.client.get_fd_info.company_logo_base64"></v-img>
                        </v-col>
                        <v-col cols="7"></v-col>
                        <v-col cols="3">
                            {{ funeral_cost.client.get_fd_info.address }}
                            <br>
                            {{ funeral_cost.client.get_fd_info.town }}
                            <br>
                            {{ funeral_cost.client.get_fd_info.county }}
                            <br>
                            Eircode - {{ funeral_cost.client.get_fd_info.eircode }}
                            <br>
                            Tel: {{ funeral_cost.client.get_fd_info.mobile }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <h2>Present Day Funeral Cost Estimate</h2>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="3">
                            <p><b>Date of Estimate:</b></p>
                        </v-col>
                        <v-col cols="3">
                            <p>{{ $date().format('DD/MM/YYYY') }}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="8">
                            <b>Funeral Director Services</b>
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            <b>Amount</b>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Professional Service Charge
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.professional_service_charge }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Coffin
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.coffin }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Ashes/Casket/Urn
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.ashes_casket_urn }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Hygienic Treatment/Embalming and Presentation
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.hygienic_embalming_presentation }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Funeral Home Use
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.funeral_home_use }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Additional Movements of the Deceased
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.movements_of_deceased }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Funeral Cars
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.funeral_cars }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Temporary Grave Markers
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.temporary_grave_markers }}
                        </v-col>
                    </v-row>
                    <v-row v-for="(optional, index) in funeral_cost.optional_details_1" :key="'optional1' + index">
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            {{ optional.name }}
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ optional.cost }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="8">
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            <hr>
                            Subtotal: €{{ subtotal1 }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="8">
                            <b>Payments Made on Your Behalf</b>
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Cemetery Fees
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.cemetary_fees }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Cremation Fees
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.cremation_fees }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Church/Ceremony Venue Fees
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.church_ceremony_venue }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Priest/Minister/Celebrant Fees
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.priest_minister_celebrant }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Musicians/Soloist
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.musicians_soloist }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Funeral Flowers
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.flowers }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Funeral Notices (Radio/Newspaper/Online)
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.notices }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Live Streaming/Recording
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.live_streaming }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            Grave Digging
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ funeral_cost.grave_digging }}
                        </v-col>
                    </v-row>
                    <v-row v-for="(optional, index) in funeral_cost.optional_details_2" :key="'optional2' + index">
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="7">
                            {{ optional.name }}
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            €{{ optional.cost }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="8">
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            <hr>
                            Subtotal: €{{ subtotal2 }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="8">
                        </v-col>
                        <v-col cols="4" style="text-align:right;">
                            <hr>
                            <b>Total: €{{ total }}</b>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <div v-html="funeral_cost.notes"></div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import html2pdf from 'html2pdf.js'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        api_url: null,
        funeral_cost: null,
    }),
    async mounted() {
        await this.getData()
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        subtotal1() {
            let subtotal1 = 0
            if (this.funeral_cost != null) {
                subtotal1 += parseFloat(this.funeral_cost.professional_service_charge)
                subtotal1 += parseFloat(this.funeral_cost.coffin)
                subtotal1 += parseFloat(this.funeral_cost.ashes_casket_urn)
                subtotal1 += parseFloat(this.funeral_cost.hygienic_embalming_presentation)
                subtotal1 += parseFloat(this.funeral_cost.funeral_home_use)
                subtotal1 += parseFloat(this.funeral_cost.movements_of_deceased)
                subtotal1 += parseFloat(this.funeral_cost.funeral_cars)
                subtotal1 += parseFloat(this.funeral_cost.temporary_grave_markers)
                this.funeral_cost.optional_details_1.forEach(optional => {
                    if (optional.cost > 0) {
                        subtotal1 += parseFloat(optional.cost)
                    }
                })
            }
            return subtotal1
        },
        subtotal2() {
            let subtotal2 = 0
            if (this.funeral_cost != null) {
                subtotal2 += parseFloat(this.funeral_cost.cemetary_fees)
                subtotal2 += parseFloat(this.funeral_cost.cremation_fees)
                subtotal2 += parseFloat(this.funeral_cost.church_ceremony_venue)
                subtotal2 += parseFloat(this.funeral_cost.priest_minister_celebrant)
                subtotal2 += parseFloat(this.funeral_cost.musicians_soloist)
                subtotal2 += parseFloat(this.funeral_cost.flowers)
                subtotal2 += parseFloat(this.funeral_cost.notices)
                subtotal2 += parseFloat(this.funeral_cost.live_streaming)
                subtotal2 += parseFloat(this.funeral_cost.grave_digging)
                this.funeral_cost.optional_details_2.forEach(optional => {
                    if (optional.cost > 0) {
                        subtotal2 += parseFloat(optional.cost)
                    }
                })
            }
            return subtotal2
        },
        total() {
            return this.subtotal1 + this.subtotal2
        },
    },
    methods: {
        async getData() {
            await this.$axios.get('/non_auth/decode_funeral_cost_id', { data: this.$router.currentRoute.params.token })
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.funeral_cost = data.data
                    }
                })
        },
        print() {
            const opt = {
                margin: 0,
                filename: "Funeral Cost Estimate.pdf",
                image: { type: 'png', quality: 1 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
            }
            html2pdf().set(opt).from(document.getElementById("funeral_cost")).save()
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
}
</script>

<style scoped lang="scss">
p,
b,
div {
    color: black;
}

p,
div {
    font-weight: 500;
}

b {
    font-weight: 800;
}

:deep(.col) {
    padding: 3px !important;
}
</style>