<template>
    <v-container fluid>
        <h3 style="font-size:25px;color:#172C54;white-space: pre-line;word-break: break-word;" class="mb-8">Request a Certificate of Your Wishes by Post</h3>
        <v-card elevation="0" style="border:solid 2px #F3F3F3;border-radius:10px;" class="mb-3 mt-8 pb-10">
            <v-card-subtitle style="font-size:18px;white-space: pre-line;word-break: break-word;" class="mb-5">
                Request a certificate of your wishes to be sent by post to you, your funeral director and your nominated contact for €65.
            </v-card-subtitle>
            <v-card-subtitle>
                <v-btn dark
                    style="text-transform: none !important;background-color:#172C54;border-radius:10px;font-size: 16px;"
                    class="pt-6 pb-6" @click="send_request">Send
                    Request</v-btn></v-card-subtitle>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            user: "auth/get_user"
        })
    },
    data: () => ({
    }),
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async send_request() {
            let payload = {
                fd_email: this.user.get_user_add_info.get_fd_info.email,
            }
            this.showSnackBar("Please wait...")
            await this.$axios.post('/auth/client/request_certificate', payload)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    this.$store.dispatch('auth/fetch_user')
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>