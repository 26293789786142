import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import authstates from "../modules/Login/states/index.js";
import clientpage from "../module_admin/funeral_directors/states/index.js";
import fdclientsgeneral from "../module_admin/clients/states/index.js";
import FDAdminSurvery from "../module_admin/survey/states/index.js";
import AdminVisitors from "../module_admin/visitors/states/index.js";
import AdminFuneralNotice from "../module_admin/funeral_notice/states/index.js";
import AdminDocuments from "../module_admin/documents/states/index.js";
import AdminPaymentTerms from "../module_admin/dashboard/states/index.js";
import AdminEmails from "../module_admin/emails/states/index.js";

// Funeral Director State
import funeral_director_client from "../module_funeral_director/clients/states/index.js";
import funeral_director_survey from "../module_funeral_director/survey/states/index.js";
import funeral_dashboard from "../module_funeral_director/dashboard/states/index.js";
import funeral_director_documents from "../module_funeral_director/documents/states/index.js";
import FDFuneralNotice from "../module_funeral_director/funeral_notice/states/index.js";
import FDEmails from "../module_funeral_director/emails/states/index.js";

// Funeral Director State End

// Client State
import ClientProfile from "../module_client/Profile/states/index.js";
import ClientDashboardState from "../module_client/dashboard/states/index.js";
import ClientAcitivityState from "../module_client/activity/states/index.js";
import ClientNotification from "../module_client/notification/states/index.js";
import ClientPaymentTerms from "../module_client/payment/states/index.js";
// End Client State

// Survey State
import Survey from "../modules/survey/states/index.js";
import FuneralRequest from "../modules/funeral_request/states/index.js";
import PublicDocuments from "../modules/funeral_documents/states/index.js";
import FuneralPayment from "../modules/funeral_payment/states/index.js";
// End Survey State

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authstates,
    client: clientpage,
    fds: fdclientsgeneral,
    fd_client: funeral_director_client,
    client_profile: ClientProfile,
    client_dboard: ClientDashboardState,
    client_activity: ClientAcitivityState,
    client_notification: ClientNotification,
    fd_admin_survey: FDAdminSurvery,
    fd_survey: funeral_director_survey,
    fd_dashboard: funeral_dashboard,
    fd_documents: funeral_director_documents,
    survey: Survey,
    visitors: AdminVisitors,
    admin_funeral_notice: AdminFuneralNotice,
    fd_funeral_notice: FDFuneralNotice,
    funeral_request: FuneralRequest,
    admin_documents: AdminDocuments,
    public_documents: PublicDocuments,
    admin_payment_terms: AdminPaymentTerms,
    client_payment_terms: ClientPaymentTerms,
    public_funeral_payment: FuneralPayment,
    admin_emails: AdminEmails,
    fd_emails: FDEmails,
  },
  plugins: [createPersistedState()],
});
