<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card width="100%" elevation="0" no-gutters>
        <v-card-title style="word-break: break-word;">
          <h5 v-if="!isMobile()" style="color: #4374f3 !important; font-size: 20px; font-family: 'Montserrat', sans-serif; font-weight: 500;">
            Will it be burial or cremation for you?
          </h5>
          <label
            v-else
            style="color: #4374f3 !important; font-size: 20px; font-family: 'Montserrat', sans-serif; font-weight: 500;"
          >
            Will it be burial or cremation for you?
          </label>
        </v-card-title>
        <v-card-text>
          <v-row class="scroll-to">
            <v-col
              cols="4"
              v-for="(item, itemindex) in items"
              :key="itemindex"
              style="word-break: break-word;"
            >
              <v-card
                elevation="0"
                style="cursor: pointer"
                :color="item.active === 1 ? '#DCC397' : ''"
                @click="set_active(item)"
                width="100%"
              >
                <v-card-title
                  class="justify-center"
                >
                  <v-img :src="item.image" max-width="90" max-height="90"/>
                </v-card-title>
                <v-card-subtitle class="mt-5 text-center" style="padding:0;">
                  <strong
                    :style="
                      item.active === 1 ?
                        'color: white; font-size:14px; font-family: Montserrat, sans-serif; font-weight: 500' 
                          : 
                        'font-size:14px; font-family: Montserrat, sans-serif;'
                    "
                  >
                    {{item.label}}
                  </strong>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card elevation="0">
          <v-card-text v-if="get_funeral_service.label === 'Burial'">
            <v-col cols="12">
              <strong
                style="font-family: 'Montserrat', sans-serif; font-weight: 500;"
              >Do you have a preferred cemetery location?</strong>
              <v-text-field
                dense
                placeholder="Write your answer here..."
                v-model="answer_to_question"
                style="font-family: Montserrat, sans-serif;"
              >
              </v-text-field>
            </v-col>
          </v-card-text>
          <v-card-text v-if="get_funeral_service.label === 'Cremation'">
            <v-col cols="12">
              <strong
                style="font-family: 'Montserrat', sans-serif; font-weight: 500;"
              >Do you have a preferred crematorium location?</strong>
              <v-text-field
                dense
                placeholder="Write your answer here..."
                v-model="answer_to_question"
              >
              </v-text-field>
            </v-col>
            <strong
              style="font-family: 'Montserrat', sans-serif; font-weight: 500;"
            >
              Would you like to leave instructions for cremated remains? You can choose more than one.
            </strong>
            <v-row
              justify="center"
              align="center"
            >
              <v-col
                :cols="isMobile() ? '12' : '3'"
                v-for="(check, checkindex) in cremated_check_box_items"
                :key="checkindex"
              >
                <v-checkbox
                  v-model="check_box_selected"
                  :label="check.label"
                  :value="check.value"
                  style="font-family: 'Montserrat', sans-serif; font-weight: 400;"
                >
                </v-checkbox>
              </v-col>
              <v-col
                cols="12"
                v-if="check_box_selected.includes('Other')"
              >
                <strong>Other</strong>
                <v-text-field
                  placeholder="Write your answer here..."
                  dense
                  v-model="others"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card elevation="0">
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="Object.keys(get_funeral_service).length !== 0"
              color="primary"
              style="texttransform: none !important"
              class="pl-10 pr-10"
              @click="next_step"
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: [],
  data: () => ({
    items: [
      {
        label: "Burial",
        image: require("../../../../assets/burial_option.svg"),
        active: 0,
        selected: false,
      },
      {
        label: "Cremation",
        image: require("../../../../assets/cremated_option.svg"),
        active: 0,
        selected: false,
      },
      {
        label: "Not sure",
        image: require("../../../../assets/not_sure_option.png"),
        active: 0,
        selected: false,
      },
    ],
    answer_to_question: null,
    cremated_check_box_items: [
      {
        label: "Bury in a grave",
        value: "Bury in a grave",
      },
      {
        label: "Scatter",
        value: "Scatter",
      },
      {
        label: "Place in a cremation wall",
        value: "Place in a cremation wall",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
    check_box_selected: [],
    others: null
  }),
  mounted() {},
  created() {},
  computed: {
    ...mapGetters({
      get_funeral_service: "survey/get_funeral_service",
    }),
  },
  methods: {
    async scrollToElement() {
      await this.$nextTick();
      document.getElementsByClassName('scroll-to')[0].scrollIntoView({ behavior: 'smooth' })
    },
    set_active(data) {
      this.scrollToElement()
      this.items.forEach((q) => {
        q.active = 0;
        q.selected = false;
      });
      data.active = 1;
      data.selected = true;

      let tp = {
        answer: data,
        choice: "funeral_service",
      };

      this.$store.dispatch("survey/set_answer_r", tp);
      this.answer_to_question = null;
    },
    next_step() {
      let tp = {
        answer: {
          label: this.get_funeral_service.label,
          image: this.get_funeral_service.image,
          active: this.get_funeral_service.active,
          selected: this.get_funeral_service.selected,
          answer: this.answer_to_question,
          check_box: this.check_box_selected,
          others: this.others
        },
        choice: "funeral_service",
      };
      this.$store.dispatch("survey/set_answer_r", tp);
      this.$emit("next");
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
