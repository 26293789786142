<template>
    <v-container fluid :style="isMobile() ? '' : 'width: 1000px;'" class="draft-background">
        <v-skeleton-loader type="article" v-if="loading"></v-skeleton-loader>
        <v-row justify="center" v-if="!loading && will != null" class="draft-content">
            <v-card :width="isMobile() ? '100%' : '100%'" class="mt-10 mb-15 no-select" elevation="0"
                style="background:transparent;" @copy.prevent="preventCopy">
                <v-btn v-if="get_user.id == will.client_id" @click="editWill()" text block>Edit Will</v-btn>
                <v-card-title class="justify-center">
                    Last Will and Testament
                </v-card-title>
                <v-card-subtitle class="text-center" style="padding-bottom:0px;">
                    <strong>of</strong>
                </v-card-subtitle>
                <v-card-title class="justify-center" style="padding-top:0px;">
                    {{ will.client.name }}
                </v-card-title>
                <v-card-text>
                    <p>I, {{ will.client.name }}, currently residing at {{ will.client.address }} hereby declare this
                        to
                        be my last Will and Testament.</p>

                    <h2 class="mt-10 mb-3">1. Revocation</h2>
                    <p>
                        I, {{ will.client.name }}, born on {{ $date(will.client.birthday).format('MMMM DD, YYYY') }},
                        being of sound mind, hereby revoke all previous Wills and testamentary dispositions made by me.
                    </p>

                    <h2 class="mt-10 mb-3">2. Preliminary Declarations</h2>
                    <p>2.1 I have no specific preferences regarding my funeral arrangements.</p>
                    <p>2.2 I have included an Appendix with this Will, which, although a non-testamentary document, is
                        provided to assist my Trustees with arranging my funeral and delivering personal messages to
                        some of my beneficiaries as specified.</p>
                    <p>2.3 Any references to children in this Will shall include any child of mine born after the date
                        of this Will.</p>

                    <h2 class="mt-10 mb-3">3. Appointment of Guardians</h2>
                    <p>
                        <label v-if="will.guardians.length > 0">
                            3.1 I appoint
                            <label v-for="(guardian, index) in will.guardians" :key="index">
                                <b>{{ guardian }}</b><label v-if="index < will.guardians.length">, </label>
                            </label>
                            to be the guardian/s of any of my children

                            <label v-for="(child, index) in will.children" :key="index">
                                <b>{{ child }}</b><label v-if="index < will.children.length">, </label>
                            </label>
                            who are minors at the time of my death.
                        </label>
                        <label v-else>
                            3.1 I have not appointed anyone to be the guardian/s of any of my children who are minors at
                            the time of my death.
                        </label>
                    </p>
                    <p>
                        <label v-if="will.caretakers.length > 0 && will.pets.length > 0">
                            3.2 I appoint
                            <label v-for="(caretaker, index) in will.caretakers" :key="'caretaker' + index">
                                <b>{{ caretaker }}</b><label v-if="index < will.caretakers.length">, </label>
                            </label>
                            to be the caretakers/s of my pets

                            <label v-for="(pet, index) in will.pets" :key="'pet' + index">
                                <b>{{ pet }}</b><label v-if="index < will.pets.length">, </label>
                            </label>
                            at the time of my death.
                        </label>
                        <label v-else>
                            3.2 I have not appointed anyone to be the caretaker/s of any of my pets at
                            the time of my death.
                        </label>
                    </p>
                    <p>
                        <label v-if="will.pet_expenses">
                            3.3 I allocate {{ will.pet_expenses }} to be the amount of money to be provided for my pets'
                            needs at the time of my death.
                        </label>
                        <label v-else>
                            3.3 I have not specified an amount of money to be allocated for my pets' needs at the time
                            of my death.
                        </label>
                    </p>

                    <h2 class="mt-10 mb-3">4. Personal and Charitable Gifts</h2>
                    <p>
                        <label v-if="will.gifts.length > 0">
                            4.1 I have left the following gifts to be granted at the time of my death:
                            <br><br>
                            <label v-for="(gift, index) in will.gifts" :key="index" style="margin-left:20px;">
                                <b>{{ gift.type }}</b> to
                                <label v-for="(beneficiary, index2) in gift.beneficiaries" :key="index2">
                                    <b>{{ beneficiary }}</b><label v-if="index2 < gift.beneficiaries.length">, </label>
                                </label>
                                {{ gift.description }}
                                <br>
                            </label>
                        </label>
                        <label v-else>
                            4.1 I have not left any specific gifts to individuals.
                        </label>
                    </p>
                    <p>
                        <label v-if="will.charities.length > 0">
                            4.2 I have left the following gifts to be granted to charities at the time of my death:
                            <br><br>
                            <label v-for="(charity, index) in will.charities" :key="index" style="margin-left:20px;">
                                <b>{{ charity.type }}</b> of <b>{{ charity.amount }}</b> to
                                {{ charity.name }}
                                <br>
                            </label>
                        </label>
                        <label v-else>
                            4.2 I have not left any specific gifts to charities.
                        </label>
                    </p>


                    <h2 class="mt-10 mb-3">5. Major Assets</h2>
                    <p>
                        <label v-if="will.assets.length > 0">
                            5.1 I have listed my major assets and their approximate values:
                            <br><br>
                            <div v-for="(asset, index) in will.assets" :key="index" style="margin-left:20px;">
                                <label>Name: {{ asset.name }}</label><br>
                                <label>Approximate value: {{ asset.value }}</label><br>
                                <label>Type: {{ asset.type }}</label><br>
                                <label>Owner: {{ asset.owner }}</label><br>
                                <label>Description: {{ asset.description }}</label><br>
                                <br>
                            </div>
                        </label>
                        <label v-else>
                            5.1 I have not listed my major assets.
                        </label>
                    </p>
                    <p>
                        Approximate total value of assets: {{ will.assets_total ?? 'Not specified' }}
                    </p>

                    <h2 class="mt-10 mb-3">5. Definition of Estate</h2>
                    <p>5.1 My estate shall mean all my legal and beneficial interests in property, money and
                        possessions.
                    </p>

                    <h2 class="mt-10 mb-3">6. Distribution of Estate</h2>
                    <p>
                        <label v-if="will.estates.length > 0">
                            6.1 I have left the following estates to be granted at the time of my death:
                            <br><br>
                            <label v-for="(estate, index) in will.estates" :key="index" style="margin-left:20px;">
                                <b>{{ estate.percent }}%</b> to my {{ estate.relationship }},
                                <b>{{ estate.beneficiary }}</b>
                                <br>
                            </label>
                        </label>
                        <label v-else>
                            6.1 I have not left any specific estates.
                        </label>
                    </p>
                    <p>
                        <label v-if="will.takers.length > 0">
                            6.2 I have left the following takers of last resort to be granted at the time of my death:
                            <br><br>
                            <label v-for="(taker, index) in will.takers" :key="index" style="margin-left:20px;">
                                <b>{{ taker.percent }}%</b> to my {{ taker.relationship }},
                                <b>{{ taker.name }}</b>
                                <br>
                            </label>
                        </label>
                        <label v-else>
                            6.2 I have not left any specific takers of last resort.
                        </label>
                    </p>

                    <h2 class="mt-10 mb-3">7. Executors of my Will</h2>
                    <label v-if="will.executors.length > 0">
                        7.1 I have specified the following people to be executors of my will:
                        <br><br>
                        <label v-for="(executor, index) in will.executors" :key="index" style="margin-left:20px;">
                            <b>{{ executor.name }}</b>, my {{ executor.relationship }}
                            <br>
                        </label>
                    </label>
                    <label v-else>
                        7.1 I have not specified any executors of my will.
                    </label>

                    <h2 class="mt-10 mb-3">8. General Provisions</h2>
                    <p>8.1 The standard provisions and the following special provisions of the Society of Trust and
                        Estate Practitioners [2nd Edition] shall apply, including the Absolute Discretion Clause.</p>
                    <p>8.2 Any beneficiary who would otherwise inherit under this Will but does not survive me by 28
                        days shall be considered to have predeceased me for all purposes related to the distribution of
                        my estate. In such cases, my estate, along with any intermediate income, shall be distributed
                        accordingly.</p>
                    <p>8.3 For clarity, clause headings in this Will are not to be considered as part of the Will.</p>

                    <h2 class="mt-10 mb-3">9. Final Declarations</h2>
                    <p>I confirm that</p>
                    <p>a. I am mentally capable of making my own decisions about my Will;</p>
                    <p>b. I am voluntarily making this Will;</p>
                    <p>c. I have considered everyone I might reasonably be expected to be provided for by my Will;</p>
                    <p>d. I understand the contents of this Will and it is a true reflection of my wishes.</p>

                    <h2 class="mt-10 mb-3">10. Final Clause</h2>
                    <p>This is the last clause of my Will and is only followed by the attestation statement</p>

                    <h2 class="mt-10 mb-3">Signature</h2>
                    <p>I {{ will.client.name }} the within named Testator have to this my last Will set my hand. I
                        declare that this instrument is my last Will and that I am of the legal age to make a Will, and
                        that I sign this Will freely and voluntarily.</p>
                    <v-text-field label="Signature"></v-text-field>
                    <v-text-field label="Date of signing"></v-text-field>

                    <h2 class="mt-10 mb-3">Witnesses</h2>
                    <v-text-field label="First witness signature"></v-text-field>
                    <v-text-field label="First witness full name"></v-text-field>
                    <v-text-field label="First witness address"></v-text-field>
                    <v-text-field label="Second witness signature"></v-text-field>
                    <v-text-field label="Second witness full name"></v-text-field>
                    <v-text-field label="Second witness address"></v-text-field>

                    <h2 class="mt-10 mb-3">Funeral Instructions</h2>
                    <label v-if="will.funeral_instructions || will.funeral_type">
                        <label v-if="will.funeral_type == 1">
                            No preference
                        </label>
                        <label v-if="will.funeral_type == 2">
                            I would like to be cremated
                        </label>
                        <label v-if="will.funeral_type == 3">
                            I would like a burial
                        </label>
                        <label v-if="will.funeral_type == 4">
                            I would like a natural burial
                        </label>
                        <label v-if="will.funeral_type == 5">
                            I would like to donate my body to science
                        </label>
                        <br>
                        {{ will.funeral_instructions }}
                    </label>
                    <label v-else>I have no special requests for my funeral, and I will let my executors choose how to
                        handle my funeral.</label>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        loading: false,
        will_id: null,
        will: null,
    }),
    async mounted() {
        this.will_id = this.$router.currentRoute.params.will_id
        this.getWill()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                'auth/setMessage',
                { show: true, message: message },
                { root: 1 }
            )
        },
        async getWill() {
            this.loading = true
            let payload = {
                will_id: this.will_id,
            }
            await this.$axios.get('non_auth/preview_will', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.will = data.data
                        this.will.children = JSON.parse(data.data.children)
                        this.will.guardians = JSON.parse(data.data.guardians)
                        this.will.pets = JSON.parse(data.data.pets)
                        this.will.caretakers = JSON.parse(data.data.caretakers)
                        this.will.assets = JSON.parse(data.data.assets)
                        this.will.charities = JSON.parse(data.data.charities)
                        this.will.gifts = JSON.parse(data.data.gifts)
                        this.will.estates = JSON.parse(data.data.estates)
                        this.will.takers = JSON.parse(data.data.takers)
                        this.will.executors = JSON.parse(data.data.executors)
                        this.will.additionals = JSON.parse(data.data.additionals)
                        this.loading = false
                    }
                })
        },
        preventCopy(event) {
            event.preventDefault()
            console.log('Copy action prevented')
        },
        editWill() {
            this.$router.push({ name: 'Edit Will' })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.draft-background {
    position: relative;
    background-image: repeating-linear-gradient(45deg,
            rgba(0, 0, 0, 0.1) 0,
            rgba(0, 0, 0, 0.1) 1px,
            transparent 1px,
            transparent 20px),
        repeating-linear-gradient(-45deg,
            rgba(0, 0, 0, 0.1) 0,
            rgba(0, 0, 0, 0.1) 1px,
            transparent 1px,
            transparent 20px);
    color: rgba(0, 0, 0, 0.1);
    font-size: 100px;
    font-weight: bold;
    line-height: 200px;
}

.content {
    position: relative;
    z-index: 1;
}

.no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
</style>