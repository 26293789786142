import ClientDashboard from '../ClientDashboard.vue'

export default [
    {
        path: '/client-dashboard',
        name: '/client-dashboard',
        component: ClientDashboard,
        meta: {
            layout: 'client-layout'
        }
    }
]