<template>
    <v-container fluid>
        <h3 style="font-size:25px;color:#172C54;white-space: pre-line;word-break: break-word;" class="mb-8">Request a
            Funeral Cost Estimate</h3>
        <v-card elevation="0" style="border:solid 2px #F3F3F3;border-radius:10px;" class="mb-3 mt-8 pb-10">
            <v-card-subtitle class="mb-5 paragraph">
                <p>Please fill out this form to receive a quotation for our funeral plan payment options offered by My
                    Farewell Wishes Ltd. We understand the importance of preparing for the future and offer flexible payment
                    terms to suit your preferences, ensuring peace of mind during difficult times. All payments made through
                    this site comply with the European Payment Services Directive and are processed in accordance with GDPR
                    legislation
                    for data protection.</p>
                <h4>Personal Information</h4>
                <v-row id="form-details">
                    <v-col cols="6">
                        <v-text-field v-model="form_details.name" label="Full Name*"></v-text-field>
                        <v-text-field v-model="form_details.email" label="Email Address*"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="form_details.mobile" label="Phone Number*"></v-text-field>
                        <v-text-field v-model="form_details.address" label="Address*"></v-text-field>
                    </v-col>
                </v-row>
                <h4>Terms and Conditions</h4>
                <p>Before proceeding, please read and agree to our <a @click="terms_modal = true"><u>Terms &
                            Conditions</u></a>.</p>
                <input v-model="checked" type="checkbox" style="width:15px;height:15px;" id="checkbox" />
                I confirm that I have read and understood the Terms and Conditions and agree to abide by them.
                <br><br>
                <h4>Recurring Payment Admin Charge</h4>
                <p>Please note that all recurring payments for the Subscription Basis option will incur a 6% admin charge.
                    This charge will be added to each monthly payment automatically after the initial deposit.
                </p>
                <h4>Declaration</h4>
                <p>By submitting this form, I authorize My Farewell Wishes Ltd. to process my personal information and
                    payments in accordance with the European Payment Services Directive and GDPR legislation.</p>
                <p>For any inquiries or assistance, please contact us at info@myfarewellwishes.ie.
                    Thank you for choosing My Farewell Wishes Ltd. for your funeral plan. We are dedicated to providing the
                    utmost care and support during this challenging time.</p>
                <p>Note: For recurring payments, the customer's bank card will be charged automatically each month based on
                    the selected subscription amount, along with the 6% admin charge if applicable. Your card details will
                    be securely stored and processed in compliance with GDPR regulations to ensure the privacy and security
                    of your data.</p>
                <v-btn dark
                    style="text-transform: none !important;background-color:#172C54;border-radius:10px;font-size: 16px;"
                    class="pt-6 pb-6" @click="send_request">
                    Submit Request
                </v-btn>
            </v-card-subtitle>
        </v-card>
        <PrivacyTerms :terms_modal="terms_modal" :privacy_modal="privacy_modal" @close_modal="close_modal" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import PrivacyTerms from "../../components/PrivacyTerms.vue"
export default {
    components: {
        PrivacyTerms
    },
    props: [
    ],
    mounted() {
        this.setDetails()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            user: "auth/get_user"
        })
    },
    data: () => ({
        form_details: {
            name: '',
            email: '',
            mobile: '',
            address: '',
        },
        terms_modal: false,
        privacy_modal: false,
        checked: false,
    }),
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        setDetails() {
            this.form_details.name = this.user.name
            this.form_details.email = this.user.email
            this.form_details.mobile = this.user.mobile
            this.form_details.address = this.user.address
        },
        close_modal() {
            this.terms_modal = false
            this.privacy_modal = false
        },
        async send_request() {
            if (this.form_details.name == '' || this.form_details.email == '' || this.form_details.mobile == '' || this.form_details.address == '') {
                this.showSnackBar("Please fill out your details to proceed")
                this.$vuetify.goTo('#form-details', { offset: 25 })
                return
            }
            if (!this.checked) {
                this.showSnackBar("Please check this box to proceed")
                this.$vuetify.goTo('#checkbox', { offset: 60 })
                return
            }
            let new_progress = this.user.progress
            if (new_progress['tab_2'] == false) {
                new_progress['percentage'] += 33
                if (new_progress['percentage'] == 99) {
                    new_progress['percentage'] = 100
                }
                new_progress['tab_2'] = true
            }
            let payload = {
                fd_email: this.user.get_user_add_info.get_fd_info.email,
                progress: new_progress,
                name: this.form_details.name,
                email: this.form_details.email,
                mobile: this.form_details.mobile,
                address: this.form_details.address,
            }
            this.showSnackBar("Please wait...")
            await this.$axios.post('/auth/client/request_estimate', payload)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    this.$store.dispatch('auth/fetch_user')
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.paragraph {
    font-size: 18px;
    white-space: pre-line;
    word-break: break-word;
}
</style>