export default {
    namespaced: true,
    state: {
        funeral_director: {},
    },
    actions: {
        set_funeral_director({ commit }, payload) {
            commit('set_funeral_director', payload)
        },
    },
    mutations: {
        set_funeral_director(state, payload) {
            state.funeral_director = payload
        },
    },
    getters: {
        get_funeral_director: state => state.funeral_director,
    }
}