import FuneralRequest from '../FuneralRequest.vue'

export default [
    {
        path: '/funeral-request/:token',
        name: '/funeral-request/:token',
        component: FuneralRequest,
        meta: {
            layout: 'public-layout'
        }
    },
]