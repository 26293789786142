<template>
    <v-container fluid :style="isMobile() ? '' : 'width:1000px;'">
        <v-row justify="center" align="center">
            <v-col cols="12">
                <v-card elevation="0">
                    <v-card-text class="mt-5">
                        <h1 class="mb-10">Terms & Conditions</h1>
                        <vue-pdf-embed :source="app_url + '/30Nov22_t&cs.pdf'" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
    components: {
        VuePdfEmbed
    },
    data: () => ({
        app_url: null,
    }),
    created() {
        this.app_url = process.env.VUE_APP_URL
    },
    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>