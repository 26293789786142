<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card width="90%" class="mt-5" elevation="0">
        <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
          <v-img :src="require('../../assets/fd_clients.svg')" class="mr-3" contain max-width="35" max-height="35" />
          <strong style="font-size:26px;">Visitors</strong>
          <v-spacer />
          <!-- <v-btn
            icon
          >
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-title class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
          <small style="position: relative; top: -10px; font-size:12px;">Showing {{ get_visitors.length }}
            visitors</small>
          <v-spacer />
          <v-row>
            <!--<v-col :cols="isMobile() ? 12 : 12">
              <v-text-field dense placeholder="Search" append-icon="mdi-magnify" class=" liitme" v-model="search_user">
              </v-text-field>
            </v-col>
            <v-col
                :cols="isMobile() ? 12 : 3"
            >
                <v-btn :class="isMobile() ? 'mobile_sort' : 'desktop_sort'" color="primary" style="text-transform:none;" @click="open_sort_filter()">
                    Sort & Filter
                    <v-img
                        class="ml-3"
                        :src="require('../../assets/chevdown.svg')"
                    />
                </v-btn>
            </v-col>-->
          </v-row>
        </v-card-title>
      </v-card>
      <v-card width="90%" style="border-radius: 16px" elevation="0" :class="isMobile() ? 'mobile_card' : 'desktop_card'">
        <v-card-text class="mt-5">
          <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="header_visitors"
            :items="get_visitors" :items-per-page="7" :mobile-breakpoint="0" hide-default-header :sort-by.sync="sort_by"
            :sort-desc.sync="sortDesc">
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr v-if="!isMobile()">
                  <th style="padding-bottom:30px;padding-top:10px;width:25%" v-for="h in headers" :key="h.index">
                    <div style="text-align:center;" v-if="h.text">
                      <strong>{{ h.text }}</strong>
                    </div>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item, headers }">
              <tr class="mx-5" style="background-color: transparent;" v-if="item != null && !isMobile()">
                <td style="width:25%">
                  <v-layout align-center class="ms-5">
                    <v-avatar color="#4374F3" size="42"
                      style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                      <strong style="color:white;font-size:22px;">{{ item.name[0] }}</strong>
                    </v-avatar>
                    <strong style="font-size:16px;" class="ms-5">{{ item.name }}</strong>
                  </v-layout>
                </td>
                <td style="width:25%">
                  <v-layout justify-center>
                    {{
                      $date(item.updated_at).format("DD MMM YYYY")
                    }}
                    <strong style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                    {{
                      $date(item.updated_at).format("hh:mm a")
                    }}
                  </v-layout>
                </td>
                <td style="width:10%">
                  <v-layout justify-center align-center class="ms-5">
                    {{ item.get_client_user_info.get_sharer_user_info.name }}
                  </v-layout>
                </td>
                <td style="width:15%">
                  <v-layout justify-center align-center class="ms-7">
                    <v-select chips :items="funeral_directors" placeholder="Assign" v-model="assigned_fd.user_id[item.id]"
                      item-text="name" item-value="id" @change="assign_fd(item.id)">
                      <template v-slot:append>
                        <v-icon color="blue" class="mt-1" style="margin-left:-15px;">mdi-chevron-down</v-icon>
                      </template>
                      <template #selection="{ item }">
                        <v-chip style="color:white;width:90%;cursor: pointer;" class="assign">
                          <strong style="font-size:12px;">{{ item.name }}</strong>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-layout>
                </td>
              </tr>
              <tr class="mx-5" style="background-color: transparent;" v-if="item != null && isMobile()">
                <td style="width:100%" class="py-5">
                  <v-row>
                    <v-col cols="3" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[0].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="9">
                      <v-layout justify-center align-center class="ms-5">
                        <v-avatar color="#4374F3" size="42"
                          style="border:solid white 1px;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                          <strong style="color:white;font-size:22px;">{{ item.name[0] }}</strong>
                        </v-avatar>
                        <strong style="font-size:16px;" class="ms-5">{{ item.name }}</strong>
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[1].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="8">
                      <v-layout justify-center>
                        {{
                          $date(item.updated_at).format("DD MMM YYYY")
                        }}
                        <strong style="font-size:16px;margin-left:10px;margin-right:10px;margin-top:-2px">|</strong>
                        {{
                          $date(item.updated_at).format("hh:mm a")
                        }}
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[2].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="8">
                      <v-layout justify-center align-center>
                        {{ item.get_client_user_info.get_sharer_user_info.name }}
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" style="height:auto;" class="d-flex">
                      <v-layout align-center>
                        <h4>{{ headers[3].text }}</h4>
                      </v-layout>
                    </v-col>
                    <v-col cols="9">
                      <v-layout justify-center align-center class="ms-7">
                        <v-select chips :items="funeral_directors" placeholder="Assign" v-model="assigned_fd.user_id[item.id]"
                          item-text="name" item-value="id" @change="assign_fd(item.id)">
                          <template v-slot:append>
                            <v-icon color="blue" class="mt-1" style="margin-left:-15px;">mdi-chevron-down</v-icon>
                          </template>
                          <template #selection="{ item }">
                            <v-chip style="color:white;width:90%;cursor: pointer;" class="assign">
                              <strong style="font-size:12px;">{{ item.name }}</strong>
                            </v-chip>
                          </template>
                        </v-select>
                      </v-layout>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
  },
  props: [],
  data: () => ({
    header_visitors: [
      { text: "Name", value: 'name' },
      { text: "Last login", value: 'updated_at' },
      { text: "Invited by" },
      { text: "Funeral Dir." },
    ],
    assigned_fd: {
      user_id: []
    },
    search_user: null,
    sort_by: "created_at",
    sortDesc: true,
    filter_by: null,
  }),
  async mounted() {
    await this.$store.dispatch("visitors/fetch_visitors")
    await this.$store.dispatch("fds/fd_list")
  },
  created() {
  },
  computed: {
    ...mapGetters({
      get_visitors: "visitors/get_visitors",
      funeral_directors: "fds/get_fd_list"
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    async assign_fd(client_id) {
      let payload = {
        fd_id: this.assigned_fd.user_id[client_id],
        client_id: client_id
      }
      await this.$axios.post('/auth/admin/assign_visitor_to_fd', payload)
        .then(({ data }) => {
          this.showSnackBar("Assigned successfully, user moved to clients")
          this.$store.commit("visitors/set_visitors", data.data);
        })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
  border-radius: 5px;
  margin-top: -2px;
}

::v-deep .liitme .v-input__slot {
  background-color: white;
  padding: 7px;
}

table {
  border-collapse: collapse;
}

// remove border
table td,
table th {
  height: 40px; //change the height
}

.mobile_table td {
  height: 100px !important;
}

.desktop_table td {
  height: 100px !important;
}

.mobile_card {
  margin-top: 100px;
}

.desktop_table {
  padding-left: 30px;
  padding-right: 30px;
}

.mobile_sort {
  margin-top: -40px;
}

.v-data-table.row-height-50 td {
  height: 100px !important;
}

.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: bold;
}

::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
  border-style: none !important;
}

.assign {
  background-color: #DEF3FF !important;
  color: #347CE9 !important;
  justify-content: center;
}
</style>
