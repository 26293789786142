<template>
    <v-container fluid>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                width="90%"
                elevation="0"
            >
                <v-card-title style="word-break: break-word;">
                    <h5
                        v-if="!isMobile()"
                        style="color: #4374F3 !important; font-size: 20px; font-family: 'Montserrat', sans-serif; font-weight: 500;"
                    >Do you have any personal wishes that you would like to add?</h5>
                    <small
                        v-else
                        style="font-size: 20px; font-family: 'Montserrat', sans-serif; font-weight: 500;"
                    >
                        Do you have any personal wishes that you would like to add?
                    </small>
                </v-card-title>
                <v-card-text>
                    <v-col
                        cols="12"
                    >
                        <strong
                            style="font-family: 'Montserrat', sans-serif; font-weight: 400;"
                        >Things that I want</strong>
                        <v-text-field
                            dense
                            placeholder="Write your answer here..."
                            v-model="wants"
                            style="font-family: 'Montserrat', sans-serif; font-weight: 400;"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <strong
                            style="font-family: 'Montserrat', sans-serif; font-weight: 400;"
                        >Things that I don't want</strong>
                        <v-text-field
                            dense
                            placeholder="Write your answer here..."
                            v-model="dontwant"
                            style="font-family: 'Montserrat', sans-serif; font-weight: 400;"
                        >
                        </v-text-field>
                    </v-col>
                </v-card-text>
                <v-card
                    elevation="0"
                >
                    <v-card-actions>
                        <v-btn
                            text
                            @click="back_step"
                            style="textTransform: none !important;"
                        >
                            <v-img
                                :src="require('../../../../assets/chev_left_dark.svg')"
                            />
                            Back
                        </v-btn>
                        <v-spacer/>
                        <v-btn
                            color="primary"
                            style="textTransform: none !important;"
                            class="pl-10 pr-10"
                            @click="next_step"
                        >
                            Next
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapGetters } from 'vuex'
  export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        wants: null,
        dontwant: null
    }),
    mounted () {
    },
    created () {
    },
    computed: {
        ...mapGetters({
            get_personal_wishes:            'survey/get_personal_wishes'
        })
    },
    methods: {
        back_step(){
            this.$emit('back')
        },
        next_step(){
            let tp = {
                answer:     {
                    want:       this.wants,
                    dontwant:   this.dontwant
                },
                choice:     'personal_wishes'
            }
            this.$store.dispatch('survey/set_answer_r', tp)
            this.$emit('next')  
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
  }
</script>
  
<style scoped lang="scss">
</style>