<template>
  <v-container fluid style="background-color: #FAFBFF !important; height: 100%">
    <v-row justify="center" align="center" class="mt-15">
      <v-card :width="isMobile() ? '95%' : '70%'" class="mt-5 pt-10 pb-10"
        :id="isMobile() ? 'mobile_card' : 'desktop_card'" elevation="2" style="border-radius: 16px;margin-bottom:10px;">
        <v-card-title>
          <v-list-item two-line class="pa-5">
            <v-avatar v-if="user.get_user_add_info && user.get_user_add_info.profile_picture === null" size="75"
              color="blue"
              style="position: relative; right: 18px;top:-10px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
              <strong v-if="user.representative != null" style="color:white;font-size:22px;">{{ user.representative[0]
              }}</strong>
              <strong v-else style="color:white;font-size:22px;">{{ user.name[0] }}</strong>
            </v-avatar>
            <v-avatar v-else-if="user.get_user_add_info" size="75" color="blue"
              style="position: relative; right: 18px;top:-10px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
              <v-img contain :src="`${api_url}${user.get_user_add_info.profile_picture}`" />
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title v-if="user.representative != null" style="color:#494949;" class="mb-5">
                <h4 style="word-break: break-word;font-size: 25px;white-space: pre-wrap;">{{ user.representative }}
                </h4>
                <small>Representative of {{ user.name }}</small>
              </v-list-item-title>
              <v-list-item-title v-else style="color:#494949;" class="mb-5">
                <h4 style="word-break: break-word;font-size: 25px;white-space: pre-wrap;">{{ user.name }}
                </h4>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="!isMobile()">
              <v-list>
                <v-list-item style="cursor: pointer" @click="logout_fn">
                  <v-list-item-title>
                    <v-icon>mdi-logout-variant</v-icon>
                    Logout
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="user.role_id != 4" style="cursor: pointer" @click="profile_edit">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon>
                    Edit Profile
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="user.role_id != 4" style="cursor: pointer" @click="open_edit_password = true">
                  <v-list-item-title>
                    <v-icon>mdi-account</v-icon>
                    Change Password
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-list-item-action v-if=isMobile()>
          <v-list>
            <v-list-item style="cursor: pointer" @click="logout_fn">
              <v-list-item-title>
                <v-icon>mdi-logout-variant</v-icon>
                Logout
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user.role_id != 4" style="cursor: pointer" @click="profile_edit">
              <v-list-item-title>
                <v-icon>mdi-pencil</v-icon>
                Edit Profile
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user.role_id != 4" style="cursor: pointer" @click="open_edit_password = true">
              <v-list-item-title>
                <v-icon>mdi-pencil</v-icon>
                Change Password
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-item-action>
        <v-card-subtitle>
          <v-row class="mt-10">
            <v-col :cols="isMobile() ? '12' : '6'">
              <v-row>
                <v-col v-if="user.role_id != 4" cols="4"> Funeral Director </v-col>
                <v-col v-if="user.role_id != 4" cols="8" class="float-right" style="text-align: right">
                  <strong v-if="user.get_user_add_info">{{ user.get_user_add_info.get_fd_info.company_name }}</strong>
                </v-col>
                <v-col cols="4"> Contact Number </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong v-if="user.mobile === null"> N/A </strong>
                  <strong>{{ user.mobile }}</strong>
                </v-col>
                <v-col cols="4"> Email </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong v-if="user.email === null"> N/A </strong>
                  <strong>{{ user.email }}</strong>
                </v-col>
                <v-col cols="4"> Address </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong v-if="user.address === null"> N/A </strong>
                  <strong>
                    {{ user.address }}
                    {{ user.county }}
                    {{ user.town }}
                    {{ user.eircode }}
                  </strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="isMobile() ? '12' : '6'">
              <v-row>
                <v-col cols="4"> Age </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong v-if="user.age !== null">{{ user.age }}</strong>
                  <strong v-else>N/A</strong>
                </v-col>
                <v-col cols="4"> Gender </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong v-if="user.get_gender !== null">{{
                    user.get_gender.name
                  }}</strong>
                  <strong v-else> N/A </strong>
                </v-col>
                <v-col cols="4"> Date of birth </v-col>
                <v-col cols="8" class="float-right" style="text-align: right">
                  <strong v-if="user.birthday === null"> N/A </strong>
                  <strong v-else>{{
                    $date(user.birthday).format("MMMM DD, YYYY")
                  }}</strong>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-subtitle>
      </v-card>
    </v-row>
    <v-row align="center" justify="center" v-if="user.role_id != 4">
      <v-card :width="isMobile() ? '95%' : '70%'" class="mt-5 pt-10 pb-10"
        :id="isMobile() ? 'mobile_card_2' : 'desktop_card_2'" elevation="2"
        style="border-radius: 16px;margin-bottom:10px;">
        <v-card-title class="text-h5">
          <v-img :src="require('../../assets/share_profile.svg')" contain max-width="40" max-height="40" class="mr-3" />
          <strong>Profile Sharing</strong>
        </v-card-title>
        <v-divider />
        <v-card-title class="text-label">
          <strong style="word-break: break-word;">You shared your profile to {{ get_invited.length }} people</strong>
        </v-card-title>
        <v-card-subtitle>
          <label style="word-break: break-word;">Share your profile so someone knows your plans.</label>
        </v-card-subtitle>
        <v-card-text v-if="get_invited.length > 0">
          <v-row>
            <v-col :cols="isMobile() ? 12 : 6" v-for="(user, userindex) in get_invited" :key="userindex">
              <v-list-item two-line>
                <v-avatar v-if="user.get_client_info && user.get_client_info.profile_picture !== null" size="55"
                  color="blue"
                  style="position: relative; right: 18px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                  <v-img contain :src="`${api_url}${user.get_client_info.profile_picture}`" />
                </v-avatar>
                <v-avatar v-else-if="user.get_client_info" size="55" color="blue"
                  style="position: relative; right: 18px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                  <strong style="fontsize: 20px;color:white;">{{ user.email[0] }}</strong>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title style="fontsize: 16" class="ml-3">
                    <strong>{{ user.email }}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle class="ml-3">
                    <label v-if="user.client_id !== null"> Has profile </label>
                    <label v-else> Has no profile yet </label>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-btn dark style="text-transform: none !important;background-color:#172C54;border-radius:10px;font-size: 16px;"
            class="pt-6 pb-6" @click="share_model_change">
            Share my profile
            <v-img :src="require('../../assets/chev_right_white.svg')" class="ml-3" />
          </v-btn>
        </v-card-text>
      </v-card>
    </v-row>
    <EditProfileDialog :value="profile_edit_dialog" :client="user" @cancel_edit_profile="cancel_edit_profile" />
    <v-row justify="center">
      <v-dialog v-model="share_model" persistent max-width="560">
        <v-card>
          <v-card-title>

          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field filled v-model="s.firstname" dense label="First Name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field filled v-model="s.lastname" dense label="Last Name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field filled v-model="s.email" dense label="Email"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field filled v-model="s.mobile" dense label="Mobile"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="pl-10 pr-10" @click="cancel_dialog_share">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="share_profile_fn" style="textTransform: none !important;">
              Share my profile
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row><v-row justify="center">
      <v-dialog v-model="open_edit_password" persistent max-width="560">
        <v-card>
          <v-card-title>

          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_password = !show_password" :type="show_password ? 'text' : 'password'" filled
                  v-model="new_password" dense label="New Password"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field :append-icon="show_repeat_password ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_repeat_password = !show_repeat_password"
                  :type="show_repeat_password ? 'text' : 'password'" filled v-model="repeat_new_password" dense
                  label="Repeat New Password"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="open_edit_password = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="save_new_password()" style="text-transform: none !important;">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <ClientFooter />
  </v-container>
</template>

<script>
import EditProfileDialog from "./component/DialogComponent.vue";
import ClientFooter from "../components/ClientFooter.vue"
// import LastWill from "./modules/LastWill.vue";
// import FareWell from "./modules/FareWell.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    EditProfileDialog,
    ClientFooter,
    // LastWill,
    // FareWell,
  },
  props: [],
  data: () => ({
    api_url: null,
    tab: null,
    items_activity: [
      "My Last Will",
      "My Farewell Wishes",
      // 'My Documents',
      // 'My Memorial',
      // 'My Post Loss Checklist'
    ],
    profile_edit_dialog: false,
    open_edit_password: false,
    new_password: null,
    repeat_new_password: null,
    show_password: false,
    show_repeat_password: false,
    share_model: false,
    s: {
      firstname: null,
      lastname: null,
      email: null,
      mobile: null,
    }
  }),
  async mounted() {
    await this.$store.dispatch(
      "client_profile/fetch_client_funeral",
      this.user.id
    );
    await this.$store.dispatch(
      "client_profile/get_marital_status",
      this.user.marital_status_id
    );
    await this.$store.dispatch("client_profile/fetch_invited");
  },
  async created() {
    this.api_url = process.env.VUE_APP_API_URL
  },
  computed: {
    ...mapGetters({
      user: "auth/get_user",
      get_client_funeral: "client_profile/get_client_funeral",
      get_marital_status_client: "client_profile/get_marital_status_client",
      get_invited: "client_profile/get_invited",
    }),
  },
  methods: {
    profile_edit() {
      this.profile_edit_dialog = true;
    },
    async save_new_password() {
      if (this.new_password == this.repeat_new_password && this.new_password != null) {
        await this.$axios.patch("/auth/change_password", { password: this.new_password })
          .then(({ data }) => {
            if (data.response) {
              this.open_edit_password = false
              this.showSnackBar('Changed password successfully!')
            }
          })
      }
      else {
        this.showSnackBar('Please make sure your password matches')
      }
    },
    cancel_edit_profile() {
      this.profile_edit_dialog = false;
    },
    async logout_fn() {
      await this.$axios.post("/auth/logout");
      this.$store.commit("auth/set_empty_data");
      this.$router.push({ name: "/" });
    },
    showSnackBar(message) {
      this.$store.commit("auth/setMessage",
        { show: true, message: message },
        { root: 1 })
    },
    share_model_change() {
      this.share_model = true
    },
    cancel_dialog_share() {
      this.share_model = false
    },
    async share_profile_fn() {
      this.showSnackBar('Please wait...')
      await this.$axios.post('/auth/client/profile_share/search_email', {
        email: this.s.email,
        firstname: this.s.firstname,
        lastname: this.s.lastname,
        mobile: this.s.mobile
      })
        .then(({ data }) => {
          if (data.response) {
            this.showSnackBar('Invite has been sent')
            this.s.email = null
            this.s.firstname = null
            this.s.lastname = null
            this.s.mobile = null
            this.share_model = false
            this.$store.dispatch('client_activity/fetch_invited')
            this.$store.dispatch('client_profile/fetch_invited')
          }
          else {
            this.showSnackBar(data.message)
          }
        })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
#desktop_card,
#desktop_card_2,
#desktop_card_3 {
  padding-left: 50px;
  padding-right: 50px;
}
</style>
