<template>
  <div>
    <v-app-bar height="150" white color="#fff" elevation="0" app>
      <v-row>
        <v-col cols="2">
          <v-img :src="require('../assets/mfw.png')" style="cursor:pointer;" contain max-height="100" max-width="100"
            class="mx-2" @click="change_tabs(0)" />
        </v-col>
        <v-col style="text-align:center;" cols="2" v-if="user.get_user_add_info != null">
          <v-img v-if="user.get_user_add_info.get_fd_info.company_logo"
            :src="`${api_url}frontend/images/${user.get_user_add_info.get_fd_info.company_logo}`" contain max-height="100"
            max-width="100" class="mx-auto" />
          <h4>{{ user.get_user_add_info.get_fd_info.company_name }}</h4>
        </v-col>
        <v-col cols="2" v-else></v-col>
        <v-col cols="8" style="padding:0px;">
          <v-spacer />
          <v-menu offset-y v-if="isMobile()">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mb-5" text color="#347CE9"
                style="text-transform: none !important;width:50px;height:50px;float:right;" v-bind="attrs" v-on="on">
                <v-icon size="x-large">mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="user.role_id != 4" :href="`#tab-0`"
                style="text-transform: none !important; font-size: 16px;color:black;width:300px;" class="mr-8"
                @click="change_tabs(0)">
                <v-list-item-title class="d-flex">
                  <v-img :src="require('../assets/client_dashboard.svg')" contain max-width="50px" />
                  <h3 style="margin-top:15px;margin-left:10px;">Dashboard</h3>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="user.role_id != 4" :href="`#tab-1`"
                style="text-transform: none !important; font-size: 16px;color:black;width:300px;" class="mr-8"
                @click="change_tabs(1)">
                <v-list-item-title class="d-flex">
                  <v-img :src="require('../assets/client_acitivty.svg')" contain max-width="50px" />
                  <h3 style="margin-top:15px;margin-left:10px;">Activity</h3>
                </v-list-item-title>
              </v-list-item>
              <v-list-item :href="`#tab-2`" v-if="user.get_user_add_info.get_fd_info.get_fd_payment_gateway"
                style="text-transform: none !important; font-size: 16px;color:black;width:300px;" class="mr-8"
                @click="change_tabs(2)">
                <v-list-item-title class="d-flex">
                  <v-img :src="require('../assets/client_documents.svg')" contain max-width="50px" />
                  <h3 style="margin-top:15px;margin-left:10px;">Payments</h3>
                </v-list-item-title>
              </v-list-item>
              <v-list-item :href="`#tab-3`"
                style="text-transform: none !important; font-size: 16px;color:black;width:300px;" class="mr-8"
                @click="change_tabs(3)">
                <v-list-item-title class="d-flex">
                  <v-img :src="require('../assets/client_notification.svg')" contain max-width="50px" />
                  <h3 style="margin-top:15px;margin-left:10px;">Notifications</h3>
                </v-list-item-title>
              </v-list-item>
              <v-list-item :href="`#tab-4`"
                style="text-transform: none !important; font-size: 16px;color:black;width:300px;" class="mr-8"
                @click="change_tabs(4)">
                <v-list-item-title class="d-flex">
                  <v-img :src="require('../assets/client_user_profile.svg')" contain max-width="50px" />
                  <h3 style="margin-top:15px;margin-left:10px;">Account</h3>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tabs v-else :value="get_tab" right icons-and-text>
            <v-tabs-slider color="#172C54"></v-tabs-slider>
            <v-tab v-if="user.role_id != 4" :href="`#tab-0`"
              style="text-transform: none !important; font-size: 16px;color:black;" class="mr-8" @click="change_tabs(0)">
              Dashboard
              <v-img contain max-height="50" max-width="50" :src="require('../assets/client_dashboard.svg')" />
            </v-tab>
            <v-tab v-if="user.role_id != 4" :href="`#tab-1`"
              style="text-transform: none !important; font-size: 16px;color:black;" class="mr-8" @click="change_tabs(1)">
              Activity
              <v-img contain max-height="50" max-width="50" :src="require('../assets/client_acitivty.svg')" />
            </v-tab>
            <v-tab :href="`#tab-2`" style="text-transform: none !important; font-size: 16px;color:black;" class="mr-8"
              @click="change_tabs(2)">
              Payments
              <v-img contain max-height="50" max-width="50" :src="require('../assets/client_documents.svg')" />
            </v-tab>
            <v-tab :href="`#tab-3`" style="text-transform: none !important; font-size: 16px;color:black;" class="mr-8"
              @click="change_tabs(3)">
              Notifications
              <v-img contain max-height="50" max-width="50" :src="require('../assets/client_notification.svg')" />
            </v-tab>
            <v-tab :href="`#tab-4`" style="text-transform: none !important; font-size: 16px;color:black;" class="mr-8"
              @click="change_tabs(4)">
              Account
              <v-img contain max-height="50" max-width="50" :src="require('../assets/client_user_profile.svg')" />
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-tabs-items :value="get_tab">
      <v-tab-item value="tab-0">
        <ClientDashboard @change_tabs="change_tabs_from_dashboard" />
      </v-tab-item>
      <v-tab-item value="tab-1">
        <ActivityComponent />
      </v-tab-item>
      <v-tab-item value="tab-2">
        <FuneralPayment />
      </v-tab-item>
      <v-tab-item value="tab-3">
        <NotificationComponent />
      </v-tab-item>
      <v-tab-item value="tab-4">
        <UserProfileComponent />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClientDashboard from "../module_client/dashboard/ClientDashboard.vue";
import ActivityComponent from "../module_client/activity/ActivityComponent.vue";
import FuneralPayment from "../module_client/payment/FuneralPayment.vue";
import NotificationComponent from "../module_client/notification/NotificationComponent.vue";
import UserProfileComponent from "../module_client/Profile/ProfileComponent.vue";
export default {
  components: {
    ClientDashboard,
    ActivityComponent,
    FuneralPayment,
    NotificationComponent,
    UserProfileComponent,
  },
  created() {
    this.api_url = process.env.VUE_APP_API_URL
  },
  data: () => ({
    tab: null,
    api_url: null,
    user_profile: null,
    navigation: [
      {
        label: "Dashboard",
        icon: require("../assets/client_dashboard.svg"),
      },
      {
        label: "Activity",
        icon: require("../assets/client_acitivty.svg"),
      },
      {
        label: 'Payments',
        icon: require('../assets/client_share.svg'),
      },
      {
        label: 'Notifications',
        icon: require('../assets/client_notification.svg'),
      },
      {
        label: "Account",
        icon: require("../assets/client_user_profile.svg"),
      },
    ],
  }),
  computed: {
    ...mapGetters({
      user: "auth/get_user",
      get_tab: "client_dboard/get_tab",
    }),
  },
  methods: {
    async change_tabs(index) {
      window.scrollTo(0, 0)
      await this.$store.dispatch("client_dboard/set_tab", index);
    },
    async change_tabs_from_dashboard(data) {
      window.scrollTo(0, 0)
      await this.$store.dispatch("client_dboard/set_tab", data.tab_index)
      await this.$store.dispatch('client_activity/set_activity_tab', data.activity_index)
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  height: inherit;
  margin-right: 15px;
  cursor: pointer;
  margin-top: 7px;
}

::v-deep .v-tabs,
::v-deep .v-tabs .v-item-group {
  height: 90%;
}
</style>
