<template>
    <v-container fluid style="background-color: #FAFBFF !important; height: 100%">
        <v-row justify="center" align="center" class="mt-15">
            <v-card :width="isMobile() ? '95%' : '70%'" class="mt-5 pt-10 pb-10"
                :id="isMobile() ? 'mobile_card' : 'desktop_card'" elevation="2"
                style="border-radius: 16px;margin-bottom:10px;">
                <v-card-title class="text-h5">
                    <v-img :src="require('../../assets/client_documents.svg')" contain max-width="40" max-height="40"
                        class="mr-3" />
                    <strong>Your payment was successful!</strong>
                </v-card-title>
                <v-card-text>
                    <v-btn dark color="#172C54" @click="returnHome()">Return Home</v-btn>
                </v-card-text>
                <v-divider />
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [],
    data: () => ({
    }),
    async mounted() {
    },
    async created() {
    },
    computed: {
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        returnHome() {
            this.$router.push({ name: '/client-dashboard' })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
#desktop_card {
    padding-left: 50px;
    padding-right: 50px;
}
</style>
