<template>
    <v-container fluid>
        <v-card
            elevation="0"
        >
            <div
                v-if="special_child"
            >
                <v-card-title>
                    Dependents: <i class="ml-2">With Special Needs</i>
                </v-card-title>
                
                <v-card-subtitle style="word-break: break-word;">
                    <h4>This section asks if you have any dependents, regardless of age, with special needs. If you do, there may be special considerations for creating your will.</h4>
                </v-card-subtitle>
                <v-card-text
                    class="mt-15"
                    style="word-break: break-word;"
                >
                    <h2>Do you have any dependents (regardless of age, child or adult) with special needs?</h2>
                    <v-btn
                        text
                        class="pt-6 pb-6 pl-15 pr-15 mr-5 mt-5"
                        outlined
                        @click="mark_fn('Yes')"
                        :disabled="get_user.get_special_children == null ? false : true"
                    >
                        Yes
                    </v-btn>
                    <v-btn
                        text
                        class="pt-6 pb-6 pl-15 pr-15 mt-5"
                        outlined
                        @click="mark_fn('No')"
                        :disabled="get_user.get_special_children == null ? false : true"
                    >
                        No
                    </v-btn>
                    <strong
                        style="display: block;"
                        class="mt-15"
                    >Answer: 
                        <label 
                            style="text-decoration: underline;"
                            v-if="get_user.get_special_children != null"
                        >
                            {{get_user.get_special_children.name}}
                        </label>
                        <label
                            v-else
                        >
                            {{this.c.mark}}
                        </label>
                    </strong>
                </v-card-text>
                <v-card-text
                    class="mt-10 pa-5"
                    style="background-color: #DEF3FF;word-break: break-word;"
                >
                    <strong><h2>Note</h2></strong>
                    <strong>If you have a child or other dependent in your life with special needs receiving money or property through your will, it may jeopardize any government benefits that the person may receive now or in the future. You may also want to consider if establishing a guardianship is right for them.</strong>
                    <br/><br/>
                    <strong>We suggest with an attorney if you care for a dependent with special needs and/or plan on leaving any money or other assets to someone with special needs.</strong>
                </v-card-text>
                <v-card-actions
                    class="mt-15"
                >
                    <v-btn
                        text
                        @click="back_to_first"
                    >
                        <v-img
                            :src="require('../../../../../assets/chev_left_dark.svg')"
                            contain
                            max-width="35"
                            max-height="35"
                        />
                        Back
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        dark
                        class="pl-10 pr-10 pt-5 pb-5"
                        style="textTransform: none !important;"
                        @click="next_step"
                    >
                        Save and continue
                        <v-img
                            class="ml-3"
                            :src="require('../../../../../assets/chev_right_white.svg')"
                        />
                    </v-btn>
                </v-card-actions>
            </div>
            <div
                v-else-if="first"
            >
                <v-card-title>
                    Dependents: <i class="ml-2">Children</i>
                </v-card-title>
                <v-card-subtitle style="word-break: break-word;">
                    This section asks whether you have children, so that. If you do, we can help you make sure they are cared for in the event that pass away.
                </v-card-subtitle>
                <v-card-text
                    class="mt-15"
                >
                    <h2>Do you have children?</h2>
                    <v-btn
                        text
                        class="pt-6 pb-6 pl-15 pr-15 mr-5 mt-5"
                        outlined
                        @click="answer('Yes')"
                        :disabled="get_children.length > 0 ? true : false"
                    >
                        Yes
                    </v-btn>
                    <v-btn
                        text
                        class="pt-6 pb-6 pl-15 pr-15 mr-5 mt-5"
                        outlined
                        @click="answer('No')"
                        :disabled="get_children.length > 0 ? true : false"
                    >
                        No
                    </v-btn>
                </v-card-text>
                <v-card-text>
                    <v-card
                        style="width: 100%;"
                        v-for="(child, childindex) in get_children"
                        :key="childindex"
                        class="mb-10"
                    >
                        <v-card-text>
                            <strong>Child: {{childindex + 1}}</strong>
                            <v-row>
                                <v-col
                                    :cols="isMobile() ? 12 : 3"
                                >
                                    <strong>First name</strong>
                                    <v-text-field
                                        v-model="child.firstname"
                                        outlined
                                        dense
                                        disabled="true"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    :cols="isMobile() ? 12 : 3"
                                >
                                    <strong>Middle name</strong>
                                    <v-text-field
                                        v-model="child.middlename"
                                        outlined
                                        dense
                                        disabled="true"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    :cols="isMobile() ? 12 : 3"
                                >
                                    <strong>Last name</strong>
                                    <v-text-field
                                        v-model="child.lastname"
                                        outlined
                                        dense
                                        disabled="true"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    :cols="isMobile() ? 12 : 3"
                                >
                                    <strong>Suffix</strong>
                                    <v-text-field
                                        v-model="child.suffix"
                                        outlined
                                        dense
                                        disabled="true"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    :cols="isMobile() ? 12 : 4"
                                >
                                    <strong>Birthday</strong>
                                    <v-text-field
                                        v-model="child.birthday"
                                        outlined
                                        dense
                                        disabled="true"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    :cols="isMobile() ? 12 : 4"
                                >
                                    <v-switch
                                        style="position: relative; top: 10px;"
                                        v-model="child.disinherit"
                                        flat
                                        label="Disinherit Child"
                                        disabled="true"
                                    ></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-text
                    v-if="get_children.length === 0 && has_children"
                    class="mt-10"
                >
                    <h2>Children's legal name:</h2>
                    <v-row
                        v-for="(child, childindex) in children"
                        :key="childindex"
                        class="mt-8"
                    >
                        <v-col
                            :cols="isMobile() ? 12 : 3"
                        >
                            <strong>First name*</strong>
                            <v-text-field
                                v-model="child.firstname"
                                prepend-inner-icon="mdi-account"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            :cols="isMobile() ? 12 : 3"
                        >
                            <strong>Middle name*</strong>
                            <v-text-field
                                v-model="child.middlename"
                                prepend-inner-icon="mdi-account"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            :cols="isMobile() ? 12 : 3"
                        >
                            <strong>Last name*</strong>
                            <v-text-field
                                v-model="child.lastname"
                                prepend-inner-icon="mdi-account"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            :cols="isMobile() ? 12 : 3"
                        >
                            <strong>Suffix</strong>
                            <v-text-field
                                v-model="child.suffix"
                                prepend-inner-icon="mdi-account"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            :cols="isMobile() ? 12 : 4"
                        >
                            <v-menu
                                ref="child.menu"
                                v-model="child.menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="child.date"
                                        label="Birthday date"
                                        append-icon="mdi-calendar"
                                        dense
                                        outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="child.date"
                                    :active-picker.sync="child.activePicker"
                                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                    min="1950-01-01"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                            :cols="isMobile() ? 12 : 4"
                        >
                            <v-switch
                                style="position: relative; bottom: 10px;"
                                v-model="child.disinherit"
                                flat
                                label="Disinherit Child"
                            ></v-switch>
                        </v-col>
                        <v-col
                            :cols="isMobile() ? 12 : 4"
                        >
                            <v-btn
                                text
                                class="pl-10 pr-10"
                                @click="remove_child(childindex)"
                            >
                                Remove child
                                <v-img
                                    class="ml-1"
                                    :src="require('../../../../../assets/delete_dark.svg')"
                                />
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-btn
                        text
                        class="mt-10"
                        @click="add_child"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Add a child
                    </v-btn>
                </v-card-text>
                <v-card-text
                    class="mt-10 pa-5"
                    style="background-color: #DEF3FF;word-break: break-word;"
                >
                    <h2><strong>Note</strong></h2>
                    <strong>If you leave a gift to someone who is also a beneficiary of your residual estate, the gift does not count toward the percent of the residual estate that person receives.</strong>
                </v-card-text>
                <v-card-actions
                    class="mt-15"
                >
                    <v-btn
                        text
                        @click="back_to_second"
                    >
                        <v-img
                            :src="require('../../../../../assets/chev_left_dark.svg')"
                            contain
                            max-width="35"
                            max-height="35"
                        />
                        Back
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        dark
                        class="pl-10 pr-10 pt-5 pb-5"
                        style="textTransform: none !important;"
                        @click="next_step_1"
                    >
                        Save and continue
                        <v-img
                            class="ml-3"
                            :src="require('../../../../../assets/chev_right_white.svg')"
                        />
                    </v-btn>
                </v-card-actions>
            </div>
            <div
                v-else
            >
                <v-card-title>
                    Dependents: <i class="ml-2">Pets</i>
                </v-card-title>
                <v-card-subtitle style="word-break: break-word;">
                    Although pets aren't considered as dependents by the court, they are family to us! This section lets you share guidance on how to care for your pets in the event that you pass away.
                </v-card-subtitle>
                <v-card-text
                    class="mt-15"
                    style="word-break: break-word;"
                >
                    <h2>Do you have any pets?</h2>
                    <v-btn
                        text
                        class="pt-6 pb-6 pl-15 pr-15 mr-5 mt-5"
                        outlined
                        @click="has_pets(true)"
                        :disabled="get_pets.length > 0 ? true : false"
                    >
                        Yes
                    </v-btn>
                    <v-btn
                        text
                        class="pt-6 pb-6 pl-15 pr-15 mr-5 mt-5"
                        outlined
                        @click="has_pets(false)"
                        :disabled="get_pets.length > 0 ? true : false"
                    >
                        No
                    </v-btn>
                </v-card-text>
                <v-card-text
                    v-if="get_pets.length > 0"
                >
                    <strong
                        v-if="get_pets[0].has_pets"
                    >
                        Answer: Yes
                    </strong>
                    <strong
                        v-else
                    >
                        Answer: No
                    </strong>
                </v-card-text>
                <v-card-actions
                    class="mt-15"
                >
                    <v-btn
                        text
                        @click="back_to_children"
                    >
                        <v-img
                            :src="require('../../../../../assets/chev_left_dark.svg')"
                            contain
                            max-width="35"
                            max-height="35"
                        />
                        Back
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        dark
                        class="pl-10 pr-10 pt-5 pb-5"
                        style="textTransform: none !important;"
                        @click="next_step_after_pets"
                    >
                        Save and continue
                        <v-img
                            class="ml-3"
                            :src="require('../../../../../assets/chev_right_white.svg')"
                        />
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    c: {
        mark: null
    },
    special_child: true,
    has_children: false,
    children: [{
        firstname: null,
        middlename: null,
        lastname: null,
        suffix: null,
        activePicker: null,
        date: null,
        menu: false,
        disinherit: false
    }],
    first: false,
    second: false
  }),
  async mounted () {
    await this.$store.dispatch('auth/fetch_children')
    await this.$store.dispatch('auth/fetch_pets')
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_user:                           'auth/get_user',
        get_children:                       'auth/get_children',
        get_pets:                           'auth/get_pets'
    })
  },
  methods: {
    showSnackBar(message){
        this.$store.commit("auth/setMessage",
        {show: true, message: message}, 
        {root: 1})
    },
    back_to_first(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.$store.dispatch('client_activity/set_last_will_el', 1)
    },
    mark_fn(data){
        this.c.mark = data
    },
    async next_step(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        await this.$axios.post('/auth/client/client_special_child', {
            answer: this.get_user.get_special_children === null ? this.c.mark : this.get_user.get_special_children.name
        })
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('auth/set_user_info', data.data)
                // this.$store.dispatch('client_activity/set_last_will_el', 3)
                this.first = true
                this.special_child = false
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    back_to_second(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.first = false
        this.special_child = true
        this.$store.dispatch('client_activity/set_last_will_el', 2)
    },
    back_to_children(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.first = true
    },
    answer(data){
        if(data === 'Yes'){
            this.has_children = true
            return
        }
        this.first = false
    },
    add_child(){
        this.children.push(Vue.util.extend({}, this.children))
    },
    async next_step_1(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if(this.get_children.length > 0){
            this.first = false
            this.second = true
            return
        }
        let dd = []
        this.children.forEach(q => {
            dd.push({
                firstname:      q.firstname,
                middlename:     q.middlename,
                lastname:       q.lastname,
                suffix:         q.suffix,
                birthday:       q.date,
                disinherit:     q.disinherit === undefined ? false : true
            })
        })
        await this.$axios.post('/auth/client/add_children', dd)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('auth/set_children', data.data)
                this.first = false
            }
        })
    },
    remove_child(index){
        this.children.splice(index, 1)
    },
    async has_pets(data){
        await this.$axios.post('/auth/client/pets/create_pets', {
            has_pets:           data
        })
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('auth/set_pets', data.data)
            }
        })
    },
    next_step_after_pets(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.$store.dispatch('client_activity/set_last_will_el', 3)
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>