<template>
    <v-container fluid>
        <v-card
            elevation="0"
        >
            <v-card-title style="word-break: break-word;">
                Personal Representative
            </v-card-title>
            <v-card-subtitle style="word-break: break-word;">
                This section asks you to choose who you want to be responsible for overseeing your estate after you die and carrying out the wishes outlined in your will.
            </v-card-subtitle>
            <v-card-text
                class="mt-15"
                style="word-break: break-word;"
            >
                <h2><strong>Who would you like to be the personal representative of your will?</strong></h2>
            </v-card-text>
            <v-card-text
                class="mt-10"
                v-if="get_digital_persona.length === 0"
            >
                <v-row
                    v-for="(persona, personaindex) in personas"
                    :key="personaindex"
                >
                    <v-col
                        cols="12"
                    >
                        <h2><strong style="word-break: break-word;">Personal representative's legal name:</strong></h2>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>First name*</strong>
                        <v-text-field
                            v-model="persona.firstname"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Middle name</strong>
                        <v-text-field
                            v-model="persona.middlename"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Last name*</strong>
                        <v-text-field
                            v-model="persona.lastname"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Suffix</strong>
                        <v-text-field
                            v-model="persona.suffix"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <h4><strong>Relationship to you</strong></h4>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 4"
                    >
                        <v-autocomplete
                            :items="relationship"
                            item-value="name"
                            item-text="name"
                            outlined
                            dense
                            v-model="persona.relationship"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-btn
                    text
                    outlined
                    class="py-10"
                    @click="add_personal_representative"
                    :disabled="get_digital_persona.length > 0 ? true : false"
                >
                    <v-icon>mdi-plus</v-icon>
                    <h4 style="word-break: break-word;white-space:pre-wrap;width:50%;">Add a backup personal representative</h4>
                </v-btn>
            </v-card-text>
            <v-card-text
                v-else
                class="mt-10"
            >
            <v-row
                    v-for="(persona, personaindex) in get_digital_persona"
                    :key="personaindex"
                >
                    <v-col
                        cols="12"
                    >
                        <h2><strong style="word-break: break-word;">Personal representative's legal name:</strong></h2>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>First name*</strong>
                        <v-text-field
                            v-model="persona.firstname"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Middle name</strong>
                        <v-text-field
                            v-model="persona.middlename"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Last name*</strong>
                        <v-text-field
                            v-model="persona.lastname"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 3"
                    >
                        <strong>Suffix</strong>
                        <v-text-field
                            v-model="persona.suffix"
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                            disabled="true"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <h4><strong>Relationship to you</strong></h4>
                    </v-col>
                    <v-col
                        :cols="isMobile() ? 12 : 4"
                    >
                        <v-autocomplete
                            :items="relationship"
                            item-value="name"
                            item-text="name"
                            outlined
                            dense
                            v-model="persona.relationship"
                            disabled="true"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    text
                    @click="back"
                >
                    <v-img
                        :src="require('../../../../../assets/chev_left_dark.svg')"
                        contain
                        max-width="35"
                        max-height="35"
                    />
                    Back
                </v-btn>
                <v-spacer/>
                <v-btn
                    dark
                    class="pl-10 pr-10 pt-5 pb-5"
                    style="textTransform: none !important;"
                    @click="next_step"
                >
                    Save and continue
                    <v-img
                        class="ml-3"
                        :src="require('../../../../../assets/chev_right_white.svg')"
                    />
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    personas: [{
        firstname: null,
        middlename: null,
        lastname: null,
        suffix: null,
        relationship: null
    }],
    relationship: [
        {id: 1, name: 'Child'},
        {id: 2, name: 'Another Dropdown data'},
    ],
  }),
  async mounted () {
    await this.$store.dispatch('auth/fetch_digital_persona')
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_digital_persona:            'auth/get_digital_persona'
    })
  },
  methods: {
    add_personal_representative(){
        this.personas.push(Vue.util.extend({}, this.personas))
    },
    back(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.$store.dispatch('client_activity/set_last_will_el', 4)
    },
    async next_step(){
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if(this.get_digital_persona.length > 0){
            this.$store.dispatch('client_activity/set_last_will_el', 6)
            return
        }
        let tp = []
        this.personas.forEach( q => {
            tp.push({
                firstname:          q.firstname,
                middlename:         q.middlename,
                lastname:           q.lastname,
                suffix:             q.suffix,
                relationship:       q.relationship
            })
        })
        await this.$axios.post('/auth/client/personas/create_personas', tp)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('auth/set_digital_persona', data.data)
                this.$store.dispatch('client_activity/set_last_will_el', 6)
            }
        })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>