<template>
  <v-container fluid>
      <v-row
        justify="center"
        align="center"
        v-if="!get_show_survey"
      >
        <v-card
            :width="isMobile() ? '100%' : '25%'"
            :class="isMobile() ? '' : 'mt-10'"
            elevation="0"
        >
          <v-card-title
            class="justify-center"
            style="font-family: 'Montserrat', sans-serif; font-weight: 500;"
          >
            My Farewell Wishes
          </v-card-title>
          <v-card-subtitle
              class="text-center"
          >
              <strong
                style="font-family: 'Montserrat', sans-serif; font-weight: 400;"
              >Funeral Plan Checklist</strong>
          </v-card-subtitle>
          <v-card-text>
              <v-img
                :src="require('../../assets/surveydefault.jpg')"
                contain
              />
          </v-card-text>
          <v-card-actions>
            <v-btn
              style="text-transform: none !important; font-family: 'Montserrat', sans-serif;"
              dark
              block
              color="rgb(23,44,84)"
              small
              @click="set_survey_state(get_show_survey)"
            >
              Let's Get Started...
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
      <v-row
          justify="center"
          align="center"
          class="mt-15"
          v-else
      >
          <StepperComponent/>
      </v-row>  
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import StepperComponent from './stepper/StepperComponent.vue'
export default {
  components: {
      StepperComponent
  },
  props: [
  ],
  data: () => ({
      // e6: 1,
      questions: [],
  }),
  async mounted () {
    await this.get_data(this.$router.currentRoute.params.token)
    this.$store.commit('survey/set_clear_answer_r_state')
  },
  created () {
  },
  computed: {
    ...mapGetters({
      get_survey:             'survey/get_survey',
      get_show_survey:        'survey/get_show_survey',
      get_question:           'survey/get_question',
      get_el_index:           'survey/get_el_index'
    })
  },
  methods: {
      async get_data(data){
        this.$store.commit('survey/set_survey_empty')
        await this.$axios.get('/non_auth/decode_survey', {
          data: data
        })
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('survey/set_survey', data.data)
          }
        })
        this.$store.commit('survey/set_show_survey_false', false)
        this.$store.commit('survey/set_questions', 0)
      },
      set_survey_state(state){
          this.$store.dispatch('survey/set_show_survey', state)
      },
      next_step(){
          if(this.get_el_index < this.get_question.length){
              this.$store.dispatch('survey/set_el_index', this.get_el_index + 1)
          }
      },
      prev_step(){
          if(this.get_el_index > 1){
              this.$store.dispatch('survey/set_el_index', this.get_el_index - 1)
          }
      },
      isMobile() {
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              return true
          } else {
              return false
          }
      }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>