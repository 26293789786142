import Vue from 'vue'

export default{
    namespaced: true,
    state: {
        activity_tab: 'tab-0',
        last_will_el: 1,
        activity_funeral_questions: [],
        invited: []
    },
    actions: {
        set_activity_tab({commit}, payload){
            let tp = 'tab-' + payload
            commit('set_activity_tab', tp)
        },
        set_last_will_el({commit}, payload){
            commit('set_last_will_el', payload)
        },
        async get_activity_funeral_questions({commit}){
            await Vue.axios.post('/auth/client/activities/funeral/generate_template')
            .then(({data}) => {
                commit('set_activity_funeral_questions', data.data)
            })
        },
        set_activity_funeral_questions({commit}, payload){
            commit('set_activity_funeral_questions', payload)
        },
        set_active_question({commit}, payload){
            commit('set_active_question', payload)
        },
        async set_answer({commit}, payload){
            await Vue.axios.post('/auth/client/activities/funeral/answer', payload)
            .then(({data}) => {
                if(data.response){
                    commit('set_answer', payload)
                }
            })
        },
        set_dismiss_answer({commit}, payload){
            commit('set_dismiss_answer', payload)
        },
        async fetch_invited({commit}){
            await Vue.axios.get('/auth/client/profile_share/get_invited')
            .then(({data}) => {
                // console.log(commit, data)
                commit('set_invited', data.data)
            })
        }
    },
    mutations: {
        set_activity_tab(state, payload){
            state.activity_tab = payload
        },
        set_last_will_el(state, payload){
            state.last_will_el = payload
        },
        set_activity_funeral_questions(state, payload){
            payload.forEach(q => {
                q.is_active = 0
            })
            state.activity_funeral_questions = [...payload]
        },
        set_active_question(state, payload){
            const questions = state.activity_funeral_questions.find(q => {
                return q.id === payload.index
            })
            questions.is_active = !payload.state
        },
        set_answer(state, payload){
            const questions = state.activity_funeral_questions.find(q => {
                return q.id === payload.index
            })
            questions.answer = payload.answer
            questions.is_active = 0
        },
        set_dismiss_answer(state, payload){
            const question = state.activity_funeral_questions.find(q => {
                return q.id === payload.index
            })
            question.answer = null
            question.is_active = !payload.state
        },
        set_invited(state, payload){
            state.invited = [...payload]
        }
    },
    getters: {
        get_activity_tab : state => state.activity_tab,
        get_last_will_el : state => state.last_will_el,
        get_activity_funeral_questions : state => state.activity_funeral_questions,
        get_invited : state => state.invited
    }
}