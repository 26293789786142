<template>
    <v-container fluid style="background-color: #FAFBFF !important; height: 100%">
        <v-row justify="center" align="center" class="mt-15">
            <v-card :width="isMobile() ? '95%' : '70%'" class="mt-5 pt-10 pb-10"
                :id="isMobile() ? 'mobile_card' : 'desktop_card'" elevation="2"
                style="border-radius: 16px;margin-bottom:100px;">
                <v-card-title>
                    <v-img :src="require('../../assets/client_acitivty.svg')" contain max-width="50" max-height="50"
                        class="mr-3" />
                    <h2>Activity</h2>
                </v-card-title>
                <v-divider style="width: 100%; background-color:#E9F0FF;" class="mb-5"></v-divider>
                <v-card-title>
                    <v-tabs show-arrows :value="get_activity_tab" icons-and-text
                        style="display:flex;justify-content:center;">
                        <v-tabs-slider style="display:none;"></v-tabs-slider>
                        <v-tab v-for="(nav_index_activity, navindex) in navigation_activity" :key="navindex"
                            :href="`#tab-${navindex}`" style="text-transform: none !important;border-radius:16px;"
                            :class="{ active: `tab-${navindex}` == get_activity_tab }"
                            :id="isMobile() ? 'mobile' : 'desktop'" @click="change_tabs_activity(navindex)">
                            <p v-if="`tab-${navindex}` != get_activity_tab"
                                style="color:black;font-size:15px;white-space:pre-line;word-break:break-word;padding-top:20px;width:100px;height:100%;">
                                {{ nav_index_activity.label }}
                            </p>
                            <v-img v-if="`tab-${navindex}` == get_activity_tab" :src="nav_index_activity.inverted_icon"
                                height="80" width="80" contain />
                            <v-img v-else :src="nav_index_activity.icon" max-height="45" max-width="45" contain />
                        </v-tab>
                    </v-tabs>
                </v-card-title>
                <v-card-title>
                    <v-tabs-items :value="get_activity_tab" style="width: 100%;">
                        <v-tab-item value="tab-0">
                            <Farewell />
                        </v-tab-item>
                        <v-tab-item value="tab-1">
                            <ShareProfile />
                        </v-tab-item>
                        <v-tab-item value="tab-2">
                            <CostEstimate />
                        </v-tab-item>
                        <v-tab-item value="tab-3">
                            <CertificateWishes />
                        </v-tab-item>
                        <v-tab-item value="tab-4">
                            <FuneralGuide />
                        </v-tab-item>
                        <v-tab-item value="tab-5">
                            <MyDocuments />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-title>
            </v-card>
        </v-row>
        <ClientFooter />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientFooter from "../components/ClientFooter.vue"
import Farewell from './components/FarewellComponent.vue'
import FuneralGuide from './components/FuneralGuide.vue'
import CostEstimate from './components/CostEstimate.vue'
import ShareProfile from './components/ProfileSharing.vue'
import CertificateWishes from './components/CertificateWishes.vue'
import MyDocuments from './components/MyDocuments.vue'
//import LastWill from './components/LastWillComponent.vue'
/*import Document from './components/MyDocument.vue'
import Memorial from './components/MemorialComponent.vue'
import PostLost from './components/PostLostComponent.vue'*/
export default {
    components: {
        ClientFooter,
        Farewell,
        FuneralGuide,
        CostEstimate,
        ShareProfile,
        CertificateWishes,
        MyDocuments,
        //LastWill,
        //Document,
        //Memorial,
        //PostLost,
    },
    props: [
        'shortcut_tab'
    ],
    data: () => ({
        selected_tab: null,
        navigation_activity: [
            {
                label: 'My Farewell Wishes',
                icon: require('../../assets/activity_farewell_dark_2.svg'),
                inverted_icon: require('../../assets/activity_farewell_dark_2_inverted.svg')
            },
            {
                label: 'Share your Wishes',
                icon: require('../../assets/activity_profile_share_dark.svg'),
                inverted_icon: require('../../assets/activity_profile_share_dark_inverted.svg')
            },
            {
                label: 'Request a Funeral Cost Estimate',
                icon: require('../../assets/activity_documents_dark.svg'),
                inverted_icon: require('../../assets/activity_documents_dark_inverted.svg')
            },
            {
                label: 'Request a Certificate of Your Wishes',
                icon: require('../../assets/farewell_legacy.svg'),
                inverted_icon: require('../../assets/farewell_legacy_inverted.svg')
            },
            {
                label: 'Funeral Planning Guides',
                icon: require('../../assets/activity_post_loss_dark.svg'),
                inverted_icon: require('../../assets/activity_post_loss_dark_inverted.svg')
            },
            {
                label: 'My Files',
                icon: require('../../assets/activity_documents_dark.svg'),
                inverted_icon: require('../../assets/activity_documents_dark_inverted.svg')
            },
            /*{
                label: 'Funeral Notice',
                icon: require('../../assets/activity_memorial_dark.svg'),
                inverted_icon: require('../../assets/activity_memorial_dark_inverted.svg')
            },*/
            /*{
                label:          'Make My \nLast Will',
                icon:           require('../../assets/activity_will_dark.svg'),
                inverted_icon:  require('../../assets/activity_will_dark_inverted.svg')
            },*/
            /*{
                label:          'My\nDocuments',
                icon:           require('../../assets/activity_documents_dark.svg'),
                inverted_icon:  require('../../assets/activity_documents_dark_inverted.svg')
            },
            {
                label:          'Create a\nMemorial',
                icon:           require('../../assets/activity_memorial_dark.svg'),
                inverted_icon:  require('../../assets/activity_memorial_dark_inverted.svg')
            },
            {
                label:          'My Post Loss\nChecklist',
                icon:           require('../../assets/activity_post_loss_dark.svg'),
                inverted_icon:  require('../../assets/activity_post_loss_dark_inverted.svg')
            },*/
        ]
    }),
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_activity_tab: 'client_activity/get_activity_tab'
        })
    },
    methods: {
        async change_tabs_activity(index) {
            await this.$store.dispatch('client_activity/set_activity_tab', index)
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
::v-deep .v-tabs--icons-and-text>.v-tabs-bar {
    height: 140px !important;
}

.active {
    background-color: #DFE9FF;
}

#desktop_card {
    padding-left: 50px;
    padding-right: 50px;
}
</style>