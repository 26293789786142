<template>
    <v-container fluid style="background-color: #FAFBFF !important; height: 100%">
        <v-row justify="center" align="center" class="mt-15">
            <v-card :width="isMobile() ? '95%' : '70%'" class="mt-5 pt-10 pb-10"
                :id="isMobile() ? 'mobile_card' : 'desktop_card'" elevation="2"
                style="border-radius: 16px;margin-bottom:10px;">
                <v-card-title class="text-h5">
                    <v-img :src="require('../../../assets/client_documents.svg')" contain max-width="40" max-height="40"
                        class="mr-3" />
                    <strong>Funeral Payment</strong>
                </v-card-title>
                <v-divider />
                <v-tabs show-arrows :value="get_tab" style="display:flex;justify-content:center;height:100px;">
                    <v-tab :href="`#tab-0`" @click="get_tab = 'tab-0'">Present Day Funeral Cost</v-tab>
                    <v-tab :href="`#tab-1`" @click="get_tab = 'tab-1'">Pay a One-time Deposit</v-tab>
                    <v-tab :href="`#tab-2`" @click="get_tab = 'tab-2'">Spread your Payment</v-tab>
                    <v-tab :href="`#tab-3`" @click="get_tab = 'tab-3'">Payment History</v-tab>
                </v-tabs>
                <v-tabs-items :value="get_tab">

                    <!-- 
                        
                        PRESENT DAY FUNERAL COST 
                    
                    -->

                    <v-tab-item value="tab-0">
                        <div align="center" class="my-5">
                            <v-card width="75%" v-if="user.get_user_add_info.funeral_cost">
                                <v-card-title style="white-space:pre-line;word-break:break-word;">
                                    <div style="text-align:center;">Present Day Funeral Cost Estimate: €{{
                                        user.get_user_add_info.funeral_cost }}</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-btn dark color="#172C54" class="mt-5" @click="dialog_cost_estimate = true;">View
                                        Funeral Cost Details</v-btn>
                                </v-card-text>
                                <v-card-text style="text-align:left;">
                                    <h4 class="my-2">TERMS AND CONDITIONS ASSOCIATED WITH THIS QUOTATION:</h4>
                                    <p>• This cost estimate has been prepared by your selected funeral director and
                                        reflects
                                        the total funeral cost at this present day - that is the cost required to carry
                                        out
                                        the funeral at the date stated above.</p>
                                    <p>• This estimate is based on the services listed above only and is valid for one
                                        month
                                        from date of issue. It is your responsibility to check the listed services in
                                        the
                                        present day funeral cost estimate to ensure that they are sufficient.</p>
                                    <p>• Grave Purchase and after funeral catering are NOT included.</p>
                                    <p>• Verification documents must be provided before payments can be processed.</p>
                                    <p>• The payments you make are a contribution towards the cost of your funeral. It
                                        is
                                        likely that prices will increase and your estate will have to pay an additional
                                        sum
                                        at the time of your funeral.</p>
                                    <p>• This estimate should be read in conjunction with the My Farewell Wishes Terms
                                        and
                                        Conditions.</p>
                                    <b>*The €300 My Farewell Wishes Administration Fee only applies if you are making
                                        payments towards your funeral cost. If you choose to make a payment less than
                                        the
                                        Total Payment Due to My Farewell Wishes Trust, please note that the My Farewell
                                        Wishes Administration Fee will be taken from the total that you pay, and the
                                        balance
                                        will be provided towards the Total Funeral Cost.</b>
                                </v-card-text>
                            </v-card>
                            <v-card width="75%" v-else>
                                <v-card-text>
                                    Your funeral director has not given you a funeral cost yet.
                                    <br>
                                    <p class="mt-2"
                                        style="font-weight:800;font-size:18px;cursor: pointer;text-decoration:underline;"
                                        @click="requestFuneralCost()">
                                        Request a Funeral Cost Estimate
                                    </p>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-tab-item>

                    <!-- 
                        
                        PAY A ONE-TIME DEPOSIT 
                    
                    -->

                    <v-tab-item value="tab-1">
                        <div align="center" class="my-5">
                            <v-card width="75%" v-if="!user.get_user_add_info.funeral_cost">
                                <v-card-text>
                                    Your funeral director has not given you a funeral cost yet.
                                    <br>
                                    <p class="mt-2"
                                        style="font-weight:800;font-size:18px;cursor: pointer;text-decoration:underline;"
                                        @click="requestFuneralCost()">
                                        Request a Funeral Cost Estimate
                                    </p>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div align="center" class="my-5" v-if="user.get_user_add_info.funeral_cost">
                            <v-card width="75%">
                                <v-card-title>
                                    Pay funeral deposit
                                </v-card-title>
                                <v-card-text>
                                    Pay a one-time deposit for your funeral, minimum €500.
                                </v-card-text>
                                <v-card-text style="text-align:left!important;">
                                    <h4>Terms and Conditions</h4>
                                    <p>
                                        Before proceeding, please read and agree to our
                                        <a @click="terms_modal = true"><u>Terms & Conditions</u></a>.
                                    </p>
                                    <h4>Recurring Payment Admin Charge</h4>
                                    <p>
                                        Please note that all recurring payments for the Subscription Basis option will
                                        incur
                                        a 6% admin charge. This charge will be added to each monthly payment
                                        automatically
                                        after the initial deposit.
                                    </p>
                                    <h4>Declaration</h4>
                                    <p>
                                        By making this payment, I authorise My Farewell Wishes Ltd. to process my
                                        personal
                                        information and payments in accordance with the European Payment Services
                                        Directive
                                        and GDPR legislation.
                                    </p>
                                    <p>
                                        For any enquiries or assistance, please contact us at info@myfarewellwishes.ie.
                                        Thank you for choosing My Farewell Wishes Ltd. for your funeral plan. We are
                                        dedicated to providing the utmost care and support during this challenging time.
                                    </p>
                                    <p>
                                        Note: For recurring payments, the customer's bank card will be charged
                                        automatically
                                        each month based on the selected subscription amount, along with the 6% admin
                                        charge
                                        if applicable. Your card details will be securely stored and processed in
                                        compliance
                                        with GDPR regulations to ensure the privacy and security of your data.
                                    </p>
                                </v-card-text>
                                <v-card-text v-if="user.get_user_add_info.get_fd_info.get_fd_payment_gateway">
                                    <v-text-field v-model="new_deposit" type="number" min="500"
                                        label="Deposit Amount"></v-text-field>
                                    <form method="POST"
                                        action="https://payments.securetrading.net/process/payments/choice">
                                        <input type="hidden" name="sitereference"
                                            :value="user.get_user_add_info.get_fd_info.get_fd_payment_gateway.site_reference">
                                        <input type="hidden" name="stprofile" value="default">
                                        <input type="hidden" name="currencyiso3a" value="EUR">
                                        <input type="hidden" name="mainamount" :value="new_deposit">
                                        <input type="hidden" name="version" value="2">
                                        <input type="hidden" name="billingemail" :value="user.email">
                                        <input type="hidden" name="billingfirstname" :value="user.firstname">
                                        <input type="hidden" name="billinglastname" :value="user.lastname">
                                        <input type="hidden" name="billingtown" :value="user.town">
                                        <input type="hidden" name="billingcounty" :value="user.county">
                                        <input type="hidden" name="billingpostcode" :value="user.eircode">
                                        <!-- Sends email confirmation to the customer, following successful transaction -->
                                        <input type="hidden" name="ruleidentifier" value="STR-2">
                                        <!-- Sends email confirmation to the customer, following declined transaction -->
                                        <input type="hidden" name="ruleidentifier" value="STR-3">
                                        <!-- Enables rule that redirects the customer following a successful transaction -->
                                        <input type="hidden" name="ruleidentifier" value="STR-6">
                                        <!-- If this payment is being made from non auth funeral payment page -->
                                        <input v-if="this.$router.currentRoute.params.token != null" type="hidden"
                                            name="successfulurlredirect"
                                            :value="app_url + '/funeral-payment-successful/' + user.get_user_add_info.encrypted_id">
                                        <!-- If this payment is being made from authenticated client payment page -->
                                        <input v-else type="hidden" name="successfulurlredirect"
                                            :value="app_url + '/payment-successful'">
                                        <v-btn dark color="#172C54" type="submit" class="mt-5"
                                            v-if="new_deposit >= 500">Pay
                                            Deposit</v-btn>
                                        <v-btn color="#172C54" class="mt-5" v-else disabled>Pay Deposit</v-btn>
                                    </form>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-tab-item>

                    <!--
                        
                        SPREAD YOUR PAYMENT
                        
                    -->

                    <v-tab-item value="tab-2">
                        <div align="center" class="my-5">
                            <v-card width="75%" v-if="user.get_user_add_info.funeral_cost == null">
                                <v-card-text>
                                    Your funeral director has not given you a funeral cost yet.
                                    <br>
                                    <p class="mt-2"
                                        style="font-weight:800;font-size:18px;cursor: pointer;text-decoration:underline;"
                                        @click="requestFuneralCost()">
                                        Request a Funeral Cost Estimate
                                    </p>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div align="center" class="my-5"
                            v-if="(user.get_payments[0].transaction_history.responses[0].found > 0 || user.get_user_add_info.bypass_deposit) && user.get_user_add_info.funeral_cost">
                            <v-row>
                                <v-col cols="12">
                                    <v-card width="75%" id="spread-payment-top">
                                        <v-card-title style="white-space:pre-line;word-break:break-word;">
                                            <div>Contribution towards your funeral services</div>
                                        </v-card-title>
                                        <v-card-text>
                                            Pay for the remainder of your funeral cost over a period of time.
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" v-if="user.get_user_add_info.get_client_payment_term == null">
                                    <v-card width="100%" elevation="0">
                                        <v-card-text>
                                            <v-btn :disabled="type == 'interval' ? true : false" class="mx-2"
                                                :dark="type == 'interval' ? false : true" color="#172C54"
                                                @click="type = 'interval'">Choose an amount per week/month</v-btn>
                                            <v-btn :disabled="type == 'spread' ? true : false" class="mx-2"
                                                :dark="type == 'spread' ? false : true" color="#172C54"
                                                @click="type = 'spread'">Choose total amount to spread over a period of
                                                time</v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" v-else>
                                    <v-card width="100%" elevation="0">
                                        <v-card-text>
                                            <v-btn v-if="user.get_user_add_info.get_client_payment_term.started == 0"
                                                dark color="#172C54" @click="changePaymentTerm()">Change Payment
                                                Term</v-btn>
                                            <v-btn v-else disabled color="#172C54">Change Payment
                                                Term</v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <!-- CHOOSE CUSTOM AMOUNT PER MONTH -->
                            <v-row v-if="type == 'interval' && user.get_user_add_info.get_client_payment_term == null">
                                <v-col cols="12">
                                    <v-card justify="center" align="center" :width="isMobile() ? '100%' : '75%'">
                                        <v-card-title style="white-space:pre-line;word-break:break-word;">
                                            Choose an amount per week/month
                                        </v-card-title>
                                        <v-card-text>
                                            <v-select :items="['week', 'month']" v-model="interval"
                                                placeholder="Please select interval..." dense></v-select>
                                        </v-card-text>
                                        <v-card-text>
                                            <v-row v-if="interval">
                                                <v-col cols="6">
                                                    <v-text-field label="Payment Amount per week/month" type="number"
                                                        v-model="interval_amount"></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field :label="'Number of ' + interval + 's'" type="number"
                                                        v-model="interval_duration"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-btn v-if="interval_amount > 0 && interval_duration > 0" dark
                                                color="#172C54" @click="selectPaymentTermInterval()">Select</v-btn>
                                            <v-btn v-else disabled color="#172C54">Select</v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-if="type == 'interval'" justify="center" align="center">
                                <v-col cols="12" v-if="show_interval_payment_term" align="center">
                                    <v-card justify="center" align="center" :width="isMobile() ? '100%' : '75%'">
                                        <v-card-text v-if="user.get_user_add_info.get_client_payment_term == null">
                                            <v-data-table :items="interval_payment_term_breakdown" hide-default-header
                                                hide-default-footer disable-pagination>
                                                <template v-slot:item="{ item }">
                                                    <tr width="100%" class="mx-5"
                                                        style="background-color: transparent;">
                                                        <td width="75%" style="text-transform:capitalize;">
                                                            <v-layout :class="item.highlight ? 'highlight' : ''">{{
                                                                item.text }}</v-layout>
                                                        </td>
                                                        <td width="25%">
                                                            <v-layout :class="item.highlight ? 'highlight' : ''">{{
                                                                item.value }}</v-layout>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </v-data-table>
                                        </v-card-text>
                                        <v-card-text>
                                            <v-btn v-if="user.get_user_add_info.get_client_payment_term == null" dark
                                                color="#172C54" class="mt-5" @click="confirmPaymentTermInterval()">
                                                Confirm
                                            </v-btn>
                                            <div v-else>
                                                <QuotationForm @openTerms="terms_modal = true"
                                                    @openTermsPayments="terms_payments_modal = true" :user="user"
                                                    :deposit="deposit" :amount="parseFloat(interval_amount).toFixed(2)"
                                                    :duration="interval_duration" />
                                                <!--<form method="POST"
                                                    action="https://payments.securetrading.net/process/payments/choice">
                                                    <input type="hidden" name="sitereference"
                                                        :value="user.get_user_add_info.get_fd_info.get_fd_payment_gateway.site_reference">
                                                    <input type="hidden" name="stprofile" value="default">
                                                    <input type="hidden" name="currencyiso3a" value="EUR">
                                                    <input type="hidden" name="mainamount" :value="interval_amount_with_admin">
                                                    <input type="hidden" name="version" value="2">
                                                    <input type="hidden" name="billingemail" :value="user.email">
                                                    <input type="hidden" name="billingfirstname" :value="user.firstname">
                                                    <input type="hidden" name="billinglastname" :value="user.lastname">
                                                    <input type="hidden" name="billingtown" :value="user.town">
                                                    <input type="hidden" name="billingcounty" :value="user.county">
                                                    <input type="hidden" name="billingpostcode" :value="user.eircode">
                                                    <input type="hidden" name="ruleidentifier" value="STR-6">
                                                    <input type="hidden" name="successfulurlredirect"
                                                        :value="app_url + '/funeral-payment-successful' + user.get_user_add_info.encrypted_id">

                                                    <input type="hidden" name="subscriptionunit" value="MONTH">
                                                    <input type="hidden" name="subscriptionfrequency" value="1">
                                                    <input type="hidden" name="subscriptionnumber" value="1">
                                                    <input type="hidden" name="subscriptionfinalnumber"
                                                        :value="interval_duration">
                                                    <input type="hidden" name="subscriptiontype" value="INSTALLMENT">
                                                    <input type="hidden" name="credentialsonfile" value="1">

                                                    <v-btn
                                                        v-if="user.get_user_add_info.get_client_payment_term.started == 0"
                                                        dark color="#172C54" type="submit" class="mt-5">Pay Now</v-btn>
                                                </form>-->
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <!-- CHOOSE NUMBER OF MONTHS -->
                            <v-row v-if="type == 'spread' && user.get_user_add_info.get_client_payment_term == null">
                                <v-col cols="12">
                                    <v-card justify="center" align="center" :width="isMobile() ? '100%' : '75%'">
                                        <v-card-title style="white-space:pre-line;word-break:break-word;">
                                            Choose an amount to spread over a period of time
                                        </v-card-title>
                                        <v-card-text>
                                            <v-select :items="['week', 'month']" v-model="interval"
                                                placeholder="Please select interval..." dense></v-select>
                                        </v-card-text>
                                        <v-card-text>
                                            <v-row v-if="interval">
                                                <v-col cols="6">
                                                    <v-text-field label="Total Amount you would like to pay"
                                                        type="number" v-model="spread_total"></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field :label="'Number of ' + interval + 's'" type="number"
                                                        v-model="spread_duration"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-btn v-if="spread_total > 0 && spread_duration > 0" dark color="#172C54"
                                                @click="selectPaymentTermSpread()">Select</v-btn>
                                            <v-btn v-else disabled color="#172C54">Select</v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-if="type == 'spread'" justify="center" align="center">
                                <v-col cols="12" v-if="show_spread_payment_term" align="center">
                                    <v-card justify="center" align="center" :width="isMobile() ? '100%' : '75%'">
                                        <v-card-text v-if="user.get_user_add_info.get_client_payment_term == null">
                                            <v-data-table :items="spread_payment_term_breakdown" hide-default-header
                                                hide-default-footer disable-pagination>
                                                <template v-slot:item="{ item }">
                                                    <tr width="100%" class="mx-5"
                                                        style="background-color: transparent;">
                                                        <td width="75%" style="text-transform:capitalize;">
                                                            <v-layout :class="item.highlight ? 'highlight' : ''">{{
                                                                item.text }}</v-layout>
                                                        </td>
                                                        <td width="25%">
                                                            <v-layout :class="item.highlight ? 'highlight' : ''">{{
                                                                item.value }}</v-layout>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </v-data-table>
                                        </v-card-text>
                                        <v-card-text>
                                            <v-btn v-if="user.get_user_add_info.get_client_payment_term == null" dark
                                                color="#172C54" class="mt-5" @click="confirmPaymentTermSpread()">
                                                Confirm
                                            </v-btn>
                                            <div v-else>
                                                <QuotationForm @openTerms="terms_modal = true"
                                                    @openTermsPayments="terms_payments_modal = true" :user="user"
                                                    :deposit="deposit" :amount="parseFloat(spread_amount).toFixed(2)"
                                                    :duration="spread_duration" />
                                                <!--<form method="POST"
                                                    action="https://payments.securetrading.net/process/payments/choice">
                                                    <input type="hidden" name="sitereference"
                                                        :value="user.get_user_add_info.get_fd_info.get_fd_payment_gateway.site_reference">
                                                    <input type="hidden" name="stprofile" value="default">
                                                    <input type="hidden" name="currencyiso3a" value="EUR">
                                                    <input type="hidden" name="mainamount" :value="spread_amount_with_admin">
                                                    <input type="hidden" name="version" value="2">
                                                    <input type="hidden" name="billingemail" :value="user.email">
                                                    <input type="hidden" name="billingfirstname" :value="user.firstname">
                                                    <input type="hidden" name="billinglastname" :value="user.lastname">
                                                    <input type="hidden" name="billingtown" :value="user.town">
                                                    <input type="hidden" name="billingcounty" :value="user.county">
                                                    <input type="hidden" name="billingpostcode" :value="user.eircode">
                                                    <input type="hidden" name="ruleidentifier" value="STR-6">
                                                    <input type="hidden" name="successfulurlredirect"
                                                        :value="app_url + '/funeral-payment-successful' + user.get_user_add_info.encrypted_id">

                                                    <input type="hidden" name="subscriptionunit" value="MONTH">
                                                    <input type="hidden" name="subscriptionfrequency" value="1">
                                                    <input type="hidden" name="subscriptionnumber" value="1">
                                                    <input type="hidden" name="subscriptionfinalnumber"
                                                        :value="spread_duration">
                                                    <input type="hidden" name="subscriptiontype" value="INSTALLMENT">
                                                    <input type="hidden" name="credentialsonfile" value="1">

                                                    <v-btn
                                                        v-if="user.get_user_add_info.get_client_payment_term.started == 0"
                                                        dark color="#172C54" type="submit" class="mt-5">Pay Now</v-btn>
                                                </form>-->
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                        <div align="center" class="my-5" v-else>
                            <v-card width="75%">
                                <v-card-text>
                                    You must pay a one-time deposit first.
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-tab-item>

                    <!-- 
                        
                        PAYMENT HISTORY
                    
                    -->

                    <v-tab-item value="tab-3">
                        <div class="my-5">
                            <v-card>
                                <v-card-title class="text-h5">
                                    All Payments
                                </v-card-title>
                                <v-card-text>
                                    <v-btn @click="refreshTransactions(true)" text class="mb-5">Refresh</v-btn>
                                    <v-data-table :items="client_transactions.filter((x) => x.settlestatus == 100)"
                                        :headers="transaction_headers" :sort-by.sync="transaction_sort_by"
                                        :sort-desc.sync="transaction_sortDesc">
                                        <template v-slot:item="{ item }">
                                            <tr class="mx-5" style="background-color: transparent;">
                                                <td>
                                                    {{ item.settleduedate }}
                                                </td>
                                                <td>
                                                    {{ item.baseamount / 100 }}
                                                </td>
                                                <td>
                                                    {{ item.settlebaseamount / 100 }}
                                                </td>
                                                <td>
                                                    {{ item.currencyiso3a }}
                                                </td>
                                                <td>
                                                    {{ item.paymenttypedescription }}
                                                </td>
                                                <td>
                                                    {{ item.transactionreference }}
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-row>
        <DialogCostEstimate :value="dialog_cost_estimate" :client="user.get_user_add_info"
            @closeDialogCostEstimate="closeDialogCostEstimate" />
        <PrivacyTerms :terms_modal="terms_modal" :terms_payments_modal="terms_payments_modal"
            :privacy_modal="privacy_modal" @closePrivacyTermsModal="closePrivacyTermsModal" />
        <ClientFooter v-if="show_footer" />
    </v-container>
</template>

<script>
import DialogCostEstimate from "./DialogCostEstimate.vue"
import QuotationForm from "./QuotationForm.vue"
import PrivacyTerms from "../../components/PrivacyTerms.vue"
import ClientFooter from "../../components/ClientFooter.vue"
export default {
    components: {
        DialogCostEstimate, QuotationForm, PrivacyTerms, ClientFooter
    },
    props: [
        'user', 'show_footer'
    ],
    data: () => ({
        app_url: null,
        api_url: null,

        funeral_cost: 0,
        admin_charge: 0.06,
        deposit: 0,
        new_deposit: 500,
        type: null,

        interval: null,
        interval_amount: 0,
        interval_amount_with_admin: 0,
        interval_duration: 0,
        show_interval_payment_term: false,
        interval_payment_term_breakdown: null,

        spread_total: 0,
        spread_amount: 0,
        spread_amount_with_admin: 0,
        spread_duration: 0,
        show_spread_payment_term: false,
        spread_payment_term_breakdown: null,

        payments: [],
        get_tab: 'tab-0',
        transaction_headers: [
            { text: 'Settle Due Date', value: 'settleduedate' },
            { text: 'Base Amount', value: 'baseamount' },
            { text: 'Settle Amount', value: 'settlebaseamount' },
            { text: 'Currency', value: 'currencyiso3a' },
            { text: 'Payment Type', value: 'paymenttypedescription' },
            { text: 'Transaction ID', value: '"transactionreference"' },
        ],
        transaction_sort_by: 'settleduedate',
        transaction_sortDesc: true,
        client_transactions: [],
        dialog_cost_estimate: false,
        terms_modal: false,
        terms_payments_modal: false,
        privacy_modal: false,
    }),
    async mounted() {
        this.refreshTransactions(false)
        this.refreshClientPaymentTerm()
    },
    async created() {
        this.app_url = process.env.VUE_APP_URL
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        closePrivacyTermsModal() {
            this.terms_modal = false
            this.terms_payments_modal = false
            this.privacy_modal = false
        },
        closeDialogCostEstimate() {
            this.dialog_cost_estimate = false
        },
        refreshClientPaymentTerm() {
            let client_payment_term = this.user.get_user_add_info.get_client_payment_term
            if (client_payment_term != null) {
                if (client_payment_term.type == 'interval') {
                    this.type = 'interval'
                    this.interval_amount = client_payment_term.amount
                    this.interval_duration = client_payment_term.duration
                    this.selectPaymentTermInterval()
                }
                else if (client_payment_term.type == 'spread') {
                    this.type = 'spread'
                    this.spread_total = client_payment_term.total
                    this.spread_duration = client_payment_term.duration
                    this.selectPaymentTermSpread()
                }
            }
        },
        async changePaymentTerm() {
            await this.$axios.delete(`auth/client/payment/delete_client_payment_term/${this.user.get_user_add_info.get_client_payment_term.id}`)
                .then(() => {
                    this.$emit('refreshUser')
                })
        },
        async refreshTransactions(refresh) {
            let payload = {
                user_id: this.user.id,
                billingemail: this.user.email
            }

            await this.$axios.get('auth/client/payment/get_transactions', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$emit('refreshUser')
                        this.setTransactions(data.data.get_user_info.get_payments[0].transaction_history.responses[0].records, refresh)
                        console.log(data.data.get_user_info.get_payments[0].transaction_history.responses[0].records)
                    }
                })
        },
        setTransactions(transactions, refresh) {
            if (transactions != null) {
                this.client_transactions = transactions
                this.client_transactions.forEach((payment, index) => {
                    if (payment.settlestatus != '100') {
                        this.client_transactions.splice(index, 1)
                    }
                })
                this.deposit = this.client_transactions[0].baseamount / 100
            }
            if (refresh) {
                this.showSnackBar((transactions == null) ? 'No payments found' : 'Refreshed transaction history')
            }
        },
        async requestFuneralCost() {
            window.scrollTo(0, 0)
            await this.$store.dispatch("client_dboard/set_tab", 1)
            await this.$store.dispatch('client_activity/set_activity_tab', 2)
        },
        selectPaymentTermInterval() {
            this.show_interval_payment_term = true
            let admin_fee = (this.admin_charge * this.interval_amount)
            this.interval_amount_with_admin = parseFloat(this.interval_amount) + parseFloat(admin_fee.toFixed(2))
            let custom_total = this.interval_amount * this.interval_duration
            this.interval_payment_term_breakdown = [];
            this.interval_payment_term_breakdown.push(
                { text: "Total funeral cost", value: "€" + (this.user.get_user_add_info.funeral_cost - this.user.get_user_add_info.get_funeral_cost.administration_fee).toFixed(2) },
                { text: "My Farewell Wishes administration fee", value: "€" + this.user.get_user_add_info.get_funeral_cost.administration_fee.toFixed(2) },
                { text: "Total payment due to My Farewell Wishes trust", value: "€" + this.user.get_user_add_info.funeral_cost.toFixed(2) }
            );

            if (!this.user.get_user_add_info.bypass_deposit) {
                this.interval_payment_term_breakdown.push(
                    { text: "Initial deposit payment (required)", value: "€" + this.deposit.toFixed(2) },
                )
            }

            this.interval_payment_term_breakdown.push(
                { text: "Balance", value: "€" + (this.user.get_user_add_info.funeral_cost - this.deposit).toFixed(2) },
                { text: "", value: "" },
                { highlight: true, text: "Duration of " + this.interval + "ly payments chosen", value: this.interval_duration + ' ' + this.interval + 's' },
                { highlight: true, text: this.interval + "ly payment amount chosen (includes 6% administration fee)", value: "€" + parseFloat(this.interval_amount_with_admin).toFixed(2) },
                { text: "", value: "" },
                { text: "Your total funeral cost deposit contribution", value: "" },
                { text: this.interval + "ly deposit towards your funeral services", value: "€" + parseFloat(this.interval_amount).toFixed(2) },
                { text: "Deposit paid after " + this.interval_duration + ' ' + this.interval + 's', value: "€" + parseFloat(custom_total).toFixed(2) },
                //{ text: "Funeral cost deposit payments value made after " + this.interval_duration + ' months', value: "€" + parseFloat(custom_total + this.deposit - this.user.get_user_add_info.get_funeral_cost.administration_fee).toFixed(2) },
            );

            if (!this.user.get_user_add_info.bypass_deposit) {
                this.interval_payment_term_breakdown.push(
                    { text: "Initial deposit", value: "€" + (this.deposit - this.user.get_user_add_info.get_funeral_cost.administration_fee).toFixed(2) });
            }
        },
        async confirmPaymentTermInterval() {
            let admin_fee = (this.admin_charge * this.interval_amount)
            let payload = {
                client_id: this.user.get_user_add_info.id,
                amount: parseFloat(this.interval_amount).toFixed(2),
                admin_fee: admin_fee.toFixed(2),
                duration: this.interval_duration,
                total: this.interval_amount * this.interval_duration,
                type: this.type,
                interval: this.interval
            }
            await this.$axios.post('auth/client/payment/save_client_payment_term', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$emit('refreshUser')
                        setTimeout(() => {
                            this.$vuetify.goTo('#spread-payment-top', { offset: -200 });
                        }, 300)
                    }
                })
        },
        selectPaymentTermSpread() {
            this.spread_amount = this.spread_total / this.spread_duration
            this.show_spread_payment_term = true
            let admin_fee = (this.admin_charge * this.spread_amount)
            this.spread_amount_with_admin = parseFloat(this.spread_amount) + parseFloat(admin_fee.toFixed(2))
            this.spread_payment_term_breakdown = [];
            this.spread_payment_term_breakdown.push(
                { text: "Total funeral cost", value: "€" + (this.user.get_user_add_info.funeral_cost - this.user.get_user_add_info.get_funeral_cost.administration_fee).toFixed(2) },
                { text: "My Farewell Wishes administration fee", value: "€" + this.user.get_user_add_info.get_funeral_cost.administration_fee.toFixed(2) },
                { text: "Total payment due to My Farewell Wishes trust", value: "€" + this.user.get_user_add_info.funeral_cost.toFixed(2) },
            );

            if (!this.user.get_user_add_info.bypass_deposit) {
                this.spread_payment_term_breakdown.push(
                    { text: "Initial deposit dayment (required)", value: "€" + this.deposit.toFixed(2) },
                );
            }

            this.spread_payment_term_breakdown.push(
                { text: "Balance", value: "€" + (this.user.get_user_add_info.funeral_cost - this.deposit).toFixed(2) },
                { text: "", value: "" },
                { highlight: true, text: "Total amount you would like to pay", value: "€" + parseFloat(this.spread_total).toFixed(2) },
                { highlight: true, text: "Duration of " + this.interval + "ly payments chosen", value: this.spread_duration + ' ' + this.interval + 's' },
                { highlight: true, text: "Payment each " + this.interval + " (includes 6% administration fee)", value: "€" + parseFloat(this.spread_amount_with_admin).toFixed(2) },
                { text: "", value: "" },
                { text: "Your total funeral cost deposit contribution", value: "" },
                { text: this.interval + "ly deposit towards your funeral services", value: "€" + parseFloat(this.spread_amount).toFixed(2) },
                { text: "Deposit paid after " + this.spread_duration + ' ' + this.interval + 's', value: "€" + (parseFloat(this.spread_amount) * parseFloat(this.spread_duration)).toFixed(2) },
            );

            if (!this.user.get_user_add_info.bypass_deposit) {
                this.spread_payment_term_breakdown.push(
                    { text: "Initial deposit", value: "€" + (this.deposit - this.user.get_user_add_info.get_funeral_cost.administration_fee).toFixed(2) },
                );
            }
            //{ text: "Funeral cost deposit payments value made after " + this.spread_duration + ' months', value: "€" + (parseFloat(this.spread_amount) * parseFloat(this.spread_duration) + this.deposit - this.user.get_user_add_info.get_funeral_cost.administration_fee).toFixed(2) },
        },
        async confirmPaymentTermSpread() {
            let admin_fee = (this.admin_charge * this.spread_amount)
            let payload = {
                client_id: this.user.get_user_add_info.id,
                amount: parseFloat(this.spread_amount).toFixed(2),
                admin_fee: admin_fee.toFixed(2),
                duration: this.spread_duration,
                total: this.spread_total,
                type: this.type,
                interval: this.interval
            }
            await this.$axios.post('auth/client/payment/save_client_payment_term', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$emit('refreshUser')
                        setTimeout(() => {
                            this.$vuetify.goTo('#spread-payment-top', { offset: -200 });
                        }, 300)
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
#desktop_card {
    padding-left: 50px;
    padding-right: 50px;
}

.highlight {
    font-size: 16px;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}
</style>
