<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <strong style="font-size:26px;">Documents</strong>
                    <v-spacer />
                </v-card-title>
                <v-card-subtitle class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
                    Upload documents for your clients to view when they login. You can also choose a document that will be
                    sent to a new client when they complete your survey.
                </v-card-subtitle>
            </v-card>
            {{ active_document }}
            <v-card width="90%" style="border-radius: 16px" class="mb-10" elevation="0">
                <v-card-text class="mt-5">
                    <v-data-table :sort-by.sync="sort_by" :sort-desc.sync="sortDesc"
                        :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="document_headers"
                        :items="get_documents" hide-default-header :items-per-page="5">
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr v-if="!isMobile()">
                                    <th style="padding-bottom:30px;padding-top:10px" v-for="h in headers" :key="h.index">
                                        <div style="text-align:center;" v-if="h.text">
                                            <strong>{{ h.text }}</strong>
                                        </div>
                                    </th>
                                    <th style="padding-bottom:30px;padding-top:10px">
                                        <div style="text-align:end;">
                                            <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                                style="height:40px;text-transform: none !important"
                                                @click="add_document_dialog">
                                                Add document <v-icon class="ml-2">mdi-plus</v-icon>
                                            </v-btn>
                                        </div>
                                    </th>
                                </tr>
                                <tr v-else>
                                    <th style="padding-bottom:30px;padding-top:10px">
                                        <div style="text-align:end;">
                                            <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                                style="height:40px;text-transform: none !important"
                                                @click="add_document_dialog">
                                                Add document <v-icon class="ml-2">mdi-plus</v-icon>
                                            </v-btn>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr v-if="!isMobile()" class="mx-5" style="background-color: transparent;">
                                <td style="width:20%;">
                                    <v-layout>
                                        {{ item.document_name }}
                                    </v-layout>
                                </td>
                                <td style="width:50%;">
                                    <v-layout v-if="item.description" class="py-5">
                                        {{ item.description }}
                                    </v-layout>
                                    <v-layout v-else justify-center>
                                        N/A
                                    </v-layout>
                                </td>
                                <!--<td>
                                    <v-layout justify-center>
                                        {{ $date(item.created_at).format("DD MMM YYYY | hh:mm a") }}
                                    </v-layout>
                                </td>-->
                                <td>
                                    <v-layout justify-center>
                                        <v-btn class="primary" v-if="!item.is_active" @click="set_active(item)">
                                            Select
                                        </v-btn>
                                        <v-btn v-else disabled>
                                            Selected
                                        </v-btn>
                                    </v-layout>
                                </td>
                                <td>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn style="float:right;" text color="#347CE9" v-bind="attrs" v-on="on">
                                                Options
                                            </v-btn>
                                        </template>
                                        <v-list style="padding-top:0px;padding-bottom:0px;width:150px;">
                                            <v-list-item style="cursor: pointer" @click="edit_document_dialog(item)">
                                                <span style="text-transform: none !important;float:right;color:#347CE9">
                                                    Edit
                                                </span>
                                            </v-list-item>
                                            <v-list-item style="cursor: pointer" @click="view_document(item)">
                                                <span style="text-transform: none !important;float:right;color:#347CE9">
                                                    Download
                                                </span>
                                            </v-list-item>
                                            <v-list-item style="cursor: pointer" @click="delete_document(item)">
                                                <span style="text-transform: none !important;float:right;color:red;">
                                                    Delete
                                                </span>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                            <tr v-else class="mx-5" style="background-color: transparent;width:100%;">
                                <td class="py-5" style="width:500px;">
                                    <v-row>
                                        <v-col cols="4">
                                            {{ document_headers[0].text }}
                                        </v-col>
                                        <v-col cols="6">
                                            <label>
                                                {{ item.document_name }}
                                            </label>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn style="float:right;margin-top:-8px;" text color="#347CE9"
                                                        v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list style="padding-top:0px;padding-bottom:0px;width:150px;">
                                                    <v-list-item style="cursor: pointer"
                                                        @click="edit_document_dialog(item)">
                                                        <span
                                                            style="text-transform: none !important;float:right;color:#347CE9">
                                                            Edit
                                                        </span>
                                                    </v-list-item>
                                                    <v-list-item style="cursor: pointer" @click="view_document(item)">
                                                        <span
                                                            style="text-transform: none !important;float:right;color:#347CE9">
                                                            Download
                                                        </span>
                                                    </v-list-item>
                                                    <v-list-item style="cursor: pointer" @click="delete_document(item)">
                                                        <span
                                                            style="text-transform: none !important;float:right;color:red;">
                                                            Delete
                                                        </span>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            {{ document_headers[1].text }}
                                        </v-col>
                                        <v-col cols="8">
                                            <label class="py-5">
                                                {{ item.description }}
                                            </label>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            {{ document_headers[2].text }}
                                        </v-col>
                                        <v-col cols="8">
                                            <v-btn class="primary" v-if="!item.is_active" @click="set_active(item)">
                                                Select
                                            </v-btn>
                                            <v-btn v-else disabled>
                                                Selected
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialog_add_document" persistent max-width="560">
                <v-card>
                    <v-card-title class="text-h5">
                        Add document
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-file-input filled v-model="document_to_upload" dense label="Upload">
                        </v-file-input>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="cancel_add_document_dialog">
                            Cancel
                        </v-btn>
                        <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="upload_document">
                            <v-icon class="mr-2">mdi-plus</v-icon>
                            Add document
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialog_edit_document" persistent max-width="560">
                <v-card v-if="to_edit != null">
                    <v-card-title class="text-h5">
                        Edit document
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-text-field v-model="to_edit.document_name" dense filled
                            placeholder="Document Name"></v-text-field>
                        <v-textarea v-model="to_edit.description" dense filled placeholder="Description"></v-textarea>
                        <v-checkbox v-model="to_edit.is_checklist" label="Checklist PDF to include in email series" />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="cancel_edit_document_dialog">
                            Cancel
                        </v-btn>
                        <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="edit_document">
                            <v-icon class="mr-2">mdi-pen</v-icon>
                            Edit document
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
    },
    props: [],
    data: () => ({
        dialog_add_document: false,
        dialog_edit_document: false,
        to_edit: {
            id: null,
            document_name: null,
            description: null
        },
        document_to_upload: null,
        active_document: null,
        sort_by: 'created_at',
        sortDesc: true,
        app_url: null,
        document_headers: [
            { text: "Document" },
            { text: "Description" },
            //{ text: "Upload date" },
            { text: "Include in survey email" },
        ],
    }),
    async mounted() {
        await this.$store.dispatch("fd_documents/get_documents");
        this.set_url()
    },
    created() { },
    computed: {
        ...mapGetters({
            get_documents: "fd_documents/get_documents",
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        set_url() {
            this.app_url = process.env.VUE_APP_API_URL
        },
        view_document(item) {
            window.open(this.app_url + 'frontend/documents/' + item.file_name + '.' + item.extension, '_blank')
        },
        async upload_document() {
            const formData = new FormData()
            formData.append('document', this.document_to_upload)
            await this.$axios.post('auth/fd_documents/upload_document', formData)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.cancel_add_document_dialog()
                        this.document_to_upload = null
                        this.$store.commit("fd_documents/set_documents", data.data)
                    }
                })
        },
        async edit_document() {
            let payload = {
                id: this.to_edit.id,
                document_name: this.to_edit.document_name,
                description: this.to_edit.description,
                is_checklist: this.to_edit.is_checklist,
            }
            await this.$axios.patch('auth/fd_documents/edit_document', payload)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.cancel_edit_document_dialog()
                        this.$store.commit("fd_documents/set_documents", data.data)
                    }
                })
        },
        async set_active(item) {
            await this.$axios.patch('auth/fd_documents/set_active_document', { id: item.id })
                .then(({ data }) => {
                    this.$store.commit("fd_documents/set_documents", data.data)
                })
        },
        async delete_document(item) {
            await this.$axios.delete(`auth/fd_documents/delete_document/${item.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch("fd_documents/get_documents")
                        this.showSnackBar(data.message)
                    }
                })
        },
        add_document_dialog() {
            this.dialog_add_document = true
        },
        cancel_add_document_dialog() {
            this.dialog_add_document = false
        },
        edit_document_dialog(item) {
            this.dialog_edit_document = true
            this.to_edit.id = item.id
            this.to_edit.document_name = item.document_name
            this.to_edit.description = item.description
        },
        cancel_edit_document_dialog() {
            this.dialog_edit_document = false
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
    width: 450px;
}

table {
    border-collapse: collapse;
}

table td,
table th {
    height: 40px;
}

.mobile_table td {
    height: 100px !important;
}

.desktop_table td {
    height: 100px !important;
}

.mobile_card {
    margin-top: 100px;
}

.desktop_table {
    padding-left: 30px;
    padding-right: 30px;
}

.mobile_sort {
    margin-top: -40px;
}

.v-data-table.row-height-50 td {
    height: 100px !important;
}

.v-data-table::v-deep th {
    font-size: 16px !important;
    font-weight: bold;
}</style>
