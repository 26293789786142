<template>
    <v-container fluid>
        <h3 style="font-size:25px;color:#172C54" class="mb-8">My Documents</h3>
        <v-card elevation="0" style="border:solid 2px #F3F3F3;border-radius:10px;" class="mb-3 mt-8">
            <v-card-subtitle style="font-size:18px;" class="mb-5">
                Upload and store your key documents, including files, photos and your will.
            </v-card-subtitle>
            <v-card-text>
                <v-data-table :sort-by.sync="sort_by" :sort-desc.sync="sortDesc"
                    :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="document_headers" hide-default-header
                    :items="user.get_user_add_info.get_files" :items-per-page="10">
                    <template v-slot:header="{ props: { headers } }">
                        <thead>
                            <tr v-if="!isMobile()">
                                <th style="padding-bottom:30px;padding-top:10px;width:25%;" v-for="h in headers"
                                    :key="h.index">
                                    <div style="text-align:center;" v-if="h.text">
                                        <strong>{{ h.text }}</strong>
                                    </div>
                                </th>
                                <th style="padding-bottom:30px;padding-top:10px;width:25%;">
                                    <div style="text-align:end;">
                                        <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                            style="height:40px;text-transform: none !important"
                                            @click="dialog_add_document = true">
                                            Add document <v-icon class="ml-2">mdi-plus</v-icon>
                                        </v-btn>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr v-if="!isMobile()" class="mx-5" style="background-color: transparent;">
                            <td>
                                <v-layout justify-center>
                                    <v-icon v-if="item.extension == 'doc' || item.extension == 'docx' ||
                                        item.extension == 'pdf' || item.extension == 'epub'"
                                        size="75px" color="#172C54">mdi-file</v-icon>
                                    <v-icon v-if="item.extension == 'jpg' || item.extension == 'jpeg' ||
                                        item.extension == 'png' || item.extension == 'svg'"
                                        size="75px" color="#172C54">mdi-file-image</v-icon>
                                </v-layout>
                                <h3 style="text-align:center;">{{ item.extension }}</h3>
                            </td>
                            <td>
                                <v-layout style="white-space:pre-line;word-break: break-word;">
                                    {{ item.original_name }}
                                </v-layout>
                            </td>
                            <td style="width:50%;">
                                <v-layout v-if="item.description" class="py-5"
                                    style="white-space:pre-line;word-break: break-word;">
                                    {{ item.description }}
                                </v-layout>
                                <v-layout v-else justify-center>
                                    N/A
                                </v-layout>
                            </td>
                            <td style="width:15%;">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn style="float:right;" text color="#347CE9" v-bind="attrs" v-on="on">
                                            Options
                                        </v-btn>
                                    </template>
                                    <v-list style="padding-top:0px;padding-bottom:0px;width:150px;">
                                        <v-list-item style="cursor: pointer" @click="edit_document_dialog(item)">
                                            <span style="text-transform: none !important;float:right;color:#347CE9">
                                                Edit
                                            </span>
                                        </v-list-item>
                                        <v-list-item style="cursor: pointer" @click="view_document(item)">
                                            <span style="text-transform: none !important;float:right;color:#347CE9">
                                                View
                                            </span>
                                        </v-list-item>
                                        <v-list-item style="cursor: pointer" @click="delete_document(item)">
                                            <span style="text-transform: none !important;float:right;color:red;">
                                                Delete
                                            </span>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                        <tr v-else class="mx-5" style="background-color: transparent;width:100%;">
                            <td class="py-5" style="width:500px;">
                                <v-row>
                                    <v-col cols="12">
                                        <v-layout justify-center>
                                            <v-icon v-if="item.extension == 'doc' || item.extension == 'docx' ||
                                                item.extension == 'pdf' || item.extension == 'epub'"
                                                size="75px" color="#172C54">mdi-file</v-icon>
                                            <v-icon v-if="item.extension == 'jpg' || item.extension == 'jpeg' ||
                                                item.extension == 'png' || item.extension == 'svg'"
                                                size="75px" color="#172C54">mdi-file-image</v-icon>
                                        </v-layout>
                                        <h3 style="text-align:center;">{{ item.extension }}</h3>
                                    </v-col>
                                    <v-col cols="12" style="white-space:pre-line;word-break: break-word;">
                                        <b>{{ document_headers[1].text }}</b>
                                        <br>{{ item.original_name }}
                                    </v-col>
                                    <v-col cols="12">
                                        <label style="white-space:pre-line;word-break: break-word;">
                                        </label>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" style="white-space:pre-line;word-break: break-word;">
                                        <b>{{ document_headers[2].text }}</b>
                                        <br>
                                        <label v-if="item.description" class="py-5"
                                            style="white-space:pre-line;word-break: break-word;">
                                            {{ item.description }}
                                        </label>
                                        <label v-else justify-center>
                                            N/A
                                        </label>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn style="float:right;" text color="#347CE9" v-bind="attrs" v-on="on">
                                                    Options
                                                </v-btn>
                                            </template>
                                            <v-list style="padding-top:0px;padding-bottom:0px;width:150px;">
                                                <v-list-item style="cursor: pointer" @click="edit_document_dialog(item)">
                                                    <span style="text-transform: none !important;float:right;color:#347CE9">
                                                        Edit
                                                    </span>
                                                </v-list-item>
                                                <v-list-item style="cursor: pointer" @click="view_document(item)">
                                                    <span style="text-transform: none !important;float:right;color:#347CE9">
                                                        View
                                                    </span>
                                                </v-list-item>
                                                <v-list-item style="cursor: pointer" @click="delete_document(item)">
                                                    <span style="text-transform: none !important;float:right;color:red;">
                                                        Delete
                                                    </span>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-row justify="center">
            <v-dialog v-model="dialog_add_document" persistent max-width="560">
                <v-card>
                    <v-card-title class="text-h5">
                        Add documents and photos
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-file-input filled v-model="document_to_upload" dense label="Upload">
                        </v-file-input>
                        <v-textarea filled v-model="document_description" dense label="Description"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialog_add_document = false">
                            Cancel
                        </v-btn>
                        <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="upload_document()">
                            <v-icon class="mr-2">mdi-plus</v-icon>
                            Add document
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialog_edit_document" persistent max-width="560">
                <v-card v-if="to_edit != null">
                    <v-card-title class="text-h5">
                        Edit document
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-text-field v-model="to_edit.original_name" dense filled
                            placeholder="Document Name"></v-text-field>
                        <v-textarea v-model="to_edit.description" dense filled placeholder="Description"></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="cancel_edit_document_dialog">
                            Cancel
                        </v-btn>
                        <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="edit_document">
                            <v-icon class="mr-2">mdi-pen</v-icon>
                            Edit document
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    mounted() {
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        ...mapGetters({
            user: "auth/get_user"
        })
    },
    data: () => ({
        sort_by: 'created_at',
        sortDesc: true,
        api_url: null,
        document_headers: [
            { text: '', sortable: false },
            { text: "Title", sortable: false },
            { text: "Description", sortable: false },
        ],
        dialog_add_document: false,
        document_to_upload: null,
        document_description: '',
        dialog_edit_document: false,
        to_edit: {
            id: null,
            original_name: null,
            description: null
        },
    }),
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        view_document(item) {
            window.open(this.api_url + 'frontend/client_documents/' + item.file_name + '.' + item.extension, '_blank')
        },
        async upload_document() {
            const formData = new FormData()
            formData.append('client_id', this.user.get_user_add_info.id)
            formData.append('document', this.document_to_upload)
            formData.append('description', this.document_description)
            await this.$axios.post('auth/client/files/upload_document', formData)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.dialog_add_document = false
                        this.document_to_upload = null
                        this.document_description = ''
                        this.$store.dispatch('auth/fetch_user')
                    }
                })
        },
        edit_document_dialog(item) {
            this.dialog_edit_document = true
            this.to_edit.id = item.id
            this.to_edit.original_name = item.original_name
            this.to_edit.description = item.description
        },
        cancel_edit_document_dialog() {
            this.dialog_edit_document = false
        },
        async edit_document() {
            let payload = {
                id: this.to_edit.id,
                original_name: this.to_edit.original_name,
                description: this.to_edit.description
            }
            await this.$axios.patch('auth/client/files/edit_document', payload)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.cancel_edit_document_dialog()
                        this.$store.dispatch('auth/fetch_user')
                    }
                })
        },
        async delete_document(item) {
            await this.$axios.delete(`auth/client/files/delete_document/${item.id}`)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.$store.dispatch('auth/fetch_user')
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.liitme {
    width: 450px;
}

table {
    border-collapse: collapse;
}

table td,
table th {
    height: 40px;
}

.mobile_table td {
    height: 100px !important;
}

.desktop_table td {
    height: 100px !important;
}

.mobile_card {
    margin-top: 100px;
}

.desktop_table {
    padding-left: 30px;
    padding-right: 30px;
}

.mobile_sort {
    margin-top: -40px;
}

.v-data-table.row-height-50 td {
    height: 100px !important;
}

.v-data-table::v-deep th {
    font-size: 16px !important;
    font-weight: bold;
}
</style>