import SurveyComponent from '../SurveyComponent.vue'

export default [
    {
        path: '/fd_survey_list',
        name: '/fd_survey_list',
        component: SurveyComponent,
        meta: {
            layout: 'funeral-director-layout'
        }
    }
]