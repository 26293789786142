import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        visitors: []
    },
    actions: {
        async fetch_visitors({ commit }) {
            await Vue.axios.get('/auth/admin/get_visitors')
                .then(({ data }) => {
                    commit('set_visitors', data.data)
                })
        },
    },
    mutations: {
        set_visitors(state, payload) {
            state.visitors = [...payload]
        },
    },
    getters: {
        get_visitors: state => state.visitors,
    }
}