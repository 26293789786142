import FuneralDirector from '../FuneralDirectorDashboard.vue'

export default [
    {
        path: '/funeral-director',
        name: '/funeral-director',
        component: FuneralDirector,
        meta: {
            layout: 'funeral-director-layout'
        }
    }
]