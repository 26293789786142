import { render, staticRenderFns } from "./FuneralDirectorDocuments.vue?vue&type=template&id=3a9543b9&scoped=true&"
import script from "./FuneralDirectorDocuments.vue?vue&type=script&lang=js&"
export * from "./FuneralDirectorDocuments.vue?vue&type=script&lang=js&"
import style0 from "./FuneralDirectorDocuments.vue?vue&type=style&index=0&id=3a9543b9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a9543b9",
  null
  
)

export default component.exports