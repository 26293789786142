<template>
    <v-row justify="center">
        <v-dialog :value="value" persistent max-width="650" scrollable>
            <v-card>
                <v-card-title class="text-label">
                    Edit Profile
                </v-card-title>
                <v-card-text class="mt-5" style="height: 500px;">
                    <v-row>
                        <v-col cols="12" v-if="user.get_user_add_info">
                            <v-list-item two-line>
                                <v-avatar v-if="user.get_user_add_info && user.get_user_add_info.profile_picture === null"
                                    color="#4374F3" size="90" class="mb-5"
                                    style="position: relative; right: 18px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                    <strong style="color:white;font-size:22px;">{{ user.name[0] }}</strong>
                                </v-avatar>
                                <v-avatar v-else-if="user.get_user_add_info" size="90" class="mb-5">
                                    <v-img contain :src="`${api_url}${user.get_user_add_info.profile_picture}`" />
                                </v-avatar>
                                <v-list-item-content>
                                    <v-list-item-title style="font-size: 16;" class="ml-3">
                                        Update Image
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="ml-3">
                                        <v-file-input @change="edit_profile_picture" chips v-model="e.image" dense
                                            label="Choose image"></v-file-input>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col :cols="isMobile() ? 12 : 6">
                            <v-text-field label="First Name *" outlined dense v-model="e.firstname"
                                prepend-inner-icon="mdi-account">
                            </v-text-field>
                            <v-text-field label="Last Name *" outlined dense v-model="e.lastname"
                                prepend-inner-icon="mdi-account">
                            </v-text-field>
                            <v-text-field label="Mobile" outlined dense v-model="e.mobile"
                                prepend-inner-icon="mdi-cellphone" type="number">
                            </v-text-field>
                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                                offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateFormatted" label="Date of birth"
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined
                                        dense></v-text-field>
                                </template>
                                <v-date-picker v-model="e.birthday" :active-picker.sync="activePicker"
                                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                    min="1900-01-01" @change="change_birthday"></v-date-picker>
                            </v-menu>
                            <v-autocomplete v-model="e.gender_id" :items="get_gender" outlined dense item-value="id"
                                item-text="name" prepend-inner-icon="mdi-account" label="Gender">
                            </v-autocomplete>
                        </v-col>
                        <v-col :cols="isMobile() ? 12 : 6">
                            <v-text-field label="Email Address*" outlined dense v-model="e.email"
                                prepend-inner-icon="mdi-account">
                            </v-text-field>
                            <v-text-field label="Address *" outlined dense v-model="e.address"
                                prepend-inner-icon="mdi-map-marker">
                            </v-text-field>
                            <!--<v-text-field label="Country *" outlined dense v-model="e.country"
                                prepend-inner-icon="mdi-map-marker">
                            </v-text-field>-->
                            <v-text-field label="County *" outlined dense v-model="e.county"
                                prepend-inner-icon="mdi-map-marker">
                            </v-text-field>
                            <v-text-field label="Town *" outlined dense v-model="e.town"
                                prepend-inner-icon="mdi-map-marker">
                            </v-text-field>
                            <v-text-field outlined dense v-model="e.eircode" label="Eircode | Zipcode"
                                prepend-inner-icon="mdi-map-marker">

                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="cancel_edit_profile">
                        Cancel
                    </v-btn>
                    <v-btn dark class="pl-15 pr-15" @click="save_profile">
                        <v-icon class="mr-3">
                            mdi-content-save-outline
                        </v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'value',
        'client',
    ],
    data: () => ({
        token: null,
        e: {
            id: null,
            firstname: null,
            lastname: null,
            gender_id: null,
            email: null,
            mobile: null,
            address: null,
            country: null,
            county: null,
            town: null,
            eircode: null,
            birthday: null,
            age: null,
            image: null
        },
        activePicker: null,
        menu: false,
        api_url: null,
        dateFormatted: null,
    }),
    async mounted() {
        this.e = {
            id: this.client.id,
            firstname: this.client.firstname,
            lastname: this.client.lastname,
            email: this.client.email,
            mobile: this.client.mobile,
            address: this.client.address,
            country: this.client.country,
            county: this.client.county,
            town: this.client.town,
            eircode: this.client.eircode,
            birthday: this.client.birthday,
            age: this.client.age,
            gender_id: this.client.gender_id,
        },
        this.dateFormatted = (this.client.birthday != null) ? this.$date(this.client.birthday).format("DD MMMM, YYYY") : null
        await this.$store.dispatch('client_profile/fetch_gender')
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        ...mapGetters({
            user: 'auth/get_user',
            get_gender: 'client_profile/get_gender'
        })
    },
    methods: {
        cancel_edit_profile() {
            this.$emit('cancel_edit_profile')
        },
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        change_birthday() {
            this.menu = false
            this.dateFormatted = this.$date(this.e.birthday).format("DD MMMM, YYYY")
            this.e.age = this.getAge(this.dateFormatted)
        },
        getAge(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        async edit_profile_picture() {
            const formData = new FormData()
            formData.append('file', this.e.image)
            formData.append('profile_picture', true)
            formData.append('user_id', this.user.id)
            await this.$axios.post('/auth/client/update_information', formData)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('auth/set_user_info', data.data)
                    }
                })
        },
        async save_profile() {
            let payload = {
                profile_picture: false,
                user_id: this.user.id,
                firstname: this.e.firstname,
                lastname: this.e.lastname,
                gender_id: this.e.gender_id,
                email: this.e.email,
                mobile: this.e.mobile,
                address: this.e.address,
                country: this.e.country,
                county: this.e.county,
                town: this.e.town,
                eircode: this.e.eircode,
                birthday: this.e.birthday,
                age: this.e.age,
            }
            await this.$axios.post('/auth/client/update_information', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('auth/set_user_info', data.data)
                        this.cancel_edit_profile()
                    }
                    this.showSnackBar(data.message)
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    }
}
</script>

<style scoped lang="scss"></style>