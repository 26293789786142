<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <v-img :src="require('../../assets/fd_clients.svg')" class="mr-3" contain max-width="35"
                        max-height="35" />
                    <strong style="font-size:26px;">Funeral Notice Default Template</strong>
                    <v-spacer />
                </v-card-title>
                <v-card-title class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
                    <v-spacer />
                    <v-row>
                    </v-row>
                </v-card-title>
            </v-card>
            <v-card width="90%" style="border-radius: 16px" elevation="0"
                :class="isMobile() ? 'mobile_card' : 'desktop_card'">
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col cols="12">
                            <v-btn dark color="#172C54" class="pl-5 pt-2 pb-2 mr-3"
                                style="float:right;height:40px;text-transform: none !important" @click="save_default_template">
                                Save changes
                            </v-btn>
                        </v-col>
                        <v-col :cols="isMobile() ? 12 : 9">
                            <v-textarea filled v-model="get_default_template.template" rows="10" dense>
                            </v-textarea>
                        </v-col>
                        <v-col :cols="isMobile() ? 12 : 3"
                            style="height:300px;overflow-y:scroll;padding-top:0px!important;padding-bottom:0px!important;margin-top:12px;margin-bottom:12px;">
                            Details of the Deceased
                            <v-text-field class="placeholder" dense v-for="contact in placeholders_contact"
                                :key="contact.index" readonly @focus="$event.target.select()"
                                :value="contact"></v-text-field>
                            <br>
                            Family of the Deceased
                            <v-text-field class="placeholder" dense v-for="family in placeholders_family"
                                :key="family.index" readonly @focus="$event.target.select()" :value="family"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
    },
    props: [],
    data: () => ({
        sort_by: "created_at",
        templates: [],
        sortDesc: true,
        filter_by: null,
        placeholders_contact: [
            "{{name}}", "{{address}}", "{{town}}", "{{county}}", "{{country}}", "{{eircode}}", "{{pronoun}}",
            "{{date_of_death}}", "{{circumstance_of_death}}", "{{place_of_death}}",
        ],
        placeholders_family: [
            "{{family.predeceased_by}}", "{{family.significant_other_type}}", "{{family.significant_other}}", "{{family.father}}", "{{family.mother}}",
            "{{family.brothers}}", "{{family.sisters}}", "{{family.sons}}", "{{family.daughters}}", "{{family.sons_in_law}}", "{{family.daughters_in_law}}",
            "{{family.grandchildren}}", "{{family.greatgrandchildren}}", "{{family.other_people}}",
        ],
    }),
    async mounted() {
        await this.$store.dispatch("admin_funeral_notice/fetch_default_template")
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_default_template: "admin_funeral_notice/get_default_template",
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                "auth/setMessage",
                { show: true, message: message },
                { root: 1 }
            );
        },
        async save_default_template() {
            let default_template = this.get_default_template
            let payload = {
                id: default_template.id,
                name: default_template.name,
                template: default_template.template
            }
            await this.$axios.patch('/auth/admin/funeral_notice/edit_template', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.showSnackBar("Edited successfully")
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
    border-radius: 5px;
    margin-top: -2px;
}

::v-deep .liitme .v-input__slot {
    background-color: white;
    padding: 7px;
}

table {
    border-collapse: collapse;
}

// remove border
table td,
table th {
    height: 40px; //change the height
}

.mobile_table td {
    height: 100px !important;
}

.mobile_card {
    margin-top: 100px;
}

.desktop_table {
    padding-left: 30px;
    padding-right: 30px;
}

.mobile_sort {
    margin-top: -40px;
}

.v-data-table::v-deep th {
    font-size: 16px !important;
    font-weight: bold;
}

::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
    border-style: none !important;
}

.assign {
    background-color: #DEF3FF !important;
    color: #347CE9 !important;
    justify-content: center;
}

.placeholder {
    height: 20px;
    font-size: 12px;
    border-radius:4px;
    padding-left:10px;
}

.placeholder:hover {
    background-color:rgb(233, 233, 233);
}

::v-deep .placeholder input {
    cursor: pointer !important;
}

::v-deep .placeholder .v-input__slot, ::v-deep .placeholder .v-input__control {
    min-height:10px!important;
    height:20px!important;
}

::v-deep .placeholder .v-input__control>.v-input__slot:before, ::v-deep .placeholder .v-input__control>.v-input__slot:after {
  border-style:none!important;
}
</style>
