<template>
    <v-container fluid>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                width="90%"
                elevation="0"
            >
                <v-card-title style="word-break: break-word;">
                    <h5
                        v-if="!isMobile()"
                        style="color: #4374F3 !important; font-size: 20px; font-family: 'Montserrat', sans-serif; font-weight: 500;"
                    >Would you like to get an estimate and learn about how to pay towards the cost of your funeral?</h5>
                    <small
                        v-else
                        style="font-size: 20px; font-family: 'Montserrat', sans-serif; font-weight: 500;"
                    >
                        Would you like to get an estimate and learn about how to pay towards the cost of your funeral?
                    </small>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col
                            cols="4"
                            v-for="(item, itemindex) in items"
                            :key="itemindex"
                        >
                            <v-card
                                elevation="0"
                                style="cursor: pointer;"
                                :color="item.active === 1 ? '#DCC397' : ''"
                                @click="set_active(item)"
                            >
                                <v-card-title
                                    class="justify-center"
                                >
                                    <v-img
                                        :src="item.image"
                                        contains
                                        max-width="80" max-height="80"
                                    />
                                </v-card-title>
                                <v-card-subtitle
                                    class="text-center mt-5"
                                >
                                    <strong
                                        :style="item.active === 1 ? 'color: white; font-size:14px; font-family: Montserrat, sans-serif;' : 'font-size:14px; font-family: Montserrat, sans-serif;' "
                                    >
                                        {{item.label}}
                                    </strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card
                    elevation="0"
                >
                    <v-card-actions>
                        <v-btn
                            text
                            @click="back_step"
                            style="textTransform: none !important;"
                        >
                            <v-img
                                :src="require('../../../../assets/chev_left_dark.svg')"
                            />
                            Back
                        </v-btn>
                        <v-spacer/>
                        <v-btn
                            v-if="Object.keys(get_funeral_cost).length !== 0"
                            color="primary"
                            style="textTransform: none !important;"
                            class="pl-10 pr-10"
                            @click="next_step"
                        >
                            Next
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapGetters } from 'vuex'
  export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        items: [
            {
                label:          'Yes',
                image:          require('../../../../assets/yes.png'),
                active:         0,
                selected:       false
            },
            {
                label:          'No',
                image:          require('../../../../assets/no.png'),
                active:         0,
                selected:       false
            },
            {
                label:          'Not sure',
                image:          require('../../../../assets/not_sure_option.png'),
                active:         0,
                selected:       false
            },
        ]
    }),
    mounted () {
    },
    created () {
    },
    computed: {
        ...mapGetters({
            get_funeral_cost:       'survey/get_funeral_cost'
        })
    },
    methods: {
        set_active(data){
            this.items.forEach(q => {
                q.active = 0
                q.selected = false
            })
            data.active = 1
            data.selected = true

            let tp = {
                answer: data,
                choice: 'funeral_cost'
            }
            this.$store.dispatch('survey/set_answer_r', tp)
        },
        back_step(){
            this.$emit('back')
        },
        next_step(){
            this.$emit('next')
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
  }
</script>
  
<style scoped lang="scss">
</style>