<template>
    <div>
        <v-app-bar
            height="100"
            white
            color="#fff"
            elevation="0"
            app
        >
            <v-img
                :src="require('../assets/mfw.png')"
                contain
                max-height="100"
                max-width="100"
                class="mx-2"
                @click="$router.push({name: '/'})"
            />
        </v-app-bar>
        <main class="main">
            <slot/>
        </main>
    </div>
</template>

<script>
export default {
    computed: {
    },
    methods: {
    }
}
</script>

<style scoped>
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: inherit;
        height: inherit;
        margin-right: 15px;
        cursor: pointer;
        margin-top: 7px;
    }
</style>