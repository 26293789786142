import TermsConditions from '../TermsConditions.vue'

export default [
    {
        path: '/terms-and-conditions',
        name: '/terms-and-conditions',
        component: TermsConditions,
        meta: {
            layout: 'public-layout'
        }
    },
]