<template>
    <v-container fluid>
        <h3 style="font-size:25px;color:#172C54">Share your Wishes</h3>
        <v-card elevation="0" style="border:solid 2px #F3F3F3;border-radius:10px;" class="mb-3 mt-8">
            <v-card-subtitle v-if="get_invited.length === 0" style="font-size:18px;word-break: break-word;">
                You haven't shared your wishes yet. Share your wishes so someone knows your plans.
            </v-card-subtitle>
            <v-card-subtitle v-else style="font-size:18px;word-break: break-word;">
                Share your wishes so someone knows your plans.
            </v-card-subtitle>
            <v-card-subtitle style="font-size:18px;color:#172C54;word-break: break-word;">
                <b>You shared your wishes to {{ get_invited.length }} people.</b>
            </v-card-subtitle>
            <v-card-subtitle v-if="get_invited.length > 0">
                <v-row>
                    <v-col :cols="isMobile() ? 12 : 6" v-for="(user, userindex) in get_invited" :key="userindex">
                        <v-list-item two-line>
                            <v-avatar v-if="user.get_client_info !== null && user.get_client_info.profile_picture !== null"
                                size="55" color="blue"
                                style="position: relative; right: 18px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                <v-img contain :src="`${api_url}${user.get_client_info.profile_picture}`" />
                            </v-avatar>
                            <v-avatar v-else size="55" color="blue"
                                style="position: relative; right: 18px;border:solid white 1px!important;box-shadow: 0px 1px 2px rgba(0,0,0,0.6);">
                                <strong style="fontsize: 20px;color:white;">{{ user.email[0] }}</strong>
                            </v-avatar>
                            <v-list-item-content>
                                <v-list-item-title style="fontSize: 16;" class="ml-3">
                                    <strong>{{ user.email }}</strong>
                                </v-list-item-title>
                                <v-list-item-subtitle class="ml-3">
                                    <label v-if="user.client_id !== null">
                                        Has profile
                                    </label>
                                    <label v-else>
                                        Has no profile yet
                                    </label>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-card-subtitle>
                <v-btn dark
                    style="text-transform: none !important;background-color:#172C54;border-radius:10px;font-size: 16px;"
                    class="pt-6 pb-6" @click="share_model_change">
                    Share my wishes
                    <v-img :src="require('../../../assets/chev_right_white.svg')" class="ml-3" />
                </v-btn>
            </v-card-subtitle>
            <!-- <v-card-title
                class="mt-10"
            >
                <h3>Profile shared with me</h3>
            </v-card-title>
            <v-card-subtitle>
                Someone haven't shared their profile with you yet.
            </v-card-subtitle> -->
        </v-card>
        <v-row justify="center">
            <v-dialog v-model="share_model" persistent max-width="560">
                <v-card>
                    <v-card-title>

                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field filled v-model="s.firstname" dense label="First Name"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field filled v-model="s.lastname" dense label="Last Name"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field filled v-model="s.email" dense label="Email"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field filled v-model="s.mobile" dense label="Mobile"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text class="pl-10 pr-10" @click="cancel_dialog_share">
                            Cancel
                        </v-btn>
                        <v-btn color="primary" @click="share_profile_fn" style="textTransform: none !important;"
                            class="pl-6 pr-6">
                            Share my wishes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        share_model: false,
        s: {
            firstname: null,
            lastname: null,
            email: null,
            mobile: null,
        },
        api_url: null,
    }),
    async mounted() {
        await this.$store.dispatch('client_activity/fetch_invited')
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL;
    },
    computed: {
        ...mapGetters({
            user: "auth/get_user",
            get_share_profile_search_result: 'client_activity/get_share_profile_search_result',
            get_invited: 'client_activity/get_invited'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        share_model_change() {
            this.share_model = true
        },
        cancel_dialog_share() {
            this.s.email = null
            this.share_model = false
        },
        async share_profile_fn() {
            this.showSnackBar('Please wait...')
            let new_progress = this.user.progress
            if (new_progress['tab_1'] == false) {
                new_progress['percentage'] += 33
                if (new_progress['percentage'] == 99) {
                    new_progress['percentage'] = 100
                }
                new_progress['tab_1'] = true
            }
            await this.$axios.post('/auth/client/profile_share/search_email', {
                email: this.s.email,
                firstname: this.s.firstname,
                lastname: this.s.lastname,
                mobile: this.s.mobile,
                progress: new_progress
            })
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('auth/fetch_user')
                        this.showSnackBar('Invite has been sent')
                        this.s.email = null
                        this.s.firstname = null
                        this.s.lastname = null
                        this.s.mobile = null
                        this.share_model = false
                        this.$store.dispatch('client_activity/fetch_invited')
                        this.$store.dispatch('client_profile/fetch_invited')
                    }
                    else {
                        this.showSnackBar(data.message)
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>