<template>
  <v-row justify="center" align="center">
    <v-card width="100%" class="mt-5 py-5" elevation="0"
      style="background-color: #172C54;border-radius:0px;padding-left:8%;padding-right:4%;">
      <v-card-text class="mb-5">
        <v-row>
          <v-col :cols="isMobile() ? 12 : 4">
            <v-img :src="require('../../assets/mfw.png')" width="250" class="mb-7" @click="home_tab()"
              style="cursor:pointer;">
            </v-img>
            <p style="color:white;font-weight:300;font-size:16px;margin-bottom:0px;">
              The Administration Manager, My Farewell Wishes,
            </p>
            <p style="color:white;font-weight:300;font-size:16px;">
              Hall Street, Kingscourt, Co. Cavan. A82 P4V8
            </p>
            <p style="color:white;font-weight:300;font-size:16px;">
              Company Registration Number: 700677
            </p>
            <p style="color:white;font-weight:300;font-size:16px;">
              info@myfarewellwishes.ie
            </p>
          </v-col>
          <v-col :cols="isMobile() ? 12 : 8">
            <v-row :class="isMobile() ? 'mobile_row' : 'desktop_row'">
              <v-col cols="6" v-if="user.role_id != 4">
                <p style="color:white;font-weight:800;font-size:14px;">
                  Activity
                </p>
                <p style="font-weight:300;font-size:14px;color:white;cursor: pointer;text-decoration:underline;"
                  @click="change_tabs_from_footer(1, 0)">
                  My Funeral Wishes
                </p>
                <p style="font-weight:300;font-size:14px;color:white;cursor: pointer;text-decoration:underline;"
                  @click="change_tabs_from_footer(1, 1)">
                  Profile Sharing
                </p>
                <p style="font-weight:300;font-size:14px;color:white;cursor: pointer;text-decoration:underline;"
                  @click="change_tabs_from_footer(1, 2)">
                  Request a Funeral Cost Estimate
                </p>
                <p style="font-weight:300;font-size:14px;color:white;cursor: pointer;text-decoration:underline;"
                  @click="change_tabs_from_footer(1, 3)">
                  Request a Certificate of Your Wishes
                </p>
                <p style="font-weight:300;font-size:14px;color:white;cursor: pointer;text-decoration:underline;"
                  @click="change_tabs_from_footer(1, 4)">
                  Funeral Planning Guides
                </p>
                <!--<p style="font-weight:300;font-size:14px;color:white;cursor: pointer;text-decoration:underline;"
                  @click="change_tabs_from_footer(1, 5)">
                  Funeral Notice
                </p>-->
              </v-col>
              <v-col cols="6">
                <p style="color:white;font-weight:800;font-size:14px;">
                  Our Company
                </p>
                <p style="font-weight:300;font-size:14px;">
                  <a style="color:white" href="https://myfarewellwishes.ie/about-us/" target="#">About Us</a>
                </p>
                <p style="font-weight:300;font-size:14px;">
                  <a style="color:white" href="https://myfarewellwishes.ie/frequently-asked-questions/"
                    target="#">FAQs</a>
                </p>
                <p style="font-weight:300;font-size:14px;">
                  <a style="color:white" href="https://myfarewellwishes.ie/contact-us/" target="#">Contact Us</a>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider style="width: 100%;background-color:white!important" class="mb-5"></v-divider>
          </v-col>
          <v-col :cols="isMobile() ? 12 : 4">
            <p style="color:white;font-size:14px;">
              © 2022 My Farewell Wishes. All rights reserved.
            </p>
          </v-col>
          <v-col :cols="isMobile() ? 12 : 4" class="d-flex">
            <p style="color:white;font-size:14px;" class="px-3">
              <a style="color:white" @click="terms_modal = true"><u>Terms & Conditions</u></a>
            </p>
            <p style="color:white;font-size:14px;" class="px-3">
              <a style="color:white" @click="privacy_modal = true"><u>Privacy Policy</u></a>
            </p>
          </v-col>
          <v-col :cols="isMobile() ? 12 : 4" class="d-flex" style="margin-top:-10px;">
            <p class="ml-15 mr-2 mt-2" style="color:white;font-size:16px;font-weight:800">
              Follow Us
            </p>
            <v-btn class="ml-5" text target="#" href="https://web.facebook.com/myfarewellwishesireland/">
              <v-icon style="color:white;">mdi-facebook</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <PrivacyTerms :terms_modal="terms_modal" :privacy_modal="privacy_modal" @closePrivacyTermsModal="closePrivacyTermsModal" />
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import PrivacyTerms from "./PrivacyTerms.vue"
export default {
  components: {
    PrivacyTerms
  },
  props: [],
  data: () => ({
    terms_modal: false,
    privacy_modal: false,
  }),
  mounted() { },
  created() { },
  computed: {
    ...mapGetters({
      user: "auth/get_user",
    }),
  },
  methods: {
    async change_tabs_from_footer(tab_index, activity_index) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      await this.$store.dispatch('client_activity/set_activity_tab', activity_index)
      await this.$store.dispatch("client_dboard/set_tab", tab_index)
    },
    closePrivacyTermsModal() {
      this.terms_modal = false
      this.privacy_modal = false
    },
    async home_tab() {
      await this.$store.dispatch("client_dboard/set_tab", 0)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.newletter {
  border-radius: 5px;
  margin-top: -2px;
}

::v-deep .newletter .v-input__slot {
  background-color: white;
  padding: 7px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .v-input__control>.v-input__slot:before,
::v-deep .v-input__control>.v-input__slot:after {
  border-style: none !important;
}

.desktop_row {
  margin-left: 10%;
  margin-top: 5%;
}
</style>
