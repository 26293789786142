import ClientComponent from '../ClientComponent.vue'
import ClientProfile from '../ClientProfile.vue'

export default [
    {
        path: '/admin_clients',
        name: '/admin_clients',
        component: ClientComponent,
        meta: {
            layout: 'admin-layout'
        }
    },
    {
        path: '/admin_client_profile',
        name: '/admin_client_profile',
        component: ClientProfile,
        meta: {
            layout: 'admin-layout'
        }
    }
]