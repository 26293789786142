<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-card width="90%" elevation="0">
                <v-card-title style="word-break: break-word;">
                    <h5 v-if="!isMobile()"
                        style="color: #4374F3 !important; font-size: 20px;  font-family: 'Montserrat', sans-serif; font-weight: 500;">
                        Would you like to have a wake / public reposing?</h5>
                    <small v-else style="font-size: 20px;  font-family: 'Montserrat', sans-serif; font-weight: 500;">
                        Would you like to have a wake / public reposing?
                    </small>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col :cols="isMobile() ? '6' : '3'" v-for="(item, itemindex) in items" :key="itemindex">
                            <v-card elevation="0" style="cursor: pointer;" :color="item.active === 1 ? '#DCC397' : ''"
                                @click="set_active(item)">
                                <v-card-title class="justify-center">
                                    <v-img :src="item.image" max-width="90" max-height="90" />
                                </v-card-title>
                                <v-card-subtitle class="text-center mt-5">
                                    <strong
                                        :style="item.active === 1 ? 'color: white; font-size:14px; font-family: Montserrat, sans-serif;' : 'font-size:14px; font-family: Montserrat, sans-serif;'">
                                        {{ item.label }}
                                    </strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card elevation="0" class="scroll-to">
                    <v-card-text
                        v-if="get_wake.label === 'Yes' || get_wake.label === 'Yes but private to family and close friends'">
                        <strong style="font-family: 'Montserrat', sans-serif; font-weight: 500;">Your preferred wake
                            location</strong>
                        <v-radio-group v-model="radioGroup" @change="radio_check_fn">
                            <v-radio v-for="(n, i) in radio_group_item" :key="i" :label="n.label" :value="n.value"
                                style="font-family: 'Montserrat', sans-serif; font-weight: 500;"></v-radio>
                        </v-radio-group>
                        <v-text-field v-model="other_answer" v-if="has_other" placeholder="Location..."
                            style="font-family: 'Montserrat', sans-serif; font-weight: 500;">
                        </v-text-field>
                        <v-radio-group v-model="radioGroup1" @change="radio_check_fn1" v-if="has_other">
                            <label style="font-family: 'Montserrat', sans-serif;">Open Coffin</label>
                            <v-radio v-for="(n, i) in radio_group_item1" :key="i" :label="n.label" :value="n.value"
                                style="font-family: 'Montserrat', sans-serif; font-weight: 500;"></v-radio>
                        </v-radio-group>
                    </v-card-text>
                </v-card>
                <v-card elevation="0">
                    <v-card-actions>
                        <v-btn text @click="back_step" style="text-transform: none !important;">
                            <v-img :src="require('../../../../assets/chev_left_dark.svg')" />
                            Back
                        </v-btn>
                        <v-spacer />
                        <v-btn v-if="Object.keys(get_wake).length !== 0" color="primary"
                            style="text-transform: none !important;" class="pl-10 pr-10" @click="next_step">
                            Next
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        items: [
            {
                label: 'Yes',
                image: require('../../../../assets/yes.png'),
                active: 0,
                selected: false
            },
            {
                label: 'Yes but private to family and close friends',
                image: require('../../../../assets/yes.png'),
                active: 0,
                selected: false
            },
            {
                label: 'No',
                image: require('../../../../assets/no.png'),
                active: 0,
                selected: false
            },
            {
                label: 'Not sure',
                image: require("../../../../assets/not_sure_option.png"),
                active: 0,
                selected: false
            }
        ],
        radio_group_item: [
            {
                label: 'Family Home',
                value: 'Family Home'
            },
            {
                label: 'Funeral Home',
                value: 'Funeral Home'
            },
            {
                label: 'Combination of Both',
                value: 'Combination of Both'
            }
        ],
        radio_group_item1: [
            {
                label: 'Yes',
                value: 'Yes'
            },
            {
                label: 'No',
                value: 'No'
            }
        ],
        radioGroup: null,
        radioGroup1: null,
        has_other: false,
        other_answer: null,
        answer_to_question: null
    }),
    mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_wake: 'survey/get_wake'
        })
    },
    methods: {
        async scrollToElement() {
            await this.$nextTick();
            document.getElementsByClassName('scroll-to')[2].scrollIntoView({ behavior: 'smooth' })
        },
        set_active(data) {
            this.scrollToElement()
            this.items.forEach(q => {
                q.active = 0
                q.selected = false
            })
            data.active = 1
            data.selected = true

            let tp = {
                answer: data,
                choice: 'wake'
            }

            this.$store.dispatch('survey/set_answer_r', tp)
        },
        next_step() {
            let tp = {
                answer: {
                    label: this.get_wake.label,
                    image: this.get_wake.image,
                    active: this.get_wake.active,
                    selected: this.get_wake.selected,
                    answer: this.other_answer,
                    choice: this.radioGroup,
                    coffin: this.radioGroup1
                },
                choice: 'wake'
            }
            this.$store.dispatch('survey/set_answer_r', tp)
            this.$emit('next')
        },
        back_step() {
            this.$emit('back')
        },
        radio_check_fn() {
            // this.has_other = true
            if (this.radioGroup === 'Family home' || this.radioGroup === 'Funeral Home') {
                this.has_other = true
                return
            }
            this.has_other = false
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>