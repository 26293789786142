<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-card elevation="2" width="560px" class="mt-15">
                <v-card-title class="text-small">
                    <small>Login</small>
                </v-card-title>
                <v-card-text>
                    <v-col cols="12">
                        <v-text-field label="email or username" prepend-inner-icon="mdi-account" dense outlined
                            type="text" v-model="l.username">
                        </v-text-field>
                        <v-text-field label="password" prepend-inner-icon="mdi-lock" dense outlined v-model="l.password"
                            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show_password = !show_password" :type="show_password ? 'text' : 'password'"
                            @keyup.enter="login">
                        </v-text-field>
                        <v-card-actions>
                            <!-- <label>Forgot Password</label> -->
                            <v-spacer />
                            <v-btn color="#172C54" dark class="pl-15 pr-15" @click="login">
                                Login
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        show_password: false,
        l: {
            username: null,
            password: null
        }
    }),
    mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async login() {
            await this.$axios.post('/non_auth/login', {
                email: this.l.username,
                password: this.l.password
            })
                .then(({ data }) => {
                    if (data.response) {
                        if (data.message) {
                            this.showSnackBar(data.message)
                        }
                        this.$store.dispatch('auth/set_user', data.data)
                        this.$store.dispatch('auth/set_token', data.token)
                        switch (data.data.role_id) {
                            case 1:
                                this.$router.push({ name: '/admin_dashboard' })
                                break
                            case 2:
                                this.$router.push({ name: '/funeral-director' })
                                break
                            case 3:
                                this.$router.push({ name: '/client-dashboard' })
                                this.$store.dispatch("client_dboard/set_tab", 0)
                                break
                            case 4:
                                this.$router.push({ name: '/client-dashboard' })
                                this.$store.dispatch("client_dboard/set_tab", 2)
                                break
                            case 5:
                                this.$router.push({ name: 'Solicitor Dashboard' })
                                break
                            case 6:
                                this.$router.push({ name: 'Edit Will' })
                                break
                        }
                    } else {
                        this.showSnackBar(data.message)
                    }
                })
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>