import FuneralDirectorDocuments from '../FuneralDirectorDocuments.vue'

export default [
    {
        path: '/fd_documents',
        name: '/fd_documents',
        component: FuneralDirectorDocuments,
        meta: {
            layout: 'funeral-director-layout'
        }
    }
]