import Vue from 'vue'

export default{
    namespaced: true,
    state: {
        survey: [],
        my_survey: []
    },
    actions: {
        async get_active_survey({commit}){
            await Vue.axios.get('auth/fd_survey/get_survey')
            .then(({data}) => {
                // let active_s = []
                commit('set_survey', data.survey)
                // data.active_survey.forEach((q, i) => {
                //     if(i === 0){
                //         active_s.push(q)
                //     }
                // })
                commit('set_my_survey', data.active_survey)
            })
        }
    },
    mutations: {
        set_survey(state, payload){
            state.survey = [...payload]
        },
        set_my_survey(state, payload){
            state.my_survey = [...payload]
        }
    },
    getters: {
        get_survey : state => state.survey,
        get_my_survey : state => state.my_survey
    }
}