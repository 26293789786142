import PreplanningClients from '../PreplanningClients.vue'
import FuneralClients from '../FuneralClients.vue'
import ClientProfile from '../ClientProfile.vue'

export default [
    {
        path: '/preplanning-clients',
        name: '/preplanning-clients',
        component: PreplanningClients,
        meta: {
            layout: 'funeral-director-layout'
        }
    },
    {
        path: '/funeral-clients',
        name: '/funeral-clients',
        component: FuneralClients,
        meta: {
            layout: 'funeral-director-layout'
        }
    },
    {
        path: '/client-profile',
        name: '/client-profile',
        component: ClientProfile,
        meta: {
            layout: 'funeral-director-layout'
        }
    }
]