import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        templates: []
    },
    actions: {
        async fetch_templates({ commit }) {
            await Vue.axios.get('/auth/admin/funeral_notice/get_templates')
                .then(({ data }) => {
                    commit('set_templates', data.data)
                })
        },
    },
    mutations: {
        set_templates(state, payload) {
            state.templates = [...payload]
        },
    },
    getters: {
        get_templates: state => state.templates,
    }
}