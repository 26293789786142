<template>
    <v-container fluid>
        <v-card
            elevation="0"
        >
            <v-card-title>
                Review and Pay
            </v-card-title>
            <v-card-actions>
                <v-btn
                    text
                    @click="back"
                >
                    <v-img
                        :src="require('../../../../../assets/chev_left_dark.svg')"
                        contain
                        max-width="35"
                        max-height="35"
                    />
                    Back
                </v-btn>
                <v-spacer/>
                <v-btn
                    dark
                    class="pl-10 pr-10 pt-5 pb-5"
                    style="textTransform: none !important;"
                    @click="next_step"
                >
                    Save and continue
                    <v-img
                        class="ml-3"
                        :src="require('../../../../../assets/chev_right_white.svg')"
                    />
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    back(){
        this.$store.dispatch('client_activity/set_last_will_el', 6)
    },
    next_step(){
        this.$router.push({
            name: '/client-dashboard'
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>