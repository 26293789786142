<template>
    <v-container fluid>
        <v-row justify="center" align="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <v-img :src="require('../../assets/fd_clients.svg')" class="mr-3" contain max-width="35"
                        max-height="35" />
                    <strong style="font-size:26px;white-space:pre-line;word-break:break-word;">Funeral Notice Requests</strong>
                    <v-spacer />
                </v-card-title>
            </v-card>
            <v-card width="90%" style="border-radius: 16px" elevation="0"
                :class="isMobile() ? 'mobile_card' : 'desktop_card'">
                <v-card-text class="mt-5">
                    <v-data-table :class="isMobile() ? 'mobile_table' : 'desktop_table'" :headers="header_templates"
                        :items="get_templates" :items-per-page="7" :mobile-breakpoint="0" hide-default-header
                        :sort-by.sync="sort_by" :sort-desc.sync="sortDesc" hide-default-footer>
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr v-if="!isMobile()">
                                    <th style="padding-bottom:30px;padding-top:10px;width:25%" v-for="h in headers"
                                        :key="h.index">
                                        <div style="text-align:center;" v-if="h.text">
                                            <strong>{{ h.text }}</strong>
                                        </div>
                                    </th>
                                    <th style="padding-bottom:30px;padding-top:10px">
                                        <div style="text-align:end;">
                                            <v-btn dark color="#172C54" class="pl-5 pr-5 pt-2 pb-2 mr-3"
                                                style="height:40px;text-transform: none !important"
                                                @click="create_template">
                                                Create new
                                            </v-btn>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="{ item, headers }">
                            <tr class="mx-5" style="cursor: pointer;" v-if="item != null && !isMobile()">
                                <td style="width:80%;" @click="open_template(item)">
                                    <v-layout justify-center align-center>
                                        {{ item.name }}
                                    </v-layout>
                                </td>
                                <td style="width:10%;">
                                    <v-layout justify-center align-center v-if="item.active && item.id > 1">
                                        <v-btn disabled @click="set_active_template(item)">Active</v-btn>
                                    </v-layout>
                                    <v-layout justify-center align-center v-else-if="item.id > 1">
                                        <v-btn class="primary" @click="set_active_template(item)">Set Active</v-btn>
                                    </v-layout>
                                </td>
                            </tr>
                            <tr class="mx-5" style="background-color: transparent;" v-if="item != null && isMobile()"
                                @click="open_template(item)">
                                <td style="width:100%" class="py-5">
                                    <v-row>
                                        <v-col cols="4" style="height:auto;" class="d-flex">
                                            <v-layout align-center>
                                                <h4>{{ headers[0].text }}</h4>
                                            </v-layout>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-layout justify-center align-center>
                                                {{ item.name }}
                                            </v-layout>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            View
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="open_template_dialog" persistent max-width="1000">
                <v-card v-if="selected_template != null">
                    <v-card-title class="text-h5">
                        <div style="font-size:16px;" v-if="selected_template.id == 1">{{ selected_template.name }}</div>
                        <v-text-field v-else filled v-model="selected_template.name"></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="open_template_dialog = false">
                            Close <v-icon class="mr-2">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-row v-if="selected_template.id == 1">
                            <v-col cols="12">
                                <v-textarea readonly filled v-model="selected_template.template" rows="10" dense>
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col :cols="isMobile() ? 12 : 9">
                                <v-textarea filled v-model="selected_template.template" rows="10" dense>
                                </v-textarea>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 3"
                                style="height:300px;overflow-y:scroll;padding-top:0px!important;padding-bottom:0px!important;margin-top:12px;margin-bottom:12px;">
                                Details of the Deceased
                                <v-text-field class="placeholder" dense
                                    v-for="contact in placeholders_contact" :key="contact.index" readonly
                                    @focus="$event.target.select()" :value="contact"></v-text-field>
                                <br>
                                Family of the Deceased
                                <v-text-field class="placeholder" dense
                                    v-for="family in placeholders_family" :key="family.index" readonly
                                    @focus="$event.target.select()" :value="family"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn v-if="selected_template.id > 1" color="red darken-1" text @click="delete_template">
                            <v-icon class="mr-2">mdi-delete</v-icon>
                            Delete
                        </v-btn>
                        <v-btn v-if="selected_template.id == 1" color="#1976D2" dark class="pl-10 pr-10"
                            @click="clone_template">
                            <v-icon class="mr-2">mdi-plus</v-icon>
                            Create new from template
                        </v-btn>
                        <v-btn v-else color="#1976D2" dark class="pl-10 pr-10" @click="edit_template">
                            <v-icon class="mr-2">mdi-plus</v-icon>
                            Save changes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {
    },
    props: [],
    data: () => ({
        img_url: null,
        header_templates: [
            { text: "Name" },
        ],
        sort_by: "created_at",
        sortDesc: true,
        filter_by: null,
        open_template_dialog: false,
        selected_template: null,
        placeholders_contact: [
            "{{name}}", "{{address}}", "{{town}}", "{{county}}", "{{country}}", "{{eircode}}", "{{pronoun}}",
            "{{date_of_death}}", "{{circumstance_of_death}}", "{{place_of_death}}",
        ],
        placeholders_family: [
            "{{family.predeceased_by}}", "{{family.significant_other_type}}", "{{family.significant_other}}", "{{family.father}}", "{{family.mother}}",
            "{{family.brothers}}", "{{family.sisters}}", "{{family.sons}}", "{{family.daughters}}", "{{family.sons_in_law}}", "{{family.daughters_in_law}}",
            "{{family.grandchildren}}", "{{family.greatgrandchildren}}", "{{family.other_people}}",
        ],
    }),
    async mounted() {
        await this.$store.dispatch("fd_funeral_notice/fetch_templates")
    },
    created() {
        this.img_url = process.env.VUE_APP_URL;
    },
    computed: {
        ...mapGetters({
            get_templates: "fd_funeral_notice/get_templates",
            get_user: "auth/get_user",
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                "auth/setMessage",
                { show: true, message: message },
                { root: 1 }
            );
        },
        open_template(item) {
            this.selected_template = item
            this.open_template_dialog = true
        },
        async create_template() {
            await this.$axios.post('/auth/admin/funeral_notice/create_template')
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Created successfully")
                    }
                })
        },
        async clone_template() {
            let payload = {
                name: this.selected_template.name + ' copy',
                template: this.selected_template.template
            }
            await this.$axios.post('/auth/admin/funeral_notice/clone_template', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.selected_template = null
                        this.open_template_dialog = false
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Created successfully")
                    }
                })
        },
        async edit_template() {
            let payload = {
                id: this.selected_template.id,
                name: this.selected_template.name,
                template: this.selected_template.template
            }
            await this.$axios.patch('/auth/admin/funeral_notice/edit_template', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.selected_template = null
                        this.open_template_dialog = false
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Edited successfully")
                    }
                })
        },
        async set_active_template(item) {
            let payload = {
                id: item.id,
            }
            await this.$axios.patch('/auth/admin/funeral_notice/set_active_template', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.selected_template = null
                        this.open_template_dialog = false
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Set active successfully")
                    }
                })
        },
        async delete_template() {
            await this.$axios.delete(`/auth/admin/funeral_notice/delete_template/${this.selected_template.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.selected_template = null
                        this.open_template_dialog = false
                        this.$store.dispatch("fd_funeral_notice/fetch_templates")
                        this.showSnackBar("Deleted successfully")
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
.liitme {
    border-radius: 5px;
    margin-top: -2px;
}

::v-deep .liitme .v-input__slot {
    background-color: white;
    padding: 7px;
}

table {
    border-collapse: collapse;
}

// remove border
table td,
table th {
    height: 40px; //change the height
}

.mobile_card {
    margin-top:50px;
}

.mobile_table td {
    height: 100px !important;
}

.desktop_table {
    padding-left: 30px;
    padding-right: 30px;
}

.placeholder {
    height: 20px;
    font-size: 12px;
    border-radius:4px;
    padding-left:10px;
}

.placeholder:hover {
    background-color:rgb(233, 233, 233);
}

::v-deep .placeholder input {
    cursor: pointer !important;
}

::v-deep .placeholder .v-input__slot, ::v-deep .placeholder .v-input__control {
    min-height:10px!important;
    height:20px!important;
}

::v-deep .placeholder .v-input__control>.v-input__slot:before, ::v-deep .placeholder .v-input__control>.v-input__slot:after {
  border-style:none!important;
}
</style>
