import FuneralPayment from '../FuneralPayment.vue'
import PaymentSuccessful from '../PaymentSuccessful.vue'

export default [
    {
        path: '/test-payments',
        name: '/test-payments',
        component: FuneralPayment,
        meta: {
            layout: 'blank-layout'
        }
    },
    {
        path: '/payment-successful',
        name: '/payment-successful',
        component: PaymentSuccessful,
        meta: {
            layout: 'public-layout'
        }
    }
]