<template>
    <v-container fluid>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                width="90%"
                elevation="0"
            >
                <v-card-title style="word-break: break-word;">
                    <label
                        v-if="!isMobile()"
                        style="color: #4374F3 !important; font-size: 20px; font-family: 'Montserrat', sans-serif; font-weight: 500;"
                    >Would you like your ceremony to be...</label>
                    <small
                        v-else
                        style="font-size: 20px; font-family: 'Montserrat', sans-serif; font-weight: 500;"
                    >
                        Would you like your ceremony to be...
                    </small>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col
                            :cols="isMobile() ? '6' : '4'"
                            v-for="(item, itemindex) in items"
                            :key="itemindex"
                        >
                            <v-card
                                elevation="0"
                                style="cursor: pointer;"
                                :color="item.active === 1 ? '#DCC397' : ''"
                                @click="set_active(item)"
                                width="100%"
                            >
                                <v-card-title
                                    class="justify-center"
                                >
                                    <v-img
                                        :src="item.image"
                                        contains
                                        max-width="90" max-height="90"
                                    />
                                </v-card-title>
                                <v-card-subtitle
                                    class="text-center mt-5" style="padding:0;"
                                >
                                    <small
                                        v-html="item.label" :style="item.active === 1 ? 'color: white; font-size:14px; font-family: Montserrat, sans-serif; text-align: justify center; font-weight: 600' : 'font-size:14px; font-family: Montserrat, sans-serif; text-align: justify center; font-weight: 600' "
                                    >
                                    </small>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card class="scroll-to"
                    elevation="0"
                >
                    <v-card-text
                        v-if="get_ceremony.label === 'Religious / Traditional'"
                    >
                        <strong
                            style="font-family: Montserrat, sans-serif; font-weight: 500"
                        >Do you have a preferred ceremony location?</strong>
                        <v-text-field
                            v-model="answer_to_question"
                            placeholder="Write your answer here..."
                            style="font-family: Montserrat, sans-serif;"
                        >
                        </v-text-field>
                    </v-card-text>
                    <v-card-text
                        v-if="get_ceremony.label === 'Non-Religious / Something Different'"
                    >
                        <strong
                            style="font-family: Montserrat, sans-serif; font-weight: 500"
                        >What kind of ceremony would you prefer?</strong>
                        <v-radio-group 
                            v-model="radioGroup"
                        >
                            <v-radio
                                v-for="(n, i) in radio_group_item"
                                :key="i"
                                :label="n.label"
                                :value="n.value"
                                style="font-family: Montserrat, sans-serif;"
                            ></v-radio>
                        </v-radio-group>
                    </v-card-text>
                </v-card>
                <v-card
                    elevation="0"
                >
                    <v-card-actions>
                        <v-btn
                            text
                            @click="back_step"
                            style="textTransform: none !important;"
                        >
                            <v-img
                                :src="require('../../../../assets/chev_left_dark.svg')"
                            />
                            Back
                        </v-btn>
                        <v-spacer/>
                        <v-btn
                            v-if="Object.keys(get_ceremony).length !== 0"
                            color="primary"
                            style="textTransform: none !important;"
                            class="pl-10 pr-10"
                            @click="next_step"
                        >
                            Next
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapGetters } from 'vuex'
  export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        items: [
            {
                label:          'Religious / Traditional',
                image:          require('../../../../assets/traditional.png'),
                active:         0,
                selected:       false
            },
            {
                label:          'Non-Religious / Something Different',
                image:          require('../../../../assets/flower.png'),
                active:         0,
                selected:       false
            },
            {
                label:          'No Ceremony',
                image:          require('../../../../assets/no.png'),
                active:         0,
                selected:       false
            }
        ],
        answer_to_question: null,
        radio_group_item: [
            {
                label:          'Civil Ceremony / Celebration of Life Ceremony',
                value:          'Civil Ceremony / Celebration of Life Ceremony'
            },
            {
                label:          'Humanist',
                value:          'Humanist'
            },
            {
                label:          'Ceremony at crematorium only (if you have chosen to be cremated)',
                value:          'Ceremony at crematorium only (if you have chosen to be cremated)'
            },
            {
                label:          'Ceremony at graveside only (if you have chosen to be burried)',
                value:          'Ceremony at graveside only (if you have chosen to be burried)'
            }
        ],
        radioGroup: null
    }),
    mounted () {
    },
    created () {
    },
    computed: {
        ...mapGetters({
            get_ceremony:           'survey/get_ceremony'
        })
    },
    methods: {
        async scrollToElement() {
            await this.$nextTick();
            document.getElementsByClassName('scroll-to')[1].scrollIntoView({ behavior: 'smooth' })
        },
        set_active(data) {
            this.scrollToElement()
            this.items.forEach(q => {
                q.active = 0
                q.selected = false
            })
            data.active = 1
            data.selected = true

            let tp = {
                answer: data,
                choice: 'ceremony'
            }

            this.$store.dispatch('survey/set_answer_r', tp)
            this.answer_to_question = null
        },
        back_step(){
            this.$emit('back')
        },
        next_step(){
            let tp = {
                answer:     {
                    label:      this.get_ceremony.label,
                    image:      this.get_ceremony.image,
                    active:     this.get_ceremony.active,
                    selected:   this.get_ceremony.selected,
                    answer:     this.answer_to_question,
                    chosen:     this.radioGroup,

                },
                choice:     'ceremony'
            }
            this.$store.dispatch('survey/set_answer_r', tp)
            this.$emit('next')
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
  }
</script>
  
<style scoped lang="scss">
</style>